import * as React from 'react';

import { Feature } from '../../types/interfaces/Feature';

interface FeatureFormProps {
  feature?: Feature | null;
  onCancel: () => void;
  onSubmit: (data: {
    id?: number | null,
    title: string,
    description: string,
    kickoff: string,
  }) => void;
}

function FeatureForm({
  feature = null,
  onCancel,
  onSubmit,
}: FeatureFormProps) {
  const [title, setTitle] = React.useState(feature?.title || '');
  const [description, setDescription] = React.useState(feature?.description || '');
  const [kickoff, setKickoff] = React.useState(
    feature?.kickoff ? feature.kickoff.replace(/T.*$/, '') : ''
  );

  const submitHandler = (e) => {
    e.preventDefault();
    onSubmit({
      id: feature ? feature.id : null,
      title,
      description,
      kickoff,
    });
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="form-group">
        <label htmlFor="feature_title">Title</label>
        <input
          id="feature_title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          className="form-control mb-2"
        />
      </div>

      <div className="form-group">
        <label htmlFor="feature_description">Description</label>
        <textarea
          id="feature_description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="form-control mb-2"
        />
      </div>

      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="feature_kickoff">Kickoff Date</label>
          <input
            id="feature_kickoff"
            type="date"
            value={kickoff}
            onChange={(e) => setKickoff(e.target.value)}
            className="form-control mb-2"
            aria-describedby="kickoff_explaination"
          />
          <small id="kickoff_explaination" className="form-text text-muted">
            Defaults to the created date of the oldest story in the feature.
            You can override that here for the burndown and projection calculations.
          </small>
        </div>
      </div>

      <div>
        <input
          type="submit"
          className="btn btn-lg btn-primary"
          value={feature ? 'Update Feature' : 'Create Feature'}
        />
        {' '}
        <input
          type="reset"
          className="btn btn-lg btn-secondary"
          value="Cancel"
          onClick={() => onCancel()}
        />
      </div>
    </form>
  );
}

export default FeatureForm;
