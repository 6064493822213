import * as React from 'react';
import classnames from 'classnames';

interface ButtonProps {
  title: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
}

function EditorButton({ title, onClick, className = 'btn-primary' }: ButtonProps) {
  return (
    <button
      type="button"
      className={classnames('btn', 'btn-sm', 'mr-1', className)}
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export default EditorButton;
