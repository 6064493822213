/**
 * Reducer to update a sprint board when a story is moved to or from an active sprint.
 *
 * @param {object} state - Slice of board state to update.
 * @param {number} sprintId - ID of current sprint being updated.
 * @param {boolean} isAdding - If story is being added or removed.
 * @param {number} storyId - ID of story that is moving.
 *
 * @returns {object} Updated board state.
 */
const updateSprintBoard = (state, sprintId, isAdding, storyId) => {
  // Find a board with this sprint.
  const boardId = Object.keys(state).find((id) => state[id].sprint?.id === sprintId);
  if (!boardId) {
    return state;
  }

  return {
    ...state,
    [boardId]: {
      ...state[boardId],
      backlog_stories: isAdding ? [
        ...state[boardId].backlog_stories,
        storyId,
      ] : state[boardId].backlog_stories,
    },
  };
};

export default updateSprintBoard;
