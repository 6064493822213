/* eslint-disable import/prefer-default-export */
import uuid from 'react-uuid';
import get from 'lodash/get';

import { MERGE_ENTITY_CHANGES } from '../types';
import getUserById from '../../selectors/getUserById';
import { receiveManyEntities } from '../entity';

const STATE_KEY = 'members';

/**
 * Set the instigator of some action on the given entity, then remove it after
 * two seconds.
 *
 * @param {object} entity - Entity to which some action applied.
 * @param {number} userId - ID of the user making the change.
 * @param {func} dispatch - Dispatch.
 * @param {object} state - State.
 */
export const setInstigator = (entity, userId, fields = []) => (dispatch, getState) => {
  const activityUuid = uuid();

  const dispatchChanges = (instigator) => {
    const oldPusherActivity = get(getState(), `data.entities.${entity.type}.${entity.id}.pusherActivity`, []);
    const pusherActivity = instigator
      ? [...oldPusherActivity, { id: activityUuid, instigator, fields }]
      : oldPusherActivity.filter((activity) => activity.id !== activityUuid);

    return dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity,
      changes: {
        pusherActivity,
      },
    });
  };

  const user = getUserById(getState(), userId);
  if (user) {
    dispatchChanges(user);
    setTimeout(() => dispatchChanges(null), 2000);
  }
};

export const setUserPresence = (id, online) => ({
  type: MERGE_ENTITY_CHANGES,
  entity: { type: 'members', id },
  changes: { online },
});

export const initializeUserPresence = (users) => (dispatch) => {
  dispatch(receiveManyEntities(STATE_KEY, users.map((user) => ({ ...user, online: true }))));
};
