import { receiveEntity } from '../../actions/entity';

/**
 * Create a getEntity action creator for single-depth items.
 *
 * This creator cannot be used with nested resources, e.g. /teams/{team}/story/{story}.
 * It can only be used with resources that only require a single ID.
 *
 * @param {string} stateKey - Key in which to store the entity, within data.elements.
 * @param {string} apiCall - API method to get the element, e.g. 'getSomeElement'.
 * @returns {function}
 */
export default (stateKey, apiCall) => (id) => (dispatch, getState, { api }) => api[apiCall](id)
  .then(({ data }) => dispatch(
    receiveEntity(stateKey, {
      id,
      ...data,
    })
  ))
  .catch((error) => dispatchAlert(error.toString()));
