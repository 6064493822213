import React, { useState } from 'react';
import {
  func,
  number,
  shape,
  string,
} from 'prop-types';

function NewSprintForm({
  addSprint,
  cancel,
  suggestion,
}) {
  const [startDate, setStartDate] = useState(suggestion.start_date);
  const [endDate, setEndDate] = useState(suggestion.end_date);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    addSprint({ start_date: startDate, end_date: endDate });
    setStartDate('');
    setEndDate('');
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="new_sprint_start_date">Start Date</label>
          <input
            type="date"
            id="new_sprint_start_date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="form-control mb-2 mr-sm-2"
            placeholder="Start Date"
          />
        </div>
        <div className="dash">&mdash;</div>
        <div className="form-group col-md-4">
          <label htmlFor="new_sprint_end_date">End Date</label>
          <input
            type="date"
            id="new_sprint_end_date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="form-control mb-2 mr-sm-2"
            placeholder="End Date"
          />
        </div>
      </div>

      <div>
        <input type="submit" className="btn btn-lg btn-primary" value="Add Sprint" />
        {' '}
        <input type="reset" className="btn btn-lg btn-secondary" value="Cancel" onClick={cancel} />
      </div>
    </form>
  );
}

NewSprintForm.propTypes = {
  addSprint: func.isRequired,
  cancel: func.isRequired,
  suggestion: shape({
    duration: number,
    start_date: string,
    end_date: string,
  }).isRequired,
};

export default NewSprintForm;
