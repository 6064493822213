import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Kanban } from 'react-bootstrap-icons';
import { connect } from 'react-redux';
import { board } from '../../utils/uri';

import {
  getActiveSprintId,
  getCurrentUserActiveTeam,
} from '../../selectors';
import { TEAM_SHAPE } from '../../types/props';

function BoardButton({
  activeTeam,
  showBoardButton,
  showText,
}) {
  if (showBoardButton) {
    return (
      <Link className="board-button" to={board(activeTeam.id)}>
        <Kanban />
        <span className={showText ? '' : 'sr-only'}>
          {' Sprint Board'}
        </span>
      </Link>
    );
  }

  return '';
}

BoardButton.defaultProps = {
  showText: true,
};

BoardButton.propTypes = {
  activeTeam: PropTypes.shape(TEAM_SHAPE).isRequired,
  showBoardButton: PropTypes.bool.isRequired,
  showText: PropTypes.bool,
};

export default connect(
  (state, { showBoardButton = true }) => {
    const activeTeam = getCurrentUserActiveTeam(state);
    return {
      activeTeam,
      showBoardButton: showBoardButton
        && !!activeTeam?.id
        && !!getActiveSprintId(state, activeTeam.id),
    };
  }
)(BoardButton);
