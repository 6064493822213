import { denormalize } from 'normalizr';
import { ADD_ENTITY, DELETE_ENTITY, UPDATE_ENTITY } from '../actions/types';
import { entitySchema } from '../types';
import createEntityReducer from '../utils/createEntityReducer';

export const STATE_KEY = 'comments';

/**
 * Comment reducer.
 */
export default createEntityReducer(STATE_KEY);

/**
 *
 * @param {number} storyId - Story ID.
 * @param {Object} comment - Comment data.
 * @param {number} comment.member.id - ID of user posting the comment.
 */
export const receiveStoryComment = (storyId, comment) => ({
  type: ADD_ENTITY,
  to: { type: 'stories', id: storyId },
  entity: { type: STATE_KEY, data: { ...comment, member: comment.member.id } },
});

export const receiveCommentUpdate = (comment) => ({
  type: UPDATE_ENTITY,
  entity: { type: STATE_KEY, data: { ...comment, member: comment.member.id } },
});

export const receiveCommentDelete = (commentId, storyId) => ({
  type: DELETE_ENTITY,
  entity: { type: STATE_KEY, id: commentId },
  from: { type: 'stories', id: storyId },
});

/**
 * Add a story comment.
 *
 * @param {number} storyId - Story ID.
 * @param {Object} comment - Payload to create a new comment.
 * @param {string} comment.body - Comment body.
 */
export const addStoryComment = (storyId, comment) => (dispatch, _getState, { api }) => (
  api.createComment(storyId, comment)
    .then(({ data }) => dispatch(receiveStoryComment(storyId, data)))
    .catch(() => dispatchAlert('Error adding comment', 'danger'))
);

export const updateComment = (commentId, comment) => (dispatch, _getState, { api }) => (
  api.updateComment(commentId, comment)
    .then(({ data }) => dispatch(receiveCommentUpdate(data)))
    .catch(api.errorHandler)
);

export const deleteComment = (commentId, storyId) => (dispatch, _getState, { api }) => (
  api.deleteComment(commentId)
    .then(() => dispatch(receiveCommentDelete(commentId, storyId)))
    .catch(api.errorHandler)
);

export const loadStoryComment = (commentId: number) => (dispatch, _getState, { api }) => (
  api.getComment(commentId)
    .then(({ data }) => {
      if (data.subject_type === 'App\\Story') {
        dispatch(receiveCommentUpdate(data));
      }
      return data;
    })
    .catch(api.errorHandler)
);

/**
 * Select the hydrated comment from state.
 *
 * @param {Object} state - Full redux state.
 * @param {string} id - Comment ID.
 * @returns {Object}
 */
export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)
);
