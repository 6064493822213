import * as React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

// Internal dependencies.
import * as actions from '../../../actions';

// Components.
import UserCard from '../../UserCard';
import { PokerGame } from '../../../types/interfaces/PokerGame';
import { Member } from '../../../types/interfaces/Member';

interface ResultsProps {
  game: PokerGame;
  cancelGame: (gameId: number, storyId: number) => Promise<void>;
  revote: (gameId: number, storyId: number, teamId: number) => void;
  endGame: (gameId: number, storyId: number, points: number) => Promise<void>;
}

function Results({
  game,
  cancelGame,
  revote,
  endGame,
}: ResultsProps) {
  const {
    in_range: inRange,
    mean,
    median,
    min,
    max,
    votes,
  } = game.results;

  const makeCards = (cur, next, maxCard, stack) => (
    cur === maxCard ? [...stack, cur] : makeCards(next, cur + next, maxCard, [...stack, cur])
  );
  const cards = makeCards(1, 2, 233, ['?', 0]);
  let votesByFib = {};
  cards.slice(cards.indexOf(min), cards.indexOf(max) + 1)
    .forEach((card) => { votesByFib[card] = []; });

  votes.forEach((vote) => {
    const votePoints = vote.points === null ? '?' : vote.points;
    votesByFib[votePoints] = votesByFib[votePoints] || [];
    votesByFib[votePoints].push(vote.user_id);
  });
  votesByFib = Object.keys(votesByFib).reduce((filtered, fib) => (
    votesByFib[fib].length > 0 ? { ...filtered, [fib]: votesByFib[fib] } : filtered
  ), {});

  const acceptClasses = classnames('btn', 'btn-primary', 'mr-2', { 'btn-sm': !inRange });
  const revoteClasses = classnames(
    'btn',
    'mr-2',
    { 'btn-sm': inRange, 'btn-outline-primary': inRange, 'btn-primary': !inRange }
  );
  const revoteButton = (
    <button type="button" className={revoteClasses} onClick={() => revote(game.id, game.story_id, game.team_id)}>
      Revote
    </button>
  );

  return (
    <>
      <h3>{inRange ? 'Voting: In Range' : 'Voting: Out of Range'}</h3>
      {Object.keys(votesByFib).map((fib) => (
        <div className="clearfix mb-2" key={fib}>
          <h4
            className={classnames('poker-card', 'story-points', `points-${fib === '?' ? 'unsure' : fib}`)}
          >
            {fib}
          </h4>
          {votesByFib[fib].map((player) => (
            <UserCard key={player.id} user={player} status="info" />
          ))}
        </div>
      ))}

      {game.responses.waiting.length ? (
        <>
          <h4>Absent Team Members</h4>
          <div className="clearfix mb-3">
            {game.responses.waiting.map((player: Member) => (
              <UserCard key={player.id} user={player} status="waiting" />
            ))}
          </div>
        </>
      ) : null}

      <div>
        {!inRange ? revoteButton : null}
        <button type="button" className={acceptClasses} onClick={() => endGame(game.id, game.story_id, mean)}>
          {`Accept mean (${mean})`}
        </button>
        <button type="button" className={acceptClasses} onClick={() => endGame(game.id, game.story_id, median)}>
          {`Accept median (${median})`}
        </button>
        {inRange ? revoteButton : null}
        <button type="button" className="btn btn-outline-danger btn-sm" onClick={() => cancelGame(game.id, game.story_id)}>
          Cancel Voting
        </button>
      </div>
    </>
  );
}

export default connect(null, {
  cancelGame: actions.cancelGame,
  revote: actions.revote,
  endGame: actions.endGame,
})(Results);
