import * as React from 'react';
import shallowObjectProps from '../../utils/shallowObjectProps';

/**
 * Hook that provides method to add items to the end.
 *
 * @param {Object[]} arrayOfItems - Items the list should contain. Items must
 *                                  have and id property.
 * @param {string[]} objectProps - Properties of the items that should trigger
 *                                 a re-rendering of the list when they change.
 *
 * @returns {Object} hook The state and functions to manage them.
 * @returns {Object[]} hook.list The array passed to the hook managed by
 *                               its functions.
 * @returns {function} hook.setList Function to update the array.
 * @returns {function} hook.setListByAppending Function to append to the array.
 */
const useAppendableList = (arrayOfItems, objectProps) => {
  const [list, setList] = React.useState(arrayOfItems);

  React.useEffect(() => {
    setList(arrayOfItems);
  }, [shallowObjectProps(arrayOfItems, objectProps)]);

  /**
   * Force new items added to the done column to be added to the end of the
   * list, no matter where they are dropped.
   * @param {array} newState - Array of new stories.
   */
  const setListByAppending = (newState) => {
    // Adding an item to the column.
    if (newState.length > list.length) {
      const currentItems = list.map((item) => item.id);
      // Move all new items to the end of the list.
      const newList = newState
        .reduce((carry, story) => {
          const [existingItems, newItems] = carry;
          if (currentItems.includes(story.id)) {
            return [
              [...existingItems, story],
              newItems,
            ];
          }
          return [
            existingItems,
            [...newItems, story],
          ];
        }, [[], []])
        .flat();

      setList(newList);
      return;
    }

    // Behave as normal when removing an item from the column.
    if (newState.length < list.length) {
      setList(newState);
    }
  };

  return {
    list,
    setList,
    setListByAppending,
  };
};

export default useAppendableList;
