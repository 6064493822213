import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import * as actions from '../../actions';
import { getTeam, getTeamMembers } from '../../selectors';
import scrollToHash from '../../utils/scrollToHash';
import { USER_SHAPE } from '../../types/props';

// Components
import SingleFieldCard from '../SingleFieldCard';
import ColumnsEdit from './ColumnsEdit';
import TeamMembers from './TeamMembers';
import Webhooks from './Webhooks';

function TeamEdit({
  getTeamConfig,
  inviteToTeam,
  removeFromTeam,
  team,
  teamMembers,
  updateTeam,
  updateTeamConfig,
}) {
  const { hash } = useLocation();

  // Load the team config if it hasn't already been loaded.
  useEffect(() => {
    if (!team.config?.board) {
      getTeamConfig(team.id);
    }
  }, []);

  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        scrollToHash(hash);
      }, 100);
    }
  }, [hash]);

  const title = 'Edit team settings';

  return (
    <>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <main className="container">
        <h1 className="sr-only">
          {title}
        </h1>
        <SingleFieldCard
          header={{ label: 'Team Settings', level: 2 }}
          fieldLabel="Team Name"
          fieldValue={team.name}
          updateHandler={(newName) => {
            updateTeam(team.id, { name: newName });
          }}
        />
        <TeamMembers
          members={teamMembers}
          teamId={team.id}
          inviteToTeam={inviteToTeam}
          removeFromTeam={removeFromTeam}
        />
        {(
        (!!team.config?.board) && (
          <ColumnsEdit
            board={team.config.board}
            updateTeamConfig={({ board }) => updateTeamConfig({ board, teamId: team.id })}
          />
        )
      )}
        <Webhooks
          teamId={team.id}
        />
      </main>
    </>
  );
}

TeamEdit.propTypes = {
  getTeamConfig: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired,
  updateTeamConfig: PropTypes.func.isRequired,
  inviteToTeam: PropTypes.func.isRequired,
  removeFromTeam: PropTypes.func.isRequired,
  team: PropTypes.shape().isRequired,
  teamMembers: PropTypes.arrayOf(
    PropTypes.shape(USER_SHAPE)
  ).isRequired,
};

export default connect(
  (state, ownProps) => ({
    team: getTeam(state, ownProps.match.params),
    teamMembers: getTeamMembers(state, ownProps.match.params),
  }),
  {
    getTeamConfig: actions.getTeamConfig,
    updateTeamConfig: actions.updateTeamConfig,
    updateTeam: actions.updateTeam,
    inviteToTeam: actions.inviteToTeam,
    removeFromTeam: actions.removeFromTeam,
  }
)(TeamEdit);
