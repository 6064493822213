import React, { useState } from 'react';
import PropTypes from 'prop-types';

function TeamMembers({
  teamId,
  members,
  inviteToTeam,
  removeFromTeam,
}) {
  const [email, setEmail] = useState('');

  const saveForm = (e) => {
    e.preventDefault();
    inviteToTeam(teamId, email)
      .then(() => {
        setEmail('');
      });
  };

  return (
    <div className="card mt-3">
      <h2 className="card-header" id="team-members">Members</h2>
      <div className="card-body">
        <ul className="team-members-list" aria-labelledby="team-members">
          {members.map((member) => (
            <li key={member.id}>
              <img src={member.avatar_url} alt="" />
              <span className="team-members-list-name">{member.name}</span>
              <button
                onClick={() => removeFromTeam(teamId, member.id)}
                className="btn btn-outline btn-outline-danger"
                type="button"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>

        <form onSubmit={saveForm} className="form-inline mt-4">
          <label className="sr-only" htmlFor="invite-email">Email Address</label>
          <input
            type="email"
            className="form-control mb-2 mr-sm-2"
            id="invite-email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button type="submit" className="btn btn-primary mb-2">Invite User</button>
        </form>
      </div>
    </div>
  );
}

TeamMembers.propTypes = {
  teamId: PropTypes.number.isRequired,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      avatar_url: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  inviteToTeam: PropTypes.func.isRequired,
  removeFromTeam: PropTypes.func.isRequired,
};

export default TeamMembers;
