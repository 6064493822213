import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import PropTypes from 'prop-types';
import { ArrowCounterclockwise } from 'react-bootstrap-icons';
import throttle from 'lodash/throttle';

import Task from './Task';
import { selectWhispers, whisperStoryAddingTask, whisperStoryDoneAddingTask } from '../../../modules/whispers';
import { getCurrentUser } from '../../../selectors';
import { USER_SHAPE } from '../../../types/props';

function TaskList({
  addTask,
  deleteTask,
  tasks,
  updateOrder,
  updateTask,
  storyId,
  currentUser,
  whispers,
}) {
  const [initiator, setInitiator] = useState(null);
  const [newTaskBody, setNewTaskBody] = useState('');
  const [deletedTasks, setDeletedTasks] = useState([]);
  const onUpdate = ({ item: { dataset: { taskId } } }) => setInitiator(taskId);

  // Send both the action to delete the task and save it's value in the
  // component state to make undo possible.
  const deleteHandler = (id, body) => {
    deleteTask(id);
    setDeletedTasks([...deletedTasks, body]);
  };

  const handleUndo = () => {
    const updatedDeletedTasks = [...deletedTasks];
    const lastDeletedTask = updatedDeletedTasks.pop();
    if (lastDeletedTask && lastDeletedTask !== '') {
      addTask(lastDeletedTask);
      // Pop will modify the array, we can save the modified array.
      setDeletedTasks(updatedDeletedTasks);
      dispatchAlert('Undo successful', 'success');
    } else {
      dispatchAlert('Nothing to undo', 'danger');
    }
  };

  const throttledWhisper = useCallback(
    throttle(
      () => whisperStoryAddingTask(storyId, currentUser.id, currentUser.name),
      1500,
      { trailing: false }
    ),
    []
  );

  const handleNewTaskChange = (e) => {
    const { value } = e.target;
    setNewTaskBody(value);
    if (value !== '') {
      throttledWhisper();
    } else {
      whisperStoryDoneAddingTask(storyId, currentUser.id);
    }
  };

  const addTaskHandler = (e) => {
    e.preventDefault();
    addTask(newTaskBody);
    setNewTaskBody('');
    whisperStoryDoneAddingTask(storyId, currentUser.id);
  };

  return (
    <div className="mb-3">
      <h2 className="h3">Acceptance Criteria</h2>

      <ReactSortable
        id="task-list"
        tag="ol"
        className="story-tasks"
        list={tasks}
        setList={(newOrder) => updateOrder(newOrder, initiator)}
        onUpdate={onUpdate}
        animation={300}
      >
        {tasks.map((task) => (
          <Task
            key={task.id}
            {...task}
            updateTask={updateTask}
            deleteTask={deleteHandler}
          />
        ))}
      </ReactSortable>

      {deletedTasks.length > 0 && (
        <button type="button" className="btn btn-link" onClick={handleUndo}>
          <ArrowCounterclockwise />
          {' '}
          Restore last deleted task
        </button>
      )}

      {whispers.length > 0 && (
        <p
          className="text-muted"
          role="alert"
          aria-live="polite"
          aria-controls="task-list"
        >
          {`${whispers.join(', ')} ${whispers.length > 1 ? 'are' : 'is'} adding a task...`}
        </p>
      )}

      <form onSubmit={addTaskHandler}>
        <label className="sr-only" htmlFor="new-task">Add acceptance criterion</label>
        <input
          type="text"
          name="body"
          className="form-control form-control-lg mb-1 mt-3"
          id="new-task"
          placeholder="Add acceptance criteria"
          value={newTaskBody}
          onChange={handleNewTaskChange}
          autoComplete="off"
        />
      </form>
    </div>
  );
}

TaskList.propTypes = {
  addTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object),
  updateOrder: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  storyId: PropTypes.number.isRequired,
  currentUser: PropTypes.shape(USER_SHAPE).isRequired,
  whispers: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TaskList.defaultProps = {
  tasks: [],
};

export default connect((state, { storyId }) => ({
  whispers: selectWhispers(state, 'storyTasks', storyId),
  currentUser: getCurrentUser(state),
}))(TaskList);
