// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Comment__collapsed__X1s-4{height:250px;position:relative}.Comment__collapsed__X1s-4:after{content:\"\";position:absolute;z-index:1;bottom:35px;left:0;pointer-events:none;background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0), white 80%);width:100%;height:4rem}.Comment__collapsedBody__dZxkP{overflow:scroll;height:215px}.Comment__expandToggle__VBVBp{border-top:2px dashed #ccc;display:block;padding:.5rem;width:100%}.Comment__collapseBtn__\\+RjCC{margin-top:1rem}", "",{"version":3,"sources":["webpack://./resources/js/components/Story/Comments/Comment.scss"],"names":[],"mappings":"AAAA,2BACE,YAAA,CACA,iBAAA,CAGF,iCACE,UAAA,CACA,iBAAA,CACA,SAAA,CACA,WAAA,CACA,MAAA,CACA,mBAAA,CACA,8EAAA,CAGA,UAAA,CACA,WAAA,CAGF,+BACE,eAAA,CACA,YAAA,CAGF,8BACE,0BAAA,CACA,aAAA,CACA,aAAA,CACA,UAAA,CAGF,8BACE,eAAA","sourcesContent":[".collapsed {\n  height: 250px;\n  position: relative;\n}\n\n.collapsed:after {\n  content: \"\";\n  position: absolute;\n  z-index: 1;\n  bottom: 35px;\n  left: 0;\n  pointer-events: none;\n  background-image: linear-gradient(to bottom,\n                    rgba(255,255,255, 0),\n                    rgba(255,255,255, 1) 80%);\n  width: 100%;\n  height: 4rem;\n}\n\n.collapsedBody {\n  overflow: scroll;\n  height: 215px;\n}\n\n.expandToggle {\n  border-top: 2px dashed #ccc;\n  display: block;\n  padding: 0.5rem;\n  width: 100%;\n}\n\n.collapseBtn {\n  margin-top: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"collapsed": "Comment__collapsed__X1s-4",
	"collapsedBody": "Comment__collapsedBody__dZxkP",
	"expandToggle": "Comment__expandToggle__VBVBp",
	"collapseBtn": "Comment__collapseBtn__+RjCC"
};
export default ___CSS_LOADER_EXPORT___;
