import get from 'lodash/get';

/**
 * Provide object properties for each member of an array as JSON strings for
 * comparison.
 *
 * @param {object[]} items - Array of object whose properties need to be compared.
 * @param {string[]} props - Array of properties to check.
 * @returns {string} - JSON string of array of items containing selected properties.
 */
const shallowObjectProps = (items, props) => JSON.stringify(
  items.map(
    (item) => props.reduce(
      (carry, key) => ({
        ...carry,
        [key]: get(item, key),
      }),
      {}
    )
  )
);

export default shallowObjectProps;
