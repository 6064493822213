import React from 'react';
import uuid from 'react-uuid';
import UserCard from '../UserCard';
import { Member } from '../../types/interfaces/Member';

interface UserListProps {
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6;
  headingClassName?: string;
  label: string;
  onClick?: ((userId: number) => void) | null;
  users: Member[];
}

function UserList({
  users,
  label,
  headingLevel = 2,
  headingClassName = '',
  onClick = null,
}: UserListProps) {
  const Heading: 'h1'|'h2'|'h3'|'h4'|'h5'|'h6' = `h${headingLevel}`;
  const headingId = uuid();

  return (
    <>
      <Heading id={headingId} className={headingClassName}>{label}</Heading>
      <ul aria-labelledby={headingId} className="contributors-list">
        {users.map((user) => {
          const clickProps = onClick ? { onClick: () => onClick(user.id), role: 'button' } : {};

          return (
            <li key={user.id} {...clickProps}>
              <UserCard user={user} status="neutral" />
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default UserList;
