/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import truthyFilterArray from '../utils/truthyFilterArray';
import { entitySchema } from '../types';
import { LABEL_MAP } from '../actions/types';
import getEntities from '../selectors/getEntities';
import { BacklogEntity } from '../types/interfaces/BacklogEntity';
import { BacklogType } from '../types/BacklogType';

// These are the the params defined in the BacklogScreen route.
// They make make sense elsewhere.
// @see routes.js
type RouteParams = {
  contextType: BacklogType;
  id: number;
}

/**
 * Select a backlogable entity from the route context with meta information
 * about the backlog attached.
 */
export const makeGetBacklog = ({ contextType, id }: RouteParams) => createSelector(
  getEntities,
  (entities): BacklogEntity => {
    const currentEntity = denormalize(id, entitySchema[contextType], entities);
    const stories = truthyFilterArray(currentEntity?.backlog_stories || []);

    return {
      ...currentEntity,
      stories,
      backlogType: contextType,
      backlogId: id,
      backlogTypeLabel: LABEL_MAP[contextType],
    };
  }
);

export default makeGetBacklog;
