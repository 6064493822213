import get from 'lodash/get';
import { combineReducers } from 'redux';

// Actions.
// ---------------------------

const COMMENTING = 'whispers/COMMENTING';
const DONE_COMMENTING = 'whispers/DONE_COMMENTING';
const ADDING_TASK = 'whispers/ADDING_TASK';
const DONE_ADDING_TASK = 'whispers/DONE_ADDING_TASK';
const EDITING_BODY = 'whispers/EDITING_BODY';
const DONE_EDITING_BODY = 'whispers/DONE_EDITING_BODY';

// Action creators.
// ---------------------------

const makeWhisperAction = (type) => (storyId, data) => ({
  type,
  id: storyId,
  data,
});

export const setCommenting = makeWhisperAction(COMMENTING);
export const setDoneCommenting = makeWhisperAction(DONE_COMMENTING);
export const setEditingBody = makeWhisperAction(EDITING_BODY);
export const setDoneEditingBody = makeWhisperAction(DONE_EDITING_BODY);
export const setAddingTask = makeWhisperAction(ADDING_TASK);
export const setDoneAddingTask = makeWhisperAction(DONE_ADDING_TASK);

const makeWhisperReducer = (startType, endType) => (state = {}, action = {}) => {
  const {
    type,
    id,
    data,
  } = action;

  switch (type) {
    case startType:
      return {
        ...state,
        [id]: {
          ...state?.[id] || {},
          [data.userId]: data,
        },
      };

    case endType:
      // eslint-disable-next-line no-case-declarations
      const { [data.userId]: removedData, ...keptData } = state?.[id] || {};
      return {
        ...state,
        [id]: keptData,
      };
  }

  return state;
};

// Reducer.
// ---------------------------

export default combineReducers({
  storyComments: makeWhisperReducer(COMMENTING, DONE_COMMENTING),
  storyBody: makeWhisperReducer(EDITING_BODY, DONE_EDITING_BODY),
  storyTasks: makeWhisperReducer(ADDING_TASK, DONE_ADDING_TASK),
});

// Whisperers.
// ---------------------------

const makeStoryWhisperer = (event) => (storyId, data) => window.Echo.join(`stories.${storyId}`).whisper(event, data);

export const whisperStoryCommenting = (storyId, userId, name) => makeStoryWhisperer('Commenting')(storyId, { userId, name });
export const whisperStoryDoneCommenting = (storyId, userId) => makeStoryWhisperer('DoneCommenting')(storyId, { userId });

export const whisperStoryEditingBody = (storyId, userId, name) => makeStoryWhisperer('EditingBody')(storyId, { userId, name });
export const whisperStoryDoneEditingBody = (storyId, userId) => makeStoryWhisperer('DoneEditingBody')(storyId, { userId });

export const whisperStoryAddingTask = (storyId, userId, name) => makeStoryWhisperer('AddingTask')(storyId, { userId, name });
export const whisperStoryDoneAddingTask = (storyId, userId) => makeStoryWhisperer('DoneAddingTask')(storyId, { userId });

// Whisper Selecter.
// ---------------------------

export const selectWhispers = (state, subject, id) => {
  const whispers = get(state, `whispers.${subject}.${id}`, {});
  return Object.keys(whispers).map((key) => whispers[key].name);
};
