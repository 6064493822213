import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import { Stopwatch, StopwatchFill } from 'react-bootstrap-icons';
import ConfirmationDialog from '../ConfirmationDialog';

import * as actions from '../../actions';

function PureToggleSprintButton({
  sprintId,
  startSprint,
  endSprint,
  isActiveSprint,
  isDisabled,
  className,
}) {
  const [isConfirmingAction, setIsConfirmingAction] = useState(false);
  const submitHandler = (e) => {
    e.preventDefault();
    if (isActiveSprint) {
      setIsConfirmingAction(true);
    } else {
      startSprint(sprintId).then(() => (
        dispatchAlert('Sprint started', 'success')
      ));
    }
  };

  const confirmAction = () => {
    endSprint(sprintId)
      .then(({ roll }) => {
        if (roll.total_points > 0) {
          dispatchAlert(
            (
              <>
                {`Sprint ended, rolling ${roll.total_points} points to the `}
                <a href={roll.to.url}>
                  {roll.to.title}
                </a>
                {' backlog'}
              </>
            ), 'success'
          );
        } else {
          dispatchAlert('Sprint ended', 'success');
        }
      })
      .catch((error) => {
        dispatchAlert('There was an error ending the sprint', 'danger');
        console.error(error); // eslint-disable-line no-console
      });
    setIsConfirmingAction(false);
  };

  return (
    <>
      <form method="post" onSubmit={submitHandler}>
        <button
          type="submit"
          className={classnames('btn', {
            'btn-secondary': isDisabled,
          }, className)}
          disabled={isDisabled}
          title={isDisabled ? 'Another sprint is currently active' : undefined}
        >
          {isActiveSprint
            ? (
              <>
                <Stopwatch />
                {' '}
                End Sprint
              </>
            )
            : (
              <>
                <StopwatchFill />
                {' '}
                Start Sprint
              </>
            )}
        </button>
        {}
      </form>
      {isConfirmingAction && (
      <ConfirmationDialog
        confirmAction={confirmAction}
        message="Are you sure you want to end this sprint? Stories not yet completed in this sprint will roll to the next sprint and this action cannot be undone." // eslint-disable-line max-len
        closeDialog={() => setIsConfirmingAction(false)}
      />
      )}
    </>
  );
}

PureToggleSprintButton.propTypes = {
  sprintId: PropTypes.number.isRequired,
  startSprint: PropTypes.func.isRequired,
  endSprint: PropTypes.func.isRequired,
  isActiveSprint: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

PureToggleSprintButton.defaultProps = {
  className: '',
};

export { PureToggleSprintButton };

export default connect(
  (state, { teamId, sprintId }) => {
    const isActiveSprint = !!get(state.data.entities, `sprints.${sprintId}.is_active`, false);
    const isDisabled = !isActiveSprint
      && !!get(state.data.entities, `teams.${teamId}.current_sprint`, false);

    return {
      isActiveSprint,
      isDisabled,
      sprintId: Number.parseInt(sprintId, 10),
    };
  },
  {
    startSprint: actions.startSprint,
    endSprint: actions.endSprint,
  }
)(PureToggleSprintButton);
