import * as React from 'react';
import PropTypes from 'prop-types';
import { STORY_SHAPE } from '../../types/props';

import StoryCard from './StoryCard';

const StoryListProps = {
  stories: PropTypes.arrayOf(PropTypes.shape(STORY_SHAPE)).isRequired,
  context: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }),
};

function StoryList({
  stories,
  context = {},
}) {
  return (
    <ol className="story-list">
      {stories.map((story) => (
        <StoryCard
          key={story.id}
          story={story}
          pusherActivity={story.pusherActivity}
          context={context}
        />
      ))}
    </ol>
  );
}

StoryList.propTypes = StoryListProps;

export default StoryList;
