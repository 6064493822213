// Actions.
const ADD_FILTER = 'storyFilters/ADD_FILTER';
const REMOVE_FILTER = 'storyFilters/REMOVE_FILTER';
const RESET_FILTERS = 'storyFilters/RESET_FILTERS';

// Reducer.
export default function reducer(state = [], action = {}) {
  const { type, filter } = action;

  switch (type) {
    case ADD_FILTER:
      // For now, only support one filter at a time.
      return [action.filter];

    case REMOVE_FILTER:
      return state.filter(
        (current) => current.type !== filter.type || current.id !== filter.id
      );

    case RESET_FILTERS:
      return [];
  }

  return state;
}

// Action Creators.
export const addStoryFilter = (filter) => ({
  type: ADD_FILTER,
  filter,
});

export const removeStoryFilter = (filter) => ({
  type: REMOVE_FILTER,
  filter,
});

export const resetStoryFilters = () => ({
  type: RESET_FILTERS,
});

// Filter creators.
const buildFilterForNumericId = (type) => (id) => ({ type, id: Number.parseInt(id, 10) });
export const filterData = {
  contributor: (id) => ({
    type: 'contributor',
    id: id === 'none' ? 'none' : Number.parseInt(id, 10),
  }),
  epic: buildFilterForNumericId('epic'),
  feature: buildFilterForNumericId('feature'),
  points: (id) => ({ type: 'points', id }),
  status: (id) => ({ type: 'status', id }),
};
