import React from 'react';
import PropTypes from 'prop-types';
import { USER_SHAPE } from '../../types/props';
import initialsFromName from '../../utils/initialsFromName';

function UserCard({ user, status, hideInitials }) {
  const getStatusClass = () => {
    if (status) {
      return status;
    }
    return user.online ? 'online' : 'offline';
  };
  return (
    <div className={`user-card status-${getStatusClass()}`}>
      <img src={user.avatar_url} alt="" />
      <span className="sr-only">
        {user.name}
      </span>
      {!hideInitials
        && (
        <span className="name" aria-hidden>
          {initialsFromName(user.name)}
        </span>
        )}
    </div>
  );
}

UserCard.defaultProps = {
  status: null,
  hideInitials: false,
};

UserCard.propTypes = {
  status: PropTypes.string,
  user: PropTypes.shape(USER_SHAPE).isRequired,
  hideInitials: PropTypes.bool,
};

export default UserCard;
