import startCase from 'lodash/startCase';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { getLocation } from 'connected-react-router';
import getParams from './getParams';
import getEntities from './getEntities';
import { TYPE_MAP_FROM_SINGULAR } from '../actions/types';
import * as uri from '../utils/uri';

/**
 * Provide props for breadcrumb for the first crumb.
 *
 * @param {string} type Type of entity to provide link for.
 */
export const rootLinkFromType = (type) => ({
  path: `/${type}`,
  title: startCase(type),
  anchor: true,
});

/**
 * Provide props for a crumb.
 *
 * @param {string} param.id A valid string.
 * @param {string} param.type A valid string.
 * @param {string} param.title A valid string.
 * @param {string} param.name A valid string.
 */
export const buildCrumb = ({
  id, type, title, name, path, isText = false,
}) => {
  const displayTitle = title || name || '';

  // Cannot build crumb without a title.
  if (displayTitle === '') {
    return false;
  }

  // Cannot build crumb without a path.
  if (!path && !isText) {
    if (typeof id === 'undefined' || typeof type === 'undefined') {
      return false;
    }
  }

  return {
    path: path || `/${type}/${id}`,
    title: displayTitle,
    isText,
  };
};

/**
 * Create an array of crumbs based on the context of the page.
 *
 * @returns array Array of objects that configure breadcrumb props.
 */
export default createSelector(
  // @todo this should and could be refactored for performance improvements;
  // it appears to run ~6 times on every page mount.
  [
    getEntities,
    getParams,
    getLocation,
  ],
  (entities, params, location = {}) => (
    // Self-executing function builds an array of crumbs to iterate over based on parameters.
    (() => {
      const {
        contextType,
        id,
        storyId,
        featureId,
      } = params;

      const contextEntity = get(entities, `${contextType}.${id}`, {});

      if (storyId) {
        const backlog = get(entities, `stories.${storyId}.backlog`, {});

        const { current_column: currentColumn, columns = [] } = backlog?.board || {};
        const columnTitle = currentColumn
          ? columns.find(({ id: columnId }) => columnId === currentColumn)?.title
          : undefined;

        return [
          // Teams | Projects
          rootLinkFromType(contextType),
          // Name of context
          buildCrumb({ ...contextEntity, type: contextType }),
          // Sprint title or "backlog"
          buildCrumb({
            ...backlog,
            type: TYPE_MAP_FROM_SINGULAR[backlog.type],
            title: backlog.type === 'sprint' ? backlog.title : 'Backlog',
          }),
          // Column title, if active
          (columnTitle)
            ? buildCrumb({ title: columnTitle, path: uri.board(id) })
            : null,
        ];
      }

      if (contextType === 'sprints') {
        const teamName = get(entities, `teams.${contextEntity.team_id}.name`, '');

        return [
          rootLinkFromType('teams'),
          buildCrumb({
            id: contextEntity.team_id,
            type: 'teams',
            name: teamName,
          }),
          buildCrumb({
            title: 'All Sprints',
            path: `/teams/${contextEntity.team_id}/sprints`,
          }),
          buildCrumb({
            title: get(entities, `sprints.${id}.title`),
            isText: true,
          }),
        ];
      }

      const isBoard = /board$/.exec(location.pathname);
      const isEdit = /\d+\/edit$/.exec(location.pathname);
      const isBucketList = /(teams|projects)\/\d+\/stories/.exec(location.pathname);
      const isBucketListFilter = ['done', 'all'].includes(location.query?.view);
      const isSprints = /sprint/.exec(location.pathname);
      const isProjects = /projects$/.exec(location.pathname);
      const isFeature = /\/features\//.exec(location.pathname);
      const isFeatures = /\/features$/.exec(location.pathname);

      return [
        contextType ? rootLinkFromType(contextType) : null,
        buildCrumb({ ...contextEntity, type: contextType }),
        isProjects ? buildCrumb({ title: 'Projects', path: '/projects' }) : null,
        isBoard ? buildCrumb({
          title: `Sprint Board (${get(entities, `sprints.${contextEntity.current_sprint_id}.title`, '')})`,
          isText: true,
        }) : null,
        isFeature || isFeatures ? buildCrumb({
          title: 'Features',
          path: `/${contextType}/${id}/features`,
        }) : null,
        isFeature ? buildCrumb({
          title: get(entities, `features.${featureId}.title`),
          path: location.pathname,
        }) : null,
        isEdit ? buildCrumb({ title: 'Edit', path: location.pathname, isText: true }) : null,
        isBucketList ? buildCrumb({
          title: 'Stories',
          path: location.pathname,
          isText: !isBucketListFilter,
        }) : null,
        isBucketList && isBucketListFilter ? buildCrumb({
          title: capitalize(location.query?.view),
          path: `${location.pathname}${location.search}`,
          isText: true,
        }) : null,
        isSprints ? buildCrumb({
          title: 'All Sprints',
          path: `/${contextType}/${id}/sprints`,
        }) : null,
      ];
    })())
    // Remove unset crumbs.
    .filter((crumb) => crumb)
);
