/**
 * Immutably remove the item at the given index from an array.
 *
 * @param {array} list - Array from which to remove an item.
 * @param {number} index - Index of item to remove.
 * @returns {array} New list.
 */
export default (list, index) => {
  const newList = [...list];
  newList.splice(index, 1);
  return newList;
};
