import joinProjectChannel from './joinProjectChannel';
import joinStoryChannel from './joinStoryChannel';
import joinTeamChannel from './joinTeamChannel';
import joinOrganizationChannel from './joinOrganizationChannel';

export default (type, id) => {
  switch (type) {
    case 'story':
    case 'Story':
    case 'stories':
      return joinStoryChannel(id);

    case 'team':
    case 'teams':
    case 'Team':
      return joinTeamChannel(id);

    case 'project':
    case 'Project':
    case 'projects':
      return joinProjectChannel(id);

    case 'organization':
      return joinOrganizationChannel(id);
  }

  return null;
};
