/**
 * Create a new Sprint.
 *
 * @param {number} teamId - Team ID.
 * @param {Object} payload - Request payload.
 * @param {string} payload.start_date - Start date, in the format yyyy-mm-dd.
 * @param {string} payload.end_date - End date, in the format yyyy-mm-dd.
 * @returns {Promise} Promise object representing the API request.
 */
const createSprint = (teamId, payload) => axios.post(`/api/teams/${teamId}/sprints`, payload);
export default createSprint;
