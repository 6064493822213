import * as React from 'react';
import {
  CalendarCheck, CheckCircle, Flag, QuestionOctagonFill,
} from 'react-bootstrap-icons';
import classnames from 'classnames';

import format from 'date-fns/format';
import { Story } from '../../types/interfaces/Story';

import BigStat from '../BigStat';
import Loading from '../Loading';
import StatsRow from '../BigStat/StatsRow';
import ProgressBar from '../ProgressBar/ProgressBar';

// Styles.
import styles from './SectionReport.scss';
import calculateEndDate from '../../utils/calculateEndDate';

// Dynamic import.
const SectionBurndown = React.lazy(() => import('./SectionBurndown'));

interface SectionReportProps {
  /** Array of all stories in the project. */
  allStories: Story[];

  /** Type of section for which this is rendering a report. */
  type: 'project' | 'feature';

  /** Start date for the burndown and projections. */
  startDate?: string | null;
}

function SectionReport({
  allStories,
  type,
  startDate: startDateOverride = null,
}: SectionReportProps) {
  const { total, complete, unpointed } = allStories.reduce((sums, story) => ({
    total: sums.total + (story.points || 0),
    complete: sums.complete + (story.completed_at ? (story.points || 0) : 0),
    unpointed: sums.unpointed + (story.points === null ? 1 : 0),
  }), { total: 0, complete: 0, unpointed: 0 });

  // Determine the timestamp where the burndown should start.
  const startDate = startDateOverride || React.useMemo(() => allStories.reduce(
    (earliestDate, story) => {
      const storyTime = (new Date(story.created_at)).getTime();
      return storyTime < earliestDate ? storyTime : earliestDate;
    },
    Date.now()
  ), [allStories]);

  // @todo Temporarily using a naively-calculated end date.
  const naiveEnd = calculateEndDate(startDate, allStories)?.naiveEnd
    || (Date.now() + 30 * 24 * 60 * 60 * 1000);

  const getFormattedDate = (datetime) => {
    const date = new Date(datetime);
    if (date.getTime() - Date.now() < (1000 * 365 * 24 * 60 * 60)) {
      return format(date, 'M/d');
    }
    return format(date, 'M/yyyy');
  };

  const progress = total ? Math.floor(complete / total * 100) : 0;
  const totalStories = allStories.length;
  const pointingProgress = totalStories > 0 && unpointed > 0
    ? Math.floor((totalStories - unpointed) / totalStories * 100)
    : 100;

  return (
    <div className="mb-3">
      <StatsRow>
        {unpointed ? (
          <BigStat stat={unpointed} label="unpointed stories">
            <QuestionOctagonFill className="text-danger" />
          </BigStat>
        ) : null}
        {pointingProgress < 100 ? (
          <dl className={styles.progressbarStat}>
            <dt>
              <ProgressBar
                progress={pointingProgress}
                className={classnames({
                  'bg-success': pointingProgress >= 90,
                  'bg-warning': pointingProgress >= 50 && pointingProgress < 90,
                  'bg-danger': pointingProgress < 50,
                })}
                showLabel={pointingProgress > 25}
              />
            </dt>
            <dd>pointing progress</dd>
          </dl>
        ) : null}
        {complete ? (
          <BigStat stat={complete} label="points completed">
            <CheckCircle className="text-success" />
          </BigStat>
        ) : null}
        {total ? (
          <BigStat stat={total} label="total points">
            <Flag className="text-primary" />
          </BigStat>
        ) : null}
        {complete ? (
          <dl className={styles.progressbarStat}>
            <dt>
              <ProgressBar progress={progress} showLabel={progress > 25} />
            </dt>
            <dd>{`${type} progress`}</dd>
          </dl>
        ) : null}
        {complete && naiveEnd ? (
          <BigStat stat={getFormattedDate(naiveEnd)} label="estimated completion">
            <CalendarCheck />
          </BigStat>
        ) : null}
      </StatsRow>
      <React.Suspense fallback={<Loading />}>
        <SectionBurndown allStories={allStories} startDate={startDate} endDate={naiveEnd} />
      </React.Suspense>
    </div>
  );
}

export default SectionReport;
