import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function StickyContainer({ children, className = '', ...rest }) {
  return (
    <div className={classnames(className, 'sticky-sidebar')} {...rest}>
      {children}
    </div>
  );
}

StickyContainer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

StickyContainer.defaultProps = {
  className: '',
};

export default StickyContainer;
