import {
  saveGameToStore, deleteGameFromStore, changeVotingStatus, maybeAlertNewPokerGame,
} from '../../modules/game';
import { MERGE_ENTITY_CHANGES } from '../types';

export const votingToggled = (data) => (dispatch) => {
  const { game, status, storyData } = data;
  if (status === 'accept') {
    dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: 'stories', id: game.story_id },
      changes: { points: data.points },
    });
  }
  if (status === 'accept' || status === 'delete') {
    return dispatch(deleteGameFromStore(game.id, game.story_id));
  }

  if (status === 'open') {
    // New game, alert to its existence.
    maybeAlertNewPokerGame(storyData);
  }

  return dispatch(saveGameToStore(game, storyData));
};

export const voteReceived = (data) => (dispatch) => dispatch(
  changeVotingStatus(data.game_id, data.user_id, true)
);

export const voteCanceled = (data) => (dispatch) => dispatch(
  changeVotingStatus(data.game_id, data.user_id, false)
);
