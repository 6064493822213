/* eslint-disable react/prop-types */
import React from 'react';
import classNames from 'classnames';
import intersection from 'lodash/intersection';
import UserCard from '../../components/UserCard';
import styles from './withInstigator.scss';

function withInstigator(Component) {
  return function HOC({
    pusherActivity = [],
    children = null,
    className = '',
    fields = [],
    ...props
  }) {
    if (Array.isArray(pusherActivity) && pusherActivity.length) {
      // Use the most recent instigator whose fields matched this component's fields.
      const usableActivity = pusherActivity.reverse().find(
        (activity) => fields.length === 0 || intersection(fields, activity.fields).length > 0
      );
      if (usableActivity) {
        const { instigator } = usableActivity;

        if (instigator?.name) {
          const newProps = {
            ...props,
            className: classNames(className, styles.withInstigator),
          };

          return (
            <Component {...newProps}>
              {children}
              <div className={styles.instigator}>
                <UserCard user={instigator} status="online" />
              </div>
            </Component>
          );
        }
      }
    }

    return <Component {...props}>{children}</Component>;
  };
}

export default withInstigator;
