import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-dropdown-select';

import { makeGetBacklog } from '../../modules/backlogEntity';
import { getCurrentUserActiveTeamId } from '../../modules/team';
import { Story } from '../../types/interfaces/Story';
import { BacklogTarget } from '../../types/interfaces/BacklogTarget';
import * as actions from '../../actions';

import StoryCard from '../Backlog/StoryCard';
import Loading from '../Loading';

type OwnProps = {
  /** Action to go back to the resolution paths screen/ */
  changeMethod(): void;
  /** Action which initiated this dialog. */
  confirmAction(): void;
  /** Items to move to the target backlog. */
  items: Story[];
  /** Target backlog. */
  target: BacklogTarget;
}

const mapState = (state, { target: { id } }: OwnProps) => {
  const activeTeamId = getCurrentUserActiveTeamId(state);
  const teamBacklog = activeTeamId > 0
    ? makeGetBacklog({ id: activeTeamId, contextType: 'teams' })(state)
    : undefined;
  return ({
    backlogEntity: makeGetBacklog({ id, contextType: 'sprints' })(state),
    teamBacklog,
  });
};

const mapDispatch = {
  moveStories: actions.moveStories,
  loadStories: actions.loadStories,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & OwnProps;

export function Swap({
  backlogEntity,
  changeMethod,
  confirmAction,
  items,
  loadStories,
  moveStories,
  teamBacklog,
}: Props) {
  const [
    selection,
    setSelection,
  ] = useState<{ value: number; label: string }[]>([]);

  // When loading a backlog directly, prevent the api request.
  const [initializing, setInitializing] = useState(true);

  // Loading state for <Loading />.
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!initializing || backlogEntity.stories.length === 0) {
      setLoading(true);
      loadStories(backlogEntity)
        .then(() => setLoading(false));
    }
    setInitializing(false);
  }, []);

  if (!backlogEntity || !teamBacklog) {
    return (
      <div>
        There was an error.
      </div>
    );
  }

  /**
   * Confirmation action handler.
   */
  const acceptScopeChange = () => {
    if (selection.length < 1 || !moveStories || !teamBacklog) {
      return;
    }

    // Move items to active sprint.
    confirmAction();

    // Move selected item to team backlog.
    const storiesToMove = backlogEntity.stories
      .filter(({ id }) => id === selection[0].value);

    if (!storiesToMove) {
      dispatchAlert('There was an error moving this story to the backlog', 'error');
      return;
    }

    moveStories(
      storiesToMove,
      teamBacklog.backlogType,
      teamBacklog.backlogId,
      backlogEntity
    );

    dispatchAlert('Swap complete.', 'success');
  };

  const swapStoryOptions = backlogEntity.stories
    .filter(({ status }) => status !== 'done')
    .map((item) => ({
      label: `(${typeof item.points === 'number' ? item.points : '-'}) #${item.id} ${item.title}`,
      value: item.id as unknown as number,
    }));

  return (
    <div className="w-100">
      <h2 className="sr-only">
        Swap stories
      </h2>
      <p className="blockquote mb-1">
        To accommodate this story in this sprint
      </p>
      <ol className="story-list backlog-list mb-3">
        {items.map((item) => (
          <StoryCard key={item.id} story={item} />
        ))}
      </ol>
      <p className="blockquote mb-1">
        choose a story to postpone:
      </p>
      <div className="mb-4">
        {loading ? <Loading /> : (
          <Select
            additionalProps={{ id: 'swap-select' }}
            values={selection}
            onChange={setSelection}
            onDropdownOpen={() => setSelection([])}
            options={swapStoryOptions}
          />
        )}
        <label htmlFor="swap-select" className="form-text text-muted">
          This story will be sent to your team backlog.
        </label>
      </div>
      <div>
        <button type="button" onClick={acceptScopeChange} className="btn btn-outline-danger">
          Accept Scope Change
        </button>
        {' '}
        <button type="button" onClick={changeMethod} className="btn b tn-secondary">
          Go back
        </button>
      </div>
    </div>
  );
}

export default connector(Swap);
