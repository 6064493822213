import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import keycode from 'keycode';
import { ChevronExpand } from 'react-bootstrap-icons';
import pasteAsPlainText from '../../../utils/pasteAsPlainText';

// Components
import withInstigator from '../../../hocs/withInstigator';

function Task({
  id,
  updateTask,
  deleteTask,
  body: initialBody,
  completed,
  className,
  children,
}) {
  const [body, setBody] = useState(initialBody);

  // Update the body if it was changed externally.
  useEffect(() => {
    setBody(initialBody);
  }, [initialBody]);

  const delButton = useRef(null);

  const deleteHandler = (e) => {
    e.preventDefault();
    deleteTask(id, body);
  };

  const handleBlur = ({ target: { textContent } }) => {
    updateTask(id, textContent, completed);
    setBody(textContent);
  };

  const handleKeyDown = (e) => {
    // @todo Holding the modifier key will prevent the form from submission,
    // but we need to add user input sanitization so that the line breaks are maintained.
    const modifierIsPressed = e.ctrlKey || e.shiftKey;
    if (keycode(e) === 'enter' && !modifierIsPressed) {
      e.target.blur();
    }
  };

  return (
    <li className={classNames(className, 'story-task', { 'task-completed': completed })} data-task-id={id}>
      <div className="form-check form-check-inline col-12">
        {/* @todo apply keyboard interaction here. */}
        <span aria-hidden>
          <ChevronExpand size={16} className="mr-2" />
        </span>
        <input
          name="completed"
          id={`task-${id}-completed`}
          type="checkbox"
          className={classNames('form-check-input', { checked: completed })}
          checked={completed}
          onChange={() => updateTask(id, body, !completed)}
        />
        <label className="sr-only" htmlFor={`task-${id}-completed`}>Completion Status</label>
        <div
          className="task-body form-control form-control-lg mb-0"
          contentEditable
          suppressContentEditableWarning
          // contentEditable and role="textbox" are acceptable a11y practices
          // see: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/textbox_role
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
          role="textbox"
          aria-label="Task Content"
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          onPaste={pasteAsPlainText}
          tabIndex="0"
          autoComplete="off"
        >
          {body}
        </div>
        <button ref={delButton} type="button" className="delete-task" onClick={deleteHandler}>
          <span className="sr-only">Delete task</span>
          <span aria-hidden>&times;</span>
        </button>
      </div>
      {children}
    </li>
  );
}

Task.defaultProps = {
  className: '',
  children: null,
};

Task.propTypes = {
  id: PropTypes.number.isRequired,
  updateTask: PropTypes.func.isRequired,
  deleteTask: PropTypes.func.isRequired,
  body: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default withInstigator(Task);
