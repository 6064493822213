import React from 'react';
import PropTypes from 'prop-types';
import { GearFill } from 'react-bootstrap-icons';
import uuid from 'react-uuid';

function Menu({
  children,
  className,
  headingLevel,
  label,
  showLabel,
}) {
  const id = uuid();
  const headingId = uuid();
  const Heading = `h${headingLevel}`;
  return (
    <div className={className}>
      <button
        type="button"
        className="btn btn-sm"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        aria-controls={id}
      >
        {!showLabel && <GearFill aria-hidden />}
        <Heading id={headingId} className={showLabel ? 'h6' : 'sr-only'}>
          {label}
        </Heading>
      </button>
      <div
        className="dropdown-menu dropdown-menu-right"
        id={id}
        aria-labelledby={headingId}
        role="menu"
      >
        {children}
      </div>
    </div>
  );
}

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headingLevel: PropTypes.number,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
};

Menu.defaultProps = {
  className: '',
  headingLevel: 2,
  showLabel: false,
  label: 'Menu',
};

export default Menu;
