/**
 * Update a Project Story.
 *
 * @param {number} projectId - Project ID.
 * @param {number} storyId - Story ID.
 * @param {Object} story - Story data.
 * @param {string} [story.title] - Story title.
 * @param {number} [story.points] - Story points.
 * @param {string} [story.body] - Story body.
 * @param {?string} [story.status] - Story status, either null or "done".
 * @returns {Promise} Promise object representing the API request.
 */
const updateProjectStory = (projectId, storyId, story) => axios.patch(`/api/projects/${projectId}/stories/${storyId}`, story);
export default updateProjectStory;
