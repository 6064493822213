import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';

import * as actions from '../../actions';
import * as uri from '../../utils/uri';
import { selectDehydratedSprint } from '../../selectors';

import Loading from '../Loading';
import { Sprint } from '../../types/interfaces/Sprint';

interface SprintEditPayload {
  /* eslint-disable babel/camelcase */
  start_date: string;
  end_date: string;
  points_committed?: number;
  /* eslint-enable babel/camelcase */
};

function SprintEdit() {
  const getFormattedDate = (iso: string) => iso.replace(/T.*$/, '');
  const { id } = useParams() as { id: string };
  const history = useHistory();

  const dispatch = useDispatch();
  const sprint = useSelector((state) => selectDehydratedSprint(state, id));

  const [startDate, setStartDate] = React.useState(getFormattedDate(sprint?.start_date || ''));
  const [endDate, setEndDate] = React.useState(getFormattedDate(sprint?.end_date || ''));
  const [pointsCommitted, setPointsCommitted] = React.useState(sprint?.points_committed || 0);
  const [loading, setLoading] = React.useState(() => !sprint?.id);

  React.useEffect(() => {
    (async () => {
      if (!sprint) {
        setLoading(true);
        const sprintData = await dispatch(actions.loadSprint(+id)) as unknown as Sprint;
        setStartDate(getFormattedDate(sprintData?.start_date || ''));
        setEndDate(getFormattedDate(sprintData?.end_date || ''));
        setPointsCommitted(sprintData?.points_committed || '');
        setLoading(false);
      }
    })();
  }, [id]);

  React.useEffect(() => {
    if (sprint?.is_ended) {
      history.push(uri.sprint(id));
      dispatchAlert('Completed sprints cannot be edited', 'danger');
    }
  }, [sprint?.is_ended]);

  const title = sprint ? `Editing Sprint ${sprint.title}` : 'Editing Sprint';

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const payload: SprintEditPayload = {
      start_date: startDate,
      end_date: endDate,
    };
    if (sprint.is_active) {
      payload.points_committed = pointsCommitted;
    }

    await dispatch(actions.updateSprint(sprint.id, payload));
    dispatchAlert('Sprint updated successfully', 'success');
    history.push(uri.sprint(id));
  };

  return (
    <>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <main className="container">
        <h1 className="sr-only">
          {title}
        </h1>

        {loading ? <Loading /> : (
          <div className="card mt-3">
            <h2 className="card-header">
              Sprint Settings
            </h2>
            <div className="card-body">
              <form onSubmit={handleFormSubmit}>
                <div className="form-row">
                  <div className="form-group col-md-3">
                    <label htmlFor="new_sprint_start_date">Start Date</label>
                    <input
                      type="date"
                      id="new_sprint_start_date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="form-control"
                      placeholder="Start Date"
                    />
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="new_sprint_end_date">End Date</label>
                    <input
                      type="date"
                      id="new_sprint_end_date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="form-control"
                      placeholder="End Date"
                    />
                  </div>
                </div>

                {sprint.is_active ? (
                  <div className="form-row">
                    <div className="form-group col-md-2">
                      <label htmlFor="points_committed">Point Commitment</label>
                      <input
                        id="points_committed"
                        type="number"
                        className="form-control"
                        value={pointsCommitted}
                        onChange={(e) => setPointsCommitted(+e.target.value)}
                        min={0}
                      />
                    </div>
                  </div>
                ) : null}

                <div className="mt-2">
                  <input type="submit" className="btn btn-lg btn-primary" value="Update Sprint" />
                  {' '}
                  <Link to={uri.sprint(id)} className="btn btn-lg btn-secondary">Cancel</Link>
                </div>
              </form>
            </div>
          </div>
        )}
      </main>
    </>
  );
}

export default SprintEdit;
