import * as React from 'react';
import { Link } from 'react-router-dom';
import withInstigator from '../../../hocs/withInstigator';

interface FeatureLabelProps {
  className?: string;
  isModifyingFeature: boolean;
  setIsModifyingFeature: (toggle: boolean) => void;
  title?: string | null;
  url?: string | null;
  children?: React.ReactNode;
}

function FeatureLabel({
  isModifyingFeature,
  setIsModifyingFeature,
  title = null,
  className = '',
  children = null,
  url = null,
}: FeatureLabelProps) {
  return (
    <div className={className}>
      <div className="feature-label">
        <button
          className="btn badge badge-secondary"
          type="button"
          aria-label="Modify feature"
          onClick={() => setIsModifyingFeature(true)}
          disabled={isModifyingFeature}
        >
          <span className="sr-only">Edit</span>
          {title ? 'Feature' : 'Set Feature'}
        </button>
        {url ? (
          <Link to={url} className="ml-1 truncatable">{title}</Link>
        ) : (
          <span className="ml-1 truncatable">{title}</span>
        )}
      </div>
      {children}
    </div>
  );
}

export default withInstigator(FeatureLabel);
