import * as React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

const props = {
  fieldValue: PropTypes.string.isRequired,
  fieldLabel: PropTypes.string.isRequired,
  updateHandler: PropTypes.func.isRequired,
  header: PropTypes.shape({
    label: PropTypes.string.isRequired,
    level: PropTypes.number,
  }).isRequired,
};

function SingleFieldCard({
  fieldValue: originalFieldValue,
  fieldLabel,
  updateHandler,
  header: {
    label: headerLabel,
    level: headerLevel = 2,
  },
}) {
  const [field, setField] = React.useState(originalFieldValue);
  const HeadEl = `h${headerLevel}`;
  const id = uuid();

  const saveForm = (e) => {
    e.preventDefault();
    updateHandler(field);
  };

  return (
    <div className="card mt-3">
      <HeadEl className="card-header">
        {headerLabel}
      </HeadEl>
      <div className="card-body">
        <form onSubmit={saveForm}>
          <div className="form-group">
            <label htmlFor={`single-field-card-${id}`}>
              {fieldLabel}
            </label>
            <input
              type="text"
              id={`single-field-card-${id}`}
              className="form-control"
              placeholder={fieldLabel}
              value={field}
              onChange={(e) => setField(e.target.value)}
              autoComplete="off"
            />
          </div>
          {field !== originalFieldValue && (
            <button type="submit" className="btn btn-primary mr-2">Save Changes</button>
          )}
        </form>
      </div>
    </div>
  );
}

SingleFieldCard.propTypes = props;

export default SingleFieldCard;
