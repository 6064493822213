import { createSelector } from 'reselect';
import get from 'lodash/get';
import getEntities from './getEntities';
import getTeamId from './getTeamId';

/**
 * Provides an array of team members based on active context.
 */
export default createSelector(
  [getEntities, getTeamId],
  (entities, teamId) => get(
    entities,
    `teams.${teamId}.members`,
    []
  )
    .map((memberId) => get(
      entities,
      `members.${memberId}`,
      false
    ))
    .filter((member) => member)
);
