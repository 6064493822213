/* eslint-disable babel/camelcase */
import { func, number } from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { getCreateSprintData } from '../../services/api';
import Loading from '../Loading';
import NewSprintForm from './NewSprintForm';

function NewSprintDialog({
  closeDialog,
  addSprint,
  teamId,
}) {
  const appEl = document.getElementById('appBinding');

  const [sprintData, setSprintData] = useState();

  useEffect(() => {
    getCreateSprintData(teamId)
      .then(({ data }) => setSprintData(data));
  }, [teamId]);

  return (
    <ReactModal
      isOpen
      onRequestClose={closeDialog}
      contentLabel="Add a new sprint"
      appElement={appEl}
      ariaHideApp={(!!appEl)} // Prevent console errors when testing and no app element available.
    >
      <div className="w-100 py-5 container">
        <h2>Add a New Sprint</h2>
        {!sprintData ? (
          <Loading />
        ) : (
          <div className="row">
            <div className="new-sprint-data-wrapper">
              {sprintData.sprints.length > 0 && (
                <>
                  <h3 className="h4">Recent Sprints</h3>
                  <ol className="list-group">
                    {sprintData.sprints.map((sprint) => (
                      <li key={sprint.id} className="list-group-item">{sprint.title}</li>
                    ))}
                  </ol>
                </>
              )}
            </div>

            <div className="new-sprint-form-wrapper">
              {!!sprintData?.suggestion?.days && (
                <p className="alert alert-info">
                  Based on your recent sprint history, we propose a
                  {` ${sprintData.suggestion.days}-day sprint `}
                  <span className="text-nowrap">
                    {'starting '}
                    {sprintData.suggestion.start_date}
                  </span>
                </p>
              )}
              <NewSprintForm
                addSprint={addSprint}
                cancel={closeDialog}
                suggestion={sprintData?.suggestion}
              />
            </div>
          </div>
        )}
      </div>
    </ReactModal>
  );
}

NewSprintDialog.propTypes = {
  closeDialog: func.isRequired,
  addSprint: func.isRequired,
  teamId: number.isRequired,
};

export default NewSprintDialog;
