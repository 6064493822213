/**
 * Create a new Comment.
 *
 * @param {number} storyId - Story ID.
 * @param {Object} comment - Comment data.
 * @param {string} comment.body - Comment body.
 * @returns {Promise} Promise object representing the API request.
 */
const createComment = (storyId, comment) => axios.post(`/api/stories/${storyId}/comments`, comment);
export default createComment;
