/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Update (replace) a Team config.
 */
const updateTeamConfig = (
  teamId: number,
  config: {
    board?: {
      columns: {
        id?: number;
        title: string;
      }[];
      backlog: {
        title: string;
      };
      done: {
        title: string;
      };
    };
  }
): void => axios.put(`/api/teams/${teamId}/config`, config);
export default updateTeamConfig;
