import * as React from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { Pencil } from 'react-bootstrap-icons';
import { BOARD_COLUMN_SHAPE } from '../../types/props';
import truthyFilterArray from '../../utils/truthyFilterArray';
import { filterStories } from '../../services/storyFilters';
import * as uri from '../../utils/uri';
import { getActiveSprintId } from '../../selectors';

// Components
import StoryFilters from '../StoryFilters';
import BoardColumn from './BoardColumn';
import Menu from '../Menu';
import ToggleSprintButton from '../BacklogScreen/ToggleSprintButton';
import LinkMaybe from '../LinkMaybe';

function FilteredBoard({
  columns,
  updateBoard,
  teamId,
}) {
  const allStories = React.useMemo(() => (columns.reduce(
    (carry, column) => [...carry, ...column.backlog_stories],
    []
  ) || []), [columns]);

  const sprintId = useSelector((state) => getActiveSprintId(state, teamId));

  return (
    <>
      <div className="row">
        <div className="col-12 d-flex justify-content-between">
          <StoryFilters
            key={`board.${teamId}`}
            allStories={allStories}
          />
          <Menu className="display-inline">
            <div className="dropdown-item" role="menuitem">
              <ToggleSprintButton sprintId={sprintId} teamId={teamId} />
            </div>
            <div className="dropdown-divider" aria-hidden />
            <LinkMaybe to={uri.editContext('sprints', sprintId)} className="dropdown-item" role="menuitem">
              <Pencil aria-hidden />
              {' Edit Sprint'}
            </LinkMaybe>
            <LinkMaybe to={`${uri.editContext('teams', teamId)}#edit-board-columns`} className="dropdown-item" role="menuitem">
              <Pencil aria-hidden />
              {' Edit Board Columns'}
            </LinkMaybe>
          </Menu>
        </div>
      </div>

      {/* key on BoardColumn is necessary to re-render SortableJS */}
      <div className="board-columns">
        {columns.map(({
          id,
          title,
          status,
          backlog_stories: backlogStories,
        }) => (
          <BoardColumn
            key={`boardcolumn.${id}`}
            id={id}
            title={title}
            status={status}
            stories={backlogStories}
            teamId={teamId}
            updateHandler={(payload) => updateBoard({ teamId, ...payload })}
          />
        ))}
      </div>
    </>
  );
}

FilteredBoard.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(BOARD_COLUMN_SHAPE)).isRequired,
  updateBoard: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
};

export default connect(({ storyFilters }, { columns }) => ({
  columns: columns.map((column) => ({
    ...column,
    backlog_stories: filterStories(storyFilters, truthyFilterArray(column?.backlog_stories)),
  })),
}))(FilteredBoard);
