/**
 * Move a story around in the team's sprint board.
 *
 * @param {number} teamId - Team ID.
 * @param {Object} payload - Request payload.
 * @param {number} payload.story_id - Story ID.
 * @param {number|string} payload.to_column - Column to which to move the story.
 *                                            May be a BoardColumn ID, "backlog",
 *                                            or "done".
 * @param {number} [payload.order] - New order for the story. May be omitted if
 *                                   to_column is "done", otherwise is required.
 * @returns {Promise} Promise object representing the API request.
 */
const updateBoard = (teamId, payload) => axios.patch(`/api/teams/${teamId}/board`, payload);
export default updateBoard;
