/**
* Echo exposes an expressive API for subscribing to channels and listening
* for events that are broadcast by Laravel. Echo and event broadcasting
* allows your team to easily build robust real-time web applications.
*/

import Echo from 'laravel-echo';

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 *
 * @todo Audit the bootstrap features we are using and list them explicitly here.
 */
import 'bootstrap';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;
window.axios.defaults.withXSRFToken = true;

window.Pusher = require('pusher-js');

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: process.env.MIX_PUSHER_APP_KEY,
  cluster: process.env.MIX_PUSHER_APP_CLUSTER,
  forceTLS: true,
  encrypted: true,
  // eslint-disable-next-line no-unused-vars
  authorizer: (channel, options) => ({
    authorize: (socketId, callback) => {
      axios.post('/api/broadcasting/auth', {
        socket_id: socketId,
        channel_name: channel.name,
      }, {
        headers: {
          'X-Active-Team': 'ignore',
        },
      })
        .then((response) => {
          callback(false, response.data);
        })
        .catch((error) => {
          callback(true, error);
        });
    },
  }),
});

// Refresh the CSRF token every hour.
setInterval(
  () => window.axios.get('/sanctum/csrf-cookie').catch((error) => {
    window.dispatchAlert('Something went wrong behind-the-scenes, please refresh your browser.', 'danger', 3599900);
    // eslint-disable-next-line no-console
    console.error(error);
  }),
  3600000
);
