import React from 'react';
import PropTypes from 'prop-types';

function StoryDate({ date, className, children }) {
  const [isoDate] = date.toISOString().split('T');
  const formatted = date.getFullYear() === (new Date()).getFullYear()
    ? date.toLocaleDateString().replace(/[/-]?\d{4}[/-]?/, '')
    : date.toLocaleDateString().replace(/20(\d\d)/, '$1');

  return (
    <>
      <time dateTime={isoDate} className={className}>
        {children}
        {' '}
        {formatted}
      </time>
      {' '}
    </>
  );
}

StoryDate.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  date: PropTypes.instanceOf(Date).isRequired,
};

StoryDate.defaultProps = {
  children: '',
  className: '',
};

export default StoryDate;
