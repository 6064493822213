import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Calendar3,
  CalendarX,
  Check,
  X,
} from 'react-bootstrap-icons';

// Internal dependencies.
import dateFromYMD from '../../../utils/dateFromYMD';
import withInstigator from '../../../hocs/withInstigator';

import StoryDate from '../../StoryDate';

function DateField({
  date,
  setDate,
  className,
  children,
}) {
  const [editing, setEditing] = useState(false);
  const [dateValue, setDateValue] = useState(date || '');

  const submitHandler = (e) => {
    e.preventDefault();
    setDate(dateValue);
    setEditing(false);
  };

  const cancelForm = () => {
    setDateValue(date);
    setEditing(false);
  };

  const removeDate = () => {
    setDate('');
    setEditing(false);
  };

  return (
    <div className={`instigator-wrapper ${className}`}>
      {editing ? (
        <form className="form-inline" onSubmit={submitHandler}>
          <label htmlFor="story-date-picker" className="sr-only">Select Date</label>
          <div className="input-group">
            <input
              type="date"
              className="form-control story-date-picker"
              id="story-date-picker"
              value={dateValue}
              onChange={(e) => setDateValue(e.target.value)}
            />
            <div className="input-group-append">
              <button type="submit" className="btn btn-outline-success">
                <Check />
                <span className="sr-only">Save Changes</span>
              </button>
              <button type="button" className="btn btn-outline-secondary" onClick={cancelForm}>
                <X />
                <span className="sr-only">Cancel Changes</span>
              </button>
              {!!date && (
                <button type="button" className="btn btn-outline-danger" onClick={removeDate}>
                  <CalendarX />
                  <span className="sr-only">Remove Date</span>
                </button>
              )}
            </div>
          </div>
        </form>
      ) : (
        <button className="btn" type="button" onClick={() => setEditing(true)} aria-label="Set story date">
          <Calendar3 />
          {!!date && <StoryDate date={dateFromYMD(date)} className="ml-1" />}
        </button>
      )}
      {children}
    </div>
  );
}

DateField.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

DateField.defaultProps = {
  date: '',
  className: '',
  children: null,
};

export default withInstigator(DateField);
