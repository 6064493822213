import uniqBy from 'lodash/uniqBy';

/**
 * Filter stories by a contributor.
 *
 * @param {number} contributorId - The user id of the contributor to filter.
 * @param {array} stories - The stories to match against the contributorId.
 * return {array} Stories that have that contributor.
 */
const contributor = (contributorId, stories) => stories.map(
  (story) => ({
    ...story,
    filtered: contributorId === 'none'
      ? story.contributors_data?.length > 0
      : typeof story.contributors_data?.find(({ id }) => id === contributorId) === 'undefined',
  })
);
export default contributor;

/**
 * Given an array of stories, get a unique array of contributors from them.
 *
 * @param {array} stories - Stories.
 * @returns {array} Distinct array of story contributors.
 */
export const getAvailableContributors = (stories) => uniqBy(
  stories.reduce((carry, story) => {
    if (story?.contributors_data?.length) {
      carry.push(...story.contributors_data);
    }
    return carry;
  }, []),
  'id'
);
