import * as React from 'react';
import {
  Archive,
  CheckCircle,
  Flag,
  Pencil,
  QuestionOctagonFill,
  Trash,
} from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

// Internal dependencies.
import { Feature } from '../../types/interfaces/Feature';
import * as uri from '../../utils/uri';

// Components.
import ConfirmationDialog from '../ConfirmationDialog';
import Menu from '../Menu';
import StatsRow from '../BigStat/StatsRow';
import BigStat from '../BigStat';
import ProgressBar from '../ProgressBar/ProgressBar';

// Styles.
import styles from './FeatureCard.scss';

interface FeatureCardProps {
  contextId: number;
  contextType: string;
  feature: Feature;
  isArchived?: boolean;
  onArchive?: (featureId: number) => void;
  onUnarchive?: (featureId: number) => void;
  onDelete: (featureId: number) => void;
  onEdit: (featureId: number) => void;
}

export default function FeatureCard({
  contextId,
  contextType,
  feature: {
    id,
    title,
    description,
    meta = {},
  },
  isArchived = false,
  onArchive = () => {},
  onDelete,
  onEdit,
  onUnarchive = () => {},
}: FeatureCardProps) {
  const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);
  const [isConfirmingArchive, setIsConfirmingArchive] = React.useState(false);

  const {
    stories,
    unpointed_stories: unpointed,
    points,
    closed_points: complete,
  } = meta;
  const deleteMessage = stories !== 0
    ? `This will remove the feature from ${stories || 'all'} ${stories === 1 ? 'story' : 'stories'}.`
    : null;

  const deleteHandler = () => {
    setIsConfirmingDelete(false);
    onDelete(id);
  };

  const archiveHandler = () => {
    setIsConfirmingArchive(false);
    onArchive(id);
  };

  const progress = points && complete ? Math.floor((complete / points) * 100) : 0;

  return (
    <div className="col mb-4">
      <div className="card h-100">
        <div className={classnames('card-body', styles.cardBody)}>
          <div>
            <div className="float-right">
              <Menu className="display-inline">
                <button
                  type="button"
                  onClick={() => onEdit(id)}
                  className={classnames('dropdown-item')}
                >
                  <Pencil aria-hidden />
                  {' Edit Feature'}
                </button>
                {isArchived ? (
                  <button
                    type="button"
                    onClick={() => onUnarchive(id)}
                    className={classnames('dropdown-item')}
                  >
                    <Archive aria-hidden />
                    {' Unarchive Feature'}
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={() => setIsConfirmingArchive(true)}
                    className={classnames('dropdown-item')}
                  >
                    <Archive aria-hidden />
                    {' Archive Feature'}
                  </button>
                )}
                <div className="dropdown-divider" aria-hidden />
                <button
                  type="button"
                  onClick={() => setIsConfirmingDelete(true)}
                  className={classnames('dropdown-item', 'text-danger')}
                >
                  <Trash aria-hidden />
                  {' Delete Feature'}
                </button>
              </Menu>
            </div>
            <h2 className="card-title h4">
              <Link to={uri.feature(contextType, contextId, id)}>
                {title}
              </Link>
            </h2>
          </div>
          <div className={styles.body}>
            <p className="flex-grow-1">{description}</p>
            {stories ? (
              <StatsRow className={styles.statsRow}>
                {unpointed ? (
                  <BigStat stat={unpointed} label="unpointed stories">
                    <QuestionOctagonFill className="text-danger" />
                  </BigStat>
                ) : null}
                <BigStat stat={complete || 0} label="points completed">
                  <CheckCircle aria-hidden className="text-success" />
                </BigStat>
                <BigStat stat={points || 0} label="total points">
                  <Flag aria-hidden className="text-primary" />
                </BigStat>
              </StatsRow>
            ) : null}
            {stories === 0 ? (
              <div className="alert alert-warning">This feature has no stories</div>
            ) : null}
          </div>
          {stories ? (
            <ProgressBar progress={progress} showLabel={progress > 15} />
          ) : null}
        </div>
        {isConfirmingDelete ? (
          <ConfirmationDialog
            message="Are you sure you want to delete this feature?"
            additionalInfo={deleteMessage}
            closeDialog={() => setIsConfirmingDelete(false)}
            confirmAction={deleteHandler}
          />
        ) : null}
        {isConfirmingArchive ? (
          <ConfirmationDialog
            message="Are you sure you want to archive this feature?"
            additionalInfo="Stories cannot be added to the feature while it is archived, but stories in this feature will not be impacted. You can unarchive a feature if needed."
            closeDialog={() => setIsConfirmingArchive(false)}
            confirmAction={archiveHandler}
          />
        ) : null}
      </div>
    </div>
  );
}
