/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Download, Trash } from 'react-bootstrap-icons';

// Internal dependecies.
import withInstigator from '../../../hocs/withInstigator';
import { Attachment as IAttachment } from '../../../types/interfaces/Attachment';
import { isImage, isVideo } from '../../../utils/fileHelpers';

// Components.
import FileIcon from './FileIcon';

interface AttachmentProps {
  attachment: IAttachment;
  setSelectedAttachment: (attachment: IAttachment) => void;
  downloadHandler: (id: number) => void;
  deleteHandler: (id: number) => void;
  styles: {
    attachment: string;
    thumbnailButton: string;
    clickScreen: string;
    download: string;
    delete: string;
  };
  className?: string;
  children?: React.ReactNode;
}

function Attachment({
  attachment,
  setSelectedAttachment,
  downloadHandler,
  deleteHandler,
  styles,
  className = '',
  children = null,
}: AttachmentProps) {
  const boolIsImage = isImage(attachment.filetype);
  const boolIsVideo = isVideo(attachment.filetype);
  const thumbClickHandler = () => {
    if (boolIsImage || boolIsVideo) {
      setSelectedAttachment(attachment);
    } else {
      window.open(attachment.url);
    }
  };
  return (
    <div className={`${styles.attachment} ${className}`}>
      <button
        type="button"
        className={`btn ${styles.thumbnailButton}`}
        onClick={thumbClickHandler}
      >
        {boolIsImage ? (
          <>
            <img src={attachment.url} alt={attachment.name} className="img-thumbnail" data-id={attachment.id} />
            <span className="sr-only">Enlarge image</span>
          </>
        ) : null}
        {boolIsVideo ? (
          <>
            <span className={styles.clickScreen} />
            <video
              width="400"
              controls
              preload="metadata"
              className="img-thumbnail"
              data-id={attachment.id}
              aria-label={`Video: ${attachment.name}`}
            >
              <source src={attachment.url} type={attachment.filetype} />
            </video>
            <span className="sr-only">Enlarge video</span>
          </>
        ) : null}
        {!boolIsImage && !boolIsVideo ? (
          <FileIcon filetype={attachment.filetype} />
        ) : null}
      </button>
      <button
        type="button"
        className={`btn text-info ${styles.download}`}
        onClick={() => downloadHandler(attachment.id)}
      >
        <Download aria-hidden />
        <span className="sr-only">Download</span>
      </button>
      <button
        type="button"
        className={`btn text-danger ${styles.delete}`}
        onClick={() => deleteHandler(attachment.id)}
      >
        <Trash aria-hidden />
        <span className="sr-only">Delete</span>
      </button>

      {children}
    </div>
  );
}

export default withInstigator(Attachment);
