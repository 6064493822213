import * as React from 'react';
import classnames from 'classnames';

interface StoryTitleWarningProps {
  length: number;
}

function StoryTitleWarning({ length }: StoryTitleWarningProps) {
  return (
    <>
      {length > 90 && length <= 180 ? (
        <div role="alert" className="alert alert-primary">
          This title is getting long! We recommend keeping titles brief and elaborating on stories
          in the story body.
        </div>
      ) : null}

      {length > 180 ? (
        <div
          role="alert"
          className={classnames('alert', (length > 191 ? 'alert-danger' : 'alert-warning'))}
        >
          {`${191 - length} characters remaining`}
        </div>
      ) : null}
    </>
  );
}

export default StoryTitleWarning;
