/**
 * Merge changes into an entity's data, leaving other properties untouched. This
 * is like a PATCH request.
 *
 * See `storeEntity()` to replace an entity's data (akin to a PUT request).
 *
 * @param {Object} state - Entities state.
 * @param {number|string} id - Entity ID.
 * @param {Object} changes - Changes to merge into.
 * @returns {Object} New state.
 */
export default (state, id, changes) => {
  // Be sure not to override if partial = false.
  const partial = {};
  if (changes?.partial === true && state?.[id]?.partial === false) {
    partial.partial = false;
  }

  return {
    ...state,
    [id]: {
      ...state[id] || {},
      ...changes,
      ...partial,
    },
  };
};
