/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { batch } from 'react-redux';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { entitySchema } from '../types';
import createEntityReducer from '../utils/createEntityReducer';
import mergeReducers from '../utils/mergeReducers';
import createMoveStoryReducer from '../utils/createMoveStoryReducer';
import createGetEntity from '../utils/apiCreators/createGetEntity';
import { receiveEntity, receiveManyEntities } from '../actions';
import { UPDATE_CURRENT_TEAM } from '../actions/types';

// @todo this is here to pass type checking; should be moved to a shared location
declare function dispatchAlert(body: any, type?: string, timeout?: number): void;

export const STATE_KEY = 'teams';

/**
 * Team reducer.
 */
export default mergeReducers(
  // Default entity reducer.
  createEntityReducer(STATE_KEY),

  // Reducer for moving stories in the backlog.
  createMoveStoryReducer(STATE_KEY)
);

/**
 * Select the hydrated team from state.
 *
 * @param {Object} state - Full redux state.
 * @param {string} id - Team ID.
 * @returns {Object}
 */
export const selectHydrated = (state: any, id: string): any => (
  denormalize(id, entitySchema[STATE_KEY], state?.data?.entities || {})
);

/**
 * Select all the dehydrated (normalized) teams in state. Note that this is
 * not necessarily all teams in the system, just the ones that have so far
 * been queried.
 *
 * @param {Object} state - Full redux state.
 * @returns {Object} Dehydrated teams keyed by ID.
 */
export const selectDehydratedTeams = (state) => (state?.data?.entities?.teams || {});

/**
 * Select all hydrated teams from the state.
 *
 * @param {Object} state - Full redux state.
 * @returns {Team[]} Array of hydrated teams.
 */
export const selectTeams = createSelector(
  selectDehydratedTeams,
  (state) => (key) => selectHydrated(state, key),
  (teams, hydrator) => Object.keys(teams).map(hydrator)
);

/**
 * Select team_ids out of appMeta.
 *
 * @param {object} Redux state.
 *
 * @returns {Number[]} Ids of the user's active teams.
 */
export const getActiveTeams = (state) => (state?.appMeta?.team_ids || []);

/**
 * Select current_team out of appMeta.
 *
 * @param {object} Redux state.
 *
 * @returns {number} ID of the user's current team.
 */
export const getCurrentUserActiveTeamId = (state) => +(state?.appMeta?.current_team || 0);

/**
 * Switch the current user's current team.
 *
 * @param {number} teamId - Team ID.
 */
export const switchCurrentTeam = (teamId) => (dispatch, _getState, { api }) => (
  api.switchCurrentTeam(teamId)
    .then(({ data: team }) => {
      // Update the active team cookie and default header. This is duplicated in
      // ScrumApp.js, but needs to be here as well to prevent race conditions.
      (window as any).axios.defaults.headers.common['X-Active-Team'] = teamId;
      document.cookie = `activeTeam=${teamId};path=/;secure`;

      batch(() => {
        dispatch({
          type: UPDATE_CURRENT_TEAM,
          teamId,
        });
        dispatch(receiveEntity(STATE_KEY, team));
      });
      dispatchAlert(`Active team switched to ${team.name}`, 'success');
      return team;
    })
    .catch((error) => dispatchAlert(error.toString()))
);

/**
 * Dispatch action to get team.
 */
export const getTeam = createGetEntity(STATE_KEY, 'getTeam');

/**
 * Dispatch an action to get all teams.
 */
export const getAllTeams = () => (dispatch, _getState, { api }) => (
  api.getAllTeams()
    .then(({ data }) => dispatch(receiveManyEntities(STATE_KEY, data)))
    .catch(api.errorHandler)
);
