// thunks
export const SET_STORIES = 'SET_STORIES';

// regular redux
export const SET_UI_FLAG = 'SET_UI_FLAG';

// composed reducers
export const INITIALIZE_APP_DATA = 'INITIALIZE_APP_DATA';
export const MOVE_STORY = 'MOVE_STORY';
export const MOVE_BOARD_STORY = 'MOVE_BOARD_STORY';
export const UPDATE_CURRENT_TEAM = 'UPDATE_CURRENT_TEAM';

export const ADD_ENTITY = 'ADD_ENTITY';
export const DELETE_ENTITY = 'DELETE_ENTITY';
export const MERGE_ENTITY_CHANGES = 'MERGE_ENTITY_CHANGES';
export const RECEIVE_ENTITY = 'RECEIVE_ENTITY';
export const SET_ENTITY_ORDER = 'SET_ENTITY_ORDER';
export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const SET_ENTITY = 'SET_ENTITY';
export const UNSET_ENTITY = 'UNSET_ENTITY';

export const PUSHER_JOIN = 'PUSHER_JOIN';
export const PUSHER_LEAVE = 'PUSHER_LEAVE';
export const PUSHER_INIT_MY_CHANNELS = 'PUSHER_INIT_MY_CHANNELS';
export const PUSHER_ADD_MY_CHANNEL = 'PUSHER_ADD_MY_CHANNEL';

// context type map... cheaper than installing an inflection lib.
export const TYPE_MAP = {
  features: 'feature',
  projects: 'project',
  sprints: 'sprint',
  stories: 'story',
  teams: 'team',
};

export const TYPE_MAP_FROM_SINGULAR = {
  feature: 'features',
  project: 'projects',
  sprint: 'sprints',
  story: 'stories',
  team: 'teams',
};

export const LABEL_MAP = {
  features: 'Feature',
  projects: 'Project',
  sprints: 'Sprint',
  stories: 'Story',
  teams: 'Team',
};
