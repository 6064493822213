import { Feature } from '../../../types/interfaces/Feature';

/**
 * Create a new project feature.
 */
export default function createProjectFeature(
  projectId: number,
  payload: { title: string, description?: string }
): Promise<Feature> {
  return axios.post(`/api/projects/${projectId}/features`, payload)
    .then(({ data }) => data);
}
