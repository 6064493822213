import * as React from 'react';
import { func, shape } from 'prop-types';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

// Internal dependencies.
import * as actions from '../../actions';
import { TYPE_MAP_FROM_SINGULAR } from '../../actions/types';
import { selectDehydratedTeams } from '../../modules/team';
import { selectDehydratedProjects } from '../../modules/project';
import { BACKLOG_SHAPE, STORY_SHAPE } from '../../types/props';

// Components.
import Loading from '../Loading';

const backlogFinderPropTypes = {
  closeDialog: func.isRequired,
  source: shape(BACKLOG_SHAPE),
  story: shape(STORY_SHAPE),
};

function BacklogSelector({
  closeDialog,
  story,
  source,
}) {
  const appEl = document.getElementById('appBinding');
  const [loading, setLoading] = React.useState(true);
  const [backlogSelected, setBacklogSelected] = React.useState('');

  // Redux stuff.
  const dispatch = useDispatch();
  const teams = Object.values(useSelector(selectDehydratedTeams));
  const projects = Object.values(useSelector(selectDehydratedProjects));

  React.useEffect(() => {
    // Load the full list of teams and projects into state.
    Promise.all([
      dispatch(actions.getAllTeams()),
      dispatch(actions.getAllProjects()),
    ])
      .then(() => setLoading(false));
  }, []);

  const onSaveHandler = async (e) => {
    e.preventDefault();
    const [type, id] = backlogSelected.split(':');

    await dispatch(actions.moveStory(
      story,
      TYPE_MAP_FROM_SINGULAR[type],
      +id,
      source
    ));
    window.dispatchAlert('Story moved successfully', 'success');
  };

  return (
    <ReactModal
      isOpen
      contentLabel="Move story to a new backlog"
      appElement={appEl}
      ariaHideApp={(!!appEl)} // Prevent console errors when testing and no app element available.
      onRequestClose={closeDialog}
    >
      <div className="container d-flex align-items-center" style={{ height: '100%' }}>
        <div>
          <h2 className="text-muted mb-3">
            {`Moving story #${story.id} "${story.title}" from ${source.backlogTypeLabel} ${source.title || source.name}`}
          </h2>
          {loading ? <Loading /> : (
            <>
              <div className="form-group">
                <label htmlFor="destination_backlog" className="sr-only">Destination Backlog</label>
                {/* TODO this isn't scalable, someday make it an autocomplete search field */}
                <select
                  id="destination_backlog"
                  value={backlogSelected}
                  onChange={(e) => setBacklogSelected(e.target.value)}
                  className="form-control form-control-lg"
                >
                  <option value="">Select a destination backlog</option>
                  <optgroup label="Teams">
                    {teams.map((team) => (
                      <option key={team.id} value={`team:${team.id}`}>{team.name}</option>
                    ))}
                  </optgroup>
                  <optgroup label="Projects">
                    {projects.map((project) => (
                      <option key={project.id} value={`project:${project.id}`}>{project.title}</option>
                    ))}
                  </optgroup>
                </select>
              </div>
              <div className="form-group">
                <button type="submit" onClick={onSaveHandler} className="btn btn-lg btn-outline-danger" disabled={backlogSelected === ''}>
                  Move Story
                </button>
                {' '}
                <button type="button" onClick={closeDialog} className="btn btn-lg btn-secondary">
                  Cancel
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

BacklogSelector.propTypes = backlogFinderPropTypes;

export default BacklogSelector;
