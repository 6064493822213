import * as React from 'react';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import classnames from 'classnames';

// Internal dependencies.
import useDropHtmlImageAsMarkdown from '../../../hooks/useDropHtmlImageAsMarkdown';
import useFileUpload from '../../../hooks/useFileUpload';
import { Attachment } from '../../../types/interfaces/Attachment';
import { generateMarkdownForAttachment } from '../../../utils/fileHelpers';
import { insertText } from '../../../utils/textHelpers';

// Components.
import ProgressBar from '../../ProgressBar/ProgressBar';

// Styles.
import styles from './Comments.scss';

interface CommentEditorProps {
  body: string;
  updateBody: (content) => void;
  storyId: number;
  id: string;
}

function CommentEditor({
  body, updateBody, storyId, id: textareaId,
}: CommentEditorProps) {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const dropzoneRef = React.useRef<DropzoneRef>(null);
  const { handleUpload, uploadProgress, isUploading } = useFileUpload(storyId);
  useDropHtmlImageAsMarkdown(textareaRef, updateBody);

  const changeHandler = (e: React.ChangeEvent) => {
    updateBody((e.target as HTMLTextAreaElement).value);
  };

  /**
   * Insert a newly-uploaded attachment into the markdown editor if it's active.
   */
  const insertAttachmentIntoMarkdownEditor = (attachment: Attachment) => {
    if (textareaRef?.current) {
      const newContent = insertText(
        textareaRef.current,
        generateMarkdownForAttachment(attachment),
        { appendNewline: true }
      );

      updateBody(newContent);
    }
  };

  /**
   * Drop handler for React Dropzone. Uploads the files and inserts them into the editor.
   */
  const onFileDrop = async (files: File[]) => {
    const attachments = await handleUpload(files);

    attachments.forEach(insertAttachmentIntoMarkdownEditor);
  };

  const pasteHandler = (e: React.ClipboardEvent) => {
    const { files } = e.clipboardData;
    if (files.length) {
      onFileDrop(Array.from(files));
    }
  };

  const openFileDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <>
      {isUploading ? (
        <div className={styles.progressWrapper}>
          <ProgressBar progress={uploadProgress} />
        </div>
      ) : null}

      <Dropzone ref={dropzoneRef} onDrop={onFileDrop} noClick noKeyboard>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()} role="none">
            <textarea
              id={textareaId}
              ref={textareaRef}
              value={body}
              className={classnames('form-control', styles.commentEditor, { [styles.dragging]: isDragActive })}
              onChange={changeHandler}
              onPaste={pasteHandler}
              required
            />
            <div
              className={classnames(
                styles.attachmentInstructions,
                { [styles.dragging]: isDragActive }
              )}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop files to upload</p>
              ) : (
                <button
                  className="btn"
                  type="button"
                  onClick={openFileDialog}
                >
                  Drag and drop attachments to upload, or click here to select
                </button>
              )}
            </div>
          </div>
        )}
      </Dropzone>
    </>
  );
}

export default CommentEditor;
