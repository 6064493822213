import contributor from './contributor';
import epic from './epic';
import feature from './feature';
import points from './points';
import status from './status';
import project from './project';
import team from './team';

export const filters = {
  contributor,
  epic,
  feature,
  points,
  project,
  status,
  team,
};

/**
 * Filter stories.
 *
 * @param {object|object[]} filter - Filter(s) to apply to stories. If an array,
 *                                   applies all the filters sequentially.
 * @param {string} filter.type - Type of filter to apply.
 * @param {number} filter.id - ID of filtering object.
 * @param {array} stories - Stories to filter.
 */
export const filterStories = (filter, stories) => {
  if (Array.isArray(filter)) {
    return filter.reduce(
      (filteredStories, currentFilter) => filterStories(currentFilter, filteredStories),
      stories
    );
  }

  if (typeof filters[filter.type] === 'function') {
    return filters[filter.type](filter.id, stories);
  }

  return stories;
};
