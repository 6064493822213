import { INITIALIZE_APP_DATA } from '../actions/types';

/**
 * Create an order reducer. Used by normalizr to maintain root object order.
 *
 * @param {string} entityPlural - Plural entity key, e.g. stories.
 * @returns {function} Reducer.
 */
export default (entityPlural) => (state = [], action = {}) => {
  switch (action.type) {
    case INITIALIZE_APP_DATA:
      return [...action.data.result[entityPlural]];
  }
  return state;
};
