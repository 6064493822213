/**
 * Scroll an element into view using a hash.
 *
 * @param {string} hash - Has to scroll to. Hashmark is optional.
 */
export default (hash) => {
  if (hash) {
    const el = document.getElementById(hash.replace(/^#/, ''));
    if (el) {
      el.scrollIntoView();
    }
  }
};
