/**
 * Remove an entity ID reference from a references list in some other entity's
 * state.
 *
 * This differs from unsetEntityId in that this works on a list of references vs
 * a singular reference.
 *
 * For instance, given the teams state `1.backlog_stories = [3, 2, 1]`, we would
 * call `removeEntityId(state, 3, 1, 'backlog_stories')` to produce the result
 * `1.backlog_stories = [2, 1]`.
 *
 * @param {Object} state - Entities state.
 * @param {number|string} id - ID of referenced (child) entity to remove.
 * @param {number|string} targetId - ID of the referencing (parent) entity.
 * @param {string} key - Reference key, e.g. `backlog_stories`.
 * @returns {Object} New state.
 */
export default (state, id, targetId, key) => ({
  ...state,
  [targetId]: {
    ...state[targetId],
    [key]: state[targetId][key].filter((entityId) => entityId !== id),
  },
});
