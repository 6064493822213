import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  CheckCircleFill, Calendar3, CollectionFill, StickyFill,
} from 'react-bootstrap-icons';

// Internal dependencies.
import { STORY_SHAPE } from '../../types/props';
import { TYPE_MAP } from '../../actions/types';
import { hasCurrentUserVotedInGame } from '../../modules/game';
import dateFromYMD from '../../utils/dateFromYMD';
import buildSourceMeta from '../../utils/buildSourceMeta';
import withInstigator from '../../hocs/withInstigator';

// Components
import StoryDate from '../StoryDate';
import StoryId from '../StoryId';
import UserList from '../UserList';
import Points from '../Points';
import NewFlag from '../NewFlag';

// Styles
import styles from './StoryCard.scss';

const StoryCardProps = {
  /** The story to display. */
  story: PropTypes.shape(STORY_SHAPE).isRequired,
  /** Whether or not the current user has voted on an open poker game. */
  currentUserHasVoted: PropTypes.bool,
  /** Whether or not there is an open poker game. */
  hasPokerGame: PropTypes.bool,
  /** Any React children. Required to place withInstigator styles. */
  children: PropTypes.node,
  /** Additional classnames to display on root component. Required for withInstigator. */
  className: PropTypes.string,
  /** The backlog in which this story is being displayed. */
  context: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
  }),
  /** The teamID that owns the sprint this story is in, if in a sprint context. */
  sprintTeam: PropTypes.number,
  /** If the component should show the epic and parent information. */
  showRelationshipFlags: PropTypes.bool,
};

function StoryCard({
  story: {
    id,
    points,
    title,
    url,
    contributors_data: contributors = [],
    epic = {},
    is_epic: isEpic = false,
    filtered,
    status,
    date,
    project,
    team,
    source_type: sourceType,
    source_id: sourceId,
    created_at: createdAt = '',
  } = {},
  hasPokerGame = false,
  currentUserHasVoted = false,
  children = null,
  className = '',
  context: storyContext = {},
  sprintTeam = false,
  showRelationshipFlags = false,
}) {
  /**
   * Formatted source meta based on the source type.
    * @type {object}
    * @property {number} id The source ID.
    * @property {string} type The source type.
    * @property {string} label The name or title of the source.
   */
  const source = React.useMemo(
    () => buildSourceMeta(project, team, sourceType),
    [project?.id, team?.id, sourceType]
  );

  /**
   * If this story is in the same backlog as its source.
   * @type bool
   */
  const sourceMatchesBacklog = (TYPE_MAP[storyContext.type] === sourceType)
      && storyContext.id === sourceId;

  /**
   * If this story is in a sprint owned by its source team.
   * @type bool
   */
  const sourceMatchesTeam = sourceType === 'team' && sprintTeam === sourceId;

  /**
   * Whether or not the story is split from an epic.
   * @type bool
   */
  const isChild = typeof epic?.id === 'number';

  return (
    <li
      className={classnames(
        className,
        styles.container,
        {
          'card-filtered': filtered,
          done: status === 'done',
          [styles.epic]: showRelationshipFlags && isEpic,
          [styles.child]: showRelationshipFlags && isChild && !isEpic,
        }
      )}
      data-story-id={id}
    >
      <div className={styles.body}>
        <div className="card-top">
          {status === 'done' ? (
            <span className="story-done-check">
              <CheckCircleFill />
              <span className="sr-only">Done</span>
              {' '}
            </span>
          ) : null}

          <NewFlag createdAt={createdAt} />

          {date ? (
            <StoryDate date={dateFromYMD(date)} className="story-date"><Calendar3 /></StoryDate>
          ) : null}

          <StoryId id={id} />

          {hasPokerGame ? (
            <>
              <span
                className={classnames('story-flag-poker', {
                  voted: currentUserHasVoted,
                })}
              >
                {currentUserHasVoted ? 'Voted' : 'Voting'}
              </span>
              {' '}
            </>
          ) : null}

          {!(sourceMatchesBacklog || sourceMatchesTeam) ? (
            <div className="badge badge-secondary">
              {source.label}
            </div>
          ) : null}

          {showRelationshipFlags && isChild ? (
            <div className={styles.epicLabel}>
              {epic.title}
            </div>
          ) : null}

          <div className="user-strip">
            <UserList
              label="Contributors"
              users={contributors}
              headingLevel={4}
              headingClassName="sr-only"
            />
          </div>
        </div>
        <div className="card-bottom">
          <div className="flex-grow-1">
            <Link to={url} className="story-title d-block">
              {title}
            </Link>
          </div>
          <Points value={points} />
        </div>
      </div>
      {showRelationshipFlags && (isEpic || isChild) ? (
        <div className={styles.relationship}>
          {isEpic ? (
            <div title="Epic">
              <span className="sr-only">
                Epic
              </span>
              <CollectionFill aria-hidden />
            </div>
          ) : (
            <span title="Split story">
              <span className="sr-only">Split story</span>
              <StickyFill aria-hidden />
            </span>
          )}
        </div>
      ) : null}
      {children}
    </li>
  );
}

StoryCard.propTypes = StoryCardProps;

export default withInstigator(
  connect(
    (
      state,
      {
        story: {
          active_game: gameId,
        },
        context: {
          id,
          type: contextType,
        } = {},
      }
    ) => {
      const sprintTeam = (contextType === 'sprints')
        ? state.data?.entities?.sprints?.[id]?.team_id : undefined;
      const currentUserHasVoted = hasCurrentUserVotedInGame(state, gameId);
      return {
        currentUserHasVoted,
        hasPokerGame: currentUserHasVoted !== null,
        sprintTeam,
      };
    },
    {}
  )(StoryCard)
);
