import * as React from 'react';
import PropTypes from 'prop-types';

function StoryId({ id }) {
  return (
    <>
      <span className="story-id">
        {`#${id}`}
      </span>
      {' '}
    </>
  );
}

StoryId.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default StoryId;
