import * as React from 'react';

interface MarkdownEditorProps {
  value: string;
  setValue: (newValue: string) => void;
  onPaste: (e: React.ClipboardEvent) => void;
}
export type Ref = HTMLTextAreaElement;

const MarkdownEditor = React.forwardRef<Ref, MarkdownEditorProps>(
  ({ value, setValue, onPaste }, textareaRef) => (
    <textarea
      value={value}
      ref={textareaRef}
      onChange={(e) => setValue(e.target.value)}
      className="markdownEditor"
      onPaste={onPaste}
    />
  )
);

export default MarkdownEditor;
