/**
 * Update a Story's Epic.
 *
 * @param {number} storyId - Story ID.
 * @param {number} epicId - Story ID to set as the epic.
 * @returns {Promise} Promise object representing the API request.
 */
const updateStoryEpic = (storyId, epicId) => axios.put(`/api/story/${storyId}/epic`, {
  parent_id: epicId,
});
export default updateStoryEpic;
