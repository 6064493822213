import { createSelector } from 'reselect';
import { Team } from '../types/interfaces/Team';
import { Project } from '../types/interfaces/Project';
import getEntities from './getEntities';

const getContext = createSelector(
  getEntities,
  (_state, contextType) => contextType,
  (_state, _contextType, contextId) => contextId,
  (entities, contextType, contextId) => (entities?.[contextType]?.[contextId] || null)
) as (state: any, contextType: 'teams' | 'projects', contextId: number) => Team | Project | null;
export default getContext;
