import { denormalize } from 'normalizr';
import { entitySchema } from '../types/schema';
import { MOVE_BOARD_STORY, MOVE_STORY } from '../actions/types';
import mergeReducers from '../utils/mergeReducers';
import createEntityReducer from '../utils/createEntityReducer';
import createGetEntity from '../utils/apiCreators/createGetEntity';
import getIndexById from '../utils/getIndexById';
import updateSprintBoard from '../utils/updateSprintBoard';

export const STATE_KEY = 'boards';

export default mergeReducers(
  // Default entity reducer.
  createEntityReducer('boards'),

  // A custom reducer for moving stories around a board.
  (state = {}, action = {}) => {
    const {
      teamId,
      report,
    } = action;

    switch (action.type) {
      case MOVE_BOARD_STORY:
        return {
          ...state,
          [teamId]: {
            ...(state[teamId] || {}),
            sprint: {
              ...state?.[teamId]?.sprint,
              report: {
                ...state?.[teamId]?.sprint?.report,
                ...report,
              },
            },
          },
        };

      case MOVE_STORY: {
        let newState = state;

        if (action.to.type === 'sprints') {
          newState = updateSprintBoard(
            newState,
            action.to.id,
            true,
            action.story.id
          );
        }

        if (action.from?.type === 'sprints') {
          newState = updateSprintBoard(
            newState,
            action.from.id,
            false,
            action.story.id
          );
        }

        return newState;
      }
    }

    return state;
  }
);

export const getBoard = createGetEntity(STATE_KEY, 'getBoard');

export const updateBoard = ({
  teamId,
  storyId,
  toColumnId,
  fromColumnId,
  oldIndex,
  newIndex,
}) => (dispatch, getState, { api }) => {
  const state = getState();
  const order = typeof newIndex === 'number' ? newIndex + 1 : null;
  const fromStories = state?.data?.entities?.columns?.[fromColumnId]?.backlog_stories || [];
  const fromIndex = typeof oldIndex === 'number'
    ? oldIndex
    : getIndexById(fromStories, storyId);
  api.updateBoard(teamId, { story_id: storyId, to_column: toColumnId, order })
    .then(({ data: { report } }) => {
      dispatch({
        type: MOVE_BOARD_STORY,
        teamId,
        storyId,
        from: { id: fromColumnId, order: fromIndex },
        to: { id: toColumnId, order: newIndex || 0 },
        report,
      });

      return report;
    })
    .catch((error) => dispatchAlert(error.toString()));
};

export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema.boards, state.data.entities)
);
