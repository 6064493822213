/**
 * Sum the points for a list of stories, optionally filtered by open/closed
 * status.
 *
 * @param {Object[]} stories - Array of stories. Must have `status` and `points`
 *                             properties.
 * @param {string} [filter] - Optional filter. Accepts 'open', 'closed', 'all'.
 * @returns {number} Point total.
 */
export default (stories, filter = 'all') => stories.reduce((sum, { status, points }) => {
  switch (true) {
    case (filter === 'open' && status === 'done'):
    case (filter === 'closed' && status !== 'done'):
      return sum;
  }

  return sum + points;
}, 0);
