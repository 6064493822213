/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import capitalize from 'lodash/capitalize';
import { denormalize } from 'normalizr';
import { receiveEntity } from '../actions/entity';
import {
  ADD_ENTITY,
  DELETE_ENTITY,
  LABEL_MAP,
  MOVE_STORY,
  UNSET_ENTITY,
  MOVE_BOARD_STORY,
} from '../actions/types';
import { entitySchema } from '../types/schema';
import createEntityReducer from '../utils/createEntityReducer';
import mergeReducers from '../utils/mergeReducers';
import mergeEntityChanges from '../utils/mergeEntityChanges';
import { Story } from '../types/interfaces/Story';
import { BacklogEntity } from '../types/interfaces/BacklogEntity';

export const STATE_KEY = 'stories';

/**
 * Story reducer.
 */
export default mergeReducers(
  // Default entity reducer.
  createEntityReducer(STATE_KEY),

  // A custom reducer for moving stories.
  // eslint-disable-next-line default-param-last
  (state = {}, action): any => {
    if (action.type === MOVE_STORY) {
      return mergeEntityChanges(state, action.story.id, action.story);
    }

    if (action.type === MOVE_BOARD_STORY) {
      const doneReport = action?.report?.done_stories?.find(({ id }) => (id === action.storyId));

      return {
        ...state,
        [action.storyId]: {
          ...state[action.storyId],
          status: doneReport ? 'done' : null,
          completed_at: doneReport?.completed_at || null,
          backlog: {
            ...state[action.storyId]?.backlog,
            board: {
              ...state[action.storyId]?.backlog?.board,
              current_column: action.to?.id,
            },
          },
        },
      };
    }

    return state;
  }
);

/**
 * Load or reload a story from the API.
 *
 * Note that this does not catch errors and the caller must handle errors.
 */
export const loadStory = ({
  id,
  source_id: sourceId,
  source_type: sourceType,
// eslint-disable-next-line babel/camelcase
}: { id: number; source_id: number; source_type: string }) => (
  (dispatch: any, _: any, { api }: any): Story => (
    api[`get${LABEL_MAP[sourceType]}Story`](sourceId, id)
      .then(({ data }) => dispatch(
        receiveEntity(STATE_KEY, {
          id,
          ...data,
        })
      ))
  )
);

/**
 * Load stories for a backlog (sprint, team, or project).
 *
 * @param {object} backlog - Backlog for which to load stories.
 * @param {number} backlog.backlogId - The ID of the sprint, project or team.
 * @param {string} backlog.backlogType - One of sprint, project or team
 * @returns {function}
 */
export const loadStories = (backlog: BacklogEntity) => (dispatch, _getState, { api }) => (
  api[`get${LABEL_MAP[backlog.backlogType]}`](backlog.backlogId)
    .then(({ data }) => {
      dispatch(
        receiveEntity(backlog.backlogType, data)
      );
      return data;
    })
);

export const deleteStory = (story) => (dispatch, _getState, { api }) => (
  api[`delete${capitalize(story.source_type)}Story`](story.source_id, story.id)
    .then(() => {
      dispatch({
        type: DELETE_ENTITY,
        entity: { type: 'stories', id: story.id },
      });
      dispatchAlert('Story deleted', 'success');
    })
    .catch((error) => dispatchAlert(error.toString()))
);

/**
 * Create an action creator to split a story.
 *
 * @param {number} storyId - ID of story to split.
 * @param {Object} payload - See {@link api.splitStory}
 * @returns {function}
 */
export const splitStory = (storyId, payload) => (dispatch, _getState, { api }) => (
  api.splitStory(storyId, payload)
    .then(({ data: story }) => {
      dispatch(receiveEntity(STATE_KEY, story));
      return story;
    })
    .catch(api.errorHandler)
);

/**
 * Create an action creator to clone a story.
 *
 * @param {number} storyId - ID of story to clone.
 * @returns {function}
 */
export const cloneStory = (storyId) => (dispatch, _getState, { api }) => (
  api.cloneStory(storyId)
    .then(({ data: story }) => {
      dispatch(receiveEntity(STATE_KEY, story));
      return story;
    })
    .catch(api.errorHandler)
);

/**
 * Set the feature for given story.
 *
 * @param {number} storyId - ID of the story receiving feature.
 * @param {number} featureId - ID of the feature to be added.
 */
export const setFeature = (
  storyId: number,
  featureId: number
) => (dispatch, _getState, { api }) => (
  api.setFeature(storyId, featureId)
    .then(({ data }) => {
      dispatch(receiveEntity(STATE_KEY, data));
      if (data?.feature?.title) {
        dispatchAlert(`Added label "${data.feature.title}"`, 'success');
      }
    })
    .catch(api.errorHandler)
);

/**
 * Remove a feature reference from a story.
 *
 * @param {number} storyId - ID of story losing feature.
 * @param {number} [featureId] - ID of feature to be removed.
 */
export const removeFeature = (
  storyId: number
) => (dispatch, _getState, { api }) => (
  api.removeFeature(storyId)
    .then(({ removed }) => {
      dispatch({
        type: UNSET_ENTITY,
        entity: { type: 'features', id: removed },
        unset: { type: 'stories', id: storyId, key: 'feature' },
      });
      dispatchAlert('Feature label removed.', 'success');
    })
    .catch(api.errorHandler)
);

/**
 * Set the epic for given story.
 *
 * @param {number} storyId - ID of the story associating with an epic.
 * @param {number} epicId - ID of the epic to be associated.
 */
export const setEpic = (
  storyId: number,
  epicId: number
) => (dispatch, _getState, { api }) => (
  api.updateStoryEpic(storyId, epicId)
    .then(({ data }) => {
      dispatch(receiveEntity(STATE_KEY, data));
      if (data?.parent?.title) {
        dispatchAlert(`Added epic "${data.parent.title}" to story "${data.title}"`, 'success');
      }
    })
    .catch(api.errorHandler)
);

/**
 * Remove an epic from a story.
 *
 * @param {number} storyId - ID of story losing its epic.
 * @param {number} epicId - ID of epic being disassociated. This is needed to
 *                          update that story in state.
 */
export const removeEpic = (
  storyId: number,
  epicId: number
) => (dispatch, _getState, { api }) => (
  api.deleteStoryEpic(storyId)
    .then(({ data }) => {
      dispatch({
        type: UNSET_ENTITY,
        entity: { type: 'stories', id: epicId },
        unset: { type: 'stories', id: storyId, key: 'parent' },
      });
      dispatch(receiveEntity(STATE_KEY, data));
      dispatchAlert('Epic relationship removed.', 'success');
    })
    .catch(api.errorHandler)
);

/**
 * Create an action creator to get a story from the server and update it in state.
 *
 * @param {string} sourceType - Source type, plural. One of 'teams' or 'projects'.
 * @param {number} sourceId - Story source ID.
 * @param {object} story - Story payload.
 * @param {object} [context] - Context to which to add the new story.
 * @returns {function}
 */
export const createStory = (
  sourceType: string,
  sourceId: number,
  story: Story,
  context: { type?: string; id?: number, } = {}
) => (
  (dispatch, _getState, { api }) => (
    api[`create${LABEL_MAP[sourceType]}Story`](sourceId, story)
      .then(({ data }) => dispatch({
        type: ADD_ENTITY,
        entity: { type: 'stories', data },
        to: { type: context.type || sourceType, id: context.id || sourceId, key: 'backlog_stories' },
      }))
      .catch(api.errorHandler)
  )
);

export const addStoryContributor = (storyId, userId = null) => (dispatch, _getState, { api }) => (
  api.addContributor(storyId, userId)
    .then(({ data }) => dispatch(
      receiveEntity('stories', {
        id: storyId,
        contributors_data: data,
      })
    ))
    .catch(api.errorHandler)
);

export const removeStoryContributor = (storyId, userId) => (dispatch, _getState, { api }) => (
  api.removeContributor(storyId, userId)
    .then(({ data }) => dispatch(
      receiveEntity('stories', {
        id: storyId,
        contributors_data: data,
      })
    ))
    .catch(api.errorHandler)
);

/**
 * Select the hydrated story from state.
 *
 * @param {Object} state - Full redux state.
 * @param {number} id - Story ID.
 * @returns {Object}
 */
export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)
);
