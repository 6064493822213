/* eslint-disable import/no-import-module-exports */
/**
 * Create the main React application.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-hot-loader/patch';
import { AppContainer } from 'react-hot-loader';
import ScrumApp from './ScrumApp';
import store from './store';

import './bootstrap';
import './components/Alerts';

window.dispatchAlert = (body, type = 'info', timeout = 7500) => {
  const event = new CustomEvent('newalert', {
    detail: { body, type, timeout },
  });
  document.dispatchEvent(event);
};

const el = document.getElementById('appBinding');
if (el) {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}><ScrumApp /></Provider>
    </AppContainer>,
    el
  );
}

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept();
}
