import React from 'react';

export type Props = {
  closeDialog: () => void;
  confirmAction: () => void;
};

function NoPoints({
  closeDialog,
  confirmAction,
}: Props) {
  return (
    <div className="col-md-10 m-auto">
      <div className="w-100 mb-3">
        <h2 className="h6 alert alert-danger">
          You are adding a story without points to the active sprint.
        </h2>
        <h3 id="tips" className="">
          Don&apos;t build a sprint of lies! Adjust scope after this story is pointed by ...
        </h3>
        <ol className="list-group list-group-flush" aria-labelledby="tips">
          <li className="list-group-item list-group-item-numbered">
            <span className="font-weight-bold">
              Swapping with another story
            </span>
            {' '}
            from the sprint.
          </li>
          <li className="list-group-item list-group-item-numbered">
            <span className="font-weight-bold">Deducting from the buffer</span>
            {' '}
            if
            this is a planned emergency.
          </li>
          <li className="list-group-item list-group-item-numbered">
            <span className="font-weight-bold">Adding it on capacity</span>
            {' '}
            if your team is ready to commit to new work.
          </li>
        </ol>
      </div>
      <div>
        <button type="button" onClick={confirmAction} className="btn btn-lg btn-outline-danger">
          I know what I&apos;m doing, let&apos;s go
        </button>
        {' '}
        <button type="button" onClick={closeDialog} className="btn btn-lg btn-secondary">
          Cancel
        </button>
      </div>
    </div>
  );
}

export default NoPoints;
