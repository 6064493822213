import { batch } from 'react-redux';

import {
  ADD_ENTITY,
  DELETE_ENTITY,
  TYPE_MAP_FROM_SINGULAR,
  UNSET_ENTITY,
} from '../types';
import { receiveEntity } from '../entity';
import { setInstigator } from './member';
import getUserById from '../../selectors/getUserById';

const STATE_KEY = 'stories';

/**
 * Dispatch an action to add a story to state.
 *
 * @param {object} data - Payload from API response.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const storyAdded = (data, instigatorId) => (dispatch) => {
  const to = (() => {
    const {
      source_type: sourceType,
      source_id: sourceId,
      backlog,
    } = data;

    return {
      type: TYPE_MAP_FROM_SINGULAR[backlog?.type || sourceType],
      id: backlog?.id || sourceId,
      key: 'backlog_stories',
    };
  })();

  batch(() => {
    dispatch({
      type: ADD_ENTITY,
      to,
      entity: { type: 'stories', data },
    });
    dispatch(setInstigator({ type: STATE_KEY, id: data.id }, instigatorId));
  });
};

/**
 * Dispatches an action to update the story in state.
 *
 * @param {object} data - Payload from API response.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const storyChanged = (story, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch(receiveEntity(STATE_KEY, story));
    const changedFields = Object.keys(story).filter(
      (field) => field !== 'id' && field !== 'updated_at'
    );
    dispatch(setInstigator({ type: STATE_KEY, id: story.id }, instigatorId, changedFields));
  });
};

/**
 * Dispatches an action to delete a story from state.
 *
 * @param {number} id - The story ID.
 * @param {number} storyId - The story ID.
 */
export const storyDeleted = (storyId, instigatorId) => (dispatch, getState) => {
  dispatch({
    type: DELETE_ENTITY,
    entity: { type: 'stories', id: storyId },
  });

  // Get user name for alert.
  const user = getUserById(getState(), instigatorId);
  if (!user) {
    return;
  }

  dispatchAlert(`Story ${storyId} deleted by ${user.name}`, 'info');
};

export const featureAssigned = (storyId, feature, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch(receiveEntity(STATE_KEY, { id: storyId, feature }));
    dispatch(setInstigator({ type: STATE_KEY, id: storyId }, instigatorId, ['feature']));
  });
};

export const featureRemoved = (storyId, featureId, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch({
      type: UNSET_ENTITY,
      entity: { type: 'features', id: featureId },
      unset: { type: 'stories', id: storyId, key: 'feature' },
    });
    dispatch(setInstigator({ type: STATE_KEY, id: storyId }, instigatorId, ['feature']));
  });
};
