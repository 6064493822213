import { PUSHER_JOIN } from '../types';
import leaveChannel from './leaveChannel';
import { projectTeamUpdated } from './projectTeam';
import { projectChanged } from './project';

export default () => (dispatch, getState) => {
  // @todo one day this will be dynamic for multiple organizations.
  const channel = 'organizations.1';
  const { pusher: { [channel]: existingConnection } } = getState();

  if (!existingConnection) {
    window.Echo.join(channel)
      .listen('ProjectChanged', ({ project, instigator }) => dispatch(projectChanged(project, instigator)))
      .listen('ProjectTeamAdded', ({ project_id, teams, instigator }) => dispatch(projectTeamUpdated(project_id, teams, instigator)))
      .listen('ProjectTeamDeleted', ({ project_id, teams, instigator }) => dispatch(projectTeamUpdated(project_id, teams, instigator)));

    dispatch({
      type: PUSHER_JOIN,
      channel,
    });
  }

  return () => dispatch(leaveChannel(channel));
};
