/* eslint-disable import/prefer-default-export */
import { batch } from 'react-redux';

import { MOVE_BOARD_STORY } from '../types';
import { selectHydrated as selectStory } from '../../modules/story';
import { setInstigator } from './member';
import { getBoard, selectHydrated as selectBoard } from '../../modules/board';
import getIndexById from '../../utils/getIndexById';

/**
 * Response to broadcast event when card moved across board.
 */
export const boardUpdated = (teamId, storyId, to, order, instigatorId) => (dispatch, getState) => {
  const state = getState();
  const story = selectStory(state, storyId);
  const board = selectBoard(state, teamId);
  const { columns, sprint: { report } = {} } = board || {};

  // If there are no columns, the board is not in state, so we can update the
  // story without reconciling the story location with the current board state.
  if (!columns) {
    dispatch({
      type: MOVE_BOARD_STORY,
      teamId,
      storyId,
      to: { id: to.id, order },
      report: {
        done_stories: [
          ...(to.status === 'done' ? [{
            id: storyId,
            points: story.points,
            title: story.title || '',
            completed_at: new Date().toISOString(),
          }] : []),
        ],
      },
    });
    return;
  }

  // Find the column the story is coming from.
  const from = { id: undefined, order: -1, status: undefined };
  columns.some(({ status, id, backlog_stories = [] }) => {
    const fromColumnOrder = getIndexById(backlog_stories, storyId);
    if (fromColumnOrder > -1) {
      from.id = id;
      from.order = fromColumnOrder;
      from.status = status;
      return true;
    }

    return false;
  });

  // Abort if story not found in a column.
  if (typeof from.id !== 'number') {
    return;
  }

  // If moving to the done column, we need to update the report.
  const newReport = (() => {
    if (to.status === 'done') {
      return {
        open_points: +(report.open_points - (story.points || 0)),
        done_points: +(report.done_points + (story.points || 0)),
        done_stories: [
          ...report.done_stories,
          {
            id: storyId,
            title: story.title || '',
            points: story.points,
            completed_at: new Date().toISOString(),
          },
        ],
      };
    }

    if (from.status === 'done') {
      return {
        open_points: +(report.open_points + (story.points || 0)),
        done_points: +(report.done_points - (story.points || 0)),
        done_stories: report.done_stories.filter(({ id }) => id !== storyId),
      };
    }

    if (
      story
    ) {
      return report;
    }

    return false;
  })();

  if (!newReport) {
    // Abandon all hope and request a new board.
    dispatch(getBoard(teamId));
    return;
  }

  batch(() => {
    dispatch({
      type: MOVE_BOARD_STORY,
      teamId,
      storyId,
      from,
      to: { id: to.id, order },
      report: newReport,
    });
    dispatch(setInstigator({ type: 'stories', id: storyId }, instigatorId));
  });
};
