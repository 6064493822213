import { batch } from 'react-redux';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import get from 'lodash/get';

import { receiveEntity, addEntity, receiveManyEntities } from '../actions/entity';
import { entitySchema } from '../types/schema';
import getEntities from '../selectors/getEntities';
import getParams from '../selectors/getParams';
import createEntityReducer from '../utils/createEntityReducer';
import {
  ADD_ENTITY,
  DELETE_ENTITY,
  LABEL_MAP,
  MERGE_ENTITY_CHANGES,
  TYPE_MAP_FROM_SINGULAR,
  UNSET_ENTITY,
  UPDATE_ENTITY,
} from '../actions/types';
import { Feature } from '../types/interfaces/Feature';
import { SourceType } from '../types/ContextTypes';

export const STATE_KEY = 'features';

type FeatureContextType = 'teams' | 'projects';

/**
 * Features reducer.
 */
export default createEntityReducer(STATE_KEY);

const functionPartFromSource = (sourceType: 'team' | 'project') => (
  LABEL_MAP[TYPE_MAP_FROM_SINGULAR[sourceType]]
);

// Action Creators
// -----------------

export const receiveFeatureUpdate = (data) => ({
  type: UPDATE_ENTITY,
  entity: { type: STATE_KEY, data },
});

export const receiveFeatureDelete = (
  featureId: number,
  type: FeatureContextType,
  contextId: number
) => ({
  type: DELETE_ENTITY,
  entity: { type: STATE_KEY, id: featureId },
  from: { type, id: contextId },
});

export const receiveFeatureArchive = (
  featureId: number,
  type: FeatureContextType,
  contextId: number
) => ({
  type: UNSET_ENTITY,
  entity: { type: 'features', id: [featureId] },
  unset: { type, id: contextId, key: 'features' },
});

export const receiveFeatureUnarchive = (
  data: Feature,
  type: FeatureContextType,
  contextId: number
) => ({
  type: ADD_ENTITY,
  to: { type, id: contextId },
  entity: { type: STATE_KEY, data },
});

export const receiveFeaturesForContext = (
  features: number[],
  type: FeatureContextType,
  contextId: number
) => ({
  type: MERGE_ENTITY_CHANGES,
  entity: { type, id: contextId },
  changes: { features },
});

/**
 * Dispatch action to create new feature for context.
 */
export const createFeature = (
  sourceId: number,
  sourceType: SourceType,
  payload?: { title: string, description?: string }
) => (dispatch, _getState, { api }) => {
  const type = TYPE_MAP_FROM_SINGULAR[sourceType];
  const call = `create${functionPartFromSource(sourceType)}Feature`;
  if (api[call] !== undefined) {
    api[call](sourceId, payload)
      .then(({ data }) => {
        batch(() => {
          dispatch(receiveEntity(STATE_KEY, data));
          dispatch(addEntity({
            entity: { type: 'features', data },
            to: { type, id: sourceId, key: 'features' },
          }));
        });
      })
      .then(dispatchAlert('Feature added', 'success'))
      .catch((error) => dispatchAlert(error.toString()));
  } else {
    dispatchAlert('There was a problem');
  }
};

/**
 * Dispatch action to update a feature.
 */
export const deleteFeature = (
  featureId: number,
  contextType: FeatureContextType,
  contextId: number
) => (dispatch, _getState, { api }) => {
  api.deleteFeature(featureId, contextType, contextId)
    .then(() => dispatch(receiveFeatureDelete(featureId, contextType, contextId)))
    .then(dispatchAlert('Feature deleted', 'success'))
    .catch((error) => dispatchAlert(error.toString()));
};

/**
 * Dispatch action to update a feature.
 */
export const updateFeature = (featureId: number, payload) => (dispatch, _getState, { api }) => {
  api.updateFeature(featureId, payload)
    .then(({ data }) => dispatch(receiveEntity(STATE_KEY, data)))
    .then(dispatchAlert('Feature updated', 'success'))
    .catch((error) => dispatchAlert(error.toString()));
};

/**
 * Dispatch action to archive a feature.
 */
export const archiveFeature = (
  featureId: number,
  contextType: FeatureContextType,
  contextId: number
) => (
  (dispatch, _getState, { api }) => {
    api.updateFeature(featureId, { status: 'archived' })
      .then(({ data }) => {
        batch(() => {
          dispatch(receiveEntity(STATE_KEY, data));
          dispatch(receiveFeatureArchive(featureId, contextType, contextId));
        });
      })
      .then(dispatchAlert('Feature archived', 'success'))
      .catch((error) => dispatchAlert(error.toString()));
  }
);

/**
 * Dispatch action to unarchive a feature.
 */
export const unarchiveFeature = (
  featureId: number,
  contextType: FeatureContextType,
  contextId: number
) => (
  (dispatch, _getState, { api }) => {
    api.updateFeature(featureId, { status: null })
      .then(({ data }) => {
        batch(() => {
          dispatch(receiveEntity(STATE_KEY, data));
          dispatch(receiveFeatureUnarchive(data, contextType, contextId));
        });
      })
      .then(dispatchAlert('Feature reactivated', 'success'))
      .catch((error) => dispatchAlert(error.toString()));
  }
);

/**
 * Returns list of hydrated features for a given entity.
 */
export const getFeatures = createSelector(
  [getEntities, getParams],
  (entities, params) => {
    const { contextType, id } = params;
    return get(entities, `${contextType}.${id}.features`, [])
      .map((featureId) => denormalize(featureId, entitySchema[STATE_KEY], entities));
  }
) as (state, params) => Feature[];

/**
 * Dispatch an action to load all (unarchived) features.
 */
export const loadAllFeatures = (
  sourceType: SourceType,
  sourceId: number
) => (dispatch, _getState, { api }) => {
  const type = TYPE_MAP_FROM_SINGULAR[sourceType] as FeatureContextType;
  const call = `getAll${functionPartFromSource(sourceType)}Features`;
  if (api[call] !== undefined) {
    api[call](sourceId)
      .then(({ data }) => batch(() => {
        dispatch(receiveManyEntities(STATE_KEY, data));
        dispatch(receiveFeaturesForContext(data.map(({ id }) => id), type, sourceId));
      }))
      .catch((error) => dispatchAlert(error.toString()));
  } else {
    dispatchAlert('There was a problem');
  }
};

/**
 * Dispatch an action to get all archived features.
 *
 * Archived features are stored in state, but not as child entities of their sources.
 */
export const getArchivedFeatures = (
  sourceType: SourceType,
  sourceId: number
) => (dispatch, _getState, { api }) => {
  const call = `getAll${functionPartFromSource(sourceType)}Features`;
  if (api[call] !== undefined) {
    return api[call](sourceId, { status: 'archived' })
      .then(({ data }) => {
        dispatch(receiveManyEntities(STATE_KEY, data));
        return data;
      })
      .catch((error) => dispatchAlert(error.toString()));
  }

  dispatchAlert('There was a problem');
  return Promise.resolve([]);
};

/**
 * Select the hydrated feature from state.
 *
 * @param {Object} state - Full redux state.
 * @param {number} id - Story ID.
 * @returns {Object}
 */
export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)
);
