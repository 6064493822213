/**
 * Create a new Project Story.
 *
 * @param {number} projectId - Project ID.
 * @param {Object} story - Story.
 * @param {string} story.title - Story title.
 * @param {number} [story.points] - Story points.
 * @param {string} [story.body] - Story body.
 * @param {?string} [story.status] - Story status, either null or "done".
 * @returns {Promise} Promise object representing the API request.
 */
const createProjectStory = (projectId, story) => axios.post(`/api/projects/${projectId}/stories`, story);
export default createProjectStory;
