import { receiveEntity, receiveManyEntities } from '../entity';
import { featureAdded } from './feature';
import { storyMoved, backlogReordered } from './backlog';
import {
  featureAssigned,
  featureRemoved,
  storyAdded,
  storyChanged,
  storyDeleted,
} from './story';
import {
  setUserPresence,
} from './member';
import {
  projectChanged,
} from './project';
import { PUSHER_JOIN } from '../types';
import leaveChannel from './leaveChannel';

export default (projectId) => (dispatch, getState) => {
  const channel = `projects.${projectId}`;
  const { pusher: { [channel]: existingConnection } } = getState();

  if (!existingConnection) {
    window.Echo.join(channel)
      .here((users) => dispatch(receiveManyEntities('members', users.map((user) => ({ ...user, online: true })))))
      .listen('ProjectChanged', ({ project, instigator }) => dispatch(projectChanged(project, instigator)))
      .listen('FeatureAdded', ({
        feature,
        context_id: contextId,
        context_type: contextType,
        instigator,
      }) => dispatch(featureAdded(feature, contextType, contextId, instigator)))
      .listen('StoryMoved', (data) => dispatch(storyMoved(data)))
      .listen('BacklogReordered', (data) => dispatch(backlogReordered(data)))
      .listen('StoryAdded', ({ story, instigator }) => dispatch(storyAdded(story, instigator)))
      .listen('StoryDeleted', ({ story_id, instigator }) => dispatch(storyDeleted(story_id, instigator)))
      .listen('ContributorsChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('FeatureAssigned', ({ story_id, feature, instigator }) => dispatch(featureAssigned(story_id, feature, instigator)))
      .listen('FeatureRemoved', ({ story_id, feature_id, instigator }) => dispatch(featureRemoved(story_id, feature_id, instigator)))
      .listen('StoryEpicChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StoryEpicRemoved', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StoryChildrenChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))

      .joining((user) => dispatch(receiveEntity('members', { ...user, online: true })))
      .leaving((user) => dispatch(setUserPresence(user.id, false)));

    dispatch({
      type: PUSHER_JOIN,
      channel,
    });
  }

  return () => dispatch(leaveChannel(channel));
};
