import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import uuid from 'react-uuid';
import { ErrorBoundary } from 'react-error-boundary';

import { history } from './store';
import * as actions from './actions';
import routes from './routes';
import Breadcrumbs from './components/Breadcrumbs';
import Loading from './components/Loading';
import TeamBarWrapper from './components/TeamBar/TeamBarWrapper';
import ErrorFallback from './components/ErrorFallback';

function ScrumApp({
  hasInitialized,
  initializeApp,
  joinMyTeamChannels,
  activeTeamId,
}) {
  useEffect(() => {
    initializeApp(window.scrumAppState);
    joinMyTeamChannels();
  }, []);

  useEffect(() => {
    if (activeTeamId) {
      // Include the active team in async requests.
      window.axios.defaults.headers.common['X-Active-Team'] = activeTeamId;

      // Store the current active team in a cookie on unload so following an
      // anchor tag to a new page can persist the active team.
      const listener = () => {
        document.cookie = `activeTeam=${activeTeamId};path=/;secure`;
      };
      window.addEventListener('beforeunload', listener);
      return () => {
        window.removeEventListener('beforeunload', listener);
      };
    }
    return () => {};
  }, [activeTeamId]);

  if (!hasInitialized) {
    return <Loading />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ConnectedRouter history={history}>
        <TeamBarWrapper />
        <Breadcrumbs />
        <Switch>
          {routes.map((route) => (
            <Route path={route.path} component={route.component} exact={route.exact} key={uuid()} />
          ))}
        </Switch>
      </ConnectedRouter>
    </ErrorBoundary>
  );
}

ScrumApp.defaultProps = {
  hasInitialized: false,
  activeTeamId: 0,
};

ScrumApp.propTypes = {
  hasInitialized: PropTypes.bool,
  initializeApp: PropTypes.func.isRequired,
  joinMyTeamChannels: PropTypes.func.isRequired,
  activeTeamId: PropTypes.number,
};

export default connect(
  ({ ui: { hasInitialized }, appMeta: { current_team: activeTeamId } }) => ({
    hasInitialized,
    activeTeamId,
  }),
  {
    initializeApp: actions.initializeApp,
    joinMyTeamChannels: actions.joinMyTeamChannels,
  }
)(ScrumApp);
