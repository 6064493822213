/**
 * Filter stories by a epic.
 *
 * @param {number} storyId - The user id of the epic to filter.
 * @param {array} stories - The stories to match against the epic id.
 * return {array} Stories within that epic, or the epic itself.
 */
const epic = (storyId, stories) => stories.map(
  (story) => ({
    ...story,
    filtered: storyId === ''
      ? story.parent_id !== null
      : story.parent_id !== storyId && story.id !== storyId,
  })
);
export default epic;
