import { createSelector } from 'reselect';
import get from 'lodash/get';
import getEntities from './getEntities';

const getTeamId = (state, teamId) => teamId;

/**
 * Get the current sprint ID for a given team ID.
 */
export default createSelector(
  [getEntities, getTeamId],
  (entities, teamId) => +get(entities, `teams.${teamId}.current_sprint`, 0) || false
);
