import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { entitySchema } from '../types';
import createEntityReducer from '../utils/createEntityReducer';
import mergeReducers from '../utils/mergeReducers';
import createMoveStoryReducer from '../utils/createMoveStoryReducer';
import createGetEntity from '../utils/apiCreators/createGetEntity';
import { MERGE_ENTITY_CHANGES } from '../actions/types';
import { receiveEntity, receiveManyEntities } from '../actions/entity';

export const STATE_KEY = 'projects';

/**
 * Project reducer.
 */
export default mergeReducers(
  // Default entity reducer.
  createEntityReducer(STATE_KEY),

  // Reducer for moving stories in the backlog.
  createMoveStoryReducer(STATE_KEY)
);

/**
 * Dispatch action to get project.
 */
export const getProject = createGetEntity(STATE_KEY, 'getProject');

/**
 * Dispatch an API request to get all projects.
 *
 * @returns {function} Function to dispatch action.
 */
export const getAllProjects = () => (dispatch, _getState, { api }) => (
  api.getAllProjects()
    .then(({ data }) => dispatch(receiveManyEntities(
      STATE_KEY,
      data
    )))
    .catch(api.errorHandler)
);

/**
 * Make API request to update a project.
 */
export const updateProject = (projectId, project) => (
  dispatch,
  _getState,
  { api }
) => (
  api.updateProject(projectId, project)
    .then(({ data: changes }) => dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: 'projects', id: projectId },
      changes,
    }))
    .catch(api.errorHandler)
);

/**
 * Make an API request to add a team to a project.
 *
 * @param {number} projectId - Project ID.
 * @param {number} teamId - Team ID.
 * @returns {Promise} Promise object representing the dispatch request.
 */
export const addTeamToProject = (projectId, teamId) => (
  dispatch,
  _getState,
  { api }
) => (
  api.addTeamToProject(projectId, teamId)
    .then(({ data }) => dispatch(receiveEntity('projects', {
      id: projectId,
      teams: data,
    })))
    .catch(api.errorHandler)
);

/**
 * Make an API request to remove a team from a project.
 *
 * @param {number} projectId - Project ID.
 * @param {number} teamId - Team ID.
 * @returns {Promise} Promise object representing the dispatch request.
 */
export const removeTeamFromProject = (projectId, teamId) => (
  dispatch,
  _getState,
  { api }
) => (
  api.removeTeamFromProject(projectId, teamId)
    .then(({ data }) => dispatch(receiveEntity('projects', {
      id: projectId,
      teams: data,
    })))
    .catch(api.errorHandler)
);

/**
 * Select the hydrated project from state.
 *
 * @param {Object} state - Full redux state.
 * @param {string} id - Project ID.
 * @returns {Object}
 */
export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)
);

/**
 * Select all the dehydrated (normalized) projects in state. Note that this is
 * not necessarily all projects in the system, just the ones that have so far
 * been queried.
 *
 * @param {Object} state - Full redux state.
 * @returns {Object} Dehydrated projects keyed by ID.
 */
export const selectDehydratedProjects = (state) => (state?.data?.entities?.projects || {});

/**
 * Select a dehydrated project from state by id.
 */
export const selectDehydratedProject = createSelector(
  selectDehydratedProjects,
  (_, id) => id,
  (projects, id) => projects?.[id]
);

/**
 * Select all the projects from state.
 *
 * @param {Object} state - Full redux state.
 * @returns {Project[]} Array of projects.
 */
export const selectProjects = createSelector(
  selectDehydratedProjects,
  (state) => (key) => selectHydrated(state, key),
  (projectEntities, hydrator) => Object.keys(projectEntities).map(hydrator)
);
