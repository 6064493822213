export const teams = () => '/teams';
export const createTeam = () => '/teams/create';
export const team = (teamId) => `/teams/${teamId}`;
export const editTeam = (teamId) => `/teams/${teamId}/edit`;

export const sprints = (teamId) => `/teams/${teamId}/sprints`;
export const sprint = (sprintId) => `/sprints/${sprintId}`;
export const editSprint = (sprintId) => `/sprints/${sprintId}/edit`;

export const board = (teamId) => `/teams/${teamId}/board`;

export const teamStories = (teamId) => `/teams/${teamId}/stories`;
export const teamStory = (teamId, storyId) => `/teams/${teamId}/stories/${storyId}`;

export const projects = () => '/projects';
export const createProject = () => '/projects/create';
export const project = (projectId) => `/projects/${projectId}`;
export const editProject = (projectId) => `/projects/${projectId}/edit`;

export const projectStories = (projectId) => `/projects/${projectId}/stories`;
export const projectStory = (projectId, storyId) => `/projects/${projectId}/stories/${storyId}`;

export const story = (context, contextId, storyId) => (
  context === 'project' ? projectStory(contextId, storyId) : teamStory(contextId, storyId)
);

export const feature = (context, contextId, featureId) => (
  `/${context}/${contextId}/features/${featureId}/stories`
);
export const featuresList = (context, id) => `/${context}/${id}/features`;

export const source = ({ type, id = '' }) => (type === 'team' ? team(id) : project(id));

export const bucketList = (context, id) => `/${context}/${id}/stories`;

export const editContext = (context, id) => `/${context}/${id}/edit`;
