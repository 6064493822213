import { createSelector } from 'reselect';

/**
 * Selects the hydrated current user object.
 */
export default createSelector(
  [
    (state) => state?.appMeta?.user_id,
    (state) => state?.data?.entities?.members || {},
  ],
  (userId, members) => members[userId] || undefined
);
