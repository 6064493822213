import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Points.scss';

const PointsPropTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
};

function Points({
  value = null,
  className = null,
}) {
  /**
 * String to identify the className to style the points.
 * @type string
 */
  const pointClass = typeof value === 'number' ? `${value}` : 'unpointed';

  return (
    <div className={classnames(
      className,
      styles.points,
      styles[`points-${pointClass}`],
      { threeDigitPoints: value > 99 }
    )}
    >
      <div className={classnames(styles.pointsText, 'pointsText')}>
        {value}
        <span className="sr-only">
          points
        </span>
      </div>
    </div>
  );
}

Points.propTypes = PointsPropTypes;

export default Points;
