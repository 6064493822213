import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { STORY_SHAPE } from '../../../types/props';
import SplitStoryRow from './SplitStoryRow';

function SplitStory({ story, splitHandler, cancelSplitHandler }) {
  const intVal = (num) => (num ? Number.parseInt(num, 10) : 0);

  const [original, setOriginal] = useState({
    id: story.id,
    title: story.title,
    points: story.points,
  });
  const setOriginalTitle = (title) => setOriginal({ ...original, title });
  const setOriginalPoints = (points) => setOriginal({ ...original, points });

  const [originalPointsChanged, setOriginalPointsChanged] = useState(false);

  const [splits, setSplits] = useState([{ title: '', points: '' }]);

  const sumSplitPoints = (s) => s.reduce((sum, { points: p }) => sum + intVal(p), 0);

  const automateOriginalPoints = (splitsSum) => {
    if (!originalPointsChanged && story.points !== null) {
      const difference = +story.points - splitsSum;
      setOriginalPoints(difference < 0 ? 0 : difference);
    }
  };

  const setSplitTitle = (index, title) => {
    const newSplits = [...splits];
    newSplits[index] = { ...splits[index], title };
    setSplits(newSplits);
  };
  const setSplitPoints = (index, newPoints) => {
    const points = intVal(newPoints) > 0 || newPoints === '0' ? intVal(newPoints) : '';
    setSplits((oldSplits) => {
      const newSplits = [...oldSplits];
      newSplits[index] = { ...oldSplits[index], points };
      automateOriginalPoints(sumSplitPoints(newSplits));
      return newSplits;
    });
  };

  const addSplit = () => {
    setSplits([
      ...splits,
      { title: '', points: '' },
    ]);
  };
  const deleteSplit = (index) => {
    setSplits((oldSplits) => {
      const newSplits = [
        ...oldSplits.slice(0, index),
        ...oldSplits.slice(index + 1),
      ];
      automateOriginalPoints(sumSplitPoints(newSplits));
      return newSplits;
    });
  };

  const saveSplit = (e) => {
    e.preventDefault();
    splitHandler(story.id, {
      updates: original,
      split: splits,
    });
  };

  const cancelSplit = (e) => {
    e.preventDefault();
    cancelSplitHandler();
  };

  const totalPoints = sumSplitPoints(splits) + intVal(original.points);

  return (
    <div className="card mb-3">
      <div className="card-body">
        <h3>Split Story</h3>
        <form>
          <h4>Original Story</h4>
          <div className="form-row">
            <div className="form-group col-2 col-lg-1">
              <label htmlFor={`og-points-${story.id}`}>Points</label>
              <input
                className="form-control"
                id={`og-points-${story.id}`}
                value={original.points === null ? '' : original.points}
                onChange={(e) => {
                  setOriginalPointsChanged(true);
                  setOriginalPoints(e.target.value);
                }}
                autoComplete="off"
              />
            </div>
            <div className="form-group col-10 col-lg-11">
              <label htmlFor={`og-title-${story.id}`}>Title</label>
              <input
                className="form-control"
                id={`og-title-${story.id}`}
                value={original.title}
                onChange={(e) => setOriginalTitle(e.target.value)}
                autoComplete="off"
              />
            </div>
          </div>

          {+story.points > 0 && !originalPointsChanged && (
            <div className="alert alert-info" role="alert">
              This story&apos;s points will update automatically unless you change it.
            </div>
          )}

          <h4>Split to...</h4>
          <div className="row" aria-hidden>
            <p className="col-2 col-lg-1 mb-2">Points</p>
            <p className="col-9 col-lg-10 mb-2">Title</p>
          </div>

          {splits.map(({ title, points }, index) => (
            <SplitStoryRow
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              index={index}
              title={title}
              points={points}
              setTitle={(newTitle) => setSplitTitle(index, newTitle)}
              setPoints={(newPoints) => setSplitPoints(index, newPoints)}
              deleteSplit={() => deleteSplit(index)}
            />
          ))}

          {story.points !== null && +story.points !== totalPoints && (
            <div className="alert alert-warning" role="alert">
              {`Splitting ${story.points} points into ${totalPoints}`}
            </div>
          )}

          <button className="btn btn-primary" type="button" onClick={addSplit}>Add Split</button>
          {' '}
          <input type="submit" value="Save Changes" className="btn btn-success" onClick={saveSplit} />
          {' '}
          <input type="reset" value="Cancel" className="btn btn-danger float-right" onClick={cancelSplit} />
        </form>
      </div>
    </div>
  );
}

SplitStory.propTypes = {
  story: PropTypes.shape(STORY_SHAPE).isRequired,
  splitHandler: PropTypes.func.isRequired,
  cancelSplitHandler: PropTypes.func.isRequired,
};

export default SplitStory;
