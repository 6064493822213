import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import * as actions from '../../actions';
import * as uri from '../../utils/uri';
import { selectHydrated as selectSprintSummaries } from '../../modules/sprintSummary';
import Loading from '../Loading';
import Velocities from './Velocities';

function AllSprints({ sprints, teamId, getSprints }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sprints.length === 0) {
      setLoading(true);
    }
    getSprints(teamId).then(() => setLoading(false));
  }, []);

  const activeSprints = sprints
    .filter(({ ended_at: endedAt, points_committed: pointsCommitted, is_active: isActive }) => (
      (!!endedAt || isActive) && typeof pointsCommitted === 'number'
    ))
    .map(({
      title,
      total_points: totalPoints,
      ended_at: endedAt,
      points_committed: pointsCommitted,
      points_completed: pointsCompleted,
      is_active: isActive,
    }) => ({
      title: isActive ? `${title} (in progress)` : title,
      points: typeof pointsCompleted === 'undefined' ? totalPoints : pointsCompleted,
      date: isActive ? Date.now() : endedAt,
      pointsCommitted,
      isActive,
    }));

  return (
    <>
      <Helmet>
        <title>
          All sprints
        </title>
      </Helmet>
      <main className="container">
        <h1>All Sprints</h1>

        {loading && <Loading />}

        {activeSprints.length ? (
          <Velocities sprints={activeSprints} />
        ) : null}

        {sprints.map((sprint) => (
          <div key={sprint.id} className="card mb-2">
            <div className="card-body">
              {sprint.total_points !== undefined && (
              <>
                <span className="badge badge-secondary">{sprint.total_points}</span>
                {' '}
              </>
              )}
              <Link to={uri.sprint(sprint.id)}>
                {sprint.title}
              </Link>
            </div>
          </div>
        ))}
      </main>
    </>
  );
}

AllSprints.defaultProps = {
  sprints: [],
};

AllSprints.propTypes = {
  teamId: PropTypes.number.isRequired,
  sprints: PropTypes.arrayOf(PropTypes.object),
  getSprints: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => {
    const teamId = Number.parseInt(ownProps.match.params.id, 10);
    return {
      teamId,
      sprints: selectSprintSummaries(state, teamId),
    };
  },
  {
    getSprints: actions.getSprintSummaries,
  }
)(AllSprints);
