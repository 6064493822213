import React from 'react';
import PropTypes from 'prop-types';

import StoryTitleWarning from '../../StoryTitleWarning';

function SplitStoryRow({
  index, points, title, setTitle, setPoints, deleteSplit,
}) {
  return (
    <>
      <div className="form-row">
        <div className="form-group col-2 col-lg-1">
          <label htmlFor={`split-story-points-${index}`} className="sr-only">Points</label>
          <input
            className="form-control"
            id={`split-story-points-${index}`}
            value={points}
            onChange={(e) => setPoints(e.target.value)}
            autoComplete="off"
          />
        </div>
        <div className="form-group col-9 col-lg-10">
          <label htmlFor={`split-story-title-${index}`} className="sr-only">Title</label>
          <input
            className="form-control"
            id={`split-story-title-${index}`}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            autoComplete="off"
            maxLength={191}
          />
        </div>
        <div className="form-group col-1 d-flex">
          <button type="button" className="delete-split" onClick={deleteSplit}>
            <span className="sr-only">Delete split</span>
            <span aria-hidden>&times;</span>
          </button>
        </div>
      </div>

      <StoryTitleWarning length={title.length} />
    </>
  );
}

SplitStoryRow.propTypes = {
  index: PropTypes.number.isRequired,
  points: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  setTitle: PropTypes.func.isRequired,
  setPoints: PropTypes.func.isRequired,
  deleteSplit: PropTypes.func.isRequired,
};

export default SplitStoryRow;
