import { createSelector } from 'reselect';
import { selectDehydratedTeams, getCurrentUserActiveTeamId } from './team';
import { getSprints } from './sprint';
import { BacklogTargetGroup } from '../types/interfaces/BacklogTargetGroup';
import { BacklogTarget } from '../types/interfaces/BacklogTarget';

/**
 * Provides one or two series of targets to which stories can be transferred.
 * It chooses these targets based on the following criteria:
 * - The user is a member of the team that owns these backlogs.
 * - The current backlog is filtered out of the targets list.
 *
 * @returns {Object[]} Array of one or two objects describing an array of
 *                     potential targets.
 */
export const getBacklogTargets = createSelector([
  selectDehydratedTeams,
  getCurrentUserActiveTeamId,
  getSprints,
], (teams = {}, activeTeam = 0, sprints = {}) => {
  const targets: BacklogTargetGroup[] = [];

  if (!activeTeam || typeof activeTeam !== 'number') {
    return targets;
  }

  const {
    name: teamName,
    id: teamId,
    total_points: teamBacklogTotalPoints,
    open_sprints: openSprints = [],
  } = Object.keys(teams)
    .map((key) => teams[key])
    .find(({ id }) => id === activeTeam) || {};

  // Bail if team details not found.
  if (typeof teamName === 'undefined' || typeof teamId === 'undefined') {
    return targets;
  }

  targets.push({
    backlogs: [{
      id: teamId,
      type: 'teams',
      title: 'Team Backlog',
      total: teamBacklogTotalPoints,
    }],
    title: '',
  });

  if (openSprints.length > 0) {
    // Hydrate sprints, if necessary.
    const sprintTargets = openSprints
      .map((item) => (typeof item === 'object' ? item : sprints[item]))
      .filter((item) => item);

    // Display all the user's team's sprints, sans the one they are looking at.
    targets.push({
      title: 'Sprints',
      backlogs: sprintTargets
        // First, remove the sprint we are looking at, if applicable
        .map(
          ({
            id,
            instigator,
            is_active: isActive = false,
            title,
            total_points: total = 0,
          }) => ({
            id,
            instigator,
            isActive,
            title,
            total,
            type: 'sprints',
          })
        ),
    });
  }

  return targets;
});

/**
 * Remove the backlog presently in view from the backlogTargets.
 *
 * @param {Object[]} targets Array of backlogTargets.
 * @param {*} params Matches from the path from React Router.
 * @return {Object[]} Targets filtered so they do not include the backlog in view.
 */
export const filterCurrentBacklogFromTargets = (
  targets: BacklogTargetGroup[],
  params: {
    contextType: string;
    id: number;
  }
): BacklogTargetGroup[] => (
  targets.reduce((acc, group) => {
    const { contextType, id: contextId } = params;
    const filterCurrentBacklog = ({ id, type }) => !(
      contextType === type && +contextId === id
    );
    const { backlogs: initialBacklogs } = group;
    const backlogs = initialBacklogs.filter(filterCurrentBacklog);
    if (backlogs.length < 1) {
      return acc;
    }
    return [
      ...acc,
      {
        ...group,
        backlogs,
      },
    ];
  }, [] as BacklogTargetGroup[])
);

/**
 * Flatten a list of backlog targets into a single-dimensional array.
 *
 * @param {Object[]} targets - Array of backlogTargets.
 * @param {Object} exclude - Target to exclude.
 * @param {number} exclude.id - Target ID.
 * @param {string} exclude.type - Target type (plural).
 * @return {Object[]} Targets filtered so they do not include the backlog in view.
 */
export const flattenBacklogTargets = (
  targets: BacklogTargetGroup[],
  exclude: {
    contextType: string;
    id: number;
  }
): BacklogTarget[] => (
  filterCurrentBacklogFromTargets(targets, exclude)
    .reduce((carry, group) => (
      [
        ...carry,
        ...group.backlogs.reduce((groupTargets, target) => (
          [
            ...groupTargets,
            {
              ...target,
              title: `${target.title} ${target.type === 'sprints' ? 'Sprint' : ''}`,
            },
          ]
        ), [] as BacklogTarget[]),
      ]
    ), [] as BacklogTarget[])
);
