import * as React from 'react';
import PropTypes from 'prop-types';

// Components.
import { BarChartFill, Bullseye, CheckCircle } from 'react-bootstrap-icons';
import Loading from '../Loading';
import BigStat from '../BigStat';
import StatsRow from '../BigStat/StatsRow';

// Shapes.
import { STARTED_SPRINT_SHAPE } from '../../types/props';

// Dynamic import.
const Burndown = React.lazy(() => import('../Burndown'));

const Props = {
  /** Sprint. */
  sprint: PropTypes.shape(STARTED_SPRINT_SHAPE).isRequired,
};

/**
 * Connected component to show a team's board burndown.
 */
function SprintReport({
  sprint: {
    is_active: isActive = false,
    report: {
      done_points: donePoints,
      done_stories: doneStories = [],
    } = {},
    points_committed: pointsCommitted,
    start_date: startDate,
    end_date: endDate,
    started_at: startedAt = undefined,
    ended_at: endedAt = undefined,
    rolling_velocity: rollingVelocity = null,
  },
}) {
  if (!startedAt && typeof rollingVelocity === 'number') {
    // If the sprint hasn't started, share the rolling velocity.
    return (
      <StatsRow>
        <BigStat stat={rollingVelocity} label="rolling velocity">
          <BarChartFill className="text-secondary" />
        </BigStat>
      </StatsRow>
    );
  }

  if (!(endDate && startDate && pointsCommitted > 0)) {
    // @todo display something informational if the burndown conditions have not been met.
    return (<div />);
  }

  return (
    <div className="mb-3">
      <StatsRow>
        {pointsCommitted ? (
          <BigStat stat={pointsCommitted} label="points committed">
            <Bullseye className="text-danger" />
          </BigStat>
        ) : null}
        {donePoints ? (
          <BigStat stat={donePoints} label="points completed">
            <CheckCircle className="text-success" />
          </BigStat>
        ) : null}
      </StatsRow>

      <React.Suspense fallback={<Loading />}>
        <Burndown
          pointsCommitted={pointsCommitted}
          endDate={endedAt || endDate}
          showDetails
          startDate={startedAt || startDate}
          stories={doneStories}
          includeNow={isActive}
        />
      </React.Suspense>
    </div>
  );
}

SprintReport.propTypes = Props;

export default SprintReport;
