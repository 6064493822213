import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function LinkMaybe({
  to,
  children,
  useAnchor = false,
  ...linkProps
}) {
  if (!to) {
    return children;
  }

  const relativeUri = typeof to === 'string' ? to.replace(/^https?:\/\/[^/]+/, '') : to;

  if (useAnchor) {
    return (
      <a href={relativeUri} {...linkProps}>
        {children}
      </a>
    );
  }

  return (
    <Link to={relativeUri} {...linkProps}>
      {children}
    </Link>
  );
}

LinkMaybe.defaultProps = {
  to: null,
};

LinkMaybe.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  useAnchor: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default LinkMaybe;
