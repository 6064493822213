import * as React from 'react';
import classnames from 'classnames';

interface ProgressBarProps {
  progress: number;
  className?: string;
  showLabel?: boolean;
}

function ProgressBar({
  progress = 0,
  className = '',
  showLabel = false,
}: ProgressBarProps) {
  return (
    <div className="progress">
      <div
        className={classnames('progress-bar', className)}
        role="progressbar"
        style={{ width: `${progress}%` }}
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        {showLabel ? (
          `${progress}%`
        ) : (
          <span className="sr-only">{`${progress}%`}</span>
        )}
      </div>
    </div>
  );
}

export default ProgressBar;
