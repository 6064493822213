import { votingToggled } from './poker';
import {
  sprintAdded,
  sprintChanged,
  sprintDeleted,
  sprintStarted,
  sprintEnded,
} from './sprint';
import {
  initializeUserPresence,
  setUserPresence,
} from './member';
import {
  backlogReordered,
  storyMoved,
} from './backlog';
import { featureAdded } from './feature';
import {
  storyAdded, storyDeleted, storyChanged, featureAssigned, featureRemoved,
} from './story';
import { PUSHER_JOIN } from '../types';
import { leaveTeamChannel } from './leaveChannel';

export default (teamId) => (dispatch, getState) => {
  const channel = `teams.${teamId}`;
  const { pusher: { [channel]: existingConnection } } = getState();

  if (!existingConnection) {
    const connection = window.Echo.join(channel);

    connection.subscription?.bind?.('pusher:subscription_error', (status) => {
      if (status === 419) {
        // TODO: figure out how to get a new token without a reload.
        window.dispatchAlert(
          'It looks like this page expired, please reload your browser',
          'danger',
          3600000
        );
      }
    });

    connection.here((users) => dispatch(initializeUserPresence(users)))
      .listen('VotingToggled', (data) => dispatch(votingToggled(data)))
      .listen('BacklogReordered', (data) => dispatch(backlogReordered(data)))
      .listen('SprintAdded', ({ sprint, instigator }) => dispatch(sprintAdded(sprint, teamId, instigator)))
      .listen('SprintChanged', ({ sprint, instigator }) => dispatch(sprintChanged(sprint, instigator)))
      .listen('SprintStarted', ({ sprint, instigator }) => dispatch(sprintStarted(sprint, teamId, instigator)))
      .listen('SprintEnded', ({ sprint, instigator }) => dispatch(sprintEnded(sprint, teamId, instigator)))
      .listen('SprintDeleted', ({ sprint_id, team_id, instigator }) => dispatch(sprintDeleted(sprint_id, team_id, instigator)))
      .listen('FeatureAdded', ({
        feature,
        context_id: contextId,
        context_type: contextType,
        instigator,
      }) => dispatch(featureAdded(feature, contextType, contextId, instigator)))

      // FeatureDeleted and FeatureChanged also exist; however there is no UI to do so.
      .listen('StoryAdded', ({ story, instigator }) => dispatch(storyAdded(story, instigator)))
      .listen('StoryDeleted', ({ story_id, instigator }) => dispatch(storyDeleted(story_id, instigator)))
      .listen('StoryChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StorySplit', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StoryMoved', (data) => dispatch(storyMoved(data)))

      // Story changes.
      .listen('ContributorsChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('FeatureAssigned', ({ story_id, feature, instigator }) => dispatch(featureAssigned(story_id, feature, instigator)))
      .listen('FeatureRemoved', ({ story_id, feature_id, instigator }) => dispatch(featureRemoved(story_id, feature_id, instigator)))
      .listen('StoryEpicChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StoryEpicRemoved', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StoryChildrenChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))

      .joining((user) => dispatch(setUserPresence(user.id, true)))
      .leaving((user) => dispatch(setUserPresence(user.id, false)));

    dispatch({
      type: PUSHER_JOIN,
      channel,
    });
  }

  return () => dispatch(leaveTeamChannel(teamId));
};
