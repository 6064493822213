import React, { useState, useEffect } from 'react';
import {
  func, number, shape, string,
} from 'prop-types';
import { useDispatch } from 'react-redux';
import Select from 'react-dropdown-select';
import { Link } from 'react-router-dom';

// Internal dependencies.
import { SOURCE_SHAPE } from '../../../types/props';
import * as uri from '../../../utils/uri';
import * as actions from '../../../actions';
import { TYPE_MAP_FROM_SINGULAR } from '../../../actions/types';

// Components.
import Loading from '../../Loading';
import SearchInput from './EpicSearchInput';

const epicSelectPropTypes = {
  closeModal: func,
  handleEpicSave: func.isRequired,
  source: shape(SOURCE_SHAPE).isRequired,
  storyEpic: shape({
    id: number,
    title: string,
  }),
  storyId: number.isRequired,
};
function EpicSelect({
  closeModal,
  handleEpicSave,
  source: {
    id: sourceId,
    type: sourceType,
    label: sourceLabel,
  },
  storyEpic,
  storyId,
}) {
  const [selection, setSelection] = useState({
    label: storyEpic?.title || '',
    value: storyEpic?.id || '',
  });
  const [loading, setLoading] = useState(true);
  const [stories, setStories] = useState([]);

  const dispatch = useDispatch();

  /**
   * Load the stories from the API.
   */
  useEffect(() => {
    (async () => {
      const data = await dispatch(
        actions.getAllStories(TYPE_MAP_FROM_SINGULAR[sourceType], sourceId)
      );
      setStories(
        data.map(({ id, title }) => ({ id, title }))
          .filter(({ id }) => id !== storyId)
          .sort((a, b) => a.title.localeCompare(b.title))
      );
      setLoading(false);
    })();
  }, [sourceType, sourceId, storyId]);

  /**
   * Pass current selection value to handleEpicSave function and close modal.
   *
   * @param {Object} e - HTMLEvent.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    handleEpicSave(selection.value || null);
    closeModal();
  };

  /**
   * Save user input to local state.
   *
   * @param {string} newValue - User input.
   */
  const handleChange = (newValue) => {
    const [{ label = '', value = false } = {}] = newValue || [{}];
    setSelection({ label, value });
  };

  return (
    <div className="card mb-3">
      <div className="card-body">
        <h2 className="h6 card-title" id={`epicSelect-${sourceId}-${sourceType}`}>
          Assign to epic on
          {' '}
          <Link
            to={uri.source({ id: sourceId, type: sourceType })}
            className="btn btn-dark btn-sm"
          >
            {sourceLabel}
          </Link>
          :
        </h2>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Select
              clearable
              options={stories.map(({ id, title }) => ({
                label: title,
                value: id,
              }))}
              values={[selection]}
              className="mb-2"
              closeOnSelect
              onChange={handleChange}
              inputRenderer={SearchInput}
            />
            <div className="d-flex justify-content-end">
              <input
                type="submit"
                value="Save"
                aria-label="Save epic selection"
                className="btn btn-primary mr-1"
                onClick={handleSubmit}
              />
              <input
                type="reset"
                value="Cancel"
                aria-label="Cancel modifying epic"
                className="btn btn-link"
                onClick={closeModal}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

EpicSelect.propTypes = epicSelectPropTypes;

EpicSelect.defaultProps = {
  storyEpic: {},
  closeModal: () => {},
};

export default EpicSelect;
