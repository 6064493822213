/**
 * Set a singular entity reference in another entity.
 *
 * Truth be told, this is no different than `reorderEntityIds` and even, to an
 * extent, `mergeEntityChanges`. It might be worth merging the three in the
 * future.
 *
 * @param {Object} state - Entities state.
 * @param {number|string} id - Referenced (child) entity ID.
 * @param {number|string} targetId - ID of referencing (parent) entity.
 * @param {string} key - Property referencing the (child) entity.
 * @returns {Object} New state.
 */
export default (state, id, targetId, key) => ({
  ...state,
  [targetId]: {
    ...state[targetId],
    [key]: id,
  },
});
