import { Feature } from '../../../types/interfaces/Feature';

type Payload = {
  title?: string;
  description?: string | null;
  status?: 'archive' | 'active' | null;
}

/**
 * Update a feature.
 */
export default function updateFeature(
  featureId: number,
  payload: Payload
): Promise<Feature> {
  return axios.patch(`/api/features/${featureId}`, payload)
    .then(({ data }) => data);
}
