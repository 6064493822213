/**
 * Append an entity ID to a normalized array.
 *
 * @param {Object} state - Entities state.
 * @param {number|string} id - ID to append.
 * @param {string} targetId - Entity type, e.g. stories, teams, projects.
 * @param {string} key - Entity property which holds refs to other entities.
 * @returns {Object} New state.
 */
export default (state, id, targetId, key) => ({
  ...state,
  [targetId]: {
    ...state[targetId],
    [key]: [
      ...state[targetId][key] || [],
      id,
    ],
  },
});
