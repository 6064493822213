// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Story__card__a48fy.Story__epic__ezcyZ{border-left:10px solid #007bff;margin-left:-10px}.Story__card__a48fy:not(.Story__epic__ezcyZ).Story__child__Os20v{border-left:10px solid #e9ecef}.Story__card__a48fy h3{border-top:1px solid #dee2e6;margin-top:1rem;padding-top:1rem}.Story__card__a48fy blockquote{border-left:.375rem solid #dee2e6;padding-left:1rem}", "",{"version":3,"sources":["webpack://./resources/js/components/Story/Story.scss"],"names":[],"mappings":"AAME,uCACE,8BAAA,CACA,iBAAA,CAGF,iEACE,8BAAA,CAGF,uBACE,4BAAA,CACA,eAAA,CACA,gBAAA,CAGF,+BACE,iCAAA,CACA,iBAAA","sourcesContent":["@import '~bootstrap/scss/functions';\n@import '~bootstrap/scss/variables';\n@import '~bootstrap/scss/mixins';\n@import '../../../sass/variables';\n\n.card {\n  &.epic {\n    border-left: 10px solid $primary;\n    margin-left: -10px;\n  }\n\n  &:not(.epic).child {\n    border-left: 10px solid $gray-200;\n  }\n\n  h3 {\n    border-top: 1px solid $gray-300;\n    margin-top: 1rem;\n    padding-top: 1rem;\n  }\n\n  blockquote {\n    border-left: 0.375rem solid $gray-300;\n    padding-left: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Story__card__a48fy",
	"epic": "Story__epic__ezcyZ",
	"child": "Story__child__Os20v"
};
export default ___CSS_LOADER_EXPORT___;
