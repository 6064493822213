/**
 * Remove a team as a contributor from a project.
 *
 * @param {number} projectId - Project ID.
 * @param {number} teamId - Team ID.
 * @returns {Promise} Promise object representing the API request.
 */
const removeTeamFromProject = (projectId, teamId) => axios.delete(`/api/project/${projectId}/teams/${teamId}`);

export default removeTeamFromProject;
