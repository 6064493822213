import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { selectHydrated as selectProject } from '../../modules/project';
import * as actions from '../../actions';
import { PROJECT_SHAPE } from '../../types/props';
import Loading from '../Loading';

// Components
import SingleFieldCard from '../SingleFieldCard';
import ProjectTeams from './ProjectTeams';

const props = {
  getProject: PropTypes.func.isRequired,
  project: PropTypes.shape(PROJECT_SHAPE),
  projectId: PropTypes.number.isRequired,
  updateProject: PropTypes.func.isRequired,
  getAllTeams: PropTypes.func.isRequired,
  allTeams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  addTeamToProject: PropTypes.func.isRequired,
  removeTeamFromProject: PropTypes.func.isRequired,
};

function PureProjectEdit({
  addTeamToProject,
  removeTeamFromProject,
  getProject,
  project = {},
  projectId,
  updateProject,
  getAllTeams,
  allTeams,
}) {
  const [isLoading, setIsLoading] = React.useState(true);

  // Load all teams into state on initial load.
  React.useEffect(() => {
    getAllTeams().then(() => setIsLoading(false));
  }, []);

  // Load the latest details from the project into state on projectId change.
  React.useEffect(() => {
    if (typeof project.id === 'undefined') {
      setIsLoading(true);
      getProject(projectId).then(() => setIsLoading(false));
    }
  }, [projectId]);

  /**
   * Add a team to this project.
   *
   * @param {number} teamId - Team ID.
   * @returns {Promise} Dispatch to state with updated project.
   */
  const handleAddTeam = (teamId) => addTeamToProject(projectId, teamId);

  /**
   * Remove a team from this project.
   *
   * @param {number} teamId - Team ID.
   * @returns {Promise} - Dispatch to state with updated project.
   */
  const handleRemoveTeam = (teamId) => removeTeamFromProject(projectId, teamId);

  const currentProjectTeams = project.teams || [];
  const availableTeams = (() => {
    if (!Array.isArray(currentProjectTeams)
    || !Array.isArray(allTeams)) {
      return [];
    }

    return allTeams.filter((team) => !(
      currentProjectTeams.find((projectTeam) => team.id === projectTeam.id)
    ));
  })();

  return (
    <>
      <Helmet>
        <title>
          {`Edit ${project.title} project`}
        </title>
      </Helmet>
      <main className="container">
        {project.title
          ? (
            <SingleFieldCard
              header={{ label: 'Edit Project', level: 1 }}
              fieldLabel="Project title"
              fieldValue={project.title}
              updateHandler={(title) => {
                updateProject(projectId, { title });
              }}
            />
          ) : null}
        {isLoading ? (
          <Loading />
        )
          : (
            <ProjectTeams
              addTeam={handleAddTeam}
              removeTeam={handleRemoveTeam}
              teams={currentProjectTeams}
              availableTeams={availableTeams}
            />
          )}
      </main>
    </>
  );
}

PureProjectEdit.propTypes = props;

export default connect(
  (state, ownProps) => ({
    project: selectProject(state, ownProps?.match?.params?.id),
    projectId: +(ownProps?.match?.params?.id || 0),
    allTeams: Object.keys(state?.data?.entities?.teams).reduce((acc, teamId) => ([
      ...acc,
      {
        id: +teamId,
        name: state.data.entities.teams[teamId].name,
      }]), []),
  }),
  {
    getProject: actions.getProject,
    updateProject: actions.updateProject,
    getAllTeams: actions.getAllTeams,
    addTeamToProject: actions.addTeamToProject,
    removeTeamFromProject: actions.removeTeamFromProject,
  }
)(PureProjectEdit);
