import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { connect } from 'react-redux';
import Backlog from '../Backlog';
import LinkMaybe from '../LinkMaybe';
import { STORY_BUCKET_SHAPE, STORY_FILTER_SHAPE } from '../../types/props';
import { filterStories } from '../../services/storyFilters';
import truthyFilterArray from '../../utils/truthyFilterArray';

function Bucket({ bucket, storyFilters = [] }) {
  return (
    <div className="backlog-bucket">
      {bucket.title ? (
        <h3 id={`backlog-${bucket.type}-${bucket.id}`}>
          <LinkMaybe to={bucket.uri}>
            {bucket.title}
          </LinkMaybe>
        </h3>
      ) : null}

      <Backlog
        stories={filterStories(storyFilters, truthyFilterArray(bucket.backlog_stories))}
        backlogType={bucket.type}
        backlogId={bucket.id}
        disabled
      />
    </div>
  );
}

Bucket.propTypes = {
  bucket: shape(STORY_BUCKET_SHAPE),
  storyFilters: arrayOf(shape(STORY_FILTER_SHAPE)),
};

export default connect(
  ({ storyFilters }) => ({
    storyFilters,
  })
)(Bucket);
