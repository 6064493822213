import React from 'react';
import EditorButton from './EditorButton';

interface EditorButtonBarProps {
  contentLength: number;
  mode: string,
  enableEditor: (mode: string) => void;
  toggleEditMode: () => void;
  save: () => void;
  cancel: () => void;
}

function EditorButtonBar({
  contentLength,
  mode,
  enableEditor,
  toggleEditMode,
  save,
  cancel,
}: EditorButtonBarProps) {
  const verb = contentLength > 0 ? 'Edit' : 'Elaborate';
  const other = mode === 'edit' ? 'Markdown' : 'Visual Editor';

  return (
    <div className="btnRow">
      {mode === 'view' ? (
        <>
          <EditorButton title={`${verb} Story`} onClick={() => enableEditor('edit')} />
          <EditorButton title={`${verb} as Markdown`} onClick={() => enableEditor('markdown')} className="btn-outline-primary" />
        </>
      ) : (
        <>
          <EditorButton title="Save Changes" onClick={save} />
          <EditorButton title={`Switch to ${other}`} onClick={toggleEditMode} className="btn-outline-primary" />
          <EditorButton title="Cancel" onClick={cancel} className="btn-outline-danger" />
        </>
      )}
    </div>
  );
}

export default EditorButtonBar;
