import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectHydrated as selectBoard } from '../../modules/board';
import Loading from '../Loading';
import BurndownDetail from './BurndownDetail';

// Dynamic import.
const Burndown = React.lazy(() => import('../Burndown'));

const Props = {
  /** Team ID used to select the board from the state. */
  teamId: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
  /** Name of the team. */
  teamName: PropTypes.string.isRequired,
  /** Team's active board. */
  board: PropTypes.shape({
    sprint: PropTypes.shape({
      points_committed: PropTypes.number,
      start_date: PropTypes.string.isRequired,
      started_at: PropTypes.string,
      end_date: PropTypes.string.isRequired,
      ended_at: PropTypes.string,
      title: PropTypes.string.isRequired,
      report: PropTypes.shape({
        done_points: PropTypes.number.isRequired,
        done_stories: PropTypes.arrayOf(PropTypes.shape({
          id: PropTypes.number.isRequired,
          title: PropTypes.string.isRequired,
          points: PropTypes.number,
          completed_at: PropTypes.string.isRequired,
        })),
      }),
    }),
  }).isRequired,
};

/**
 * Connected component to show a team's board burndown.
 */
function BoardBurndown({
  board: {
    sprint: {
      report: {
        done_stories: doneStories = [],
        done_points: donePoints,
      } = [],
      points_committed: pointsCommitted,
      start_date: startDate,
      end_date: endDate,
      started_at: startedAt = undefined,
      ended_at: endedAt = undefined,
      title: sprintTitle,
    } = {},
  },
  teamName,
}) {
  const [showDetail, setShowDetail] = React.useState(false);
  if (!(endDate && startDate && pointsCommitted > 0)) {
    // @todo display something informational if the burndown conditions have not been met.
    return (<div />);
  }

  return (
    <>
      <div
        className="card"
        type="button"
        aria-label="Explore sprint burndown in greater detail"
        onClick={() => setShowDetail(true)}
        onKeyDown={({ key }) => {
          if (key === 'Enter') {
            setShowDetail(true);
          }
        }}
        role="button"
        tabIndex="0"
      >
        <React.Suspense fallback={<Loading />}>
          <Burndown
            pointsCommitted={pointsCommitted}
            endDate={endedAt || endDate}
            showDetails={false}
            startDate={startedAt || startDate}
            stories={doneStories}
          />
        </React.Suspense>
      </div>
      {
        showDetail && (
        <BurndownDetail
          endDate={endDate}
          handleClose={() => setShowDetail(false)}
          remainingPoints={+(pointsCommitted - donePoints)}
          title={`${teamName}: ${sprintTitle}`}
        >
          <React.Suspense fallback={<Loading />}>
            <Burndown
              pointsCommitted={pointsCommitted}
              endDate={endedAt || endDate}
              showDetails
              startDate={startedAt || startDate}
              stories={doneStories}
            />
          </React.Suspense>
        </BurndownDetail>
        )
      }
    </>
  );
}

BoardBurndown.propTypes = Props;

export default connect(
  (state, { teamId = 0 }) => ({
    board: selectBoard(state, teamId) || {},
    teamName: state?.data?.entities?.teams?.[teamId]?.name || '',
  })
)(BoardBurndown);
