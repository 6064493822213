import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Internal dependencies.
import * as actions from '../../actions';
import { getContext, getFeatures } from '../../selectors';
import { Feature } from '../../types/interfaces/Feature';

// Components.
import FeatureCard from './FeatureCard';
import FeatureForm from './FeatureForm';
import Loading from '../Loading';
import Modal from '../Modal';

export default function Features() {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const {
    contextType,
    id: contextIdParam,
  } = useParams() as {
    contextType: 'teams' | 'projects',
    id: string,
  };
  const contextId = +(contextIdParam || 0);
  const sourceType = contextType === 'teams' ? 'team' : 'project';

  // Get features from state.
  const features = useSelector((state) => getFeatures(state, { contextType, id: contextId }));

  // Get context from state.
  const context = useSelector((state) => getContext(state, contextType, contextId));

  const [showingArchives, showArchives] = React.useReducer(() => true, false);
  const [archives, setArchives] = React.useState<Feature[] | null>(null);

  // Join the pusher channel for the feature's context.
  React.useEffect(() => {
    if (contextType === 'projects') {
      dispatch(actions.joinProjectChannel(contextId));
    } else {
      dispatch(actions.joinTeamChannel(contextId));
    }
  }, []);

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(actions.loadAllFeatures(sourceType, contextId));
      // Give useSelector a moment to select the newly loaded features.
      setTimeout(() => setLoading(false), 50);
    })();
  }, [contextType, contextId]);

  // Load the archives when requested.
  React.useEffect(() => {
    if (showingArchives) {
      (async () => {
        const archivedFeatures = await dispatch(
          actions.getArchivedFeatures(sourceType, contextId)
        ) as unknown as Feature[];
        setArchives(archivedFeatures);
      })();
    }
  }, [showingArchives, contextType, contextId]);

  const [editingFeature, setEditingFeature] = React.useState<null | 'new' | Feature>(null);

  const archiveHandler = async (featureId: number) => {
    await dispatch(actions.archiveFeature(featureId, contextType, contextId));
    if (showingArchives) {
      const feature = features.find(({ id }) => id === featureId);
      if (feature) {
        setArchives((oldArchives) => [
          ...(oldArchives || []),
          feature,
        ].sort((a, b) => a.id - b.id));
      }
    }
  };

  const unarchiveHandler = async (featureId: number) => {
    await dispatch(actions.unarchiveFeature(featureId, contextType, contextId));
    setArchives((oldArchives) => oldArchives?.filter(({ id }) => id !== featureId) || []);
  };

  const deleteHandler = (featureId: number) => {
    dispatch(actions.deleteFeature(featureId, contextType, contextId));
  };

  const editHandler = (featureId: number) => {
    const feature = features.find(({ id }) => featureId === id);
    if (feature) {
      setEditingFeature(feature);
    }
  };

  const closeFeatureForm = () => setEditingFeature(null);

  const featureFormSubmitHandler = async (data) => {
    const {
      id,
      title,
      description,
      kickoff,
    } = data;
    if (id) {
      await dispatch(actions.updateFeature(id, {
        title,
        description,
        kickoff_at: kickoff || null,
      }));
    } else {
      await dispatch(actions.createFeature(contextId, sourceType, { title, description }));
    }
    closeFeatureForm();
  };

  // @ts-expect-error Unclear how to tell TS that this is totally fine.
  const title = `${context?.title || context?.name || ''} Features`;

  // Assuming we have buckets, loop over them.
  return (
    <main className="container">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <div className="d-flex justify-content-between my-3">
            <h1>{title}</h1>
            <ul className="nav">
              <li className="nav-item">
                <button type="button" className="btn btn-primary" onClick={() => setEditingFeature('new')}>
                  New Feature
                </button>
              </li>
            </ul>
          </div>

          {features.length === 0 ? (
            <div className="alert alert-primary">There are no features to display</div>
          ) : (
            <div className="row row-cols-1 row-cols-md-3">
              {features.map((feature) => (
                <FeatureCard
                  key={feature.id}
                  feature={feature}
                  contextId={contextId}
                  contextType={contextType}
                  onDelete={deleteHandler}
                  onArchive={archiveHandler}
                  onEdit={editHandler}
                />
              ))}
            </div>
          )}

          {showingArchives ? (
            <div className="mt-3 pt-3 border-top">
              <h2 className="h3 mb-3">Archived Features</h2>
              {archives === null ? <Loading /> : (
                <div>
                  {archives.length ? (
                    <div className="row row-cols-1 row-cols-md-3">
                      {archives.map((feature) => (
                        <FeatureCard
                          isArchived
                          key={feature.id}
                          feature={feature}
                          contextId={contextId}
                          contextType={contextType}
                          onDelete={deleteHandler}
                          onUnarchive={unarchiveHandler}
                          onEdit={editHandler}
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="alert alert-primary">
                      There are no archived features to display
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <button type="button" className="btn text-primary" onClick={() => showArchives()}>
              Show archived features
            </button>
          )}

          {editingFeature ? (
            <Modal
              onClose={closeFeatureForm}
              title={editingFeature === 'new' ? 'Adding a New Feature' : `Editing ${editingFeature.title}`}
            >
              <FeatureForm
                feature={editingFeature === 'new' ? null : editingFeature}
                onCancel={closeFeatureForm}
                onSubmit={featureFormSubmitHandler}
              />
            </Modal>
          ) : null}
        </>
      )}
    </main>
  );
}
