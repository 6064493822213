import get from 'lodash/get';
import store from '../../store';

/**
 * Filter stories by points.
 *
 * @param {string} value - Either 'none' or 'estimating'.
 * @param {array} stories - The stories to match.
 * return {array} Unpointed stories.
 */
const points = (value, stories) => stories.map(
  (story) => ({
    ...story,
    filtered: (
      (value === 'none' && story.points !== null)
      || (value === 'estimating' && (
        typeof story.active_game === 'undefined'
        || get(store.getState(), `data.entities.games.${story.active_game}`, false) === false
      ))
    ),
  })
);
export default points;
