import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import * as actions from '../../actions';
import { reduceBucketsToStories, selectHydrated as selectBucketData } from '../../modules/bucket';
import { CONTEXT_BUCKET_SHAPE } from '../../types/props';

import Loading from '../Loading';
import StoryFilters from '../StoryFilters';
import Bucket from './Bucket';
import DoneBucket from './DoneBucket';
import LinkMaybe from '../LinkMaybe';
import SectionReport from '../SectionReport';

function BucketList({
  allBuckets: {
    buckets: allBuckets = [],
  },
  bucketData: {
    title,
    buckets = [],
  },
  contextId,
  contextType,
  getBuckets,
  getProject,
  getTeam,
  joinProjectChannel,
  view,
}) {
  const [loading, setLoading] = React.useState(true);

  const allStories = React.useMemo(() => reduceBucketsToStories(allBuckets), [allBuckets]);

  // Subscribe to project-level updates.
  React.useEffect(() => {
    if (contextType === 'projects') {
      joinProjectChannel(contextId);
    }
  }, []);

  React.useEffect(() => {
    setLoading(true);
    Promise.all([
      getBuckets(contextType, contextId, view),
      // @todo Only query for all stories, and filter in JS.
      view !== 'all' ? getBuckets(contextType, contextId, 'all') : Promise.resolve(),
      contextType === 'projects' ? getProject(contextId) : getTeam(contextId),
    ])
      .then(() => setLoading(false));
  }, [contextId, contextType, view]);

  const pageTitle = () => {
    if (view === 'all') {
      return `All ${title} Stories`;
    }
    if (view === 'done') {
      return `Completed ${title} Stories`;
    }
    return `Active ${title} Stories`;
  };

  // assuming we have buckets, loop over them.
  return (
    <main className="container">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <h1>
              {pageTitle()}
            </h1>
          </div>

          {contextType === 'projects' ? (
            <SectionReport type="project" allStories={allStories} />
          ) : null}

          {buckets.length === 0 && (
            <div className="alert alert-primary">There are no stories to display</div>
          )}

          {buckets.length > 0 && (
            <StoryFilters
              allStories={allStories}
              activeViewFilter={view}
              showPrimaryFilters={false}
              showViewFilters
            />
          )}

          {buckets.map((contextBucket) => (
            <div key={`${contextBucket.type}.${contextBucket.id}`} className="contexts-bucket">
              {!!contextBucket.title && (
                <h2>
                  {!!contextBucket.type && (
                    <span className="badge badge-secondary">
                      {contextBucket.type === 'team' ? 'Team' : 'Project'}
                    </span>
                  )}
                  {' '}
                  <LinkMaybe to={contextBucket.uri}>
                    {contextBucket.title}
                  </LinkMaybe>
                </h2>
              )}

              {contextBucket.title === 'Done' ? (
                <DoneBucket stories={contextBucket.buckets[0].backlog_stories} />
              ) : (
                contextBucket.buckets.map((bucket) => (
                  <Bucket key={`${bucket.type}.${bucket.id}`} bucket={bucket} />
                ))
              )}
            </div>
          ))}
        </>
      )}
    </main>
  );
}

BucketList.defaultProps = {
  bucketData: {},
  allBuckets: {},
  view: 'active',
};

BucketList.propTypes = {
  contextId: PropTypes.number.isRequired,
  contextType: PropTypes.oneOf(['teams', 'projects']).isRequired,
  joinProjectChannel: PropTypes.func.isRequired,
  getBuckets: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
  getTeam: PropTypes.func.isRequired,
  bucketData: PropTypes.shape({
    title: PropTypes.string,
    buckets: PropTypes.arrayOf(PropTypes.shape(CONTEXT_BUCKET_SHAPE)),
  }),
  allBuckets: PropTypes.shape({
    title: PropTypes.string,
    buckets: PropTypes.arrayOf(PropTypes.shape(CONTEXT_BUCKET_SHAPE)),
  }),
  view: PropTypes.oneOf(['active', 'all', 'done']),
};

export default connect(
  (state, {
    match: {
      params: {
        id,
        contextType,
      },
    },
    location,
  }) => {
    const qs = queryString.parse(location.search);
    const view = typeof qs.view === 'string'
      && ['active', 'done', 'all'].includes(qs.view)
      ? qs.view
      : 'active';

    return {
      contextId: +id,
      contextType,
      bucketData: selectBucketData(state, `${contextType}.${id}.${view}`),
      allBuckets: selectBucketData(state, `${contextType}.${id}.all`),
      view,
    };
  },
  {
    getBuckets: actions.getStoryBuckets,
    getProject: actions.getProject,
    getTeam: actions.getTeam,
    joinProjectChannel: actions.joinProjectChannel,
  }
)(BucketList);
