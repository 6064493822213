import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { CollectionFill, Diagram3 } from 'react-bootstrap-icons';

import { STORY_FILTER_SHAPE, STORY_SHAPE } from '../../types/props';
import shallowObjectProps from '../../utils/shallowObjectProps';
import sumStories from '../../utils/sumStories';
import * as actions from '../../actions';
import { filterData } from '../../modules/storyFilters';
import { filterStories } from '../../services/storyFilters';
import { getAvailableContributors } from '../../services/storyFilters/contributor';

// Components
import Filter from './Filter';
import LinkMaybe from '../LinkMaybe';
import UserCard from '../UserCard';

const storyFiltersProps = {
  activeViewFilter: PropTypes.string,
  addFilter: PropTypes.func.isRequired,
  allStories: PropTypes.arrayOf(PropTypes.shape(STORY_SHAPE)).isRequired,
  removeFilter: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  showPrimaryFilters: PropTypes.bool,
  showViewFilters: PropTypes.bool,
  storyFilters: PropTypes.arrayOf(PropTypes.shape(STORY_FILTER_SHAPE)).isRequired,
};

function StoryFilters({
  activeViewFilter = '',
  addFilter,
  allStories,
  removeFilter,
  resetFilters,
  showPrimaryFilters = true,
  showViewFilters = false,
  storyFilters,
}) {
  const location = useLocation();

  React.useEffect(() => {
    // If a valid filter is in the `filter` query string, apply it.
    const qs = queryString.parse(location.search);
    if (qs.filter) {
      const [type, id] = qs.filter.split(':');
      if (filterData[type]) {
        addFilter(filterData[type](id));
      }
    }

    return resetFilters;
  }, []);

  const checkIsActive = ({ type, id }) => (
    storyFilters.findIndex((active) => active.type === type && active.id === id) > -1
  );

  const hasPokerGames = allStories.findIndex((story) => typeof story.active_game !== 'undefined') > -1;

  const dropdownFilters = [
    { text: 'New', data: { type: 'status', id: 'new' } },
    { text: 'Active', data: { type: 'status', id: 'open' } },
    { text: 'Done', data: { type: 'status', id: 'done' } },
    { text: 'No Contributors', data: { type: 'contributor', id: 'none' } },
    { text: 'Unpointed', data: { type: 'points', id: 'none' } },
  ];

  if (hasPokerGames) {
    dropdownFilters.push(
      { text: 'Active Poker Game', data: { type: 'points', id: 'estimating' } }
    );
  }

  const sourceFilters = React.useMemo(() => {
    const sources = allStories
      // Build list of all sources.
      .reduce((acc, story) => {
        const { source_id, source_type } = story;
        // eslint-disable-next-line babel/camelcase
        const label = source_type === 'team' ? story?.team?.name
          : story?.project?.title;

        // Do not add to filter list if there was not a source in state.
        if (typeof label === 'undefined') {
          return acc;
        }

        return {
          ...acc,
          [source_type]: {
            ...acc[source_type],
            [source_id]: label,
          },
        };
      }, {});

    // Flatten sources object.
    return Object.keys(sources)
      .reduce((acc, type) => {
        const typeSources = Object.keys(sources[type])
          .map((id) => ({
            text: sources[type][id],
            data: {
              type,
              id,
            },
          }));

        return [
          ...acc,
          ...typeSources,
        ];
      }, []);
  }, [shallowObjectProps(allStories, ['id', 'project.title', 'team.name', 'source_id', 'source_type'])]);

  const buildFilterList = (stories, key) => Object.values(
    stories.reduce((carry, story) => {
      if (!story[key] || carry[story[key].id]) {
        return carry;
      }
      // eslint-disable-next-line no-param-reassign
      carry[story[key].id] = {
        data: {
          type: key,
          id: story[key].id,
        },
        text: story[key].title,
      };
      return carry;
    }, {})
  ).sort((a, b) => a.text.localeCompare(b.text));

  const epicFilters = buildFilterList(allStories, 'epic');
  const featureFilters = buildFilterList(allStories, 'feature');

  const renderFilterList = ({ data, text }) => {
    const isActive = checkIsActive(data);
    return (
      <Filter
        key={`filter.${data.type}.${data.id}`}
        addFilter={addFilter}
        removeFilter={removeFilter}
        filterData={data}
        isActive={isActive}
        className={classnames('dropdown-item', { active: isActive })}
      >
        {text}
        {data.type !== 'points' ? (
          <>
            {' '}
            <span className="badge badge-secondary">
              {sumStories(filterStories(data, allStories)
                .filter(({ filtered }) => !filtered))}
            </span>
          </>
        ) : null}
      </Filter>
    );
  };

  return (
    <div className="backlog-filters">
      {showPrimaryFilters && (
        <div className="dropdown mr-2">
          <button className="btn btn-secondary dropdown-toggle" type="button" id="filter-stories-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Filter by
            {' '}
          </button>
          <div className="dropdown-menu" aria-labelledby="filter-stories-button">
            {dropdownFilters.map(renderFilterList)}
            {sourceFilters.length > 0 ? (
              <>
                <div className="dropdown-divider" />
                <h3 className="h6 dropdown-header">
                  Sources
                </h3>
              </>
            ) : null}
            {sourceFilters.map(renderFilterList)}
          </div>
        </div>
      )}

      {showViewFilters && (
        <div className="dropdown mr-2">
          <button className="btn btn-secondary dropdown-toggle" type="button" id="view-all-button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            View
            {' '}
          </button>
          <div className="dropdown-menu" aria-labelledby="view-all-button">
            {['Active', 'Done', 'All'].map((status) => (
              <LinkMaybe
                key={status}
                className={classnames('dropdown-item', { active: activeViewFilter === status.toLowerCase() })}
                to={(loc) => {
                  const params = new URLSearchParams(loc.search);
                  params.set('view', status.toLowerCase());
                  return { ...loc, search: params.toString() };
                }}
              >
                {`${status} Stories`}
              </LinkMaybe>
            ))}
          </div>
        </div>
      )}

      {epicFilters.length ? (
        <div className="dropdown mr-2">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="filter-epic-button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="Filter by Epic"
          >
            <span className="sr-only">Filter by Epic</span>
            <CollectionFill aria-hidden />
            {' '}
          </button>
          <div className="dropdown-menu" aria-labelledby="filter-epic-button">
            {renderFilterList({ data: { type: 'epic', id: '' }, text: 'No Epic' })}
            <div className="dropdown-divider" />
            {epicFilters.map(renderFilterList)}
          </div>
        </div>
      ) : null}

      {featureFilters.length ? (
        <div className="dropdown mr-2">
          <button
            className="btn btn-secondary dropdown-toggle"
            type="button"
            id="filter-feature-button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            title="Filter by Feature"
          >
            <span className="sr-only">Filter by Feature</span>
            <Diagram3 aria-hidden />
            {' '}
          </button>
          <div className="dropdown-menu" aria-labelledby="filter-feature-button">
            {renderFilterList({ data: { type: 'feature', id: '' }, text: 'No Feature' })}
            <div className="dropdown-divider" />
            {featureFilters.map(renderFilterList)}
          </div>
        </div>
      ) : null}

      <div className="user-strip">
        <ul className="contributors-list">
          {getAvailableContributors(allStories).map((user) => {
            const filterProps = filterData.contributor(user.id);
            const isActive = checkIsActive(filterProps);

            return (
              <li key={user.id}>
                <Filter
                  addFilter={addFilter}
                  removeFilter={removeFilter}
                  filterData={filterProps}
                  isActive={isActive}
                  className={classnames('btn', { 'btn-success': isActive })}
                >
                  <UserCard user={user} status="neutral" />
                  <span className="badge badge-secondary">
                    {sumStories(filterStories(filterProps, allStories)
                      .filter(({ filtered }) => !filtered))}
                  </span>
                </Filter>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

StoryFilters.propTypes = storyFiltersProps;

export default connect(
  ({ storyFilters }) => ({
    storyFilters,
  }),
  (dispatch) => ({
    addFilter: (filter) => dispatch(actions.addStoryFilter(filter)),
    removeFilter: (filter) => dispatch(actions.removeStoryFilter(filter)),
    resetFilters: (filter) => dispatch(actions.resetStoryFilters(filter)),
  })
)(StoryFilters);
