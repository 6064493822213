import { schema } from 'normalizr';

// Schema for app state from Laravel
const entitySchema = {
  attachments: new schema.Entity('attachments'),
  buckets: new schema.Entity('buckets'),
  tasks: new schema.Entity('tasks'),
  members: new schema.Entity('members'),
  features: new schema.Entity('features'),
  comments: new schema.Entity('comments'),
  games: new schema.Entity('games'),
  stories: new schema.Entity('stories'),
  projects: new schema.Entity('projects'),
  sprints: new schema.Entity('sprints'),
  teams: new schema.Entity('teams'),
  boards: new schema.Entity('boards'),
  searches: new schema.Entity('searches'),
  sprintSummaries: new schema.Entity('sprintSummaries'),
  columns: new schema.Entity('columns'),
};

entitySchema.comments.define(
  { member: entitySchema.members }
);

entitySchema.games.define(
  {
    responses: {
      voted: [entitySchema.members],
      waiting: [entitySchema.members],
    },
    results: {
      votes: [
        {
          user_id: entitySchema.members,
        },
      ],
    },
  }
);

entitySchema.stories.define({
  attachments: [entitySchema.attachments],
  tasks: [entitySchema.tasks],
  games: entitySchema.games,
  comments: [entitySchema.comments],
  parent: entitySchema.stories,
  children: [entitySchema.stories],
  feature: entitySchema.features,
  team: entitySchema.teams,
  project: entitySchema.projects,
  contributors_data: [entitySchema.members],
});

entitySchema.projects.define({
  backlog_stories: [entitySchema.stories],
  features: [entitySchema.features],
  teams: [entitySchema.teams],
});

entitySchema.sprints.define({
  backlog_stories: [entitySchema.stories],
  team: entitySchema.teams,
  columns: [entitySchema.columns],
});

entitySchema.teams.define(
  {
    backlog_stories: [entitySchema.stories],
    open_sprints: [entitySchema.sprints],
    current_sprint: entitySchema.sprints,
    members: [entitySchema.members],
    features: [entitySchema.features],
    games: [entitySchema.games],
  }
);

entitySchema.columns.define({
  backlog_stories: [entitySchema.stories],
});

entitySchema.boards.define(
  {
    team: entitySchema.teams,
    backlog_stories: [entitySchema.stories],
    columns: [entitySchema.columns],
  }
);

entitySchema.searches.define({ stories: [entitySchema.stories] });

entitySchema.buckets.define({
  buckets: [{
    buckets: [{
      stories: [entitySchema.stories],
    }],
  }],
});

entitySchema.sprintSummaries.define({ sprints: [entitySchema.sprints] });

const appSchema = {
  teams: [entitySchema.teams],
  projects: [entitySchema.projects],
  stories: [entitySchema.stories],
  sprints: [entitySchema.sprints],
  members: [entitySchema.members],
};

export { entitySchema, appSchema };
