import get from 'lodash/get';

/**
 * Reducer to update an entity when a story is moved either to or from it.
 *
 * @param {object} state Slice of state containing entity that is being updated.
 * @param {number} storyId The ID of the story being moved.
 * @param {number} storyPoints The points for the story that is being moved.
 * @param {Object} target - Holds information about the entity being updated.
 * @param {number} target.id - The ID of the entity that is changing because of the move.
 * @param {string} direction - Either 'to' or 'from', determines if story is moving to
 *                             or from the target.
 */
const updateBacklogStories = (state, storyId, storyPoints, target = {}, direction = 'from') => {
  const previousPoints = get(state, `${target?.id}.total_points`, 0);
  const updatedPoints = direction === 'to' ? previousPoints + storyPoints
    : previousPoints - storyPoints;
  const { backlog_stories: prevBacklogStories = [] } = state[target.id] || {};

  const updatedBacklogStories = (() => {
    if (direction === 'to') {
      return [
        ...prevBacklogStories,
        storyId,
      ];
    }

    return prevBacklogStories.filter((id) => id !== storyId);
  })();

  return {
    ...state,
    [target.id]: {
      ...state[target.id],
      backlog_stories: updatedBacklogStories,
      total_points: updatedPoints,
    },
  };
};

export default updateBacklogStories;
