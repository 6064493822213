import * as React from 'react';
import { schema } from 'prosemirror-markdown';
import { EditorState, Plugin, PluginKey } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { Node } from 'prosemirror-model';
import { exampleSetup } from 'prosemirror-example-setup';

// These styles are imported inline because babel is not ignoring it as it should.
import '!style-loader!css-loader!../../../../../node_modules/prosemirror-view/style/prosemirror.css';
import '!style-loader!css-loader!../../../../../node_modules/prosemirror-example-setup/style/style.css';
import '!style-loader!css-loader!../../../../../node_modules/prosemirror-menu/style/menu.css';

const reactPropsKey = new PluginKey('reactProps');

const reactProps = (initialProps) => new Plugin({
  key: reactPropsKey,
  state: {
    init: () => initialProps,
    apply: (tr, prev) => tr.getMeta(reactPropsKey) || prev,
  },
});

interface EditorProps {
  markdownDoc: Node;
}

export type Ref = EditorView | null;

const Editor = React.forwardRef<Ref, EditorProps>((props, editorRef: any) => {
  const view = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => { // initial render
    const plugins = exampleSetup({ schema });
    plugins.push(reactProps(props));
    const state = EditorState.create({
      doc: props.markdownDoc,
      plugins,
    });
    // eslint-disable-next-line no-param-reassign
    editorRef.current = new EditorView(view.current, { state });
    return () => editorRef.current.destroy();
  }, []);

  React.useEffect(() => { // every render
    const tr = editorRef.current.state.tr.setMeta(reactPropsKey, props);
    editorRef.current.dispatch(tr);
  });

  return (
    <div ref={view} />
  );
});

export default Editor;
