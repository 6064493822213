// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FeatureCard__body__4HwcZ{display:flex;flex-direction:column;flex-grow:1}.FeatureCard__body__4HwcZ dl{padding:0 .5rem;flex:1 1 0}.FeatureCard__body__4HwcZ dt{font-size:1.3rem}.FeatureCard__body__4HwcZ dd{font-size:.7rem;text-align:center}.FeatureCard__statsRow__kZ1vT{gap:0}.FeatureCard__cardBody__m3miS{display:flex;flex-direction:column}", "",{"version":3,"sources":["webpack://./resources/js/components/Features/FeatureCard.scss"],"names":[],"mappings":"AAAA,0BACE,YAAA,CACA,qBAAA,CACA,WAAA,CAEA,6BACE,eAAA,CACA,UAAA,CAGF,6BACE,gBAAA,CAGF,6BACE,eAAA,CACA,iBAAA,CAIJ,8BACE,KAAA,CAGF,8BACE,YAAA,CACA,qBAAA","sourcesContent":[".body {\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n\n  dl {\n    padding: 0 0.5rem;\n    flex: 1 1 0;\n  }\n\n  dt {\n    font-size: 1.3rem;\n  }\n\n  dd {\n    font-size: 0.7rem;\n    text-align: center;\n  }\n}\n\n.statsRow {\n  gap: 0;\n}\n\n.cardBody {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "FeatureCard__body__4HwcZ",
	"statsRow": "FeatureCard__statsRow__kZ1vT",
	"cardBody": "FeatureCard__cardBody__m3miS"
};
export default ___CSS_LOADER_EXPORT___;
