/**
 *
 * Returns the item's index in the column.
 *
 * @param {object[]|number[]} items - Array of objects from which to find the order.
 * @param {number} id - ID to match.
 * @returns {number} - ID if found, -1 if not found.
 */
const getIndexById = (items, id) => (items.findIndex((item) => {
  if (typeof item === 'number') {
    return id === item;
  }
  return item.id === id;
}));

export default getIndexById;
