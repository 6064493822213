import React from 'react';
import PropTypes from 'prop-types';

function EpicSearchInput({
  state,
  methods,
  inputRef,
}) {
  return (
    <>
      <label className="sr-only" htmlFor="epicSelect-input">Assign to epic</label>
      <input
        ref={inputRef}
        tabIndex="0"
        className="react-dropdown-select-input"
        size={methods.getInputSize()}
        value={state.search}
        onClick={() => {
          methods.dropDown('open');
        }}
        onChange={methods.setSearch}
        id="epicSelect-input"
        autoComplete="off"
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
EpicSearchInput.propTypes = {
  state: PropTypes.object.isRequired,
  methods: PropTypes.object.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]).isRequired,
};

export default EpicSearchInput;
