/**
 * Add a user as a contributor to a story.
 *
 * @param {number} storyId - Story ID.
 * @param {int} [userId] - ID of the User to add to the story. If absent, the
 *                          current user will be added.
 * @returns {Promise} Promise object representing the API request.
 */
const addContributor = (storyId, userId = null) => axios.post(`/api/story/${storyId}/contributors`, userId ? { user_id: userId } : {});
export default addContributor;
