/**
 * Update a Team.
 *
 * @param {number} teamId - Team ID.
 * @param {Object} team - Team.
 * @param {string} team.name - Team name.
 * @returns {Promise} Promise object representing the API request.
 */
const updateTeam = (teamId, team) => axios.put(`/api/teams/${teamId}`, team);
export default updateTeam;
