import {
  arrayOf,
  bool,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
} from 'prop-types';

export const USER_SHAPE = {
  id: number,
  avatar_url: string,
  name: string,
};

export const COMMENT_SHAPE = {
  body: string,
  member: object,
};

export const FEATURE_SHAPE = {
  description: string,
  id: number,
  title: string,
};

export const POKER_GAME_SHAPE = {
  id: number,
  responses: shape({
    voted: arrayOf(shape(USER_SHAPE)),
    waiting: arrayOf(shape(USER_SHAPE)),
  }),
  results: shape({
    votes: arrayOf(shape({
      user: shape(USER_SHAPE),
      points: number,
    })),
    min: number,
    max: number,
    mean: number,
    median: number,
    in_range: bool,
  }),
  user_vote: shape({
    points: number,
  }),
  is_open: bool,
};

export const BACKLOG_MINIMAL_SHAPE = {
  type: string,
  id: number,
  title: string,
  parent: shape({
    id: number,
    title: string,
  }),
};

export const STORY_SHAPE = {
  body: string,
  comments: arrayOf(object),
  context_link: object,
  contributors_data: arrayOf(shape(USER_SHAPE)),
  created_at: string,
  filtered: bool,
  games: shape(POKER_GAME_SHAPE),
  id: number.isRequired,
  partial: bool.isRequired,
  points: number,
  backlog: shape(BACKLOG_MINIMAL_SHAPE),
  tasks: arrayOf(object),
  title: string.isRequired,
  url: string.isRequired,
};

export const STORY_FILTER_SHAPE = {
  type: string.isRequired,
  id: oneOfType([number, string]).isRequired,
};

export const BACKLOG_SHAPE = {
  add_story_endpoint: string,
  backlog_title: string,
  backlogId: string,
  backlogType: oneOf(['teams', 'sprints', 'projects']),
  backlogTypeLabel: string,
  id: number,
  stories: arrayOf(shape(STORY_SHAPE)),
  title: string,
};

export const BOARD_COLUMN_SHAPE = {
  id: oneOfType([string, number]).isRequired,
  status: string,
  title: string.isRequired,
  backlog_stories: arrayOf(shape(STORY_SHAPE)).isRequired,
};

export const BOARD_SHAPE = {
  columns: arrayOf(shape({
    title: string,
    id: number,
  })),
  backlog: shape({
    title: string,
  }),
  done: shape({
    title: string,
  }),
};

export const PROJECT_SHAPE = {
  id: number.isRequired,
  title: string.isRequired,
  features: arrayOf(shape(FEATURE_SHAPE)),
  backlog_title: string.isRequired,
};

export const TEAM_CONFIG_SHAPE = {
  board: shape({
    backlog: shape({
      title: string,
    }),
    columns: arrayOf(
      shape({
        id: number,
        team_id: number,
        title: string,
      })
    ),
    done: shape({
      title: string,
    }),
  }),
};

export const TEAM_SHAPE = {
  id: number.isRequired,
  name: string.isRequired,
  config: shape(TEAM_CONFIG_SHAPE),
};

export const SOURCE_SHAPE = {
  features: arrayOf(oneOfType([
    shape(FEATURE_SHAPE),
    number,
  ])),
  id: number.isRequired,
  label: string.isRequired,
  type: string.isRequired,
};

export const SPRINT_SHAPE = {
  id: number,
  team_id: number,
  start_date: string,
  end_date: string,
  started_at: string,
  ended_at: string,
  title: string,
  is_active: bool,
  total_points: number,
};

// This shape represents a sprint that is either active or completed, and as a
// result, has a report and point commitment.
export const STARTED_SPRINT_SHAPE = {
  is_active: bool,
  points_committed: number,
  start_date: string.isRequired,
  started_at: string,
  end_date: string.isRequired,
  ended_at: string,
  title: string.isRequired,
  report: shape({
    done_points: number.isRequired,
    done_stories: arrayOf(shape({
      id: number.isRequired,
      title: string.isRequired,
      points: number,
      completed_at: string.isRequired,
    })),
  }),
};

export const STORY_BUCKET_SHAPE = {
  id: number,
  title: string,
  type: oneOf(['team', 'project', 'sprint', 'board_column']),
  uri: string,
  backlog_stories: arrayOf(shape(STORY_SHAPE)).isRequired,
};

export const CONTEXT_BUCKET_SHAPE = {
  id: number,
  type: oneOf(['team', 'project']),
  title: string,
  uri: string,
  buckets: arrayOf(shape(STORY_BUCKET_SHAPE)).isRequired,
};
