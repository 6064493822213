import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';

import { STORY_SHAPE } from '../../types/props';
import StoryCard from './StoryCard';
import { TYPE_MAP } from '../../actions/types';

const BacklogPropTypes = {
  alwaysRender: PropTypes.bool,
  backlogId: PropTypes.number.isRequired,
  backlogType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  reorderStoriesHandler: PropTypes.func,
  sortable: PropTypes.bool,
  stories: PropTypes.arrayOf(PropTypes.shape(STORY_SHAPE)).isRequired,
  updateHandler: PropTypes.func,
  showRelationshipFlags: PropTypes.bool,
};

function Backlog({
  alwaysRender = false,
  backlogType,
  backlogId,
  disabled = false,
  reorderStoriesHandler = () => {},
  sortable = true,
  stories,
  updateHandler = null,
  showRelationshipFlags = false,
}) {
  if (!alwaysRender && stories.length === 0) {
    return (
      <p>
        There are no stories in this
        {' '}
        {TYPE_MAP[backlogType]}
        {' '}
        backlog yet.
      </p>
    );
  }

  // ReactSortable needs to manage its own state, but it helpfully lets us
  // define how it does that. This slice of state is for ReactSortable.
  const [list, setList] = useState(stories);

  // When ReactSortable changes the list, because state is managed in two places,
  // we end up double-updating it. ReactSortable's update is naive, and only
  // moves the item at index X to index Y, which causes double-moving. This
  // slice of state serves as a flag to help determine if Backlog should update
  // the list or if ReactSortable should.
  const [updating, setUpdating] = useState(false);

  const onUpdate = updateHandler || (({
    from,
    item,
    newIndex,
    oldIndex,
    to,
  }) => {
    // ReactSortable is updating the list, set the flag so useEffect doesn't also.
    setUpdating(true);
    const { storyId } = item.dataset;
    const operation = (to === from) ? 'reorder' : 'remove';
    reorderStoriesHandler(storyId, operation, oldIndex, newIndex);
  });

  /**
   * Helper for ReactSortable to update the list and change the updating flag.
   *
   * @param {array} newList - Updated list.
   */
  const setListHandler = (newList) => {
    setList(newList);
    setUpdating(false);
  };

  useEffect(() => {
    if (updating) {
      // If ReactSortable is doing the updating, don't double-update the list.
      return;
    }

    setList(
      stories.map((item) => ({
        ...item,
        chosen: false,
        selected: false,
      }))
    );
  }, [stories]);

  return (
    <ReactSortable
      tag="ol"
      className="story-list backlog-list"
      list={list}
      onUpdate={onUpdate}
      setList={(newList) => setListHandler(newList)}
      animation={300}
      group="stories"
      sort={sortable}
      disabled={disabled}
    >
      {list.map((story) => (
        <StoryCard
          key={story.id}
          story={story}
          context={{
            id: backlogId,
            type: backlogType,
          }}
          showRelationshipFlags={showRelationshipFlags}
          pusherActivity={story.pusherActivity}
        />
      ))}
    </ReactSortable>
  );
}

Backlog.propTypes = BacklogPropTypes;

export default Backlog;
