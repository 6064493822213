import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { X, HourglassSplit } from 'react-bootstrap-icons';
import { formatDistance, isAfter, parseISO } from 'date-fns';

const Props = {
  /** Function to close the modal. */
  handleClose: PropTypes.func.isRequired,
  /** Title of the modal. */
  title: PropTypes.string.isRequired,
  /** How many points are left to complete in the sprint. */
  remainingPoints: PropTypes.number.isRequired,
  /** The last day of the sprint, in UTC format. */
  endDate: PropTypes.string.isRequired,
  /** Anything that will be displayed within the modal. */
  children: PropTypes.node.isRequired,
};

function BurndownDetail({
  handleClose,
  title,
  remainingPoints,
  endDate,
  children,
}) {
  const appEl = document.getElementById('appBinding');
  return (
    <ReactModal
      isOpen
      onRequestClose={handleClose}
      appElement={appEl}
      ariaHideApp={(!!appEl)} // Prevent console errors when testing and no app element available.
      contentLabel="Sprint burndown"
      className="burndown-detail"
    >
      <div className="d-flex flex-column justify-content-center">
        <button
          type="button"
          onClick={handleClose}
          className="btn btn-close"
          style={{ position: 'absolute', top: '20px', right: '20px' }}
        >
          <X />
          <span className="sr-only">
            Close dialog
          </span>
        </button>
        <div className="mb-4 d-flex flex-column card-header">
          <h2 className="modal-title">
            {title}
          </h2>
          <p className="mb-0">
            <HourglassSplit />
            {' '}
            <strong>{remainingPoints}</strong>
            {' points to go'}
            {isAfter(new Date(), parseISO(endDate))
              ? '.'
              : ` in ${formatDistance(parseISO(endDate), new Date())}.`}
          </p>
        </div>
        <div className="card-body">
          {children}
        </div>
      </div>
    </ReactModal>
  );
}
BurndownDetail.propTypes = Props;

export default BurndownDetail;
