import { denormalize } from 'normalizr';
import { receiveEntity } from '../actions/entity';
import { entitySchema } from '../types';
import createEntityReducer from '../utils/createEntityReducer';

export const STATE_KEY = 'sprintSummaries';

/**
 * Sprint Summary reducer.
 */
export default createEntityReducer(STATE_KEY);

/**
 * Get a list of all sprints for a team.
 *
 * @param {number} teamId - Team ID.
 * @returns {function}
 */
export const getSprintSummaries = (teamId) => (dispatch, getState, { api }) => (
  api.getAllTeamSprints(teamId)
    .then(({ data: sprints }) => {
      dispatch(receiveEntity(STATE_KEY, { id: teamId, sprints }));
      return sprints;
    })
    .catch(api.errorHandler)
);

/**
 * Select the hydrated sprint summaries from state.
 *
 * @param {Object} state - Full redux state.
 * @param {string} teamId - Team ID.
 * @returns {Object}
 */
export const selectHydrated = (state, teamId) => (
  denormalize(teamId, entitySchema[STATE_KEY], state.data.entities)?.sprints || []
);
