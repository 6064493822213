import React from 'react';
import {
  FileEarmarkExcel,
  FileEarmarkFont,
  FileEarmarkImage,
  FileEarmarkMusic,
  FileEarmarkPdf,
  FileEarmarkPlay,
  FileEarmarkPpt,
  FileEarmarkText,
  FileEarmarkWord,
  FileEarmarkZip,
} from 'react-bootstrap-icons';

const getIcon = (filetype) => {
  const [type, subtype] = filetype.split('/');
  switch (type) {
    case 'audio':
      return [FileEarmarkMusic, 'audio'];

    case 'video':
      return [FileEarmarkPlay, 'video'];

    case 'image':
      return [FileEarmarkImage, 'image'];

    case 'font':
      return [FileEarmarkFont, 'font'];
  }

  switch (subtype) {
    case 'pdf':
      return [FileEarmarkPdf, 'pdf'];

    case 'vnd.ms-powerpoint':
    case 'vnd.openxmlformats-officedocument.presentationml.presentation':
      return [FileEarmarkPpt, 'powerpoint'];

    case 'msword':
    case 'vnd.openxmlformats-officedocument.wordprocessingml.document':
      return [FileEarmarkWord, 'word document'];

    case 'vnd.ms-excel':
    case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return [FileEarmarkExcel, 'excel'];

    case 'zip':
      return [FileEarmarkZip, 'zip'];
  }

  return [FileEarmarkText, filetype];
};

function FileIcon({ filetype }: { filetype: string }) {
  const [Icon, description] = getIcon(filetype);

  return (
    <>
      <Icon width="50%" height="50%" aria-hidden />
      <span className="sr-only">{`Open ${description} file in a new tab or window`}</span>
    </>
  );
}

export default FileIcon;
