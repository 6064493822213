// The modal wrapper is clickable to close as a convenience, and not at the cost of a11y.
/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import * as React from 'react';
import ReactModal from 'react-modal';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// Internal dependencies.
import * as actions from '../../../actions';
import useFileUpload from '../../../hooks/useFileUpload';
import { Attachment as IAttachment } from '../../../types/interfaces/Attachment';
import { isImage, isVideo } from '../../../utils/fileHelpers';

// Components.
import ProgressBar from '../../ProgressBar/ProgressBar';
import Attachment from './Attachment';

// Styles
import styles from './Attachments.scss';
import ConfirmationDialog from '../../ConfirmationDialog';

interface AttachmentsProps {
  storyId: number;
  attachments: IAttachment[];
}

function Attachments({ storyId, attachments }: AttachmentsProps) {
  const [selectedAttachment, setSelectedAttachment] = React.useState<IAttachment | null>(null);
  const [idPendingDeletion, setIdPendingDeletion] = React.useState<number>(0);
  const { handleUpload, isUploading, uploadProgress } = useFileUpload(storyId);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  // On hash change, open the appropriate attachment in a modal.
  React.useEffect(() => {
    const matches = location.hash.match(/^#attachment-(\d+)$/);
    if (matches) {
      const attachment = attachments.find((a) => a.id === +matches[1]);
      if (attachment && (isImage(attachment.filetype) || isVideo(attachment.filetype))) {
        setSelectedAttachment(attachment);
      }
    }
  }, [location.hash]);

  const appEl = document.getElementById('appBinding');

  const closeDialog = () => {
    setSelectedAttachment(null);
    history.push(location.pathname);
  };

  const onDrop = (files: File[] | undefined) => {
    if (files) {
      handleUpload(files);
    }
  };

  const downloadHandler = (id: number) => {
    window.open(`/stories/${storyId}/attachments/${id}/download`);
  };

  const hasAttachments = attachments.length > 0;

  const handleConfirm = () => {
    dispatch(actions.deleteAttachment(storyId, idPendingDeletion));
    setIdPendingDeletion(0);
  };

  return (
    <>
      <h2 className="h3">Attachments</h2>
      <Dropzone onDrop={onDrop} noClick={hasAttachments}>
        {({ getRootProps, isDragActive }) => (
          <div {...getRootProps({ className: styles.dropzone })} role={hasAttachments ? 'none' : 'button'}>
            {hasAttachments ? (
              <ol className={styles.attachments} aria-busy={isUploading}>
                {attachments.map((attachment) => (
                  <li key={attachment.id}>
                    <Attachment
                      attachment={attachment}
                      downloadHandler={downloadHandler}
                      deleteHandler={() => setIdPendingDeletion(attachment.id)}
                      setSelectedAttachment={setSelectedAttachment}
                      styles={styles}
                      pusherActivity={attachment.pusherActivity}
                    />
                  </li>
                ))}
              </ol>
            ) : (
              <div className={styles.emptyZone}>Drag files to add them to the story</div>
            )}

            {isDragActive ? (
              <div className={styles.dropLabel}>Drop files to upload</div>
            ) : null}

            {isUploading ? (
              <div className={styles.dropLabel}>
                <ProgressBar progress={uploadProgress} />
              </div>
            ) : null}
          </div>
        )}
      </Dropzone>

      {selectedAttachment ? (
        <ReactModal
          isOpen
          appElement={appEl}
          ariaHideApp={(!!appEl)} // Prevent console errors when testing.
          onRequestClose={closeDialog}
          aria={{
            label: selectedAttachment.name,
          }}
        >
          <div className={styles.lightbox} onClick={closeDialog}>
            <div className={styles.lightboxInner}>
              {isImage(selectedAttachment.filetype) ? (
                <img src={selectedAttachment.url} alt={selectedAttachment.name} />
              ) : null}
              {isVideo(selectedAttachment.filetype) ? (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                <video controls preload="metadata" autoPlay>
                  <source src={selectedAttachment.url} type="video/mp4" />
                </video>
              ) : null}
            </div>
          </div>
        </ReactModal>
      ) : null}

      {idPendingDeletion > 0 ? (
        <ConfirmationDialog
          message="Are you sure you want to delete this attachment?"
          closeDialog={() => setIdPendingDeletion(0)}
          confirmAction={handleConfirm}
        />
      ) : null}
    </>
  );
}

export default Attachments;
