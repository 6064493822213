/**
 * Get a list of items that were reordered via ReactSortable. Given a new list
 * and the old list, where each item in the list has an id property, this
 * function returns a new array of {index, id} objects of the items that are in
 * a new position.
 *
 * @param {Object[]} newOrder - List of objects in the new order.
 * @param {Object[]} oldOrder - List of objects in the old order.
 * @returns {Object[]} List of objects that changed position, as {id, index}.
 */
export default (newOrder, oldOrder) => newOrder
  .map(({ id }, index) => ({ index, id }))
  .filter(({ id }, index) => oldOrder[index].id !== id);
