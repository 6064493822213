/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import data from './data';
import pusher from './pusher';
import storyFilters from '../modules/storyFilters';
import whispers from '../modules/whispers';

import {
  INITIALIZE_APP_DATA,
  SET_UI_FLAG,
  UPDATE_CURRENT_TEAM,
} from '../actions/types';

export const defaultState = {
  appMeta: {},
  ui: {
    isInitialized: false,
    isModifyingActiveScope: false,
  },
  // our "database"
  data: {},
};

/* eslint-disable default-param-last */
const ui = (state = defaultState.ui, action) => {
  switch (action.type) {
    case SET_UI_FLAG:
      return { ...state, ...action.flag };
  } // disable default case everywhere
  return state;
};

const appMeta = (state = defaultState.appMeta, action) => {
  switch (action.type) {
    case INITIALIZE_APP_DATA:
      return { ...action.data.result.meta };

    case UPDATE_CURRENT_TEAM:
      return {
        ...state,
        current_team: action.teamId,
      };
  }
  return state;
};
/* eslint-enable default-param-last */

export default (history: any) => combineReducers({
  router: connectRouter(history),
  appMeta,
  ui,
  data,
  storyFilters,
  whispers,
  pusher,
});
