import React from 'react';
import PropTypes from 'prop-types';
import { SOURCE_SHAPE, STORY_SHAPE } from '../../../types/props';
import StoryList from '../../Backlog/StoryList';

function StoryChildren({ stories, parentContext }) {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <h3>Split To...</h3>
        <StoryList stories={stories} context={parentContext} />
      </div>
    </div>
  );
}

StoryChildren.propTypes = {
  parentContext: PropTypes.shape(SOURCE_SHAPE).isRequired,
  stories: PropTypes.arrayOf(PropTypes.shape(STORY_SHAPE)).isRequired,
};

export default StoryChildren;
