import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { STORY_SHAPE } from '../../types/props';
import * as uri from '../../utils/uri';
import buildSourceMeta from '../../utils/buildSourceMeta';
import dateFromYMD from '../../utils/dateFromYMD';
import { hasCurrentUserVotedInGame } from '../../modules/game';
import withInstigator from '../../hocs/withInstigator';

// Components
import Points from '../Points';
import StoryDate from '../StoryDate';
import StoryId from '../StoryId';
import UserList from '../UserList';
import NewFlag from '../NewFlag';

// Styles
import styles from './Card.scss';

const cardPropTypes = {
  /** The story to display. */
  story: PropTypes.shape(STORY_SHAPE).isRequired,
  /** The ID of the team that owns the board. */
  teamId: PropTypes.number.isRequired,
  /** Whether or not the current user has voted. */
  currentUserHasVoted: PropTypes.bool,
  /** Whether or not the story is open for estimation. */
  hasPokerGame: PropTypes.bool,
  /** Any React children. Required to place withInstigator styles. */
  children: PropTypes.node,
  /** Additional classnames to display on root component. Required for withInstigator. */
  className: PropTypes.string,
};

function Card({
  story: {
    contributors_data: contributors,
    created_at: createdAt = '',
    date,
    epic = {},
    filtered,
    id,
    is_epic: isEpic = false,
    points,
    project,
    source_type: sourceType,
    team,
    title,
  },
  children = null,
  className = '',
  teamId,
  currentUserHasVoted = false,
  hasPokerGame = false,
}) {
  const source = React.useMemo(
    () => buildSourceMeta(project, team, sourceType),
    [project, team, sourceType]
  );

  const sourceMatchesTeam = teamId === source.id && sourceType === 'team';

  /**
   * Whether or not the story is split from an epic.
   * @type bool
   */
  const isChild = typeof epic?.id === 'number';

  /**
   * String to identify the className to style the points.
   * @type string
   */
  const pointClass = typeof points === 'number' ? `${points}` : 'unpointed';

  return (
    <li
      className={classnames(
        className,
        styles.card,
        styles[`points-${pointClass}`],
        {
          [styles.filtered]: filtered,
          [styles.epic]: isEpic,
          [styles.child]: isChild,
        }
      )}
      data-story-id={id}
    >
      <div className={styles.body}>
        <div className={styles.labelRow}>
          <StoryId id={id} />

          <NewFlag createdAt={createdAt} />

          {!sourceMatchesTeam ? (
            <>
              <span className="badge badge-secondary source-label">
                {source.label}
              </span>
              {' '}
            </>
          ) : null}

          {isChild ? (
            <span className={styles.epicLabel}>
              {epic.title}
            </span>
          ) : null}
        </div>

        <h3 className={styles.titleHeading}>
          <Link to={uri.story('teams', teamId, id)} className={styles.title}>
            {hasPokerGame && (
            <>
              <span
                className={classnames('badge', {
                  'badge-success': currentUserHasVoted,
                  'badge-danger': !currentUserHasVoted,
                })}
              >
                {currentUserHasVoted ? 'Voted' : 'Voting'}
              </span>
              {' '}
            </>
            )}
            {title}
          </Link>
        </h3>
        <div className={styles.meta}>
          <div className="user-strip">
            <UserList users={contributors} headingLevel={4} label="Contributors" headingClassName="sr-only" />
          </div>
          {!!date && <StoryDate date={dateFromYMD(date)} className="mr-1" />}
          <Points value={points} className={styles.points} />
        </div>
      </div>
      {children}
    </li>
  );
}
Card.propTypes = cardPropTypes;

export default withInstigator(
  connect(
    (
      state,
      {
        story: {
          active_game: gameId,
        } = {},
      }
    ) => {
      const currentUserHasVoted = hasCurrentUserVotedInGame(state, gameId);
      return {
        currentUserHasVoted,
        hasPokerGame: currentUserHasVoted !== null,
      };
    }
  )(Card)
);
