/**
 * Determine if the index of a given `value` in an array matches the given
 * `index`. Note that this does not itself determine if `value` is unique within
 * `self`, but when coupled with Array.prototype.filter, the resulting array
 * will only contain unique values. Also note that this performs a strict and
 * shallow comparison, so {} !== {}.
 *
 * @param {*} value - Value to find within `self`.
 * @param {number} index - Index to compare.
 * @param {array} self - Array to search within.
 * @returns {boolean}
 */
const unique = (value, index, self) => (self.indexOf(value) === index);

/**
 * Remove an entity from one grouped list and add it to another grouped list.
 *
 * This is a fairly niche function, so it's best described in the context for
 * which it was written. When a poker game starts up, it has responses, which
 * get broken down into two lists (groups): voted and waiting. This function
 * moves those votes between state.{entityId}.responses.voted and
 * state.{entityId}.responses.waiting, as applicable. In this case, `entityId`
 * is the ID of the poker game and `targetId` is the ID of the user whose vote
 * is moving. See the `changeVotingStatus()` action creator.
 *
 * @todo Consider moving this to a pokerVote module, it may never be reused.
 *
 * @param {Object} state - Entities state.
 * @param {number|string} from - Group key.
 * @param {number|string} to - Group key.
 * @param {number|string} entityId - ID of entity which owns the group lists.
 * @param {number|string} targetId - ID of item being moved.
 * @param {string} key - Entity property.
 * @returns {Object} New state.
 */
export default (state, from, to, entityId, targetId, key) => ({
  ...state,
  [entityId]: {
    ...state[entityId],
    [key]: {
      [from]: state[entityId][key][from].filter((id) => (id !== targetId)),
      [to]: [...state[entityId][key][to], targetId].filter(unique),
    },
  },
});
