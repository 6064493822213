/**
 * Reorder stories within a backlog.
 *
 * The 'initiator' param may not live long. It's used for pusher and works
 * great, but assumes only singular stories move. That's the case for now, but
 * we will at some point want to shift-select or cmd/ctrl-select multiple
 * stories to move, and the initiator paradigm doesn't support that.
 *
 * @param {Object} payload - Data indicating which stories to move.
 * @param {number[]} payload.stories - Story IDs to move.
 * @param {string} payload.type - Backlog context. One of team, project, or
 *                                sprint.
 * @param {number} payload.id - Backlog ID.
 * @param {Object} payload.initiator - Data about the story initiating the move.
 * @param {number} payload.initiator.story_id - ID of the initating story.
 * @param {number} payload.initiator.old_index - Old index of the initiating
 *                                               story.
 * @param {number} payload.initiator.new_index - New index of the initiating
 *                                               story.
 * @returns {Promise} Promise object representing the API request.
 */
const updateBacklog = (payload) => axios.put('/api/backlog', payload);
export default updateBacklog;
