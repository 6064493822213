import { PUSHER_LEAVE } from '../types';

const leaveChannel = (channel) => (dispatch) => {
  window.Echo.leave(channel);
  dispatch({
    type: PUSHER_LEAVE,
    channel,
  });
};

export const leaveStoryChannel = (storyId) => leaveChannel(`stories.${storyId}`);
export const leaveProjectChannel = (projectId) => leaveChannel(`projects.${projectId}`);
export const leaveBoardChannel = (teamId) => leaveChannel(`board.${teamId}`);
export const leaveTeamChannel = (teamId) => (dispatch, getState) => {
  const { pusher: { myTeams } } = getState();

  // Only leave the channel if it isn't one of "my" teams.
  if (!(myTeams && myTeams.includes(`teams.${teamId}`))) {
    dispatch(leaveChannel(`teams.${teamId}`));
  }
};

export default leaveChannel;
