import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CheckCircleFill } from 'react-bootstrap-icons';
import classnames from 'classnames';

// Internal dependencies.
import { STORY_SHAPE } from '../../types/props';
import * as uri from '../../utils/uri';
import dateFromYMD from '../../utils/dateFromYMD';

// Components.
import StoryDate from '../StoryDate';
import UserList from '../UserList';

// eslint-disable-next-line react/prop-types
function SourceLink({ type, id, linkText }) {
  return (
    <Link
      to={uri.source({ type, id })}
      className="card-source"
    >
      <span className="badge badge-secondary">{linkText}</span>
    </Link>
  );
}

function SearchResult({
  story: {
    points,
    title,
    url,
    contributors_data: contributors = [],
    source_type: sourceType,
    source_id: sourceId,
    date,
    status,
  } = {},
  sourceTitle,
}) {
  return (
    <li className={classnames('card', 'mb-1', { done: status === 'done' })}>
      <div className="card-body">
        <span className="card-points">{points}</span>
        {' '}

        {status === 'done' && (
          <span className="mr-1">
            <CheckCircleFill />
            <span className="sr-only">Done</span>
            {' '}
          </span>
        )}

        <SourceLink type={sourceType} id={sourceId} linkText={sourceTitle} />
        {' '}

        {!!date && <StoryDate date={dateFromYMD(date)} className="mr-1 text-muted font-weight-light" />}
        {' '}

        <Link to={url}>
          {title}
        </Link>
        {' '}

        <div className="user-strip">
          <UserList users={contributors} headingLevel={4} label="Contributors" headingClassName="sr-only" />
        </div>
      </div>
    </li>
  );
}

SearchResult.propTypes = {
  story: PropTypes.shape(STORY_SHAPE).isRequired,
  sourceTitle: PropTypes.string.isRequired,
};

export default connect(
  (state, ownProps) => {
    const { source_type: type, source_id: id } = ownProps.story;
    const sourceTitle = type === 'project'
      ? state.data.entities.projects[id].title
      : state.data.entities.teams[id].name;

    return {
      sourceTitle,
    };
  },
  {}
)(SearchResult);
