/**
 * Filter stories by a feature.
 *
 * @param {number} featureId - The user id of the feature to filter.
 * @param {array} stories - The stories to match against the featureId.
 * return {array} Stories within that feature.
 */
const feature = (featureId, stories) => stories.map(
  (story) => ({
    ...story,
    filtered: featureId === '' ? story.feature?.id !== undefined : story.feature?.id !== featureId,
  })
);
export default feature;
