import * as React from 'react';

type ReturnValue = {
  isPending: boolean;
  call: (callback: () => Promise<any>) => Promise<any>;
};

/**
 * Hook to make an async call with some state noting if the request is pending or not. This is
 * especially useful to disable buttons to prevent double-clicks.
 *
 * Example of usage:
 *
 *   ```
 *   const { isPending, call } = usePendingRequest();
 *   const handler = () => call(() => someAsyncRequest());
 *   return <button type="button" onClick={handler} disabled={isPending}>Action!</button>;
 *   ```
 */
export default function usePendingRequest(): ReturnValue {
  const [isPending, setIsPending] = React.useState(false);
  const call = async (callback: () => Promise<any>) => {
    setIsPending(true);
    const response = await callback();
    setIsPending(false);
    return response;
  };

  return { isPending, call };
}
