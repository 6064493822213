/**
 * This helper takes an array of reducers and creates a new reducer which
 * applies all of them sequentially. This differs from combineReducers in that
 * it ultimately applies multiple reducers to one state key.
 *
 * @param {...function} reducers - Reducers to merge.
 * @returns {function}
 */
const mergeReducers = (...reducers) => (state, action) => (
  reducers.reduce((newState, reducer) => reducer(newState, action), state)
);

export default mergeReducers;
