import { selectHydrated as selectTeam } from '../modules/team';

/**
 * Select the first team_id from the current user's teams.
 *
 * @param state The current state.
 * @returns object|undefined The hydrated team, if found.
 */
export default (state) => {
  const { appMeta: { current_team: currentTeam } } = state;

  // @todo Currently only supports a single team; ability to switch team
  // contexts is an upcoming feature;
  if (typeof currentTeam === 'number') {
    return selectTeam(state, currentTeam);
  }

  return undefined;
};
