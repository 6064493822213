import { Feature } from '../../../types/interfaces/Feature';

/**
 * Create a new team feature.
 */
export default function createTeamFeature(
  teamId: number,
  payload: { title: string, description?: string }
): Promise<Feature> {
  return axios.post(`/api/teams/${teamId}/features`, payload)
    .then(({ data }) => data);
}
