import * as React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { defaultMarkdownSerializer, defaultMarkdownParser, schema } from 'prosemirror-markdown';
import { DOMSerializer } from 'prosemirror-model';
import { TextSelection } from 'prosemirror-state';
import Dropzone, { DropEvent, DropzoneRef, FileRejection } from 'react-dropzone';

// Internal dependencies.
import withInstigator from '../../../hocs/withInstigator';
import { selectWhispers, whisperStoryDoneEditingBody, whisperStoryEditingBody } from '../../../modules/whispers';
import { getCurrentUser } from '../../../selectors';
import { getDragEventCoords } from '../../../utils/updateCaret';
import { insertText } from '../../../utils/textHelpers';
import { generateMarkdownForAttachment, isImage, isVideo } from '../../../utils/fileHelpers';
import useFileUpload from '../../../hooks/useFileUpload';
import useDropHtmlImageAsMarkdown from '../../../hooks/useDropHtmlImageAsMarkdown';
import { Attachment } from '../../../types/interfaces/Attachment';
import { Member as IMemberType } from '../../../types/interfaces/Member';
import { Story as IStoryType } from '../../../types/interfaces/Story';

// Components.
import EditorButtonBar from '../Editor/EditorButtonBar';
import RichEditor, { Ref as RichEditorRef } from '../Editor/ProseMirrorEditor';
import MarkdownEditor, { Ref as MarkdownEditorRef } from '../Editor/MarkdownEditor';
import ProgressBar from '../../ProgressBar/ProgressBar';

// Styles.
import styles from './Body.scss';

interface BodyProps {
  content?: string;
  updateStory: ({ body: string }) => Promise<IStoryType>;
  className?: string;
  children?: React.ReactNode;
  storyId: number;
  currentUser: IMemberType;
  whispers: string[];
}

function Body({
  content = '',
  updateStory,
  className: wrapperClassName,
  children = null,
  storyId,
  currentUser,
  whispers,
}: BodyProps) {
  const [mode, setMode] = React.useState('view');
  const [localContent, setLocalContent] = React.useState(content);
  const [isDirty, setIsDirty] = React.useState(false);
  const [alert, setAlert] = React.useState('');
  const editorRef = React.useRef<RichEditorRef>(null);
  const textareaRef = React.useRef<MarkdownEditorRef>(null);
  const dropzoneRef = React.useRef<DropzoneRef>(null);
  const { handleUpload, uploadProgress, isUploading } = useFileUpload(storyId);

  const setDirty = () => {
    setIsDirty(true);
    whisperStoryEditingBody(storyId, currentUser.id, currentUser.name);
  };

  const setNotDirty = () => {
    setIsDirty(false);
    whisperStoryDoneEditingBody(storyId, currentUser.id);
  };

  /**
   * Update the content state and set it as dirty if the new content is different from the current
   * state. This is a wrapper for the react state to determine if the content has changes or not.
   * That's important to know to alert the user when there are changes but pusher updates the
   * content in the background.
   */
  const maybeUpdateLocalContent = (newContent: string) => {
    if (localContent === newContent) {
      return true;
    }

    setDirty();
    return setLocalContent(newContent);
  };

  useDropHtmlImageAsMarkdown(textareaRef, maybeUpdateLocalContent);

  const enableEditor = (newMode) => {
    whisperStoryEditingBody(storyId, currentUser.id, currentUser.name);
    setMode(newMode);
  };

  React.useEffect(() => {
    if (!isDirty && mode === 'view') {
      setLocalContent(content);
    } else if (content !== localContent) {
      setAlert('Look out! You may have unsaved changes in the story content, but the story content also updated elsewhere.');
    }
  }, [content]);

  /**
   * Insert a newly-uploaded attachment into the markdown editor if it's active.
   */
  const insertAttachmentIntoMarkdownEditor = (attachment: Attachment) => {
    if (textareaRef?.current) {
      const newContent = insertText(
        textareaRef.current,
        generateMarkdownForAttachment(attachment),
        { appendNewline: true }
      );

      maybeUpdateLocalContent(newContent);
    }
  };

  /**
   * Insert a newly-uploaded attachment into the visual editor if it's active.
   */
  const insertAttachmentIntoVisualEditor = (attachment: Attachment, event: DropEvent) => {
    if ((event as React.DragEvent)?.nativeEvent?.type === 'drop' && editorRef?.current) {
      const [left, top] = getDragEventCoords(event as DragEvent);
      const pos = editorRef.current.posAtCoords({ left, top });
      const { tr } = editorRef.current.state;

      tr.setSelection(TextSelection.create(tr.doc, pos.pos));
      let node;
      const marks = [schema.marks.link.create({
        href: isImage(attachment.filetype) || isVideo(attachment.filetype)
          ? `#attachment-${attachment.id}`
          : attachment.url,
      })];

      if (isImage(attachment.filetype)) {
        node = schema.nodes.image.create({
          src: attachment.url,
          alt: attachment.name,
        }, [], marks);
      } else {
        node = schema.text(attachment.name, marks);
      }

      editorRef.current.dispatch(
        tr.replaceSelectionWith(node, false)
      );
    }
  };

  /**
   * Drop handler for React Dropzone. Uploads the files and inserts them into the editor.
   */
  const onFileDrop = async (files: File[], _rejectedFiles: FileRejection[], event: DropEvent) => {
    const attachments = await handleUpload(files);

    if (mode === 'markdown') {
      attachments.forEach(insertAttachmentIntoMarkdownEditor);
    } else if (mode === 'edit') {
      attachments.forEach((a) => insertAttachmentIntoVisualEditor(a, event));
    }
  };

  /**
   * Paste handler for the Markdown editor.
   */
  const pasteHandler = async (e: React.ClipboardEvent) => {
    const { files } = e.clipboardData;
    if (files.length) {
      const attachments = await handleUpload(Array.from(files));
      attachments.forEach(insertAttachmentIntoMarkdownEditor);
    }
  };

  const view = () => {
    if (mode === 'markdown') {
      return (
        <MarkdownEditor
          value={localContent}
          setValue={maybeUpdateLocalContent}
          ref={textareaRef}
          onPaste={pasteHandler}
        />
      );
    }
    const markdownDoc = defaultMarkdownParser.parse(localContent);
    const htmlContent = DOMSerializer
      .fromSchema(schema)
      .serializeFragment(markdownDoc);
    const rootElement = document.createElement('div');
    rootElement.appendChild(htmlContent);
    if (mode === 'edit') {
      return (<RichEditor markdownDoc={markdownDoc} ref={editorRef} />);
    }
    // eslint-disable-next-line react/no-danger
    return (<div dangerouslySetInnerHTML={{ __html: rootElement.innerHTML }} />);
  };

  const cancel = () => {
    setLocalContent(content); // from props again
    setNotDirty();
    setAlert('');
    setMode('view');
  };

  const editorContent = () => defaultMarkdownSerializer.serialize(editorRef.current.state.doc);

  const save = () => {
    const whenDone = () => {
      setAlert('');
      setNotDirty();
    };

    if (mode === 'edit') {
      const newContent = editorContent();
      maybeUpdateLocalContent(newContent);
      updateStory({ body: newContent }).then(whenDone);
    } else if (mode === 'markdown') {
      const newContent = textareaRef?.current?.value;
      if (typeof newContent !== 'undefined') {
        maybeUpdateLocalContent(newContent);
        updateStory({ body: newContent }).then(whenDone);
      }
    }
    setMode('view');
  };

  const toggleEditMode = () => {
    if (mode === 'edit') {
      const newContent = editorContent();
      maybeUpdateLocalContent(newContent);
      setMode('markdown');
    } else {
      const newContent = textareaRef?.current?.value;
      if (typeof newContent !== 'undefined') {
        maybeUpdateLocalContent(newContent);
      }
      setMode('edit');
    }
  };

  const openFileDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  return (
    <div className={`issueBody instigator-wrapper ${wrapperClassName}`}>
      {isUploading ? (
        <div className={styles.progressWrapper}>
          <ProgressBar progress={uploadProgress} />
        </div>
      ) : null}

      {whispers.length > 0 && (
        <p className="bg-warning p-2 border-bottom border-secondary mb-0" role="alert" aria-live="polite" aria-controls="story-view">
          {`${whispers.join(', ')} ${whispers.length > 1 ? 'are' : 'is'} currently editing...`}
        </p>
      )}

      <Dropzone ref={dropzoneRef} onDrop={onFileDrop} noClick noKeyboard>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div {...getRootProps()} role="none">
            <div className={classnames(
              styles.contentWrapper,
              {
                richEditorWrapper: mode === 'edit',
                [styles.dragging]: isDragActive,
              }
            )}
            >
              <div id="story-view" className={classnames('issueView', { 'bg-warning p-2': whispers.length > 0 })}>
                {view()}
              </div>

              {alert ? (
                <div className="alert alert-danger" role="alert" aria-live="polite" aria-controls="story-view">{alert}</div>
              ) : null}

              <EditorButtonBar
                contentLength={content.length}
                mode={mode}
                enableEditor={enableEditor}
                toggleEditMode={toggleEditMode}
                save={save}
                cancel={cancel}
              />
            </div>

            <div
              className={classnames(
                styles.attachmentInstructions,
                { [styles.dragging]: isDragActive }
              )}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop files to upload</p>
              ) : (
                <button
                  className="btn"
                  type="button"
                  onClick={openFileDialog}
                >
                  Drag and drop attachments to upload, or click here to select
                </button>
              )}
            </div>
          </div>
        )}
      </Dropzone>

      {children}
    </div>
  );
}

export default withInstigator(connect(
  (state, { storyId }: { storyId: number | string}) => ({
    whispers: selectWhispers(state, 'storyBody', storyId),
    currentUser: getCurrentUser(state),
  })
)(Body));
