import get from 'lodash/get';

export default (error) => {
  const message = get(error, 'response.data.message', error.toString());
  dispatchAlert(`Error: ${message}`, 'danger');
  // eslint-disable-next-line no-console
  console.error(error);

  // Post the error to the logger.
  axios.post('/api/error-report', {
    message,
    level: 'ERROR',
    context: JSON.stringify(get(error, 'response.data.trace', [])),
    extra: JSON.stringify({
      line: error?.response?.data?.line,
      file: error?.response?.data?.file,
    }),
  });

  return error;
};
