/**
 * Given a list of users, this returns an object with those users grouped into
 * online and offline buckets.
 *
 * @param {Object[]} users - List of users.
 * @param {boolean} users.online - Online indicator for the user.
 * @returns {Object} groupedUsers - Buckets of users.
 * @returns {array} groupedUsers.online - List of users who are online.
 * @returns {array} groupedUsers.offline - List of users who are offline.
 */
export default (users) => users.reduce((acc, user) => {
  const group = user.online ? 'online' : 'offline';
  return { ...acc, [group]: [...acc[group], user] };
}, { online: [], offline: [] });
