import * as React from 'react';
import ReactModal from 'react-modal';

interface ConfirmationDialogProps {
  message: string;
  additionalInfo?: string | null;
  confirmAction: () => void;
  closeDialog: () => void;
}

function ConfirmationDialog({
  message,
  additionalInfo = '',
  confirmAction,
  closeDialog,
}: ConfirmationDialogProps) {
  const appEl = document.getElementById('appBinding');
  return (
    <ReactModal
      isOpen
      onRequestClose={closeDialog}
      contentLabel="Confirm action"
      appElement={appEl}
      ariaHideApp={(!!appEl)} // Prevent console errors when testing and no app element available.
      className="confirmation-dialog"
    >
      <div className="container d-flex align-items-center" style={{ height: '100%' }}>
        <div>
          <p className="h2 text-muted mb-3">
            {message}
          </p>
          {additionalInfo ? (
            <p>{additionalInfo}</p>
          ) : null}
          <div>
            <button type="button" onClick={confirmAction} className="btn btn-lg btn-outline-danger">
              Confirm
            </button>
            {' '}
            <button type="button" onClick={closeDialog} className="btn btn-lg btn-secondary">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default ConfirmationDialog;
