import { MOVE_STORY } from '../actions/types';
import updateBacklogTarget from './updateBacklogTarget';

/**
 * Create an entity reducer for moving stories across and within backlogs.
 *
 * @param {string} entityPlural - The plural form of the entities, e.g. "teams".
 * @return {function} Reducer.
 */
export default (entityPlural) => (state = {}, action = {}) => {
  if (action.type === MOVE_STORY) {
    let newState = state;

    if (entityPlural === action.to.type) {
      newState = updateBacklogTarget(
        newState,
        action.story.id,
        action.storyPoints,
        action.to,
        'to'
      );
    }

    if (entityPlural === action.from.type) {
      newState = updateBacklogTarget(
        newState,
        action.story.id,
        action.storyPoints,
        action.from,
        'from'
      );
    }

    return newState;
  }

  return state;
};
