import { denormalize } from 'normalizr';
import { batch } from 'react-redux';
import { ADD_ENTITY, DELETE_ENTITY } from '../actions/types';
import { AttachmentArgs } from '../services/api/attachments/types';
import { entitySchema } from '../types';
import { Attachment } from '../types/interfaces/Attachment';
import createEntityReducer from '../utils/createEntityReducer';

export const STATE_KEY = 'attachments';

type BulkCreateAttachmentResponse = {
  attachments: Attachment[];
  errors?: {
    message: string;
    status: number;
  }[];
};

/**
 * Attachment reducer.
 */
export default createEntityReducer(STATE_KEY);

/**
 * Action creator to indicate an attachment is incoming.
 */
export const addAttachmentAction = (storyId: number, data: Attachment) => ({
  type: ADD_ENTITY,
  to: { type: 'stories', id: storyId },
  entity: { type: STATE_KEY, data },
});

/**
 * Action creator to indicate an attachment is being deleted.
 */
export const deleteAttachmentAction = (storyId: number, attachmentId: number) => ({
  type: DELETE_ENTITY,
  entity: { type: STATE_KEY, id: attachmentId },
  from: { type: 'stories', id: storyId },
});

/**
 * Thunk to add an attachment.
 */
export const addAttachment = (storyId: number, attachment: AttachmentArgs) => (
  (dispatch, _getState, { api }) => (
    api.createAttachment(storyId, attachment)
      .then(({ data }: { data: Attachment }) => {
        dispatch(addAttachmentAction(storyId, data));
        return data;
      })
      .catch(() => dispatchAlert('Error adding attachment', 'danger'))
  )
);

/**
 * Thunk to bulk add attachments.
 */
export const bulkAddAttachments = (storyId: number, attachments: AttachmentArgs[]) => (
  (dispatch, _getState, { api }) => (
    api.bulkCreateAttachments(storyId, attachments)
      .then(({ data }: { data: BulkCreateAttachmentResponse }) => {
        batch(() => {
          data.attachments.map((d) => dispatch(addAttachmentAction(storyId, d)));
        });
        if (data.errors) {
          data.errors.map((error) => dispatchAlert(error.message, 'danger'));
        }
        return data.attachments;
      })
      .catch(() => dispatchAlert('Error adding attachment', 'danger'))
  )
);

/**
 * Thunk to delete an attachment.
 */
export const deleteAttachment = (storyId: number, attachmentId: number) => (
  (dispatch, _getState, { api }) => (
    api.deleteAttachment(storyId, attachmentId)
      .then(() => dispatch(deleteAttachmentAction(storyId, attachmentId)))
      .catch(api.errorHandler)
  )
);

/**
 * Select the hydrated attachment from state.
 *
 * @param {Object} state - Full redux state.
 * @param {string} id - Attachment ID.
 * @returns {Object}
 */
export const selectHydrated = (state: any, id: number) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)
);
