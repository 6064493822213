import * as React from 'react';
import { insertText } from '../utils/textHelpers';
import updateCaret from '../utils/updateCaret';

export default function useDropHtmlImageAsMarkdown(
  textareaRef: React.RefObject<HTMLTextAreaElement>,
  setContent: (content: string) => void
) {
  React.useEffect(() => {
    if (textareaRef?.current) {
      let dragged: HTMLImageElement | null;
      const { current } = textareaRef;

      // If the user starts dragging an HTML image on the page, make note of it.
      const maybeRespondToDrag = (e: DragEvent) => {
        const target = e.target as Element;
        if (target.nodeName === 'IMG') {
          dragged = target as HTMLImageElement;
        } else {
          dragged = null;
        }
      };

      // Update the textarea cursor to reflect where the mouse is.
      const updateEditorCursor = (e) => {
        if (e.type === 'dragover') {
          e.stopPropagation();
        }
        updateCaret(e.target, e);
      };

      // If the user was dragging an image and dropped into the textarea, insert it as markdown.
      const maybeRespondToDrop = () => {
        if (dragged?.src && dragged?.alt && dragged?.dataset?.id) {
          const newContent = insertText(
            current,
            `[![${dragged.alt}](${dragged.src})](#attachment-${dragged.dataset.id})`,
            { appendNewline: true }
          );

          setContent(newContent);
          dragged = null;
        }
      };

      current.addEventListener('dragenter', updateEditorCursor);
      current.addEventListener('dragover', updateEditorCursor);
      current.addEventListener('drop', maybeRespondToDrop);
      document.addEventListener('dragstart', maybeRespondToDrag);
      return () => {
        current.removeEventListener('dragenter', updateEditorCursor);
        current.removeEventListener('dragover', updateEditorCursor);
        current.removeEventListener('drop', maybeRespondToDrop);
        document.removeEventListener('dragstart', maybeRespondToDrag);
      };
    }

    return () => {};
  }, [textareaRef, setContent]);
}
