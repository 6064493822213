import React, { useState } from 'react';
import ConfirmationDialog from '../ConfirmationDialog';

export type Props = {
  title: string;
  id: number|string;
  deleteColumn(): void;
  updateColumn(arg: { title: string; id: number|string; }): void,
};

function Column({
  deleteColumn,
  id,
  title: initialTitle = '',
  updateColumn,
}: Props) {
  const [title, setTitle] = useState(initialTitle);
  const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);

  const handleConfirm = () => {
    deleteColumn();
    setIsConfirmingDeletion(false);
  };

  return (
    <div className="form-check form-check-inline col-12">
      <label className="sr-only" htmlFor={`column-${id}-body`}>
        Column name
      </label>
      <input
        name="body"
        id={`column-${id}-body`}
        value={title}
        placeholder="Column Title"
        className="form-control form-control-lg"
        onKeyDown={(e) => (e.key === 'enter') && (e.target as HTMLElement).blur()}
        onChange={({ target: { value } }) => setTitle(value)}
        onBlur={() => updateColumn({ id, title })}
        autoComplete="off"
        required
      />
      <button
        type="button"
        className="delete-task"
        onClick={() => {
          if (initialTitle !== '') {
            setIsConfirmingDeletion(true);
          } else {
            deleteColumn();
          }
        }}
      >
        <span className="sr-only">Delete column</span>
        &times;
      </button>
      {isConfirmingDeletion && (
        <ConfirmationDialog
          message="When you delete this board column, all stories in your team's active sprint with this status will move to the first column."
          closeDialog={() => setIsConfirmingDeletion(false)}
          confirmAction={handleConfirm}
        />
      )}
    </div>
  );
}

export default Column;
