import React, { useState } from 'react';
import ConfirmationDialog from '../ConfirmationDialog';

export type Props = {
  webhook: string;
  id: number|string;
  deleteHandler(): void;
  updateHandler(arg: { id: number|string, url: string }): void,
};

function Webhook({
  deleteHandler,
  id,
  webhook: initialWebhook = '',
  updateHandler,
}: Props) {
  const [webhook, setWebhook] = useState(initialWebhook);
  const [isConfirmingDeletion, setIsConfirmingDeletion] = useState(false);

  const handleConfirm = () => {
    deleteHandler();
    setIsConfirmingDeletion(false);
  };

  return (
    <div className="form-check form-check-inline col-12">
      <label className="sr-only" htmlFor={`webhook-${id}-url`}>
        Webhook URL
      </label>
      <input
        name={`webhook-${id}-url`}
        id={`webhook-${id}-url`}
        value={webhook}
        placeholder="Webhook URL"
        className="form-control form-control-lg"
        onKeyDown={(e) => (e.key === 'enter') && (e.target as HTMLElement).blur()}
        onChange={({ target: { value } }) => setWebhook(value)}
        onBlur={() => updateHandler({ id, url: webhook })}
        autoComplete="off"
        type="url"
        required
      />
      <button
        type="button"
        className="delete-task"
        onClick={() => {
          if (initialWebhook !== '') {
            setIsConfirmingDeletion(true);
          } else {
            deleteHandler();
          }
        }}
      >
        <span className="sr-only">Delete webhook</span>
        &times;
      </button>
      {isConfirmingDeletion && (
        <ConfirmationDialog
          message={`Are you sure you want to delete the webhook to ${initialWebhook}?`}
          closeDialog={() => setIsConfirmingDeletion(false)}
          confirmAction={handleConfirm}
        />
      )}
    </div>
  );
}

export default Webhook;
