import { combineReducers } from 'redux';

import attachments, { STATE_KEY as ATTACHMENTS_STATE_KEY } from '../modules/attachment';
import boards, { STATE_KEY as BOARDS_STATE_KEY } from '../modules/board';
import columns, { STATE_KEY as COLUMNS_STATE_KEY } from '../modules/columns';
import buckets, { STATE_KEY as BUCKETS_STATE_KEY } from '../modules/bucket';
import comments, { STATE_KEY as COMMENTS_STATE_KEY } from '../modules/comment';
import features, { STATE_KEY as FEATURES_STATE_KEY } from '../modules/feature';
import games, { STATE_KEY as GAMES_STATE_KEY } from '../modules/game';
import members, { STATE_KEY as MEMBERS_STATE_KEY } from '../modules/member';
import projects, { STATE_KEY as PROJECTS_STATE_KEY } from '../modules/project';
import searches, { STATE_KEY as SEARCHES_STATE_KEY } from '../modules/search';
import sprints, { STATE_KEY as SPRINTS_STATE_KEY } from '../modules/sprint';
import sprintSummaries, { STATE_KEY as SPRINT_SUMMARIES_STATE_KEY } from '../modules/sprintSummary';
import stories, { STATE_KEY as STORIES_STATE_KEY } from '../modules/story';
import tasks, { STATE_KEY as TASKS_STATE_KEY } from '../modules/task';
import teams, { STATE_KEY as TEAMS_STATE_KEY } from '../modules/team';
import createOrderReducer from '../utils/createOrderReducer';

export default combineReducers({
  entities: combineReducers({
    [ATTACHMENTS_STATE_KEY]: attachments,
    [BOARDS_STATE_KEY]: boards,
    [BUCKETS_STATE_KEY]: buckets,
    [COMMENTS_STATE_KEY]: comments,
    [FEATURES_STATE_KEY]: features,
    [GAMES_STATE_KEY]: games,
    [MEMBERS_STATE_KEY]: members,
    [SEARCHES_STATE_KEY]: searches,
    [SPRINT_SUMMARIES_STATE_KEY]: sprintSummaries,
    [SPRINTS_STATE_KEY]: sprints,
    [STORIES_STATE_KEY]: stories,
    [PROJECTS_STATE_KEY]: projects,
    [TASKS_STATE_KEY]: tasks,
    [TEAMS_STATE_KEY]: teams,
    [COLUMNS_STATE_KEY]: columns,
  }),
  order: combineReducers({
    projects: createOrderReducer('projects'),
    teams: createOrderReducer('teams'),
  }),
});
