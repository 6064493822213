/**
 * Insert an item into an array at a certain position.
 *
 * @param list {array} - Original array.
 * @param item {*} - Element to insert.
 * @param index {number} - Position in the array at which to insert item.
 * @returns {array}
 */
export default (list, item, index) => [
  ...list.slice(0, index),
  item,
  ...list.slice(index),
];
