import React from 'react';
import PropTypes from 'prop-types';

import usersByStatus from '../../utils/usersByStatus';
import UserCard from '../UserCard';

function TeamStatus({ teamMembers, pusherState }) {
  if (teamMembers.length === 0) {
    return '';
  }
  if (pusherState === 'unavailable' || pusherState === 'disconnected') {
    return (
      <div className="mb-1 user-strip">
        <div className="alert alert-warning" role="alert">
          No connection available
        </div>
      </div>
    );
  }
  if (pusherState === 'connecting') {
    return (
      <div className="mb-1 user-strip">
        <div className="alert alert-info" role="alert">
          Connecting...
        </div>
      </div>
    );
  }
  const { online, offline } = usersByStatus(teamMembers);
  return (
    <div className="mb-1 user-strip">
      {online.map((player) => <UserCard key={player.id} user={player} status="online" />)}
      {offline.map((player) => <UserCard key={player.id} user={player} status="offline" />)}
    </div>
  );
}

TeamStatus.propTypes = {
  teamMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  pusherState: PropTypes.string.isRequired,
};

export default TeamStatus;
