/**
 * Merge numerous entity changes into state. Like mergeEntityChanges, this will
 * only update properties explicitly present in the entity changes and it will
 * leave other properties in the entity's state untouched.
 *
 * @param {Object} state - Entities state.
 * @param {Object} entityChanges - Changes to make to entities' state, indexed
 *                                 by ID. e.g. {1: {title: 'Updated Title'}}.
 * @returns {Object} New state.
 */
export default (state, entityChanges) => {
  const changes = Object.keys(entityChanges).reduce(
    (acc, id) => {
      // Be sure not to override if partial = false.
      const partial = {};
      if (entityChanges[id]?.partial === true && state?.[id]?.partial === false) {
        partial.partial = false;
      }

      return {
        ...acc,
        [id]: {
          ...state[id],
          ...entityChanges[id],
          ...partial,
        },
      };
    },
    {}
  );
  return { ...state, ...changes };
};
