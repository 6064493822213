/**
 * Given someone's name, get their initials.
 *
 * @param {string} name - A user's full name.
 * @returns {string}
 */
export default (name) => name
  .split(' ')
  .map((part) => part.slice(0, 1))
  .join('')
  .toUpperCase();
