import React, { useState } from 'react';
import { Story } from '../../types/interfaces/Story';
import { BacklogTarget } from '../../types/interfaces/BacklogTarget';
import Capacity from './Capacity';
import ResolutionPaths from './ResolutionPaths';
import Swap from './Swap';
import TakeBuffer from './TakeBuffer';

export type Props = {
  /** Function to cancel dialog */
  closeDialog: () => void;
  /** Initial action that started process. */
  confirmAction: () => void;
  /** Items to move to new target. */
  items: Story[];
  /** Target backlog. */
  target: BacklogTarget;
}

export type ModifyScopeOptions = 'buffer' | 'capacity' | 'swap';

export default function HasPoints({
  closeDialog,
  confirmAction,
  items,
  target,
}: Props) {
  const [howToModifyScope, setHowToModifyScope] = useState<ModifyScopeOptions | false>(false);
  const changeMethod = () => setHowToModifyScope(false);

  return (
    <div className="col-md-10 m-auto">
      <div className="mb-3">
        {(() => {
          switch (howToModifyScope) {
            case 'capacity':
              return (
                <Capacity
                  target={target}
                  confirmAction={confirmAction}
                  items={items}
                  changeMethod={changeMethod}
                />
              );
            case 'swap':
              return (
                <Swap
                  target={target}
                  items={items}
                  changeMethod={changeMethod}
                  confirmAction={confirmAction}
                />
              );
            case 'buffer':
              return (
                <TakeBuffer
                  items={items}
                  target={target}
                  changeMethod={changeMethod}
                  confirmAction={confirmAction}
                />
              );
            default:
              return (
                <ResolutionPaths
                  items={items}
                  setHowToModifyScope={setHowToModifyScope}
                />
              );
          }
        })()}
        <div className="d-flex flex-row-reverse">
          <button type="button" onClick={closeDialog} className="btn btn-sm btn-secondary">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
