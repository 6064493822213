import { isAfter, parseISO, subDays } from 'date-fns';

/**
 * Given a date d and number of days n, determine if d is more recent than n days ago.
 *
 * @param {string} date - An ISO-8601 date string. Defaults to 7.
 * @returns bool
 */
export default function isDateRecent(date, daysAgo = 7) {
  return isAfter(parseISO(date), subDays(new Date(), daysAgo));
}
