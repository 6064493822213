import { denormalize } from 'normalizr';
import { entitySchema } from '../types';
import createEntityReducer from '../utils/createEntityReducer';

export const STATE_KEY = 'tasks';

/**
 * Task reducer.
 */
export default createEntityReducer(STATE_KEY);

/**
 * Select the hydrated task from state.
 *
 * @param {Object} state - Full redux state.
 * @param {string} id - Task ID.
 * @returns {Object}
 */
export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)
);
