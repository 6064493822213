/**
 * Remove an entity from its entities state.
 *
 * @param {Object} state - Entites state.
 * @param {number|string} id - Entity ID.
 * @returns {Object} New state.
 */
export default (state, id) => {
  const { [id]: deleted, ...newState } = state;
  return newState;
};
