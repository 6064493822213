import { createSelector } from 'reselect';
import get from 'lodash/get';
import getEntities from './getEntities';
import { getCurrentUserActiveTeamId } from '../modules/team';

/**
 * Provides an array of team members based on the user's active team.
 */
export default createSelector(
  [getEntities, getCurrentUserActiveTeamId],
  (entities, teamId) => get(
    entities,
    `teams.${teamId}.members`,
    []
  )
    .map((memberId) => get(
      entities,
      `members.${memberId}`,
      false
    ))
    .filter((member) => member)
);
