/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Create a webhook.
 */
export default (
  teamId: number,
  url: string
): Promise<any> => axios.post(`/api/teams/${teamId}/webhooks`, {
  url,
});
