import { Attachment } from '../types/interfaces/Attachment';

export function isImage(filetype: string): boolean {
  return ['image/gif', 'image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'].indexOf(filetype) > -1;
}

export function isVideo(filetype: string): boolean {
  return ['video/mp4', 'video/quicktime'].indexOf(filetype) > -1;
}

export function generateMarkdownForAttachment(attachment: Attachment) {
  const {
    id,
    name,
    url,
    filetype,
  } = attachment;

  if (isImage(filetype)) {
    return `[![${name}](${url})](#attachment-${id})`;
  }
  if (isVideo(filetype)) {
    return `[${name}](#attachment-${id})`;
  }
  return `[${name}](${url})`;
}
