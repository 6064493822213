import * as React from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';
import Select from 'react-dropdown-select';
import Loading from '../Loading';

const Props = {
  addTeam: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  availableTeams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })),
  removeTeam: PropTypes.func.isRequired,
};

function ProjectTeams({
  addTeam,
  removeTeam,
  teams,
  availableTeams,
}) {
  const [selection, setSelection] = React.useState({
    label: '',
    value: false,
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const id = uuid();

  React.useEffect(() => {
    setSelection({
      label: '',
      value: false,
    });
    setIsLoading(false);
  }, [teams, availableTeams]);

  /**
 * Save user input to local state.
 *
 * @param {string} newValue - User input.
 */
  const handleChange = (newValue) => {
    const [{ label = '', value = false } = {}] = newValue || [{}];
    setSelection({ label, value });

    if (typeof value === 'number') {
      setIsLoading(true);
      addTeam(value)
        .then(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="card mt-3">
      <h2 className="card-header" id={`member-list-${id}`}>
        Contributing teams
      </h2>
      <div className="card-body">
        <ul className="team-members-list" aria-labelledby={`member-list-${id}`}>
          {teams.map((team) => (
            <li key={team.id}>
              <span className="team-members-list-name">{team.name}</span>
              <button
                onClick={() => removeTeam(team.id)}
                className="btn btn-outline btn-outline-danger"
                type="button"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>

        {isLoading ? <Loading /> : null}

        {availableTeams.length > 0 && !isLoading ? (
          <>
            <label htmlFor="add-team-dropdown" className="form-label">
              Add team
            </label>
            <Select
              options={availableTeams.map((team) => ({
                label: team.name,
                value: team.id,
              }))}
              values={[selection]}
              className="mb-2"
              closeOnSelect
              onChange={handleChange}
              searchable
              placeholder="Add team"
              additionalProps={{ id: 'add-team-dropdown' }}
            />
          </>
        ) : ''}
      </div>
    </div>
  );
}

ProjectTeams.propTypes = Props;

export default ProjectTeams;
