/* eslint-disable babel/camelcase */
type Payload = {
  start_date?: string,
  end_date?: string,
  points_committed?: number,
}

/**
 * Update a Sprint.
 *
 * @param {number} sprintId - Sprint ID.
 * @param {Object} payload - Request payload.
 * @param {string} payload.start_date - Start date, in the format yyyy-mm-dd.
 * @param {string} payload.end_date - End date, in the format yyyy-mm-dd.
 * @param {string} payload.points_committed - Points committed.
 * @returns {Promise} Promise object representing the API request.
 */
const updateSprint = (sprintId: number, payload: Payload) => (
  axios.put(`/api/sprints/${sprintId}`, payload)
);
export default updateSprint;
