/**
 * Returns a formatted source meta object for a story based on the source type.
 *
 * @param {Object|null} project - The project, if set.
 * @param {Object|null} team - The team, if set.
 * @param {string} sourceType - The source type.
 * @returns {Object} source The source meta object.
 * @returns {number} source.id The source ID.
 * @returns {string} source.type The source type.
 * @returns {string} source.label The name or title of the source.
 */
const buildSourceMeta = (project, team, sourceType) => {
  if (typeof project?.id === 'number' && sourceType === 'project') {
    return {
      id: project.id,
      type: 'project',
      label: project.title,
    };
  }

  if (typeof team?.id === 'number' && sourceType === 'team') {
    return {
      id: team.id,
      type: 'team',
      label: team.name,
    };
  }

  return {};
};

export default buildSourceMeta;
