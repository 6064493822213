import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';
import { BOARD_SHAPE } from '../../types/props';
import Column from './Column';

function ColumnsEdit({
  board: initialBoard,
  updateTeamConfig,
}) {
  const [board, setBoard] = useState({
    backlog: initialBoard.backlog,
    columns: initialBoard.columns.map(({ id, title }) => ({
      id,
      title,
    })),
    done: initialBoard.done,
  });
  const [tempColumnKeyCount, setTempColumnKeyCount] = useState(0);
  const formEl = useRef(null);

  const {
    backlog,
    columns,
    done,
  } = board;

  /**
   * Update the title of the column on the board. If no ID to update given,
   * will append the new column to the end of the column list.
   *
   * @param {number} param.index The id of the custom column to update.
   * @param {string}      param.title The new title.
   */
  const updateColumn = ({ id, title = '' }) => {
    const indexToUpdate = columns.findIndex((column) => column.id === id);
    columns[indexToUpdate].title = title;

    setBoard({
      ...board,
      columns,
    });
  };

  /**
   * Add a new column to the column list.
   */
  const addColumn = () => {
    // Add column to board.
    setBoard({
      ...board,
      columns: [...columns, { title: '', id: `temp-${tempColumnKeyCount}` }],
    });

    // Increment the column key counter.
    setTempColumnKeyCount(tempColumnKeyCount + 1);
  };

  /**
   * Removes a column from the columns array on the board.
   *
   * @param {number} param.id The ID of the column to delete.
   */
  const deleteColumn = (index) => {
    setBoard({
      ...board,
      columns: [
        ...columns.slice(0, index),
        ...columns.slice(index + 1),
      ],
    });
  };

  /**
   * Save the board.
   */
  const saveBoard = (e) => {
    e.preventDefault();
    if (!formEl.current.checkValidity()) {
      formEl.current.reportValidity();
    } else {
      updateTeamConfig({
        board: {
          ...board,
          columns: board.columns.map(
            (col) => (Number.isInteger(col.id) ? col : { title: col.title })
          ),
        },
      });
    }
  };

  return (
    <div className="card mt-3" id="edit-board-columns">
      <h2 className="card-header">Board Columns</h2>
      <form className="card-body" ref={formEl}>
        <div className="input-group">
          <div className="input-group-prepend">
            <label className="input-group-text mb-1 mt-3" htmlFor="sprint-backlog">
              Stories yet to be worked on
            </label>
          </div>
          <input
            type="text"
            name="backlog"
            className="form-control form-control-lg mb-1 mt-3"
            id="sprint-backlog"
            placeholder="Sprint Backlog"
            value={backlog.title || ''}
            onChange={({ target: { value } }) => setBoard({ ...board, backlog: { title: value } })}
            autoComplete="off"
          />
        </div>
        <div className="card mb-1 mt-3">
          <h3 className="card-header">
            Custom Board Columns
          </h3>
          <ReactSortable
            tag="ol"
            list={columns}
            setList={(newColumns) => setBoard({ ...board, columns: newColumns })}
            animation={300}
            className="list-group list-group-flush mb-1"
          >
            {columns.map(({ title, id }, index) => (
              <li key={id} className="list-group-item">
                <Column
                  id={id}
                  title={title}
                  updateColumn={updateColumn}
                  deleteColumn={() => deleteColumn(index)}
                />
              </li>
            ))}
          </ReactSortable>
          <div className="ml-4 mb-3 mt-2">
            <button className="btn btn-secondary" onClick={addColumn} type="button">
              Add Column
            </button>
          </div>
        </div>
        <div className="input-group">
          <div className="input-group-prepend">
            <label className="input-group-text mb-1 mt-3" htmlFor="done">
              Completed stories
            </label>
          </div>
          <input
            type="text"
            name="backlog"
            className="form-control form-control-lg mb-1 mt-3"
            id="done"
            placeholder="Done"
            value={done.title || ''}
            onChange={({ target: { value } }) => setBoard({ ...board, done: { title: value } })}
            autoComplete="off"
          />
        </div>
        <button
          className="btn btn-primary mb-1 mt-3"
          onClick={saveBoard}
          type="submit"
          id="save-button"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
}

ColumnsEdit.propTypes = {
  board: PropTypes.shape(BOARD_SHAPE).isRequired,
  updateTeamConfig: PropTypes.func.isRequired,
};

export default ColumnsEdit;
