import { createSelector } from 'reselect';
import get from 'lodash/get';

// Module-based selectors.
import { selectHydrated as selectStory } from '../modules/story';
import { selectHydrated as selectFeature, getFeatures } from '../modules/feature';
import { selectHydrated as selectGame } from '../modules/game';
import { selectHydrated as selectSprint, selectDehydratedSprint, getOpenSprintsByTeam } from '../modules/sprint';
import { getBacklogTargets, filterCurrentBacklogFromTargets, flattenBacklogTargets } from '../modules/backlogTarget';
import { getCurrentUserActiveTeamId } from '../modules/team';
import { selectDehydratedProject } from '../modules/project';
import { makeGetBacklog } from '../modules/backlogEntity';

// Selectors
import getActiveSprintId from './getActiveSprintId';
import getBreadcrumbs from './getBreadcrumbs';
import getContext from './getContext';
import getCurrentUser from './getCurrentUser';
import getCurrentUserActiveTeam from './getCurrentUserActiveTeam';
import getEntities from './getEntities';
import getOrder from './getOrder';
import getParams from './getParams';
import getTeamId from './getTeamId';
import getTeamMembers from './getTeamMembers';
import getTeamMembersForCurrentTeam from './getTeamMembersForCurrentTeam';
import getUserById from './getUserById';

// Composed selectors
import makeGetSource from './makeGetSource';

export {
  filterCurrentBacklogFromTargets,
  flattenBacklogTargets,
  getActiveSprintId,
  getBacklogTargets,
  getBreadcrumbs,
  getContext,
  getCurrentUser,
  getCurrentUserActiveTeam,
  getCurrentUserActiveTeamId,
  getEntities,
  getFeatures,
  getOpenSprintsByTeam,
  getOrder,
  getParams,
  getTeamId,
  getTeamMembers,
  getTeamMembersForCurrentTeam,
  getUserById,
  makeGetBacklog,
  makeGetSource,
  selectFeature,
  selectSprint,
  selectDehydratedSprint,
  selectStory,

  // Projects.
  selectDehydratedProject,
};

// @todo The selectors below may need to be refactored to use reselect
// and also possibly moved into their own files.

export const getTeam = createSelector(
  [getEntities, getTeamId],
  (entities, teamId) => get(
    entities,
    `teams.${teamId}`,
    {}
  )
);

// Hydrate game.
export const getGame = (state, gameId) => selectGame(state, gameId);
