/**
 * Immutably move an item in an array from one index to another, shifting other
 * items as necessary.
 *
 * @param {array} list - Starting array.
 * @param {number} oldIndex - Index from which to move the item.
 * @param {number} newIndex - Index to which to move the item.
 * @returns {array} New array.
 */
export default (list, oldIndex, newIndex) => {
  const newList = [...list];
  newList.splice(newIndex, 0, newList.splice(oldIndex, 1)[0]);
  return newList;
};
