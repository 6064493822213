import React from 'react';
import classnames from 'classnames';

interface PointButtonProps {
  disabled?: boolean;
  fib: number | string;
  isActive: boolean;
  onClick: () => void;
}

function PointButton({
  fib,
  isActive,
  onClick,
  disabled = false,
}: PointButtonProps) {
  return (
    <button
      type="button"
      className={classnames('poker-card', 'story-points', `points-${fib === '?' ? 'unsure' : fib}`, { 'user-vote': isActive })}
      onClick={onClick}
      key={fib}
      disabled={disabled}
    >
      {fib}
    </button>
  );
}

export default PointButton;
