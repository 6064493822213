/**
 * Switch the current user's current (active) team.
 *
 * @param {number} teamId - Team ID.
 * @returns {Promise} Promise object representing the API request.
 */
const switchCurrentTeam = (teamId) => axios.put(`/api/teams/${teamId}/current`, {}, {
  headers: {
    // Ignore the header to avoid updating the team twice.
    'X-Active-Team': 'ignore',
  },
});
export default switchCurrentTeam;
