import React from 'react';
import { Route, Switch } from 'react-router-dom';
import uuid from 'react-uuid';

import routes from '../../routes';

function TeamBarWrapper() {
  return (
    <div className="team-bar-wrapper">
      <Switch>
        {routes.map((route) => (
          <Route path={route.path} component={route.teambar} exact={route.exact} key={uuid()} />
        ))}
      </Switch>
    </div>
  );
}

export default TeamBarWrapper;
