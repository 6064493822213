import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function Filter({
  addFilter,
  removeFilter,
  filterData,
  isActive,
  className,
  children,
}) {
  const clickHandler = () => {
    if (isActive) {
      removeFilter(filterData);
    } else {
      addFilter(filterData);
    }
  };

  const makeQueryString = (queryString) => {
    const params = new URLSearchParams(queryString);
    params.set('filter', isActive ? '' : `${filterData.type}:${filterData.id}`);
    return params.toString();
  };

  return (
    <Link
      to={(location) => ({ ...location, search: makeQueryString(location.search) })}
      className={className}
      onClick={clickHandler}
      aria-current={isActive}
    >
      {children}
    </Link>
  );
}

Filter.propTypes = {
  addFilter: PropTypes.func.isRequired,
  removeFilter: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  filterData: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Filter.defaultProps = {
  className: '',
};

export default Filter;
