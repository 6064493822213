// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MoveMenu__container__3vv5r{align-items:center;display:inline-flex;justify-content:center;text-align:center}.MoveMenu__button__ro5p3{font-size:.8rem;padding:.125rem .5rem}.MoveMenu__button__ro5p3::after{margin-left:.5rem}", "",{"version":3,"sources":["webpack://./resources/js/components/MoveMenu/MoveMenu.scss"],"names":[],"mappings":"AAAA,4BACE,kBAAA,CACA,mBAAA,CACA,sBAAA,CACA,iBAAA,CAGF,yBACE,eAAA,CACA,qBAAA,CAEA,gCACE,iBAAA","sourcesContent":[".container {\n  align-items: center;\n  display: inline-flex;\n  justify-content: center;\n  text-align: center;\n}\n\n.button {\n  font-size: 0.8rem;\n  padding: 0.125rem 0.5rem;\n\n  &::after {\n    margin-left: 0.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MoveMenu__container__3vv5r",
	"button": "MoveMenu__button__ro5p3"
};
export default ___CSS_LOADER_EXPORT___;
