/**
 * Move a story from one backlog to another.
 *
 * @param {Object} payload - Data indicating the story to move, from, and where.
 * @param {number} payload.story - Story ID.
 * @param {string} payload.from_type - Backlog context from which the story is
 *                                     moving. One of team, project, or sprint.
 * @param {number} payload.from_id - Backlog ID from which the story is moving.
 * @param {string} payload.to_type - Backlog context to which to move the story.
 *                                   One of team, project, or sprint.
 * @param {number} payload.to_id - Backlog ID to which the story is moving.
 * @returns {Promise} Promise object representing the API request.
 */
const moveStoryBacklog = (payload) => axios.post('/api/backlog/move', payload);
export default moveStoryBacklog;
