import { batch } from 'react-redux';

import { loadStoryComment, receiveStoryComment } from '../../modules/comment';
import { DELETE_ENTITY, MERGE_ENTITY_CHANGES } from '../types';
import { setInstigator } from './member';

const STATE_KEY = 'comments';

/**
 * Dispatch an action to add a comment to state.
 *
 * @param {object} data - Payload from API response.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const commentAdded = (data, subject, instigatorId) => (dispatch) => {
  batch(async () => {
    dispatch(receiveStoryComment(subject.id, data));
    // If the body was too large for pusher, fetch it.
    if (data.partial === true) {
      await dispatch(loadStoryComment(data.id));
    }
    dispatch(setInstigator({ type: STATE_KEY, id: data.id }, instigatorId));
  });
};

/**
 * Dispatches an action to update the comment in state.
 *
 * @param {object} data - Payload from API response.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const commentChanged = (data, subject, instigatorId) => (dispatch) => {
  batch(async () => {
    dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: STATE_KEY, id: data.id },
      changes: data,
    });
    if (data.partial === true) {
      await dispatch(loadStoryComment(data.id));
    }
    dispatch(setInstigator({ type: STATE_KEY, id: data.id }, instigatorId));
  });
};

/**
 * Dispatches an action to delete a comment from state.
 *
 * @param {number} id - The comment ID.
 * @param {number} storyId - The story ID.
 */
export const commentDeleted = (id, subject) => (dispatch) => dispatch({
  type: DELETE_ENTITY,
  entity: { type: STATE_KEY, id },
  from: { ...subject, key: STATE_KEY },
});
