/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { batch } from 'react-redux';
import { denormalize } from 'normalizr';
import get from 'lodash/get';

import { createSelector } from 'reselect';
import {
  addEntity,
  receiveEntity,
  unsetEntity,
  setEntity,
} from '../actions/entity';
import { MOVE_BOARD_STORY, DELETE_ENTITY } from '../actions/types';
import { entitySchema } from '../types';
import createEntityReducer from '../utils/createEntityReducer';
import mergeReducers from '../utils/mergeReducers';
import createMoveStoryReducer from '../utils/createMoveStoryReducer';

export const STATE_KEY = 'sprints';
export const ALL_SPRINTS_STATE_KEY = 'allTeamSprints';

/**
 * Sprint reducer.
 */
export default mergeReducers(
  // Default entity reducer.
  createEntityReducer(STATE_KEY),

  // Reducer for moving stories in the backlog.
  createMoveStoryReducer(STATE_KEY),

  // A custom reducer for updating when stories move on the board.
  (state = {}, action = {} as any) => {
    const {
      teamId,
      report,
    } = action;

    if (action.type === MOVE_BOARD_STORY) {
      const sprintId = Object.keys(state)
        .find((key) => state[key].team_id === teamId);

      if (!sprintId) {
        return state;
      }
      return {
        ...state,
        [sprintId]: {
          ...state[sprintId],
          report,
        },
      };
    }

    return state;
  }
);

/**
 * Add a new sprint.
 *
 * @param {number} teamId - Team ID.
 * @param {object} sprint - Payload to create the sprint.
 * @param {string} sprint.start_date - Start date, in the format yyyy-mm-dd.
 * @param {string} sprint.end_date - End date, in the format yyyy-mm-dd.
 * @returns {function}
 */
export const addSprint = (teamId: number, sprint: any) => (dispatch, _getState, { api }) => (
  api.createSprint(teamId, sprint)
    .then(({ data }) => {
      dispatch(addEntity({
        entity: { type: 'sprints', data },
        to: { type: 'teams', id: teamId, key: 'open_sprints' },
      }));
      return data;
    })
    .catch(api.errorHandler)
);

/**
 * Load a sprint via the API.
 */
export const loadSprint = (sprintId: number) => (dispatch, _getState, { api }) => (
  api.getSprint(sprintId)
    .then(({ data }) => {
      dispatch(receiveEntity(STATE_KEY, data));
      return data;
    })
    .catch(api.errorHandler)
);

/**
 * Update a sprint.
 */
export const updateSprint = (sprintId: number, sprint: {
  /* eslint-disable babel/camelcase */
  start_date?: string,
  end_date?: string,
  points_committed?: number,
  /* eslint-enable babel/camelcase */
}) => (dispatch, _getState, { api }) => (
  api.updateSprint(sprintId, sprint)
    .then(({ data }) => {
      dispatch(receiveEntity(STATE_KEY, data));
      return data;
    })
    .catch(api.errorHandler)
);

export const startSprint = (sprintId: number) => (dispatch: any, _getState, { api }:any): any => (
  api.startSprint(sprintId)
    .then(({ data }) => {
      batch(() => {
        dispatch(receiveEntity(STATE_KEY, data));
        dispatch(setEntity({
          entity: { id: sprintId, type: STATE_KEY },
          set: { type: 'teams', id: data.team_id, key: 'current_sprint' },
        }));
      });
      return data;
    })
);

export const endSprint = (sprintId: number) => (dispatch: any, getState:any, { api }: any): any => (
  api.endSprint(sprintId)
    .then(({ data: { sprint, roll } }) => {
      batch(() => {
        dispatch(receiveEntity(STATE_KEY, sprint));
        dispatch(unsetEntity({
          entity: { id: sprintId, type: STATE_KEY },
          unset: { type: 'teams', id: sprint.team_id, key: 'current_sprint' },
        }));
        dispatch(unsetEntity({
          entity: { id: [sprintId], type: STATE_KEY },
          unset: { type: 'teams', id: sprint.team_id, key: 'open_sprints' },
        }));
        if (roll.total_points > 0) {
          // Update the backlog target totals.
          dispatch(receiveEntity(roll.to.type, {
            id: roll.to.id,
            total_points: get(getState(), `data.entities.${roll.to.type}.${roll.to.id}.total_points`, 0) + roll.total_points,
          }));
        }
        dispatch({
          type: DELETE_ENTITY,
          entity: { type: 'boards', id: sprint.team_id },
        });
      });
      return { sprint, roll };
    })
);

export const getSprints = (state) => (state?.data?.entities?.sprints || {});

/**
 * Select the hydrated sprint from state.
 *
 * @param {Object} state - Full redux state.
 * @param {string} id - Sprint ID.
 * @returns {Object}
 */
export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)
);

export const selectDehydratedSprint = createSelector(
  getSprints,
  (_, id) => id,
  (sprints, id) => sprints?.[id]
);

/**
 * Select the ids of the team's sprints.
 *
 * @param {Object} state - Full redux state.
 * @param {string} teamId - The team to select.
 * @returns {Number[]} - The array of open sprints.
 */
export const getOpenSprintsByTeam = (state, teamId) => state?.data?.teams?.[teamId]?.open_sprints || []; // eslint-disable-line max-len
