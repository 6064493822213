import { batch } from 'react-redux';
import capitalize from 'lodash/capitalize';

import reorderList from '../../utils/reorderList';
import { receiveEntity } from '../entity';
import {
  SET_ENTITY_ORDER,
  TYPE_MAP_FROM_SINGULAR,
  MOVE_STORY,
  TYPE_MAP,
} from '../types';
import { setInstigator } from './member';

export const backlogReordered = ({
  source,
  user_id: userId,
  story_id: storyId,
  new_index: newIndex,
  old_index: oldIndex,
}) => (dispatch, getState) => {
  const target = { ...source, contextType: TYPE_MAP_FROM_SINGULAR[source.type] };
  const { backlog_stories: stories } = getState().data.entities[target.contextType][target.id];
  const order = reorderList(stories, oldIndex, newIndex);
  batch(() => {
    dispatch({
      type: SET_ENTITY_ORDER,
      order,
      target,
      key: 'backlog_stories',
    });
    dispatch(setInstigator({ type: 'stories', id: storyId }, userId));
  });
};

/**
 * Dispatch action from pusher event that a story has moved.
 *
 * @param {number} param.instigator - The user who initiated the action.
 * @param {object} param.to - Properties of destination backlog.
 * @param {number} param.to.id - The ID of the destination backlog.
 * @param {'teams'|'sprints'|'projects'} param.to.type - The type of the destination backlog.
 * @param {object} param.from - Properties of the previous backlog.
 * @param {number} param.from.id - The ID of the previous backlog.
 * @param {'teams'|'sprints'|'projects'} param.from.type - The type of the previous backlog.
 * @param {number} param.storyId - The ID of the story that moved.
 */
export const storyMoved = ({
  instigator,
  to,
  from,
  story: storyChanges,
}) => (dispatch, getState, { api }) => {
  const state = getState();

  const updateStoryLocation = (storyObj) => {
    batch(() => {
      dispatch({
        type: MOVE_STORY,
        story: storyChanges,
        storyPoints: storyObj.points || 0,
        to,
        from,
      });
      dispatch(setInstigator({ type: 'stories', id: storyChanges.id }, instigator));
    });
  };

  const story = state?.data?.entities?.stories?.[storyChanges.id] || false;

  if (story) {
    updateStoryLocation(story);
    return;
  }

  // Otherwise, fetch the story before moving it.
  const apiFunction = `get${capitalize(TYPE_MAP[from.type])}Story`;
  // Due to a bug in babel, apiFunction needs to be a variable [shrug].
  api?.[apiFunction](from.id, storyChanges.id)
    .then(({ data }) => {
      dispatch(receiveEntity('stories', data));
      return data;
    })
    .then(updateStoryLocation)
    .catch(api.errorHandler);
};
