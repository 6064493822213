import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { selectHydrated as selectSearch } from '../../modules/search';

import { STORY_SHAPE } from '../../types/props';
import SearchResult from './SearchResult';
import * as actions from '../../actions';
import Loading from '../Loading';

function Search({ fragment, stories, searchForStories }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    searchForStories(fragment).then(() => setLoading(false));
  }, [fragment]);

  return (
    <>
      <Helmet>
        <title>
          {`"${fragment}" search results`}
        </title>
      </Helmet>
      <main className="container">
        <h1 id="search-results">Search Results</h1>
        {loading ? (
          <Loading />
        ) : (
          <ol className="story-list" aria-labelledby="search-results">
            {stories.map((story) => (
              <SearchResult key={story.id} story={story} />
            ))}
          </ol>
        )}
      </main>
    </>
  );
}

Search.propTypes = {
  fragment: PropTypes.string.isRequired,
  stories: PropTypes.arrayOf(PropTypes.shape(STORY_SHAPE)).isRequired,
  searchForStories: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => {
    const qs = queryString.parse(ownProps.location.search);
    const fragment = qs.search ? qs.search : '';

    return {
      fragment,
      stories: fragment ? selectSearch(state, qs.search) : [],
    };
  },
  {
    searchForStories: actions.getSearch,
  }
)(Search);
