/**
 * Make a request to update a project.
 *
 * @param {number} projectId - Project ID.
 * @param {object} project - Project.
 * @param {string} project.title - Project title.
 * @returns {Promise} Promise object representing the API request.
 */
const updateProject = (projectId, project) => axios.put(`/api/projects/${projectId}`, project);

export default updateProject;
