/**
 * Filter stories by team.
 *
 * @param {number} id - The ID of the team.
 * @param {array} stories - The stories to match.
 * @return {array} Unpointed stories.
 */
const team = (id, stories) => stories.map(
  (story) => ({
    ...story,
    filtered: story.team?.id !== +id,
  })
);
export default team;
