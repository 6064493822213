import { PUSHER_JOIN } from '../types';
import leaveChannel from './leaveChannel';
import {
  boardUpdated,
} from './board';

export default (teamId) => (dispatch, getState) => {
  const channel = `boards.${teamId}`;
  const { pusher: { [channel]: existingConnection } } = getState();

  if (!existingConnection) {
    window.Echo.join(channel)
      // Board changes
      .listen('BoardUpdated', ({
        story_id, to, order, instigator,
      }) => dispatch(boardUpdated(teamId, story_id, to, order, instigator)));

    dispatch({
      type: PUSHER_JOIN,
      channel,
    });
  }

  return () => dispatch(leaveChannel(channel));
};
