import { batch } from 'react-redux';

import { setInstigator } from './member';
import { addAttachmentAction, deleteAttachmentAction } from '../../modules/attachment';

/**
 * Dispatch an action to add an attachment to state.
 *
 * @param {number} storyId - Story ID.
 * @param {object} attachment - Attachment payload.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const attachmentAdded = (storyId, attachment, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch(addAttachmentAction(storyId, attachment));
    dispatch(setInstigator({ type: 'attachments', id: attachment.id }, instigatorId));
  });
};

/**
 * Dispatches an action to delete an attachment from state.
 *
 * @param {number} storyId - The story ID.
 * @param {number} attachmentId - The attachment ID.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const attachmentDeleted = (storyId, attachmentId, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch(deleteAttachmentAction(storyId, attachmentId));
    dispatch(setInstigator({ type: 'stories', id: storyId }, instigatorId, ['attachments']));
  });
};
