import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Diagram3,
  Journals,
  List,
  Pencil,
  ToggleOff,
  Trash,
} from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import * as uri from '../../utils/uri';
import { BACKLOG_SHAPE } from '../../types/props';
import ToggleSprintButton from './ToggleSprintButton';
import ConfirmationDialog from '../ConfirmationDialog';
import Menu from '../Menu';
import LinkMaybe from '../LinkMaybe';
import { addMyTeamChannel, switchCurrentTeam } from '../../actions';

export function PureBacklogHeader({
  addMyTeamChannel: addChannel,
  backlog,
  currentTeamId,
  deleteEntity,
  switchCurrentTeam: switchTeam,
}) {
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);

  const handleDeletion = () => {
    deleteEntity(backlog);
    setIsConfirmingDelete(false);
  };

  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    setIsConfirmingDelete(true);
  };

  const handleSwitchTeam = () => switchTeam(backlog.id).then(
    // We are already listening on this channel, adding it to myTeams persists that connection.
    (team) => addChannel(team.id)
  );

  const isViewingOwnTeamBacklog = (backlog.backlogType === 'teams') && (currentTeamId === backlog.id);

  return (
    <div className={classnames('row', 'mb-3')}>
      <div className="col-9 d-md-flex align-items-center">
        <h1 className="mr-2">
          {backlog.backlogType === 'sprints' ? (
            <>
              {backlog.is_active && 'Current Sprint: '}
              <span className="text-nowrap">{backlog.title}</span>
            </>
          ) : (
            backlog.title || backlog.name
          )}
        </h1>
        {isViewingOwnTeamBacklog ? (
          <span className="h1 text-primary" title="Current team">
            <span className="sr-only">Current team</span>
            {/* @todo either a new icon or iterate on how to mark current team. */}
          </span>
        ) : null }
      </div>
      <div className="col-3">
        <div className="float-right">
          <Menu className="display-inline">
            {(backlog.backlogType === 'sprints') && (
              <>
                <div className="dropdown-item" role="menuitem">
                  <ToggleSprintButton sprintId={backlog.id} teamId={backlog.team_id} />
                </div>
                <div className="dropdown-divider" aria-hidden />
              </>
            )}
            {backlog.backlogType !== 'sprints' || !backlog?.is_ended ? (
              <LinkMaybe to={uri.editContext(backlog.backlogType, backlog.id)} className="dropdown-item" role="menuitem">
                <Pencil aria-hidden />
                {` Edit ${backlog.backlogTypeLabel}`}
              </LinkMaybe>
            ) : null}
            {backlog.backlogType !== 'projects' ? (
              <Link className="dropdown-item" role="menuitem" to={uri.sprints(backlog.team_id || backlog.id)}>
                <List aria-hidden />
                {' View All Sprints'}
              </Link>
            ) : null}
            {backlog.backlogType !== 'sprints' ? (
              <>
                <Link className="dropdown-item" role="menuitem" to={uri.bucketList(backlog.backlogType, backlog.id)}>
                  <Journals aria-hidden />
                  {' View All Stories'}
                </Link>
                <Link className="dropdown-item" role="menuitem" to={uri.featuresList(backlog.backlogType, backlog.id)}>
                  <Diagram3 aria-hidden />
                  {' View All Features'}
                </Link>
              </>
            ) : null}
            {backlog.backlogType === 'teams' && backlog.id !== currentTeamId ? (
              <button
                type="button"
                className="dropdown-item"
                role="menuitem"
                onClick={handleSwitchTeam}
              >
                <ToggleOff aria-hidden />
                {' Make Active Team'}
              </button>
            ) : null}
            <div className="dropdown-divider" aria-hidden />
            <div className="dropdown-item" role="menuitem">
              <form method="post" onSubmit={handleDeleteSubmit}>
                <button type="submit" className="btn danger">
                  <Trash aria-hidden />
                  {' Delete '}
                  {backlog.backlogTypeLabel}
                </button>
              </form>
            </div>
          </Menu>
        </div>
      </div>
      {isConfirmingDelete && (
        <ConfirmationDialog
          message={`Are you sure you want to delete this ${backlog.backlogTypeLabel.toLowerCase()}? This action cannot be undone.`}
          confirmAction={handleDeletion}
          closeDialog={() => setIsConfirmingDelete(false)}
        />
      )}
    </div>
  );
}

PureBacklogHeader.propTypes = {
  addMyTeamChannel: PropTypes.func.isRequired,
  backlog: PropTypes.shape(BACKLOG_SHAPE).isRequired,
  currentTeamId: PropTypes.number.isRequired,
  deleteEntity: PropTypes.func.isRequired,
  switchCurrentTeam: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    currentTeamId: +(state.appMeta?.current_team || 0),
  }),
  {
    addMyTeamChannel,
    switchCurrentTeam,
  }
)(PureBacklogHeader);
