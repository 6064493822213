import React from 'react';

export interface AlertProps {
  /** Text of alert message, or alert element. */
  body: string | React.ReactNode;
  /** String to describe how component is styled */
  type: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' ;
}

export default function Alert({ body, type }:AlertProps) {
  return (
    <div className={`alert alert-flash alert-${type}`} role="alert">
      {body}
      <span className="sr-only">
        {type}
        alert
      </span>
    </div>
  );
}
