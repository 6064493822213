import isDateRecent from '../../utils/isDateRecent';

/**
 * Filter stories by a status.
 *
 * @param {string} value - Status to filter by (open or done).
 * @param {array} stories - The stories to match against the status.
 * return {array} Stories within the status.
 */
const status = (value, stories) => stories.map(
  (story) => ({
    ...story,
    filtered: !(
      story.status === value
      || (value === 'open' && story.status !== 'done')
      || (value === 'new' && story.created_at && isDateRecent(story.created_at))
    ),
  })
);
export default status;
