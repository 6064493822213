/* eslint-disable react/no-danger */
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { format } from 'date-fns';

import { Comment as ICommentObj } from '../../../types/interfaces/Comment';

import withInstigator from '../../../hocs/withInstigator';
import RelativeTime from '../../RelativeTime';
import ConfirmationDialog from '../../ConfirmationDialog';
import CommentEditor from './CommentEditor';
import UserCard from '../../UserCard';
import Loading from '../../Loading';

import styles from './Comment.scss';

interface CommentProps {
  comment: ICommentObj;
  className?: string;
  currentUserId: number;
  deleteComment: (id: number) => void;
  updateComment: (id: number, body: string) => void;
  storyId: number;
}

function Comment({
  comment,
  className = '',
  currentUserId,
  deleteComment,
  updateComment,
  storyId,
}: CommentProps) {
  const [editing, setEditing] = React.useState(false);
  const [body, setBody] = React.useState(comment.body);
  const [collapsed, setCollapsed] = React.useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = React.useState(false);
  const bodyRef = React.useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    updateComment(comment.id, body);
    setEditing(false);
  };

  const resetForm = (e: React.MouseEvent) => {
    e.preventDefault();
    setBody(comment.body);
    setEditing(false);
  };

  const deleteHandler = () => {
    setIsConfirmingDelete(true);
  };

  const deleteConfirmAction = () => {
    deleteComment(comment.id);
  };

  const highlighted = hash === `#comment-${comment.id}`;

  const readableDate = (datetime: string) => format(new Date(datetime), 'PPPPpp');

  React.useEffect(() => {
    if (comment.partial !== true) {
      if (bodyRef.current && bodyRef.current.offsetHeight > 500) {
        setCollapsed(true);
      }
    }
  }, [comment.partial]);

  return (
    <li key={comment.id} id={`comment-${comment.id}`} className={classnames('comment', className, { highlighted })}>
      <UserCard user={comment.member} hideInitials />
      <div className="flex-grow-1">
        <p className="comment-meta">
          <span className="commenter">{comment.member.name}</span>
          {' '}
          <a className="comment-time" href={`#comment-${comment.id}`}>
            {'commented '}
            <RelativeTime dateTime={comment.created_at} />
            {comment.created_at !== comment.updated_at && (
              <>
                {', updated '}
                <RelativeTime dateTime={comment.updated_at} />
              </>
            )}
          </a>
          {' '}
          {currentUserId === comment.member.id && (
            <span className="comment-actions">
              {!editing && (
                <>
                  <button
                    className="edit-comment"
                    type="button"
                    onClick={() => setEditing(true)}
                    aria-label={`Edit your comment from ${readableDate(comment.created_at)}`}
                  >
                    Edit
                  </button>
                  {' '}
                </>
              )}
              <button
                className="delete-comment"
                type="button"
                onClick={deleteHandler}
                aria-label={`Delete your comment from ${readableDate(comment.created_at)}`}
              >
                Delete
              </button>
            </span>
          )}
        </p>

        {editing ? (
          <div className="comment-form">
            <div className="form-group">
              <label className="sr-only" htmlFor={`edit-comment-${comment.id}`}>Edit Comment</label>
              <CommentEditor
                id={`edit-comment-${comment.id}`}
                body={body}
                updateBody={setBody}
                storyId={storyId}
              />
            </div>
            <p>
              <input
                type="submit"
                className="btn btn-primary btn-sm"
                value="Save Changes"
                onClick={submitForm}
                disabled={body === '' || body === comment.body}
              />
              {' '}
              <input type="reset" className="btn btn-secondary btn-sm" value="Cancel" onClick={resetForm} />
            </p>
          </div>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {comment.partial === true ? (
              <Loading />
            ) : (
              <div className={classnames({ [styles.collapsed]: collapsed })}>
                <div
                  ref={bodyRef}
                  className={classnames('comment-body', { [styles.collapsedBody]: collapsed })}
                  dangerouslySetInnerHTML={{ __html: comment.body_html }}
                />
                {collapsed ? (
                  <button
                    type="button"
                    onClick={() => setCollapsed(false)}
                    className={classnames('btn', styles.expandToggle)}
                  >
                    Expand
                  </button>
                ) : null}
                {!collapsed && bodyRef.current && bodyRef.current.scrollHeight > 500 ? (
                  <button
                    type="button"
                    onClick={() => setCollapsed(true)}
                    className={classnames('btn', styles.expandToggle, styles.collapseBtn)}
                  >
                    Collapse
                  </button>
                ) : null}
              </div>
            )}
          </>
        )}
      </div>
      {isConfirmingDelete && (
        <ConfirmationDialog
          message="Are you sure you want to delete this comment?"
          closeDialog={() => setIsConfirmingDelete(false)}
          confirmAction={deleteConfirmAction}
        />
      )}
    </li>
  );
}

export default withInstigator(Comment);
