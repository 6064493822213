import React from 'react';
import { AnyAction } from 'redux';
import { batch } from 'react-redux';
import { denormalize } from 'normalizr';
import get from 'lodash/get';

import { receiveEntity } from '../actions/entity';
import {
  MERGE_ENTITY_CHANGES,
  DELETE_ENTITY,
} from '../actions/types';
import { entitySchema } from '../types/schema';
import createEntityReducer from '../utils/createEntityReducer';
import mergeReducers from '../utils/mergeReducers';
import moveEntityInListGroup from '../utils/moveEntityInListGroup';

export const STATE_KEY = 'games';

const MOVE_VOTE = 'games/MOVE_VOTE';

/**
 * Game reducer.
 */
export default mergeReducers(
  // Default entity reducer.
  createEntityReducer(STATE_KEY),

  // A custom reducer for moving stories.
  (
    // eslint-disable-next-line default-param-last
    state = {},
    action: AnyAction
  ) => {
    if (action.type === MOVE_VOTE) {
      return moveEntityInListGroup(
        state,
        action.from,
        action.to,
        action.gameId,
        action.userId,
        'responses'
      );
    }

    return state;
  }
);

/**
 * Action creator to remove the game from state.
 *
 * @param {number} gameId - Game ID.
 * @param {number} storyId - Story ID.
 * @returns {Object} Action to dispatch.
 */
export const deleteGameFromStore = (gameId, storyId) => ({
  type: DELETE_ENTITY,
  entity: { type: 'games', id: gameId },
  unset: { type: 'stories', id: storyId, key: 'games' },
});

/**
 * Action creator for changing a voting status.
 * @param {number} gameId - Game ID.
 * @param {number} userId - User ID.
 * @param {boolean} voted - Whether or not the user has voted.
 * @returns {Object} Action to dispatch.
 */
export const changeVotingStatus = (gameId, userId, voted) => ({
  type: MOVE_VOTE,
  from: voted ? 'waiting' : 'voted',
  to: voted ? 'voted' : 'waiting',
  gameId,
  userId,
});

/**
 * Create an action creator to get a poker game from the server and update it in state.
 *
 * @param {number} pokerGameId - Game ID to get.
 * @returns {function}
 */
export const getPokerGame = (pokerGameId) => (dispatch, _getState, { api }) => (
  api.getPokerGame(pokerGameId)
    .then(({ data }) => dispatch(
      receiveEntity(STATE_KEY, data)
    ))
    .catch((error) => dispatchAlert(error.toString()))
);

/**
 * Save a game to state.
 *
 * @param {Object} game - Poker Game.
 * @returns {function}
 */
export const saveGameToStore = (game) => (dispatch) => {
  batch(() => {
    dispatch(receiveEntity('games', game));
    dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: 'stories', id: game.story_id },
      changes: { games: game.id, active_game: game.id },
    });
  });
};

/**
 * Start a Poker Game.
 *
 * @param {number} storyId - Story ID.
 * @param {number} teamId - Team ID.
 * @returns {function}
 */
export const startPokerGame = (storyId, teamId) => (dispatch, _getState, { api }) => (
  api.createPokerGame(storyId, teamId)
    .then(({ data }) => dispatch(saveGameToStore(data)))
    .catch(api.errorHandler)
);

/**
 * Cancel a Poker Game.
 *
 * @param {number} gameId - Game ID.
 * @param {number} storyId - Story ID.
 * @returns {function}
 */
export const cancelGame = (gameId: number, storyId: number) => (dispatch, _getState, { api }) => (
  api.deletePokerGame(gameId)
    .then(() => dispatch(deleteGameFromStore(gameId, storyId)))
    .catch(api.errorHandler)
);

/**
 * End voting on a story.
 *
 * @param {number} gameId - Game ID.
 * @returns {function}
 */
export const endVoting = (gameId) => (dispatch, _getState, { api }) => (
  api.closePokerGame(gameId)
    .then(({ data }) => dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: 'games', id: gameId },
      changes: { is_open: false, results: data },
    }))
    .catch(api.errorHandler)
);

/**
 * Accept the results of a Poker Game.
 *
 * @param {number} gameId - Game ID.
 * @param {number} storyId - Story ID.
 * @param {number} points - Story points to accept.
 * @returns {function}
 */
export const endGame = (
  gameId: number,
  storyId: number,
  points: number
) => (dispatch, _getState, { api }) => (
  api.acceptPokerGame(gameId, points)
    .then(() => {
      batch(() => {
        dispatch(deleteGameFromStore(gameId, storyId));
        dispatch({
          type: MERGE_ENTITY_CHANGES,
          entity: { type: 'stories', id: storyId },
          changes: { points },
        });
      });
    })
    .catch(api.errorHandler)
);

/**
 * Cast a vote in a Poker Game.
 *
 * @param {number} gameId - Game ID.
 * @param {number} points - Story points to vote.
 * @returns {function}
 */
export const castVote = (gameId, points) => (dispatch, _getState, { api }) => (
  api.createPokerVote(gameId, points)
    .then(({ data }) => {
      batch(() => {
        dispatch(changeVotingStatus(gameId, data.user_id, true));
        dispatch({
          type: MERGE_ENTITY_CHANGES,
          entity: { type: 'games', id: gameId },
          changes: { user_vote: data },
        });
      });
    })
    .catch(api.errorHandler)
);

/**
 * Cancel a vote in a Poker Game.
 *
 * @param {number} gameId - Game ID.
 * @param {number} voteId - Vote ID.
 * @param {number} userId - User ID.
 * @returns {function}
 */
export const cancelVote = (gameId, voteId, userId) => (dispatch, _getState, { api }) => (
  api.deletePokerVote(voteId)
    .then(() => {
      batch(() => {
        dispatch(changeVotingStatus(gameId, userId, false));
        dispatch({
          type: MERGE_ENTITY_CHANGES,
          entity: { type: 'games', id: gameId },
          changes: { user_vote: null },
        });
      });
    })
    .catch(api.errorHandler)
);

/**
 * Reset a Poker Game for voting.
 *
 * @param {number} gameId - Game ID.
 * @param {number} storyId - Story ID.
 * @param {number} teamId - Team ID.
 * @returns {function}
 */
export const revote = (gameId: number, storyId: number, teamId: number) => (dispatch) => {
  batch(() => {
    // delete just from store, not from server.
    dispatch(deleteGameFromStore(gameId, storyId));
    // A call to startPokerGame will also delete the existing game from the server.
    dispatch(startPokerGame(storyId, teamId));
  });
};

/**
 * Alert the current user to a new poker game if they aren't currently viewing
 * that story.
 *
 * @param {string} params.uri - Story URI.
 * @param {string} params.title - Story title.
 */
export const maybeAlertNewPokerGame = ({ uri, title }) => {
  if (window.location.pathname !== uri) {
    dispatchAlert(
      (
        <>
          {'♠️ Voting is now open for '}
          <a href={uri}>{title}</a>
        </>
      ),
      'success',
      30000
    );
  }
};

/**
 * Has the current user voted in the given poker game?
 *
 * @param {object} state - State.
 * @param {number} gameId - Game ID.
 * @returns {bool|null} True if the user voted, false if not, or null on error.
 */
export const hasCurrentUserVotedInGame = (state, gameId) => {
  if (!gameId) {
    return null;
  }

  const { appMeta: { user_id: currentUser } } = state;
  const votes = get(state.data.entities, `games.${gameId}.responses.voted`, null);
  if (!Array.isArray(votes)) {
    return null;
  }

  return votes.includes(currentUser);
};

/**
 * Select the hydrated game from state.
 *
 * @param {Object} state - Full redux state.
 * @param {number} id - Story ID.
 * @returns {Object}
 */
export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)
);
