import * as React from 'react';
import {
  arrayOf,
  bool,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { Line } from 'react-chartjs-2';

function Velocities({
  sprints = [],
}) {
  // Sort the sprints, just in case.
  const sortedSprints = sprints.sort((a, b) => (+(new Date(a.date)) - +(new Date(b.date))));

  // Formatted data points to plot on the line graph.
  const completedPoints = sortedSprints
    .map(({ date, points }) => ({
      x: date,
      y: points,
    }));
  const committedPoints = sortedSprints
    .map(({ date, pointsCommitted }) => ({
      x: date,
      y: pointsCommitted,
    }));

  const options = {
    scales: {
      xAxes: [{
        type: 'time',
        distribution: 'linear',
        time: {
          unit: 'week',
        },
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true,
        },
      }],
    },
    tooltips: {
      callbacks: {
        label(tooltipItem) {
          return tooltipItem.datasetIndex === 0
            ? `  ✅  ${sortedSprints[tooltipItem.index].points} points completed`
            : `  🎯  ${sortedSprints[tooltipItem.index].pointsCommitted} points committed`;
        },
        title([tooltipItem]) {
          return sortedSprints[tooltipItem.index].title;
        },
      },
    },
  };

  return (
    <Line
      data={{
        datasets: [
          // Completed points.
          {
            borderWidth: 1,
            fill: true,
            backgroundColor: 'rgba(40, 167, 69, 0.50)', // Bootstrap "success".
            label: 'Points Completed',
            lineTension: 0,
            data: completedPoints,
            pointRadius: 4,
            pointBackgroundColor: sortedSprints.map(
              // If the sprint is active, make the point red.
              ({ isActive }) => (isActive ? 'rgba(220, 53, 69, 0.50)' : 'rgba(40, 167, 69, 0.50)')
            ),
          },

          // Committed points.
          {
            borderWidth: 1,
            fill: false,
            borderColor: 'rgba(0, 123, 255, 0.70)', // Bootstrap "primary".
            label: 'Points Committed',
            lineTension: 0,
            data: committedPoints,
            pointRadius: 4,
            pointBackgroundColor: sortedSprints.map(
              // If the sprint is active, make the point red.
              ({ isActive }) => (isActive ? 'rgba(220, 53, 69, 0.50)' : 'rgba(0, 123, 255, 0.50)')
            ),
          },
        ],
      }}
      options={options}
    />
  );
}

Velocities.propTypes = {
  sprints: arrayOf(shape({
    title: string.isRequired,
    date: oneOfType([string, number]).isRequired,
    points: number.isRequired,
    pointsCommitted: number.isRequired,
    isActive: bool.isRequired,
  })),
};

export default Velocities;
