/**
 * A handler for an event that takes the value of the clipboard and pastes it
 * as plain text.
 *
 * @param {Event} e The SyntheticEvent object.
 */
export default (e) => {
  e.preventDefault();

  // Get text representation of clipboard.
  const text = (e.originalEvent || e).clipboardData.getData('text/plain');

  // Insert text manually.
  document.execCommand('insertHTML', false, text);
};
