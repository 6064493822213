import { createSelector } from 'reselect';
import get from 'lodash/get';
import { selectHydrated as selectStory } from '../modules/story';

export default () => createSelector(
  [
    (state) => get(state, 'data.entities.projects', {}),
    (state) => get(state, 'data.entities.teams', {}),
    (state, { storyId }) => selectStory(state, storyId),
  ],
  (projects, teams, { source_type: sourceType, source_id: sourceId } = {}) => {
    const source = sourceType === 'team' ? get(teams, sourceId, false)
      : get(projects, sourceId, false);

    return source ? {
      id: sourceId,
      type: sourceType,
      features: source.features || [],
      label: source.name || source.title,
    } : {};
  }
);
