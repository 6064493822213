/**
 * Unset a singular entity reference in another entity's state.
 *
 * This differs from removeEntityId in that this works on a singular reference
 * vs a list of references.
 *
 * This is the same as calling `setEntityId(state, null, targetId, key)`.
 *
 * @param {Object} state - Entities state.
 * @param {number|string} id - Referenced (child) entity ID. This is unused, but
 *                             maintained for consistency with other functions.
 * @param {number|string} targetId - ID of referencing (parent) entity.
 * @param {string} key - Property referencing the (child) entity.
 * @returns {Object} New state.
 */
export default (state, id, targetId, key) => ({
  ...state,
  [targetId]: {
    ...state[targetId],
    [key]: null,
  },
});
