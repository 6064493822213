import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import {
  HouseFill,
  PeopleFill,
  Journals,
} from 'react-bootstrap-icons';
import LinkMaybe from '../LinkMaybe';
import { getBreadcrumbs } from '../../selectors';
import MoveMenu from '../MoveMenu';
import styles from './Breadcrumbs.scss';

const breadcrumbPropTypes = {
  crumbs: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
    title: PropTypes.string,
    anchor: PropTypes.bool,
  })).isRequired,
  matchPath: PropTypes.string.isRequired,
  container: PropTypes.bool,
  storyId: PropTypes.number,
};

function Breadcrumb({
  container = true,
  crumbs,
  matchPath,
  storyId = undefined,
}) {
  const location = useLocation();
  const isBoardView = matchPath.match(/board$/g);

  return (
    <div className="breadcrumb-wrapper">
      <div className={classNames(
        styles.container,
        {
          container: container && !isBoardView,
          'container-fluid': container && isBoardView,
        }
      )}
      >
        <div className={styles.wrapper}>
          <nav className={styles.nav} aria-labelledby="breadcrumbs">
            <h2 id="breadcrumbs" className="sr-only">
              Breadcrumbs
            </h2>
            <ol className={styles.breadcrumb} aria-labelledby="breadcrumbs">
              <li className={styles.item}>
                <a href="/">
                  <HouseFill />
                  <span className="sr-only">Home</span>
                </a>
              </li>
              {crumbs.map((crumb, index) => (
                <li
                  className={styles.item}
                  key={`${crumb.path}-${crumb.title}`}
                >
                  {crumb.isText ? crumb.title : (
                    <LinkMaybe
                      to={crumb.path}
                      useAnchor={crumb.anchor}
                      aria-current={location.pathname === crumb.path}
                    >
                      {(() => {
                        if (crumb.path === '/teams') {
                          return (
                            <>
                              <PeopleFill />
                              <span className="sr-only">Teams</span>
                            </>
                          );
                        }

                        if (crumb.path === '/projects') {
                          return (
                            <>
                              <Journals />
                              <span className="sr-only">Projects</span>
                            </>
                          );
                        }

                        return crumb.title;
                      })()}
                    </LinkMaybe>
                  )}
                  {' '}
                  {(index === crumbs.length - 1) && typeof storyId === 'number' ? (
                    <MoveMenu
                      storyId={storyId}
                    />
                  ) : null}
                </li>
              ))}
            </ol>
          </nav>
        </div>
      </div>
    </div>
  );
}

Breadcrumb.propTypes = breadcrumbPropTypes;

export default connect(
  (state, ownProps) => {
    const { storyId = false } = ownProps?.match?.params || {};
    return {
      crumbs: getBreadcrumbs(state, ownProps?.match?.params),
      matchPath: ownProps?.match?.path,
      storyId: +storyId,
    };
  }
)(Breadcrumb);
