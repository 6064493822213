import React from 'react';
import { Story } from '../../types/interfaces/Story';
import type { ModifyScopeOptions } from './HasPoints';

export type Props = {
  items: Story[],
  setHowToModifyScope(arg0: ModifyScopeOptions | false): void;
};

export default function ResolutionPaths({
  items = [],
  setHowToModifyScope,
}: Props) {
  const total = items
    .reduce((sum, { points }) => (sum + (points || 0)), 0);

  type ChoiceDict = {
    [choiceName in ModifyScopeOptions]: {
      label: string;
      help: string;
    };
  };
  const choices: ChoiceDict = {
    buffer: {
      label: 'Buffer',
      help: 'Is this an interruption?',
    },
    capacity: {
      label: 'Capacity',
      help: 'Is your team ready to take on additional work this sprint?',
    },
    swap: {
      label: 'Scope Change',
      help: 'What story will the team delay to accommodate these points?',
    },
  };
  return (
    <div className="mb-3 w-100">
      <h2 className="sr-only" id="modification-choices">
        Ways to modify the active sprint scope
      </h2>
      <p className="blockquote mb-3">
        You are bringing in
        {' '}
        <span className="badge badge-danger">{total}</span>
        {' '}
        points. How do you want to adjust the sprint scope?
      </p>
      <ul className="list-group list-group-horizontal min-vh-25" aria-labelledby="modification-choices">
        {(Object.keys(choices) as Array<ModifyScopeOptions>)
          .map((choice) => (
            <li key={choice} className="list-group-item flex-fill w-100 pt-3 pb-2">
              <button
                type="button"
                className="mx-auto btn btn-lg btn-outline-dark d-block mb-3"
                onClick={() => setHowToModifyScope(choice)}
              >
                {choices[choice].label}
              </button>
              <p className="form-text text-center">
                {choices[choice].help}
              </p>
            </li>
          ))}
      </ul>
    </div>
  );
}
