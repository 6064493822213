import React, { useState } from 'react';
import PropTypes from 'prop-types';

import StoryTitleWarning from '../StoryTitleWarning';

function NewStory({ addStorySubmit }) {
  const [title, setTitle] = useState('');
  const handleFormSubmit = (e) => {
    e.preventDefault();
    addStorySubmit(title);
    setTitle('');
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <label className="sr-only" htmlFor="story-title">Add a user story</label>
      <input
        type="text"
        name="title"
        className="form-control form-control-lg my-3"
        id="story-title"
        placeholder="New User Story"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        autoComplete="off"
        maxLength={191}
      />

      <StoryTitleWarning length={title.length} />
    </form>
  );
}

NewStory.propTypes = {
  addStorySubmit: PropTypes.func.isRequired,
};

export default NewStory;
