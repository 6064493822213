/**
 * Filter stories by project.
 *
 * @param {number} id - The ID of the project.
 * @param {array} stories - The stories to match.
 * @return {array} Unpointed stories.
 */
const project = (id, stories) => stories.map(
  (story) => ({
    ...story,
    filtered: story.project?.id !== +id,
  })
);
export default project;
