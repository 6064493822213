import BacklogScreen from './components/BacklogScreen';
import Breadcrumb from './components/Breadcrumbs/Breadcrumb';
import Story from './components/Story';
import TeamEdit from './components/TeamEdit';
import SprintEdit from './components/SprintEdit';
import SprintBoard from './components/SprintBoard';
import Search from './components/Search';
import AllSprints from './components/AllSprints';
import BucketList from './components/BucketList';
import Feature from './components/Feature';
import Features from './components/Features';
import Projects from './components/Projects';
import ProjectEdit from './components/ProjectEdit';
import TeamBar from './components/TeamBar';

export default [
  {
    path: '/:contextType(teams|projects|sprints)/:id',
    component: BacklogScreen,
    // Breadcrumb moves to within the component.
    exact: true,
  },
  {
    path: '/:contextType(teams|projects)/:id/stories',
    component: BucketList,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/:contextType(teams|projects)/:id/features',
    component: Features,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/:contextType(teams|projects)/:id/features/:featureId/stories',
    component: Feature,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/:contextType(teams|projects)/:id/stories/:storyId',
    component: Story,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/:contextType(teams)/:id/edit',
    component: TeamEdit,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/:contextType(sprints)/:id/edit',
    component: SprintEdit,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/:contextType(teams)/:id/webhooks',
    component: TeamEdit,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/:contextType(teams)/:id/board',
    component: SprintBoard,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/search',
    component: Search,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/:contextType(teams)/:id/sprints',
    component: AllSprints,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/:contextType(projects)/:id/edit',
    component: ProjectEdit,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
  {
    path: '/projects',
    component: Projects,
    breadcrumb: Breadcrumb,
    teambar: TeamBar,
    exact: true,
  },
];
