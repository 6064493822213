import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-dropdown-select';
import { Link } from 'react-router-dom';
import { selectFeature } from '../../../selectors';
import { FEATURE_SHAPE, SOURCE_SHAPE } from '../../../types/props';
import FeatureSearchInput from './FeatureSearchInput';
import * as uri from '../../../utils/uri';

function PureFeatureSelect({
  source: {
    id: sourceId,
    type: sourceType,
    label: sourceLabel,
  },
  features,
  createFeature,
  handleFeatureSave,
  storyFeature,
  closeModal,
}) {
  const [selection, setSelection] = useState({
    label: storyFeature?.title || '',
    value: storyFeature?.id || '',
  });

  const isWaitingForLabelCreation = !Number.isInteger(selection?.value) && selection?.label !== '';

  /**
   * Update the selection state with an ID when a new feature state has been created.
   */
  useEffect(() => {
    if (isWaitingForLabelCreation) {
      const newFeature = features.find(({ title }) => title === selection.label) || {};
      if (newFeature.id) {
        setSelection({
          label: newFeature.title,
          value: newFeature.id,
        });
      }
    }
  }, [features]);

  /**
   *
   * Updates the current selection of the list and executes create function.
   *
   * @param {string} param.label - The current value of the input.
   */
  const handleCreate = ({ label }) => {
    setSelection({
      label,
      value: '',
    });
    createFeature(sourceId, sourceType, { title: label });
  };

  /**
   * Pass current selection value to handleFeatureSave function and close modal.
   *
   * @param {Object} e - HTMLEvent.
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    handleFeatureSave(selection.value);
    closeModal();
  };

  /**
   * Save user input to local state.
   *
   * @param {string} newValue - User input.
   */
  const handleChange = (newValue) => {
    const [{ label = '', value = false } = {}] = newValue || [{}];
    setSelection({ label, value });
  };

  return (
    <div className="card mb-3">
      <div className="card-body">
        <h2 className="h6 card-title" id={`featureSelect-${sourceId}-${sourceType}`}>
          Assign to feature on
          {' '}
          <Link
            to={uri.source({ id: sourceId, type: sourceType })}
            className="btn btn-dark btn-sm"
          >
            {sourceLabel}
          </Link>
          :
        </h2>
        <Select
          clearable
          create
          options={features.map(({ id, title }) => ({
            label: title,
            value: id,
          }))}
          values={[selection]}
          className="mb-2"
          closeOnSelect
          onCreateNew={handleCreate}
          onChange={handleChange}
          inputRenderer={FeatureSearchInput}
        />
        <div className="d-flex justify-content-end">
          <input
            type="submit"
            value="Save"
            aria-label="Save feature selection"
            className="btn btn-primary mr-1"
            onClick={handleSubmit}
            disabled={isWaitingForLabelCreation}
          />
          <input
            type="reset"
            value="Cancel"
            aria-label="Cancel modifying feature"
            className="btn btn-link"
            onClick={closeModal}
          />
        </div>
      </div>
    </div>
  );
}

PureFeatureSelect.propTypes = {
  closeModal: PropTypes.func,
  createFeature: PropTypes.func.isRequired,
  features: PropTypes.arrayOf(PropTypes.shape(FEATURE_SHAPE)).isRequired,
  handleFeatureSave: PropTypes.func.isRequired,
  source: PropTypes.shape(SOURCE_SHAPE).isRequired,
  storyFeature: PropTypes.shape(FEATURE_SHAPE),
};

PureFeatureSelect.defaultProps = {
  storyFeature: {},
  closeModal: () => {},
};

// For testing purposes.
export { PureFeatureSelect };

export default connect(
  (state, ownProps) => ({
    // Hydrate the features out of the source.
    features: ownProps?.source?.features.map((id) => selectFeature(state, id)),
  })
)(PureFeatureSelect);
