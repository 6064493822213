import * as React from 'react';
import {
  arrayOf,
  func,
  shape,
  number,
} from 'prop-types';
import { connect } from 'react-redux';

// Internal dependencies.
import { selectHydrated as selectStory } from '../../modules/story';
import {
  makeGetSource,
  getBacklogTargets,
  flattenBacklogTargets,
  getCurrentUserActiveTeam,
  makeGetBacklog,
} from '../../selectors';
import * as actions from '../../actions';
import {
  TYPE_MAP_FROM_SINGULAR,
  LABEL_MAP,
} from '../../actions/types';
import {
  BACKLOG_MINIMAL_SHAPE,
  BACKLOG_SHAPE,
  SOURCE_SHAPE,
  STORY_SHAPE,
  TEAM_SHAPE,
} from '../../types/props';

// Components.
import BacklogSelector from './BacklogSelector';
import ModifyActiveSprintDialog from '../ModifyActiveSprintDialog';

// Styles.
import styles from './MoveMenu.scss';

const moveMenuPropTypes = {
  activeTeam: shape(TEAM_SHAPE),
  backlogEntity: shape(BACKLOG_SHAPE),
  moveStory: func.isRequired,
  story: shape(STORY_SHAPE),
  storySource: shape(SOURCE_SHAPE),
  targets: arrayOf(shape(BACKLOG_MINIMAL_SHAPE)),
  updateBoard: func.isRequired,
  storyId: number.isRequired,
};

function MoveMenu({
  activeTeam,
  backlogEntity,
  moveStory,
  story,
  storyId,
  storySource,
  targets,
  updateBoard,
}) {
  const [pendingTarget, setPendingTarget] = React.useState(null);
  const [backlogFinderOpen, setBacklogFinderOpen] = React.useState(false);
  const {
    backlog: {
      id,
      type,
      board,
    } = {},
  } = story || {};
  const {
    columns = [],
    current_column: currentColumn,
  } = board || {};

  if (!story || !backlogEntity || !storySource || !activeTeam) {
    return null;
  }

  const storySourceTypePlural = TYPE_MAP_FROM_SINGULAR[storySource.type];
  const includeSource = !(type === storySource.type && id === storySource.id)
    && (
      targets.findIndex((target) => (
        target.type === storySourceTypePlural && target.id === storySource.id
      )) === -1
    );

  const moveStoryHandler = (targetType, targetId) => (
    moveStory(story, targetType, targetId, backlogEntity)
  );

  const promptToMoveStory = (target) => {
    if (target.isActive) {
      setPendingTarget(target);
    } else {
      // Proceed without confirmation.
      moveStoryHandler(target.type, target.id);
    }
  };

  /**
   * Confirmation action to move a story.
   */
  const confirmMoveStories = () => {
    if (pendingTarget?.id) {
      moveStoryHandler(pendingTarget.type, pendingTarget.id);
    }
    setPendingTarget(null);
  };

  const updateColumn = (toColumnId) => {
    const teamId = story.backlog?.parent?.id;
    if (!teamId) {
      dispatchAlert('There was a problem moving this story. Please refresh the page.', 'info');
      return;
    }

    updateBoard({
      teamId,
      storyId,
      toColumnId,
      fromColumnId: currentColumn,
    });
  };

  return (
    <>
      {pendingTarget ? (
        <ModifyActiveSprintDialog
          closeDialog={() => setPendingTarget(null)}
          confirmAction={confirmMoveStories}
          itemsToMove={[story]}
          target={pendingTarget}
        />
      ) : null}

      {backlogFinderOpen ? (
        <BacklogSelector
          story={story}
          source={backlogEntity}
          closeDialog={() => setBacklogFinderOpen(false)}
        />
      ) : null}

      <div className={styles.container}>
        <div className="dropdown">
          <button
            className={`btn btn-primary btn-sm dropdown-toggle ml-2 ${styles.button}`}
            type="button"
            id="story-backlog-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Move Story
          </button>
          <div className="dropdown-menu" aria-labelledby="story-backlog-dropdown" role="menu">
            {columns.length > 0 ? (
              <>
                <h4 className="dropdown-header">
                  Status
                </h4>
                {columns.map((column) => (column.id !== currentColumn ? (
                  <button
                    type="button"
                    role="menuitem"
                    className="dropdown-item"
                    key={column.id}
                    onClick={() => updateColumn(column.id)}
                  >
                    {column.title}
                  </button>
                ) : null))}
                <div className="dropdown-divider" />
              </>
            ) : null}
            <h4 className="dropdown-header">
              {activeTeam.name}
            </h4>
            {targets.map((target) => (
              <button
                key={target.id}
                type="button"
                role="menuitem"
                className="dropdown-item"
                onClick={() => promptToMoveStory(target)}
              >
                {target.title}
              </button>
            ))}

            {includeSource && (
              <>
                <div className="dropdown-divider" />
                <h4 className="dropdown-header">{storySource.label}</h4>
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => moveStoryHandler(storySourceTypePlural, storySource.id)}
                >
                  {`${LABEL_MAP[storySourceTypePlural]} Backlog`}
                </button>
              </>
            )}

            <div className="dropdown-divider" />
            <button
              type="button"
              className="dropdown-item"
              onClick={() => setBacklogFinderOpen(true)}
            >
              Move to another backlog…
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

MoveMenu.propTypes = moveMenuPropTypes;

export default connect(
  (state, { storyId }) => {
    const activeTeam = getCurrentUserActiveTeam(state);
    const story = selectStory(state, storyId);
    const { backlog } = story || {};
    const backlogType = backlog?.type ? TYPE_MAP_FROM_SINGULAR[backlog.type] : undefined;
    const targets = backlogType ? flattenBacklogTargets(
      getBacklogTargets(state),
      {
        contextType: backlogType,
        id: backlog?.id || 0,
      }
    ) : undefined;
    const storySource = story
      ? makeGetSource(state)(state, { storyId })
      : undefined;
    const backlogEntity = backlog && backlogType
      ? makeGetBacklog({ contextType: backlogType, id: `${backlog.id}` })(state)
      : undefined;

    return {
      activeTeam,
      backlogEntity,
      targets,
      storySource,
      story,
    };
  },
  {
    moveStory: actions.moveStory,
    updateBoard: actions.updateBoard,
  }
)(MoveMenu);
