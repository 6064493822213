/**
 * Split a story.
 *
 * @param {number} storyId - Story ID.
 * @param {Object} payload - Request payload.
 * @param {Object} [payload.update] - Data about storyId to update.
 * @param {string} [payload.update.title] - Update storyId's title.
 * @param {number|null} [payload.update.points] - Update storyId's points.
 * @param {Object[]} payload.split - New stories to split out.
 * @param {string} payload.split[].title - The new story's title.
 * @param {number|null} [payload.split[].points] - The new story's points.
 * @returns {Promise} Promise object representing the API request.
 */
const splitStory = (storyId, payload) => axios.post(`/api/story/${storyId}/split`, payload);
export default splitStory;
