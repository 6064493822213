/**
 * Update a Comment.
 *
 * @param {number} commentId - Comment ID.
 * @param {Object} comment - Comment data.
 * @param {string} comment.body - Comment body.
 * @returns {Promise} Promise object representing the API request.
 */
const updateComment = (commentId, comment) => axios.put(`/api/comments/${commentId}`, comment);
export default updateComment;
