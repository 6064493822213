import { denormalize } from 'normalizr';
import { receiveEntity, receiveManyEntities } from '../actions/entity';
import { LABEL_MAP } from '../actions/types';
import { entitySchema } from '../types';
import createEntityReducer from '../utils/createEntityReducer';
import { STATE_KEY as STORIES_STATE_KEY } from './story';

export const STATE_KEY = 'buckets';

/**
 * Buckets reducer.
 */
export default createEntityReducer(STATE_KEY);

/**
 * Given an array of bucket data, reduce it to an array of stories.
 *
 * @param {Array} buckets Array of (context) buckets.
 * @param {string|null} sortBy Story property to sort by.
 * @returns Story[]
 */
export const reduceBucketsToStories = (buckets, sortBy = null) => {
  const stories = buckets.reduce(
    (allStories, context) => {
      context.buckets.forEach(
        (bucket) => allStories.push(...bucket.backlog_stories)
      );
      return allStories;
    },
    []
  );

  if (stories.length === 0 || !sortBy) {
    return stories;
  }
  return stories.sort((a, b) => String(a).localeCompare(String(b)));
};

/**
 * Thunk to get all stories, bucketed, and load the bucket structure into state.
 *
 * @param {string} contextType - Either "projects" or "teams" or "features".
 * @param {number} contextId - Project ID or team ID.
 * @param {string} view - One of "active", "all", or "done".
 * @returns {function} When resolved, the Promise passes the structure:
 *   {
 *     title,
 *     buckets: [
 *       {
 *         id,
 *         type,
 *         title,
 *         uri,
 *         buckets: [
 *           {
 *             id,
 *             type,
 *             title,
 *             uri,
 *             backlog_stories,
 *           }
 *         ],
 *       }
 *     ],
 *   }
 */
export const getStoryBuckets = (contextType, contextId, view = 'active') => (dispatch, getState, { api }) => (
  api[`getAll${LABEL_MAP[contextType]}Stories`](contextId, view)
    .then(({ data }) => {
      dispatch(receiveEntity(STATE_KEY, { ...data, id: `${contextType}.${contextId}.${view}` }));
      return data;
    })
    .catch(api.errorHandler)
);

/**
 * Thunk to get all stories and load them into state.
 *
 * This differs from getStoryBuckets in that it doesn't load the bucket into state.
 *
 * @param {string} contextType - Either "project" or "team".
 * @param {number} contextId - Project ID or team ID.
 * @param {string} view - One of "active", "all", or "done".
 * @returns {function} When resolved, the Promise returns stories unstructured,
 *                     and ordered by context, then bucket, then backlog order.
 *                     This order isn't porticularly useful and the resulting
 *                     array likely should be reordered as needed.
 */
export const getAllStories = (contextType, contextId, view = 'active') => (dispatch, getState, { api }) => (
  api[`getAll${LABEL_MAP[contextType]}Stories`](contextId, view)
    .then(({ data }) => {
      const stories = reduceBucketsToStories(data.buckets);
      dispatch(receiveManyEntities(STORIES_STATE_KEY, stories));
      return stories;
    })
    .catch(api.errorHandler)
);

/**
 * Select the hydrated set of buckets from state.
 *
 * @param {Object} state - Full redux state.
 * @param {string} id - Search fragment.
 * @returns {Object}
 */
export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities) || {}
);
