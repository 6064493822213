/**
 * Update the list of entities referenced by another entity.
 *
 * Truth be told, this is no different than `setEntityId` and even, to an
 * extent, `mergeEntityChanges`. It might be worth merging the three in the
 * future.
 *
 * @param {Object} state - Entities state.
 * @param {array} order - Entity references, presumably in a new order.
 * @param {number|string} targetId - ID of entity being updated.
 * @param {string} key - Property referencing the entities.
 * @returns {Object} New state.
 */
export default (state, order, targetId, key) => ({
  ...state,
  [targetId]: {
    ...state[targetId],
    [key]: order,
  },
});
