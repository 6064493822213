import React from 'react';
import { connect } from 'react-redux';
import {
  arrayOf,
  shape,
} from 'prop-types';
import { STORY_FILTER_SHAPE, STORY_SHAPE } from '../../types/props';
import { filterStories } from '../../services/storyFilters';
import truthyFilterArray from '../../utils/truthyFilterArray';

import StoryCard from '../Backlog/StoryCard';

function DoneBucket({ stories, storyFilters }) {
  return (
    <div className="backlog-bucket">
      <ol className="story-list backlog-list">
        { /* eslint-disable-next-line max-len */ }
        {filterStories(storyFilters, truthyFilterArray(stories)).map((story) => (
          <StoryCard
            key={story.id}
            story={story}
            pusherActivity={story.pusherActivity}
          />
        ))}
      </ol>
    </div>
  );
}

DoneBucket.propTypes = {
  stories: arrayOf(shape(STORY_SHAPE)),
  storyFilters: arrayOf(shape(STORY_FILTER_SHAPE)),
};

export default connect(
  ({ storyFilters }) => ({
    storyFilters,
  })
)(DoneBucket);
