import * as React from 'react';
import PropTypes from 'prop-types';

function ErrorFallback({ error }) {
  return (
    <div role="alert" className="container mt-5 alert alert-danger">
      <h1 className="mb-2">
        Something went wrong
      </h1>
      <pre style={{ whiteSpace: 'normal' }}>
        {error.message}
      </pre>
      <button type="button" onClick={() => window.location.reload(false)} className="btn btn-light">Reload the page</button>
    </div>
  );
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
};

export default ErrorFallback;
