import get from 'lodash/get';
import { createSelector } from 'reselect';
import getEntities from './getEntities';
import getParams from './getParams';

// Resolve team ID, useful if the current backlog is a sprint.
export default createSelector(
  [getEntities, getParams],
  (entities, { contextType, id } = {}) => {
    if (contextType === 'sprints') {
      return get(entities, `sprints.${id}.team_id`, undefined);
    }

    if (contextType === 'teams') {
      return id;
    }

    return undefined;
  }
);
