import {
  PUSHER_JOIN,
  PUSHER_LEAVE,
  PUSHER_INIT_MY_CHANNELS,
  PUSHER_ADD_MY_CHANNEL,
} from '../actions/types';

export default (state = {}, action = {}) => {
  const {
    type,
    channel,
    myTeams,
  } = action;

  switch (type) {
    case PUSHER_JOIN:
      return {
        ...state,
        [channel]: true,
      };

    case PUSHER_LEAVE:
      // eslint-disable-next-line no-case-declarations
      const { [channel]: removedChannel, ...otherChannels } = state;
      return otherChannels;

    case PUSHER_INIT_MY_CHANNELS:
      return {
        ...state,
        myTeams,
      };

    case PUSHER_ADD_MY_CHANNEL:
      return {
        ...state,
        myTeams: [
          ...state.myTeams,
          channel,
        ],
      };
  }

  return state;
};
