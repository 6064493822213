/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import Alert, { AlertProps } from './Alert';

interface WindowAlert extends AlertProps {
  key: number;
}

function Alerts() {
  const [alerts, setAlerts] = useState<WindowAlert[]>([]);
  const [timerIds, setTimerIds] = useState<number[]>([]);

  const addAlert = async (
    body: WindowAlert['body'],
    type: WindowAlert['type'],
    timeout: number
  ) => {
    const key = (new Date()).getTime();
    await setAlerts((currAlerts: WindowAlert[]) => [
      ...currAlerts,
      { key, body, type },
    ]);

    // Set a timer to remove the alert.
    const newTimerId = window.setTimeout(() => {
      setAlerts((currAlerts) => currAlerts.filter((alert) => alert.key !== key));
    }, timeout);
    setTimerIds((currTimerIds: number[]) => [...currTimerIds, newTimerId]);
  };

  const handleNewAlert = ({
    detail: {
      body = '',
      type = 'info',
      timeout = 7500,
    },
  }: CustomEvent) => {
    addAlert(body, type, timeout);
  };

  // Setup dom listeners.
  useEffect(() => {
    /**
     * Because these are customEvents, the event listener function with its
     * `detail` parameter does not match the overload for an EventListener.
     * This is a known issue, @see https://github.com/microsoft/TypeScript/issues/28357
     */
    // @ts-expect-error Wants a function that accepts Event
    document.addEventListener('newalert', handleNewAlert);
    return () => {
      // @ts-expect-error Wants a function that accepts Event
      document.removeEventListener('newalert', handleNewAlert);
    };
  }, []);

  // Clear timerIds on unmount.
  useEffect(() => () => {
    timerIds.forEach((timerId) => clearTimeout(timerId));
  }, []);

  // Dispatch the initial alert if one exists.
  useEffect(() => {
    if ((window as any).flashAlert) {
      const [initBody, initType = 'info'] = (window as any).flashAlert.split('|');
      addAlert(initBody, initType, 7500);
    }
  }, []);

  return (
    <>
      {alerts.map((alert) => <Alert {...alert} />)}
    </>
  );
}

export default Alerts;

const alertContainer = document.getElementById('alert-container');
if (alertContainer) {
  render(<Alerts />, alertContainer);
}
