import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import { Story } from '../../types/interfaces/Story';
import { BacklogTarget } from '../../types/interfaces/BacklogTarget';
import withInstigator from '../../hocs/withInstigator';
import BoardButton from '../BoardButton';

type SprintListProps = {
  /** Backlogs which can accept items. */
  targets: BacklogTarget[],
  /** Action when items have been moved to a target. */
  moveStoriesHandler(
    droppedItems: Story[],
    target: BacklogTarget,
  ): void,
}
interface TProps extends BacklogTarget {
  /** Action when items have been moved to a target. */
  moveStoriesHandler(
    droppedItems: Story[],
    target: BacklogTarget,
  ): void;
}

function Target({
  id,
  isActive = false,
  title,
  total,
  type,
  moveStoriesHandler,
}: TProps) {
  return (
    <div className={classNames('card', 'mb-1', { 'border-primary': isActive })}>
      {isActive && (
      <div className="active-badge">Active</div>
      )}
      <div className={classNames('card-body', 'dropzone-wrapper')}>
        <h4 className="d-flex justify-content-between">
          <Link to={`/${type}/${id}`}>{title}</Link>
          {isActive && (
          // @ts-expect-error BoardButton is a js component and types aren't matching.
          <BoardButton showText={false} />
          )}
        </h4>
        <ReactSortable
          list={[]}
          className="dropzone"
          setList={(stories) => {
            if (stories.length > 0) {
              moveStoriesHandler(stories, {
                type, id, title, total, isActive,
              });
            }
          }}
          animation={300}
          sort={false}
          group="stories"
        />
        <div className="dropzone-label">
          Drag stories here
          {typeof total !== 'undefined' && (
          <p className="h4">
            {total}
            {' '}
            points
          </p>
          )}
        </div>
      </div>
    </div>
  );
}

function SprintList({
  targets,
  moveStoriesHandler,
}: SprintListProps) {
  return (
    <>
      {targets.map((backlogTarget) => (
        withInstigator(Target)({
          ...backlogTarget,
          moveStoriesHandler,
          instigator: backlogTarget.instigator || null,
          key: backlogTarget.id,
        })))}
    </>
  );
}

export default SprintList;
