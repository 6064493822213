import React from 'react';
import { Route, Switch } from 'react-router-dom';
import uuid from 'react-uuid';

import routes from '../../routes';

function Breadcrumbs() {
  return (
    <Switch>
      {routes.map((route) => (
        <Route path={route.path} component={route.breadcrumb} exact={route.exact} key={uuid()} />
      ))}
    </Switch>
  );
}

export default Breadcrumbs;
