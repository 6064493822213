/* eslint-disable no-await-in-loop */
import * as React from 'react';
import Vapor from 'laravel-vapor';
import { useDispatch } from 'react-redux';

import * as actions from '../actions';
import { Attachment } from '../types/interfaces/Attachment';

function useFileUpload(storyId: number) {
  const [uploadProgress, setUploadProgress] = React.useState<number[]>([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const dispatch = useDispatch();

  const setIndividualUploadProgress = (progress: number, index: number) => (
    setUploadProgress((oldProgress) => [
      ...oldProgress.slice(0, index),
      Math.round(progress * 100),
      ...oldProgress.slice(index + 1),
    ])
  );

  const handleUpload = async (files: File[] = []) => {
    setIsUploading(true);
    setUploadProgress(Array(files.length).fill(0));

    const attachmentsData: Array<any> = await Promise.all(
      files.map((file, index) => (
        Vapor.store(file, {
          progress: (progress) => {
            setIndividualUploadProgress(progress, index);
          },
        })
          .then((response) => ({
            uuid: response.uuid,
            key: response.key,
            bucket: response.bucket,
            name: file.name,
            filename: file.name,
            filetype: file.type,
            filesize: file.size,
          }))
          .catch((error) => {
            window.dispatchAlert(`Error uploading ${file.name}}!`, 'danger');
            // eslint-disable-next-line no-console
            console.error(error);
            return null;
          })
      ))
    );

    const attachments = await dispatch(
      actions.bulkAddAttachments(storyId, attachmentsData)
    ) as unknown;

    setIsUploading(false);
    setUploadProgress([]);

    return attachments as Attachment[];
  };

  const calculateTotalProgress = (progresses) => {
    if (progresses.length === 0) {
      return 0;
    }

    return Math.round(
      progresses.reduce((sum, single) => sum + single, 0) / progresses.length
    );
  };

  return {
    handleUpload,
    isUploading,
    uploadProgress: calculateTotalProgress(uploadProgress),
  };
}

export default useFileUpload;
