import React from 'react';
import { ArrowRight } from 'react-bootstrap-icons';
import { Story } from '../../types/interfaces/Story';
import { BacklogTarget } from '../../types/interfaces/BacklogTarget';
import StoryCard from '../Backlog/StoryCard';

export type Props = {
  /** Action to return to the previous menu. */
  changeMethod(): void,
  /** Action which initiated this request. */
  confirmAction(): void;
  /** The items to add to the new backlog. */
  items: Story[];
  /** Target backlog. */
  target: BacklogTarget;
}

export function Capacity({
  items,
  confirmAction,
  changeMethod,
  target,
}: Props) {
  const total = items
    .reduce((sum, { points }) => (sum + (points || 0)), 0);

  return (
    <>
      <h2 className="sr-only">
        Confirm adding story on capacity
      </h2>
      <p className="h2 mb-3">
        Great! Your team is accelerating!
      </p>
      <div className="h1">
        <span className="text-muted">
          {target.total}
          <span className="sr-only">
            points to
          </span>
        </span>
        {' '}
        <ArrowRight />
        {' '}
        <span className="font-weight-bold">
          {target.total + total}
          <span className="sr-only">
            points
          </span>
        </span>
      </div>
      <div>
        <h3 className="h4 mb-2">
          Your team will commit to finishing
          {items.length === 1 ? ' this story:' : ' these stories:'}
        </h3>
        <ol className="story-list backlog-list mb-3">
          {items.map((item) => (
            <StoryCard key={item.id} story={item} />
          ))}
        </ol>
      </div>
      <div>
        <button type="button" onClick={confirmAction} className="btn btn-lg btn-outline-danger">
          Commit
        </button>
        {' '}
        <button type="button" onClick={changeMethod} className="btn btn-lg btn-secondary">
          Go back
        </button>
      </div>
    </>
  );
}

export default Capacity;
