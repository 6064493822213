import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import classnames from 'classnames';

import { getCurrentUserActiveTeam, getTeamMembersForCurrentTeam } from '../../selectors';
import { TEAM_SHAPE, USER_SHAPE } from '../../types/props';

// Components.
import TeamStatus from '../Breadcrumbs/TeamStatus';
import BoardButton from '../BoardButton';

function TeamBar({
  activeTeam,
  isHorizontalBar,
  miniBoardButton,
  pusherState,
  teamMembers,
}) {
  if (!activeTeam?.id) {
    return null;
  }

  const isBoardView = useRouteMatch('/teams/:id/board');

  return (
    <div className={classnames({ container: isHorizontalBar && !isBoardView, 'container-fluid': isHorizontalBar && isBoardView })}>
      <div className="team-bar">
        <div className="active-team-header">
          <h2>{activeTeam.name}</h2>
        </div>

        <TeamStatus teamMembers={teamMembers} pusherState={pusherState} />

        <BoardButton
          showBoardButton={!isBoardView}
          showText={!miniBoardButton}
        />
      </div>
    </div>
  );
}

TeamBar.defaultProps = {
  activeTeam: null,
  pusherState: 'none',
  miniBoardButton: true,
};

TeamBar.propTypes = {
  activeTeam: PropTypes.shape(TEAM_SHAPE),
  isHorizontalBar: PropTypes.bool.isRequired,
  miniBoardButton: PropTypes.bool,
  pusherState: PropTypes.string,
  teamMembers: PropTypes.arrayOf(PropTypes.shape(USER_SHAPE)).isRequired,
};

export default connect(
  (state, ownProps) => {
    const activeTeam = getCurrentUserActiveTeam(state);

    return {
      activeTeam,
      isHorizontalBar: !!ownProps.match,
      teamMembers: getTeamMembersForCurrentTeam(state),
      pusherState: state.ui.pusherState,
    };
  }
)(TeamBar);
