/**
 * Add a team as a contributor to a project.
 *
 * @param {number} projectId - The ID of the project.
 * @param {number} teamId - The ID of the team to add.
* @returns {Promise} Promise object representing the API request.
 */
const addTeamToProject = (projectId, teamId) => axios.post(`/api/project/${projectId}/teams/${teamId}`);

export default addTeamToProject;
