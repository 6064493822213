import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import StoryFilters from '../StoryFilters';
import truthyFilterArray from '../../utils/truthyFilterArray';
import { filterStories } from '../../services/storyFilters';
import NewStory from '../Backlog/NewStory';
import { STORY_SHAPE } from '../../types/props';
import Backlog from '../Backlog';

function FilteredBacklog({
  isFiltered,
  stories,
  backlogType,
  backlogId,
  reorderStoriesHandler,
  addStoryHandler,
}) {
  return (
    <>
      {stories.length > 0 ? (
        <StoryFilters
          key={`backlog.${backlogType}.${backlogId}`}
          allStories={stories}
        />
      ) : null}

      {/* key here is necessary to re-render SortableJS */}
      <Backlog
        stories={stories}
        reorderStoriesHandler={reorderStoriesHandler}
        sortable={!isFiltered}
        key={`backlog.${backlogType}.${backlogId}.${isFiltered}`}
        backlogType={backlogType}
        backlogId={backlogId}
        showRelationshipFlags
      />

      {!isFiltered ? (
        <NewStory addStorySubmit={addStoryHandler} />
      ) : null}
    </>
  );
}

FilteredBacklog.propTypes = {
  isFiltered: PropTypes.bool.isRequired,
  stories: PropTypes.arrayOf(PropTypes.shape(STORY_SHAPE)).isRequired,
  addStoryHandler: PropTypes.func.isRequired,
  reorderStoriesHandler: PropTypes.func.isRequired,
  backlogId: PropTypes.number.isRequired,
  backlogType: PropTypes.string.isRequired,
};

export default connect(({ storyFilters }, { stories }) => ({
  stories: filterStories(storyFilters, truthyFilterArray(stories)),
  isFiltered: storyFilters.length !== 0,
}))(FilteredBacklog);
