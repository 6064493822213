import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { TEAM_SHAPE } from '../../types/props';
import * as uri from '../../utils/uri';
import withInstigator from '../../hocs/withInstigator';

const props = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape(TEAM_SHAPE)),
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

function Project({
  id,
  title,
  teams,
  className = '',
  children = null,
}) {
  return (
    <div className="col mb-4">
      <div className={classNames(className, 'card')}>
        <div className="card-body">
          <h2 className="card-title h4">
            <Link to={uri.project(id)}>
              {title}
            </Link>
          </h2>
          {
          teams.length > 0 ? (
            <div>
              <h3 className="sr-only" id={`teams-${id}`}>
                Contributing teams
              </h3>
              <ul aria-labelledby={`teams-${id}`} className="d-flex m-0 p-0 flex-wrap">
                {teams.map((team) => (
                  <li
                    key={team.id}
                    className="badge badge-secondary mb-1 mr-1 d-inline-flex"
                    style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
                  >
                    {team.name}
                  </li>
                ))}
              </ul>
            </div>
          ) : null
      }
        </div>
        {children}
      </div>
    </div>
  );
}

Project.propTypes = props;

export default withInstigator(Project);
