import React from 'react';
import { batch } from 'react-redux';
import { push } from 'connected-react-router';
import { GraphDown, FlagFill } from 'react-bootstrap-icons';

import { ADD_ENTITY, DELETE_ENTITY, MERGE_ENTITY_CHANGES } from '../types';
import getUserById from '../../selectors/getUserById';
import * as uri from '../../utils/uri';
import { setInstigator } from './member';
import {
  receiveEntity,
  setEntity,
  unsetEntity,
} from '../entity';

/**
 * Dispatch an action to add a sprint to state.
 *
 * @param {object} sprint - Payload from API response.
 * @param {number} teamId - Sprint's team ID.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const sprintAdded = (sprint, teamId, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch({
      type: ADD_ENTITY,
      entity: { type: 'sprints', data: sprint },
      to: { type: 'teams', id: teamId, key: 'open_sprints' },
    });
    dispatch(setInstigator({ type: 'sprints', id: sprint.id }, instigatorId));
  });
};

/**
 * Dispatches an action to update the sprint in state.
 *
 * @param {object} sprint - Payload from API response.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const sprintChanged = (sprint, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: 'sprints', id: sprint.id },
      changes: sprint,
    });
    dispatch(setInstigator({ type: 'sprints', id: sprint.id }, instigatorId));
  });
};

/**
 * Dispatches an action to delete a sprint from state.
 *
 * @param {number} id - The sprint ID.
 */
export const sprintDeleted = (sprintId, teamId, instigatorId) => (dispatch, getState) => {
  // eslint-disable-next-line no-restricted-globals
  const isOnDeletedSprint = location.pathname === uri.sprint(sprintId);

  batch(() => {
    if (isOnDeletedSprint) {
      dispatch(push(uri.team(teamId)));
    }
    dispatch({
      type: DELETE_ENTITY,
      entity: { type: 'sprints', id: sprintId },
      from: { type: 'teams', id: teamId, key: 'open_sprints' },
    });
  });
  const user = getUserById(getState(), instigatorId);
  if (user) {
    dispatchAlert(`Sprint ${sprintId} deleted by ${user.name}.`, 'success');
  }
};

/**
 * Dispatch an action when a sprint has been started.
 *
 * @param {object} sprint - Payload from API response.
 * @param {number} teamId - Sprint's team ID.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const sprintStarted = (sprint, teamId, instigatorId) => (dispatch, getState) => {
  batch(() => {
    dispatch(receiveEntity('sprints', sprint));
    dispatch(setEntity({
      entity: { id: sprint.id, type: 'sprints' },
      set: { type: 'teams', id: teamId, key: 'current_sprint' },
    }));
    dispatch(setInstigator({ type: 'sprints', id: sprint.id }, instigatorId));
  });
  const user = getUserById(getState(), instigatorId);
  const message = user ? `Sprint ${sprint.title} started by ${user.name}` : `Sprint ${sprint.title} ended.`;
  dispatchAlert(
    (
      <div>
        <GraphDown />
        {' '}
        {message}
      </div>
    ), 'success'
  );
};

/**
 * Dispatch an action when a sprint has ended.
 *
 * @param {object} sprint - Payload from API response.
 * @param {number} teamId - Sprint's team ID.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const sprintEnded = (sprint, teamId, instigatorId) => (dispatch, getState) => {
  batch(() => {
    // @todo good candidate for a refactor with the sprint end action in the sprints module.
    dispatch(receiveEntity('sprints', sprint));
    dispatch(unsetEntity({
      entity: { id: sprint.id, type: 'sprints' },
      unset: { type: 'teams', id: teamId, key: 'current_sprint' },
    }));
    dispatch(unsetEntity({
      entity: { id: [sprint.id], type: 'sprints' },
      unset: { type: 'teams', id: teamId, key: 'open_sprints' },
    }));
    dispatch({
      type: DELETE_ENTITY,
      entity: { type: 'boards', id: teamId },
    });
  });

  const user = getUserById(getState(), instigatorId);
  const message = user ? `Sprint ${sprint.title} ended by ${user.name}` : `Sprint ${sprint.title} ended.`;
  dispatchAlert(
    (
      <div>
        <FlagFill />
        {' '}
        {message}
      </div>
    ), 'success'
  );
};
