/**
 * Create a new Team Story.
 *
 * @param {number} teamId - Team ID.
 * @param {Object} story - Story data.
 * @param {string} story.title - Story title.
 * @param {number} [story.points] - Story points.
 * @param {string} [story.body] - Story body.
 * @param {number} [story.sprint_id] - Sprint ID.
 * @param {?string} [story.status] - Story status, either null or "done".
 * @returns {Promise} Promise object representing the API request.
 */
const createTeamStory = (teamId, story) => axios.post(`/api/teams/${teamId}/stories`, story);
export default createTeamStory;
