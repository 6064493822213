import * as React from 'react';
import PropTypes from 'prop-types';
import { ReactSortable } from 'react-sortablejs';
import useAppendableList from '../../hooks/useAppendableList';

import { STORY_SHAPE } from '../../types/props';

import Card from './Card';
import BoardBurndown from './BoardBurndown';

function BoardColumn({
  teamId,
  id,
  title,
  status = '',
  stories,
  updateHandler,
}) {
  const {
    list,
    setList,
    setListByAppending,
  } = useAppendableList(stories, ['id', 'filtered', 'pusherActivity']);

  /**
   * Remove classNames added by ReactSortable callback in order to hack
   * CSS's lack of a previous element selector.
   */
  const removeHighlightStyles = () => {
    [...document.getElementsByClassName('highlight')]
      .forEach((el) => el.classList.remove('highlight'));
  };

  /**
   * Change handler for ReactSortable list.
   *
   * @param {HTMLElement} param.item - Dragged element.
   * @param {HTMLElement} param.from - Previous list.
   * @param {number} param.oldIndex - Dragged element's old index within previous parent.
   * @param {number} param.newIndex - Dragged element's new index within the new parent.
   */
  const onChange = ({
    item,
    from,
    oldIndex,
    newIndex,
  }) => {
    const { storyId } = item.dataset;
    const fromColumnId = from.id.replace('column-id-', '');

    updateHandler({
      storyId: +storyId,
      toColumnId: id,
      fromColumnId: +fromColumnId,
      oldIndex,
      newIndex,
    });

    removeHighlightStyles();
  };

  /**
   * Toggle classNames for elements related to the move.
   * @param {CustomEvent} e - ReactSortable onMove custom event.
   */
  const onMove = (e) => {
    // Remove existing highlight styles.
    removeHighlightStyles();

    // Add highlight class to adjacent card.
    e.related.classList.add('highlight');

    // Add highlight class to target column.
    e.to.classList.add('highlight');
    return true;
  };

  /**
   * Force adding an element to the done column to the end of the list, no
   * matter where it is dropped.
   */
  const onAddToDone = ({
    item,
    from,
    oldIndex,
  }) => {
    const newIndex = list.length;
    onChange({
      item, from, oldIndex, newIndex,
    });
  };

  const columnTotal = list.reduce((carry, story) => carry + story.points, 0);

  return (
    <div className="board-column">
      <h2>
        {title}
        {' '}
        <span className="badge badge-secondary">{columnTotal}</span>
      </h2>
      <div className="card-body">
        {(status === 'done') ? (
          <div className="mb-3">
            <BoardBurndown teamId={teamId} />
          </div>
        ) : null}
        <ReactSortable
          animation={300}
          className={`story-list column-${status}`}
          group="stories"
          id={`column-id-${id}`}
          list={list}
          onAdd={status === 'done' ? onAddToDone : onChange}
          onMove={onMove}
          onUnchoose={removeHighlightStyles}
          onUpdate={onChange}
          setList={status === 'done' ? setListByAppending : setList}
          sort={status !== 'done'}
          tag="ol"
        >
          {list.map((story) => (
            <Card
              key={story.id}
              story={story}
              teamId={teamId}
              pusherActivity={story.pusherActivity}
            />
          ))}
        </ReactSortable>
      </div>
    </div>
  );
}

BoardColumn.propTypes = {
  teamId: PropTypes.number.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  stories: PropTypes.arrayOf(PropTypes.shape(STORY_SHAPE)).isRequired,
  updateHandler: PropTypes.func.isRequired,
};

export default BoardColumn;
