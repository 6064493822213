import { batch } from 'react-redux';

import { receiveEntity } from '../entity';
import { setInstigator } from './member';

export const projectChanged = (project, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch(receiveEntity('projects', project));
    dispatch(setInstigator({ type: 'projects', id: project.id }, instigatorId));
  });
};

export default projectChanged;
