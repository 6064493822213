import React, { useState, useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import Select from 'react-dropdown-select';

import { makeGetBacklog } from '../../modules/backlogEntity';
import { Story } from '../../types/interfaces/Story';
import { BacklogTarget } from '../../types/interfaces/BacklogTarget';
import * as actions from '../../actions';

import StoryCard from '../Backlog/StoryCard';
import Loading from '../Loading';

type OwnProps = {
  /** Go back to the resolution paths screen. */
  changeMethod(): void;
  /** Action which initiated this dialog. */
  confirmAction(): void;
  /** Items to move to the target backlog. */
  items: Story[];
  /** Target backlog. */
  target: BacklogTarget;
};

const mapState = (state, { target: { id } }: OwnProps) => ({
  backlogEntity: makeGetBacklog({ id, contextType: 'sprints' })(state),
});

const mapDispatch = {
  updateStory: actions.updateStory,
  loadStories: actions.loadStories,
};

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type Props = PropsFromRedux & OwnProps;

export function TakeBuffer({
  backlogEntity,
  changeMethod,
  confirmAction,
  items,
  loadStories,
  updateStory,
}: Props) {
  const total = items.reduce((acc, { points }) => (acc + (points || 0)), 0);
  const [
    selection,
    setSelection,
  ] = useState<{ value: number; label: string }[]>([]);

  // When loading a backlog directly, prevent the api request.
  const [initializing, setInitializing] = useState(true);

  // Loading state for <Loading />.
  const [loading, setLoading] = useState(false);

  if (!backlogEntity) {
    return (<div>There was an error</div>);
  }

  useEffect(() => {
    if (!initializing || backlogEntity.stories.length === 0) {
      setLoading(true);
      loadStories(backlogEntity)
        .then(() => setLoading(false));
    }
    setInitializing(false);
  }, []);

  const buffers = useMemo(() => (
    backlogEntity.stories
      .filter(({ title }) => /buffer/gi.test(title))
  ), [backlogEntity.stories]);

  const selectedBuffer = useMemo(() => {
    if (selection.length < 1) {
      return false;
    }

    const [{ value }] = selection;
    return buffers.find(({ id }) => id === value) || false;
  }, [selection]);

  const newBufferValue = selectedBuffer ? ((selectedBuffer.points || 0) - total) : undefined;
  const hasEnoughBuffer = (newBufferValue || 0) >= 0;

  /**
   * Action handler for completing the take buffer action.
   */
  const consumeBuffer = () => {
    // Move initial story into sprint.
    confirmAction();

    // Update selected buffer with new value.
    if (typeof newBufferValue !== 'number' || !selectedBuffer) {
      dispatchAlert('Could not deduct from the buffer', 'error');
      return;
    }

    const points = newBufferValue > 0 ? newBufferValue : 0;
    updateStory(selectedBuffer, { points });
    dispatchAlert(`${selectedBuffer.title} is now ${points}.`, 'info');
  };

  const bufferOptions = buffers
    .map((item) => ({
      label: `(${typeof item.points === 'number' ? item.points : '-'}) ${item.title}`,
      value: item.id as unknown as number,
    }));

  return (
    <div>
      <h2 className="sr-only">
        Deduct these points from the buffer
      </h2>
      <p className="form-text text-muted mb-3">
        A buffer is meant to absorb urgent work. This can be newly discovered
        scope in a committed ticket, or urgent priorities raised by a stakeholder
        mid-sprint that can&apos;t be offset by existing priorities. A buffer can
        also be used to accommodate anticipated shifting or unclear priorities at
        the beginning of the sprint.
      </p>
      <p className="blockquote">
        You are bringing in
        {' '}
        <span className="badge badge-danger">{total}</span>
        {' '}
        points.
      </p>
      <ol className="story-list backlog-list mb-3">
        {items.map((item) => (
          <StoryCard key={item.id} story={item} />
        ))}
      </ol>
      <p className="blockquote">
        Which buffer would you like to deduct from?
      </p>
      <div className="mb-3">
        {loading ? <Loading /> : (
          <Select
            values={selection}
            onChange={setSelection}
            onDropdownOpen={() => setSelection([])}
            options={bufferOptions}
          />
        )}
      </div>
      {(selectedBuffer && !hasEnoughBuffer) ? (
        <div className="alert alert-danger mb-3" role="alert" aria-live="polite">
          The selected buffer does not have enough points.
        </div>
      ) : null}
      {(selectedBuffer && hasEnoughBuffer) ? (
        <div className="alert alert-light mb-3" role="alert" aria-live="polite">
          <span className="strong">{selectedBuffer.title}</span>
          {' '}
          will be updated to
          {' '}
          <span className="badge badge-dark">{newBufferValue}</span>
          {' '}
          points.
        </div>
      ) : null}
      <div>
        {selectedBuffer ? (
          <button type="button" onClick={consumeBuffer} className="btn btn-outline-danger">
            Consume buffer
          </button>
        ) : null}
        {' '}
        <button type="button" onClick={changeMethod} className="btn btn-secondary">
          Go back
        </button>
      </div>
    </div>
  );
}

export default connector(TakeBuffer);
