import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { push as dispatchPush } from 'connected-react-router';

import { BOARD_COLUMN_SHAPE } from '../../types/props';
import * as actions from '../../actions';
import * as uri from '../../utils/uri';
import { selectHydrated as selectBoard } from '../../modules/board';

import FilteredBoard from './FilteredBoard';
import Loading from '../Loading';

function SprintBoard({
  board,
  getBoard,
  hasActiveSprint,
  joinTeamChannel,
  joinBoardChannel,
  push,
  teamId,
  teamName,
  updateBoard,
}) {
  useEffect(() => {
    async function fetchData() {
      // Wait for the API request,
      const response = await getBoard(teamId);

      // See if it successfully dispatched the action. If not, redirect.
      if (response?.type !== 'RECEIVE_ENTITY') {
        dispatchAlert('Could not find a team board, redirecting to the team backlog', 'info');
        push(uri.team(teamId));
      }
    }

    if (hasActiveSprint) {
      fetchData();
    } else {
      dispatchAlert('There is no active sprint, redirecting to the team backlog', 'info');
      push(uri.team(teamId));
    }
  }, [teamId, hasActiveSprint]);

  // Join the team and board channels and leave when unmounting.
  useEffect(() => {
    const unsubs = [
      joinTeamChannel(teamId),
      joinBoardChannel(teamId),
    ];
    return () => {
      unsubs.forEach((leave) => {
        if (typeof leave === 'function') {
          leave();
        }
      });
    };
  }, [teamId]);

  return (
    <>
      <Helmet>
        <title>
          {`${teamName} sprint board`}
        </title>
      </Helmet>
      <main className="container-fluid board-screen">
        {typeof board?.columns === 'undefined' ? (
          <Loading />
        ) : (
          <FilteredBoard
            teamId={teamId}
            updateBoard={updateBoard}
            columns={board.columns}
          />
        )}
      </main>
    </>
  );
}

SprintBoard.propTypes = {
  board: PropTypes.shape({
    columns: PropTypes.arrayOf(
      PropTypes.shape(BOARD_COLUMN_SHAPE)
    ),
  }).isRequired,
  getBoard: PropTypes.func.isRequired,
  hasActiveSprint: PropTypes.bool.isRequired,
  joinTeamChannel: PropTypes.func.isRequired,
  joinBoardChannel: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  teamId: PropTypes.number.isRequired,
  teamName: PropTypes.string.isRequired,
  updateBoard: PropTypes.func.isRequired,
};

export default connect(
  (state, ownProps) => {
    const teamId = +(ownProps?.match?.params?.id || 0);
    const teamName = state?.data?.entities?.teams?.[teamId]?.name || '';
    return ({
      teamId,
      teamName,
      board: selectBoard(state, ownProps.match.params.id) || {},
      hasActiveSprint: !!(state?.data?.entities?.teams?.[teamId]?.current_sprint),
    });
  },
  {
    getBoard: actions.getBoard,
    joinTeamChannel: actions.joinTeamChannel,
    joinBoardChannel: actions.joinBoardChannel,
    push: dispatchPush,
    updateBoard: actions.updateBoard,
  }
)(SprintBoard);
