import * as React from 'react';
import unionBy from 'lodash/unionBy';
import UserList from '../../UserList';
import withInstigator from '../../../hocs/withInstigator';
import { Member } from '../../../types/interfaces/Member';
import usePendingRequest from '../../../hooks/usePendingRequest';

interface ContributorPaneProps {
  addStoryContributor: (userId: number) => Promise<void>;
  contributors?: Member[];
  currentUserId: number;
  removeStoryContributor: (userId?: number) => Promise<void>;
  teamMembers?: Member[];
  className?: string;
  children?: React.ReactNode;
}

function ContributorPane({
  contributors = [],
  removeStoryContributor,
  addStoryContributor,
  currentUserId,
  teamMembers = [],
  className = '',
  children = null,
}: ContributorPaneProps) {
  const isCurrentContributor = contributors.find(({ id }) => id === currentUserId);
  const { isPending, call } = usePendingRequest();
  const teammates = unionBy(teamMembers, contributors, 'id').filter(({ id }) => id !== currentUserId);
  const isContributor = (userId: number) => contributors.find(({ id }) => id === userId);

  // Function generator to handle link clicks.
  const handleClick = (callback) => (e) => {
    e.preventDefault();
    call(callback);
  };

  return (
    <div className={`card mb-3 ${className}`}>
      <div className="card-body story-contributors">
        <UserList
          headingClassName="h5"
          headingLevel={2}
          label="Contributors"
          users={contributors}
        />

        <div className="btn-group">
          {isCurrentContributor ? (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={handleClick(() => removeStoryContributor(currentUserId))}
              disabled={isPending}
            >
              Remove me
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={handleClick(() => addStoryContributor(currentUserId))}
              disabled={isPending}
            >
              Add me
            </button>
          )}

          {teammates.length ? (
            <>
              <button
                type="button"
                className="btn btn-primary btn-sm dropdown-toggle dropdown-toggle-split"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="sr-only">View Teammates</span>
              </button>
              <div className="dropdown-menu dropdown-menu-right">
                {teammates.map((user) => (
                  isContributor(user.id) ? (
                    <button
                      type="button"
                      key={user.id}
                      className="dropdown-item"
                      onClick={handleClick(() => removeStoryContributor(user.id))}
                    >
                      {`Remove ${user.name}`}
                    </button>
                  ) : (
                    <button
                      type="button"
                      key={user.id}
                      className="dropdown-item"
                      onClick={handleClick(() => addStoryContributor(user.id))}
                    >
                      {`Add ${user.name}`}
                    </button>
                  )
                ))}
              </div>
            </>
          ) : null}
        </div>
      </div>
      {children}
    </div>
  );
}

export default withInstigator(ContributorPane);
