/* eslint-disable import/prefer-default-export */
import React from 'react';
import { batch } from 'react-redux';
import { TagFill } from 'react-bootstrap-icons';

import { receiveEntity, addEntity } from '../entity';
import getUserById from '../../selectors/getUserById';

const STATE_KEY = 'features';

/**
 * Dispatch an action when a pusher event is recorded for when a feature has
 * been added.
 *
 * @param {object} feature - Payload of an added feature.
 * @param {'teams'|'projects'} contextType - Context of feature.
 * @param {number} contextId - ID of context.
 * @param {number} instigatorId - User ID of the person who added the feature.
 */
export const featureAdded = (feature, contextType, contextId, instigatorId) => (
  (dispatch, getState) => {
  // Dispatch actions to update state.
    batch(() => {
      dispatch(receiveEntity(STATE_KEY, feature));
      dispatch(addEntity({
        entity: { type: 'features', data: feature },
        to: { type: contextType, id: contextId, key: 'features' },
      }));
    });

    const state = getState();

    // Get entity for alert.
    const entity = state?.data?.entities?.[contextType]?.[contextId];
    if (!entity) {
      return;
    }

    // Get user name for alert.
    const user = getUserById(state, instigatorId);
    if (!user) {
      return;
    }

    dispatchAlert(
      (
        <span>
          <TagFill />
          {' Feature '}
          <strong>{feature.title}</strong>
          {` added to ${entity.name || entity.title} by ${user.name}`}
        </span>
      ),
      'info'
    );
  }
);
