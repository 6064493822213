import * as React from 'react';
import PropTypes from 'prop-types';
import isDateRecent from '../../utils/isDateRecent';

function NewFlag({ createdAt }) {
  return createdAt && isDateRecent(createdAt)
    ? (
      <>
        <span className="badge badge-danger mr-1">New</span>
        {' '}
      </>
    ) : null;
}

NewFlag.propTypes = {
  createdAt: PropTypes.string.isRequired,
};

export default NewFlag;
