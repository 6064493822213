/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState, useEffect, useRef, MouseEvent,
} from 'react';
import Webhook from './Webhook';
import * as api from '../../services/api';

/* eslint-disable babel/camelcase */
type Webhook = {
  context_id?: number;
  context_type?: string;
  created_at?: string;
  id: number|string;
  updated_at?: string;
  url: string;
  user_id?: number;
};
/* eslint-enable babel/camelcase */

export type Props = {
  teamId: number;
}

function Webhooks({
  teamId,
}: Props) {
  const [webhooks, setWebhooks] = useState([] as Webhook[]);
  const [tempKeyCount, setTempKeyCount] = useState(0);
  const formEl = useRef<HTMLFormElement>(null);

  /**
   * Request the webhooks to view/edit on load.
   */
  useEffect(() => {
    const error = () => dispatchAlert(
      'There was an error fetching the webhooks',
      'danger'
    );

    api.getTeamWebhooks(teamId)
      .then((response) => {
        if (response.status !== 200) {
        // eslint-disable-next-line no-console
          console.error?.(`Unexpected response, ${response}`);
          error();
        } else {
          setWebhooks(response.data);
        }
      })
      .catch(error);
  }, []);

  /**
   * Update the component webhooks' internal state.
   */
  const updateField = (newWebhook: { id: string|number; url: string; }) => {
    const updatedWebhooks = webhooks.map((webhook) => {
      if (webhook.id === newWebhook.id) {
        return newWebhook;
      }
      return webhook;
    });
    setWebhooks(updatedWebhooks);
  };

  /**
   * Delete a webhook from internal state and send request to delete the
   * webhook from the server.
   */
  const deleteWebhook = (id: string|number) => {
    const updateState = () => {
      const updatedWebhooks = webhooks.filter((webhook) => webhook.id !== id);
      setWebhooks(updatedWebhooks);
    };
    if (typeof id === 'number') {
      // Hook exists; send API request to delete the webhook.
      api.deleteWebhook(id)
        .then(() => {
          dispatchAlert('Webhook deleted', 'success');
          updateState();
        })
        .catch(() => dispatchAlert('Could not delete webhook', 'danger'));
    } else {
      // Remove temp field from state only.
      updateState();
    }
  };

  /**
   * Add a new field to update the internal webhooks' state.
   */
  const addNewField = () => {
    // Add a new field to edit a webhook.
    setWebhooks([
      ...webhooks,
      {
        id: `temp-${tempKeyCount}`,
        url: '',
      },
    ]);

    // Increment the temp key counter.
    setTempKeyCount(tempKeyCount + 1);
  };

  const saveWebhooks = (e: MouseEvent) => {
    e.preventDefault();

    // Validate form
    if (!formEl?.current?.checkValidity()) {
      formEl?.current?.reportValidity();
      return;
    }

    // Send API requests to create or update fields.
    Promise.all(
      webhooks.map(({ id, url }) => {
        if (typeof id === 'string') {
          return api.createTeamWebhook(teamId, url);
        }
        return api.updateWebhook(id, { url });
      })
    )
      .then((response) => {
        // Update state and confirm response.
        const updatedWebhooks = response
          .map(({ data }) => data);
        setWebhooks(updatedWebhooks);
        dispatchAlert('Webhooks updated successfully', 'success');
      })
      .catch((error) => {
        dispatchAlert('Could not update webhooks', 'danger');
        // eslint-disable-next-line no-console
        console.error(error);
      });
  };

  return (
    <div className="card mt-3">
      <h2 className="card-header">
        Webhooks
      </h2>
      <form className="card-body" ref={formEl}>
        <ul className="list-group list-group-flush">
          {webhooks.map(({ url, id }) => (
            <li key={id} className="list-group-item pl-0">
              <Webhook
                id={id}
                webhook={url}
                updateHandler={updateField}
                deleteHandler={() => deleteWebhook(id)}
              />
            </li>
          ))}
        </ul>
        <div className="mb-3 mt-1">
          <button className="btn btn-secondary" onClick={addNewField} type="button">
            Add Webhook
          </button>
        </div>
        <button
          className="btn btn-primary mb-1 mt-3"
          onClick={saveWebhooks}
          type="submit"
        >
          Save Changes
        </button>
      </form>
    </div>
  );
}

export default Webhooks;
