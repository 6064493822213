/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { batch } from 'react-redux';
import { push } from 'connected-react-router';
import { normalize } from 'normalizr';
import axios from 'axios';

import { BacklogEntity } from '../types/interfaces/BacklogEntity';
import { BacklogType } from '../types/BacklogType';
import { Story } from '../types/interfaces/Story';
import { appSchema, entitySchema } from '../types';
import removeItemFromList from '../utils/removeItemFromList';
import reorderList from '../utils/reorderList';
import getReorderedItems from '../utils/getReorderedItems';
import * as uri from '../utils/uri';

import {
  ADD_ENTITY,
  DELETE_ENTITY,
  INITIALIZE_APP_DATA,
  MERGE_ENTITY_CHANGES,
  MOVE_STORY,
  RECEIVE_ENTITY,
  SET_ENTITY_ORDER,
  SET_UI_FLAG,
  TYPE_MAP,
  UPDATE_ENTITY,
  SET_ENTITY,
  UNSET_ENTITY,
} from './types';

// Normal actions.
export const setUiFlag = (flag: any): any => ({
  type: SET_UI_FLAG,
  flag,
});

/**
 * Receive an entity and normalize its data.
 *
 * @param {string} entityType - Entity schema key.
 * @param {Object} entity - Changes to merge.
 */
export const receiveEntity = (entityType, entity) => {
  const data = normalize(entity, entitySchema[entityType]);
  return {
    type: RECEIVE_ENTITY,
    data,
  };
};

/**
 * Receive an array of entities of the same type at once and normalize their
 * data.
 *
 * @param {string} entityType - Type of entity being received.
 * @param {array} entities - Entities to add to state.
 */
export const receiveManyEntities = (entityType, entities) => {
  const data = normalize(entities, [entitySchema[entityType]]);
  return {
    type: RECEIVE_ENTITY,
    data,
  };
};

export const setEntityOrder = (entities, target, key) => ({
  type: SET_ENTITY_ORDER,
  order: entities.map((item) => item.id),
  target,
  key,
});

/**
 * Initialize the application.
 *
 * @param {Object} appData - Application data to normalize and dispatch.
 */
export const initializeApp = (appData) => (dispatch) => {
  const data = normalize(appData, appSchema);
  batch(() => {
    dispatch({
      type: INITIALIZE_APP_DATA,
      data,
    });
    dispatch({
      type: SET_UI_FLAG,
      flag: { hasInitialized: true },
    });
  });
};

/**
 * Builds object to provide to dispatch add entity event.
 *
 * @param {Object} param - Options for dispatching the ADD_ENTITY action.
 * @param {Object} param.entity - What will be updated.
 * @param {string} param.entity.type - Key to match which entity is updated.
 * @param {Object} param.entity.data - Payload.
 * @param {Object} param.to - The target to add the entity context.
 * @param {string} param.to.type - The contextType of the target.
 * @param {number} param.to.id - The ID of the target.
 * @param {string} param.to.key - The property of the target associated with the entity.
 */
export const addEntity = ({ entity, to }) => ({
  type: ADD_ENTITY,
  entity,
  to,
});

/**
 * Builds object to dispatch to set an entity reference.
 *
 * @param {Object} param - Options for dispatching the SET_ENTITY action.
 * @param {Object} param.entity - Referenced (child) entity.
 * @param {string} param.entity.type - Entity type.
 * @param {number|string} param.entity.id - Entity ID.
 * @param {Object} param.set - Target (parent) storing the reference.
 * @param {string} param.set.type - The type of the target.
 * @param {number|string} param.set.id - The ID of the target.
 * @param {string} param.set.key - The property of the target referencing the entity.
 */
export const setEntity = ({ entity, set }) => ({
  type: SET_ENTITY,
  entity,
  set,
});

/**
 * Builds object to dispatch to unset an entity reference.
 */
export const unsetEntity = ({
  entity,
  unset,
}: {
    /** Referenced (child) entity. */
    entity: {
      type?: string;
      id?: number | string | number[];
    },
    /** Target (parent) storing the reference. */
    unset: {
      type?: string;
      id?: number|string;
      key?: string;
    }
}) => ({
  type: UNSET_ENTITY,
  entity,
  unset,
});

// Backlog Actions.

export const deleteEntity = (entity) => (dispatch) => {
  if (entity.backlog_stories.length > 0) {
    dispatchAlert('You must remove all stories from this backlog first.', 'warning');
    return;
  }
  if (entity.contextType === 'teams' && entity.open_sprints.length > 0) {
    dispatchAlert('You must remove all open sprints from this team first.', 'warning');
    return;
  }
  axios.delete(entity.load_stories_endpoint)
    .then(() => {
      if (entity.backlogType === 'sprints') {
        batch(() => {
          dispatch(push(uri.team(entity.team_id)));
          dispatch({
            type: DELETE_ENTITY,
            entity: { type: entity.backlogType, id: entity.id },
            from: { type: 'teams', id: entity.team_id, key: 'open_sprints' },
          });
        });
        dispatchAlert(`Sprint ${entity.title} deleted`, 'success');
      } else {
        // hard redirect to listing for teams or projects
        // eslint-disable-next-line no-restricted-globals
        location.href = `/${entity.backlogType}`;
      }
    });
};

export const reorderStories = (storyId, operation, oldIndex, newIndex, target) => (dispatch) => {
  const setStoryOrder = (order) => dispatch(
    setEntityOrder(order, target, 'backlog_stories')
  );
  const oldOrder = target.stories;
  const newOrder = (operation === 'remove')
    ? removeItemFromList(oldOrder, oldIndex)
    : reorderList(oldOrder, oldIndex, newIndex);
  if (oldOrder.length > newOrder.length) {
    setStoryOrder(newOrder);
    return;
  }
  const changedStories = newOrder
    .map((item, index) => ({ index, id: item.id }))
    .filter((item, index) => oldOrder[index].id !== item.id);

  if (changedStories.length) {
    axios.put('/api/backlog', {
      type: TYPE_MAP[target.backlogType],
      id: target.id,
      stories: changedStories,
      initiator: {
        story_id: storyId,
        old_index: oldIndex,
        new_index: newIndex,
      },
    })
      .then(() => {
        setStoryOrder(newOrder);
      });
  }
};

export const moveStory = (
  item: Story,
  toType: BacklogType,
  toId: number,
  backlog: BacklogEntity
) => (dispatch, _getState, { api }) => (
  api.moveStoryBacklog({
    story: item.id,
    from_type: TYPE_MAP[backlog.backlogType],
    from_id: backlog.backlogId,
    to_type: TYPE_MAP[toType],
    to_id: toId,
  }).then(({ data }) => {
    dispatch({
      type: MOVE_STORY,
      story: data,
      storyPoints: item.points,
      to: { type: toType, id: toId },
      from: { type: backlog.backlogType, id: backlog.backlogId },
    });

    return data;
  })
);

export const moveStories = (
  items: Story[],
  toType: BacklogType,
  toId: number,
  backlog: BacklogEntity
) => (dispatch) => items.map((item) => dispatch(moveStory(item, toType, toId, backlog)));

// Story view actions

export const updateStory = (
  story: Story,
  changes: any
) => (dispatch: any) => axios
  .put(story.api_url as string, changes)
  .then(() => dispatch({
    type: MERGE_ENTITY_CHANGES,
    entity: { type: 'stories', id: story.id },
    changes,
  }))
  .catch(() => dispatchAlert('Error saving story', 'danger'));

export const addTask = (id:number, task:string) => (dispatch, _getState, { api }) => (
  api.createTask(id, task)
    .then(({ data }) => dispatch({
      type: ADD_ENTITY,
      to: { type: 'stories', id },
      entity: { type: 'tasks', data },
    }))
    .catch(() => dispatchAlert('Error adding task', 'danger'))
);

export const updateTask = (id:number, body:string, completed:boolean) => (
  (dispatch, _getState, { api }) => api.updateTask(id, { body, completed })
    .then(({ data }) => dispatch({
      type: UPDATE_ENTITY,
      entity: { type: 'tasks', data },
    }))
    .catch(() => dispatchAlert('Error saving task', 'danger'))
);

export const deleteTask = (storyId:number, taskId:number) => (
  (dispatch, _getState, { api }) => api.deleteTask(taskId)
    .then(() => dispatch({
      type: DELETE_ENTITY,
      entity: { type: 'tasks', id: taskId },
      from: { type: 'stories', id: storyId, key: 'tasks' },
    }))
    .catch(() => dispatchAlert('Error deleting task! Reload the page and try again.'))
);

export const updateTaskOrder = (story, newOrder, initiator) => (
  (dispatch, _getState, { api }) => {
    const changedTasks = getReorderedItems(newOrder, story.tasks);
    const setOrder = (order) => dispatch({
      type: SET_ENTITY_ORDER,
      target: { contextType: 'stories', id: story.id },
      key: 'tasks',
      order: order.map((item) => item.id),
    });
    const error = () => dispatchAlert(
      'There was an error updating the tasks, refresh your browser window and try again',
      'danger'
    );
    if (changedTasks.length) {
      api.reorderTasks(story.id, changedTasks, initiator)
        .then((response) => {
          if (response.status !== 204) {
            // eslint-disable-next-line no-console
            console.error?.('Unexpected response:', response);
            error();
          }
        })
        .catch(error);
    }
    setOrder(newOrder);
  }
);

export const updateTeamConfig = ({ board, teamId }) => (dispatch, _getState, { api }) => {
  const error = () => dispatchAlert(
    'There was an error updating the team config, refresh your browser window and try again',
    'danger'
  );

  api.updateTeamConfig(teamId, { board })
    .then((response) => {
      if (response.status !== 200) {
        // eslint-disable-next-line no-console
        console.error?.('Unexpected response', response);
        error();
      } else {
        dispatch({
          type: MERGE_ENTITY_CHANGES,
          entity: { type: 'teams', id: teamId },
          changes: { config: response.data },
        });
        dispatchAlert('Team config updated successfully.', 'success');
      }
    })
    .catch(error);
};

export const getTeamConfig = (teamId) => (dispatch, _getState, { api }) => {
  api.getTeamConfig(teamId)
    .then(({ data: config }) => dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: 'teams', id: teamId },
      changes: { config },
    }))
    .catch(() => dispatchAlert(
      'There was an error loading the team config, refresh your browser window and try again',
      'danger'
    ));
};

export const updateTeam = (teamId, team) => (dispatch, _getState, { api }) => {
  api.updateTeam(teamId, team)
    .then(({ data }) => dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: 'teams', id: teamId },
      changes: { name: data.name },
    }))
    .catch(api.errorHandler);
};

export const inviteToTeam = (teamId, email) => (dispatch, _getState, { api }) => (
  api.inviteToTeam(teamId, email)
    .then(({ data }) => dispatch({
      type: ADD_ENTITY,
      to: { type: 'teams', id: teamId },
      entity: { type: 'members', data },
    }))
    .catch((error) => {
      if (error?.response?.data?.errors?.email?.[0]?.length) {
        dispatchAlert(error.response.data.errors.email[0], 'danger');
      } else {
        dispatchAlert(error.toString(), 'danger');
      }
    })
);

export const removeFromTeam = (teamId, userId) => (dispatch, _getState, { api }) => {
  api.removeFromTeam(teamId, userId)
    .then(() => dispatch({
      type: DELETE_ENTITY,
      entity: { type: 'members', id: userId },
      from: { type: 'teams', id: teamId, key: 'members' },
    }))
    .catch(api.errorHandler);
};
