/* eslint-disable no-case-declarations */
import { denormalize } from 'normalizr';
import { entitySchema } from '../types/schema';
import { MOVE_BOARD_STORY, MOVE_STORY } from '../actions/types';
import addItemToList from '../utils/addItemToList';
import reorderList from '../utils/reorderList';
import removeItemFromList from '../utils/removeItemFromList';
import mergeReducers from '../utils/mergeReducers';
import createEntityReducer from '../utils/createEntityReducer';

export const STATE_KEY = 'columns';

export default mergeReducers(
  // Default entity reducer.
  createEntityReducer(STATE_KEY),

  // eslint-disable-next-line default-param-last
  (state = {}, action) => {
    const {
      storyId,
      from,
      to,
    } = action;

    switch (action.type) {
      case MOVE_BOARD_STORY:

        // Reordering item in column.
        if (from?.id === to.id) {
          return {
            ...state,
            [from.id]: {
              ...state?.[from.id],
              backlog_stories: from.order > -1
                ? reorderList(state?.[from.id]?.backlog_stories, from.order, to.order)
                : addItemToList(state?.[from.id]?.backlog_stories, storyId, to.order),
            },
          };
        }

        // Moving items across columns.
        const fromColumn = from?.id ? {
          [from.id]: {
            ...state?.[from.id],
            backlog_stories: from.order > -1
              ? removeItemFromList(state?.[from.id]?.backlog_stories || [], from.order)
              : state?.[from.id]?.backlog_stories,
          },
        } : null;

        return {
          ...state,
          ...fromColumn,
          [to.id]: {
            ...state?.[to.id],
            backlog_stories: addItemToList(
              state?.[to.id]?.backlog_stories || [],
              storyId,
              to.order
            ),
          },
        };

      case MOVE_STORY:

        // Columns needs to be updated if it is an active sprint
        // if it is open, add to sprint backlog.
        // if it is done, add to the done column.
        // The tricky part is this action does not have column info.
        // @see https://getscrum.app/projects/2/stories/1636
        return state;
    }

    return state;
  }
);

export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)
);
