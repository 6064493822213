import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { selectProjects } from '../../modules/project';
import { selectTeams } from '../../modules/team';
import * as actions from '../../actions';
import { PROJECT_SHAPE, TEAM_SHAPE } from '../../types/props';
import TeamFilter from './TeamFilter';
import Loading from '../Loading';
import Project from './Project';

const props = {
  projects: PropTypes.arrayOf(PropTypes.shape(PROJECT_SHAPE)),
  teams: PropTypes.arrayOf(PropTypes.shape(TEAM_SHAPE)),
  getAllProjects: PropTypes.func.isRequired,
  joinSomeChannel: PropTypes.func.isRequired,
};

function NoProjects() {
  return (
    <div className="alert alert-info" role="alert">
      <div className="bold">
        {'You don&apos;t have any projects yet! Ready to '}
        <a href="/projects/create">create one</a>
        ?
      </div>
    </div>
  );
}

function Projects({
  teams,
  projects,
  joinSomeChannel,
  getAllProjects,
}) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [activeFilter, setActiveFilter] = React.useState(false);

  React.useEffect(() => {
    // Load all projects on page load.
    getAllProjects()
      .then(() => setIsLoading(false));

    // Join organization channel.
    // @todo this should be dynamic when there is more than one org.
    joinSomeChannel('organization', 1);
  }, []);

  const filteredProjects = activeFilter
    ? projects.filter((project) => project.teams.find(({ id }) => id === activeFilter))
    : projects;

  const title = 'Projects';

  return (
    <>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <div className="container project-list">
        <div className="row my-3">
          <div className="col-6">
            <h1>{title}</h1>
          </div>
          <div className="col-6">
            <ul className="nav justify-content-end">
              <li className="nav-item">
                <a href="/projects/create" className="btn btn-primary">
                  New Project
                </a>
              </li>
            </ul>
          </div>
        </div>
        {
        isLoading ? <Loading /> : (
          <>
            {teams.length > 0 ? (
              <TeamFilter
                teams={teams}
                setFilter={setActiveFilter}
                filter={activeFilter}
              />
            ) : null}
            {
              projects ? (
                <div className="row row-cols-1 row-cols-md-3">
                  {filteredProjects.map((project) => (
                    <Project {...project} key={project.id} />
                  ))}
                </div>
              ) : (<NoProjects />)
            }
          </>
        )
      }
      </div>
    </>
  );
}

Projects.propTypes = props;

export default connect(
  (state) => ({
    projects: selectProjects(state),
    teams: selectTeams(state),
  }),
  {
    getAllProjects: actions.getAllProjects,
    joinSomeChannel: actions.joinSomeChannel,
  }
)(Projects);
