import * as React from 'react';
import ReactModal from 'react-modal';

interface ModalProps {
  children: React.ReactElement;
  onClose: () => void;
  title: string;
}

function Modal({ children, onClose, title }: ModalProps) {
  const appEl = document.getElementById('appBinding');

  return (
    <ReactModal
      isOpen
      appElement={appEl}
      ariaHideApp={(!!appEl)} // Prevent console errors when testing.
      onRequestClose={onClose}
      aria={{ labelledby: 'modal_heading' }}
    >
      <h2 id="modal_heading">{title}</h2>
      {children}
    </ReactModal>
  );
}

export default Modal;
