import React from 'react';
import {
  bool, func, node, shape, string,
} from 'prop-types';

import { Link } from 'react-router-dom';
import withInstigator from '../../../hocs/withInstigator';

const epicLabelPropTypes = {
  isModifyingEpic: bool.isRequired,
  setIsModifyingEpic: func.isRequired,
  epic: shape({
    url: string,
    title: string,
  }),
  className: string,
  children: node,
};

function EpicLabel({
  isModifyingEpic,
  setIsModifyingEpic,
  epic,
  className = '',
  children = null,
}) {
  return (
    <div className={className}>
      <div className="ancestor-label">
        <button
          className="btn badge badge-secondary"
          type="button"
          aria-label="Modify epic"
          onClick={() => setIsModifyingEpic(true)}
          disabled={isModifyingEpic}
        >
          <span className="sr-only">Edit</span>
          {epic ? 'Epic' : 'Set Epic'}
        </button>

        {epic ? (
          <Link to={epic.url} className="ml-1 truncatable">
            {epic.title}
          </Link>
        ) : null}
      </div>

      {children}
    </div>
  );
}

EpicLabel.propTypes = epicLabelPropTypes;

export default withInstigator(EpicLabel);
