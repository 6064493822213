import React from 'react';
import { node, string } from 'prop-types';
import classnames from 'classnames';

// Styles
import styles from './stats.scss';

function StatsRow({ children, className = '' }) {
  return (
    <div className={classnames(styles.row, className)}>
      {children}
    </div>
  );
}

StatsRow.propTypes = {
  children: node.isRequired,
  className: string,
};

export default StatsRow;
