import { batch } from 'react-redux';

import { ADD_ENTITY, DELETE_ENTITY, MERGE_ENTITY_CHANGES } from '../types';
import { setInstigator } from './member';

/**
 * Dispatch an action to add a task to state.
 *
 * @param {object} data - Payload from API response.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const taskAdded = (data, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch({
      type: ADD_ENTITY,
      to: { type: 'stories', id: data.story_id },
      entity: { type: 'tasks', data },
    });
    dispatch(setInstigator({ type: 'tasks', id: data.id }, instigatorId));
  });
};

/**
 * Dispatches an action to update the task in state.
 *
 * @param {object} data - Payload from API response.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const taskChanged = (data, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: 'tasks', id: data.id },
      changes: data,
    });
    dispatch(setInstigator({ type: 'tasks', id: data.id }, instigatorId));
  });
};

/**
 * Dispatches an action to delete a task from state.
 *
 * @param {number} id - The task ID.
 * @param {number} storyId - The story ID.
 */
export const taskDeleted = (id, storyId) => (dispatch) => dispatch({
  type: DELETE_ENTITY,
  entity: { type: 'tasks', id },
  from: { type: 'stories', id: storyId, key: 'tasks' },
});

/**
 * Dispatches an action to reorder tasks in state.
 *
 * @param {number} id - The story ID.
 * @param {array} tasks - All the given tasks.
 * @param {number} initiatorId - ID of the task that triggered the reordering.
 * @param {number} instigatorId - ID of user who initiated the change.
 */
export const tasksReordered = (id, tasks, initiatorId, instigatorId) => (dispatch) => {
  batch(() => {
    dispatch({
      type: MERGE_ENTITY_CHANGES,
      entity: { type: 'stories', id },
      changes: { tasks },
    });
    dispatch(setInstigator({ type: 'tasks', id: initiatorId }, instigatorId));
  });
};
