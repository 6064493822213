import React from 'react';
import ReactModal from 'react-modal';

import { Story } from '../../types/interfaces/Story';
import { BacklogTarget } from '../../types/interfaces/BacklogTarget';

import NoPoints from './NoPoints';
import HasPoints from './HasPoints';

type Props = {
  /** The action that initiated this request. */
  confirmAction(): void;
  /** Function to cancel the action. */
  closeDialog(): void;
  /** Stories to move into the new sprint */
  itemsToMove?: Story[];
  /** Title of the story to be created */
  titleOfNewStory?: string;
  /**  The ID of the destination sprint. */
  target: BacklogTarget;
}

function ModifyActiveSprintDialog({
  closeDialog,
  confirmAction,
  itemsToMove,
  titleOfNewStory,
  target,
}: Props) {
  const isCreatingNewStory = typeof titleOfNewStory !== 'undefined';
  const allItemsArePointed = (() => {
    if (typeof itemsToMove === 'undefined') {
      return false;
    }

    const itemsWithPoints = itemsToMove.filter(({ points }) => (typeof points === 'number'));
    return itemsWithPoints.length === itemsToMove.length;
  })();

  const appEl = document.getElementById('appBinding');
  return (
    <ReactModal
      isOpen
      onRequestClose={closeDialog}
      contentLabel="Confirm adding story to active sprint"
      appElement={appEl}
      ariaHideApp={(!!appEl)} // Prevent console errors when testing and no app element available.
    >
      <div className="w-100 d-flex align-items-center" style={{ height: '100%' }}>
        {(() => {
          if (
            isCreatingNewStory
            || !allItemsArePointed
          ) {
            return (
              <NoPoints
                confirmAction={confirmAction}
                closeDialog={closeDialog}
              />
            );
          }

          return (
            <HasPoints
              target={target}
              items={itemsToMove || []}
              confirmAction={confirmAction}
              closeDialog={closeDialog}
            />
          );
        })()}
      </div>
    </ReactModal>
  );
}

export default ModifyActiveSprintDialog;
