import * as React from 'react';
import { connect } from 'react-redux';
import throttle from 'lodash/throttle';

// Internal dependencies.
import { getCurrentUser } from '../../../selectors';
import { selectWhispers, whisperStoryCommenting, whisperStoryDoneCommenting } from '../../../modules/whispers';
import { Comment as ICommentObj } from '../../../types/interfaces/Comment';
import { Member as IMemberObj } from '../../../types/interfaces/Member';

// Components.
import Comment from './Comment';
import CommentEditor from './CommentEditor';

// Styles.
import styles from './Comments.scss';

interface CommentsProps {
  comments: ICommentObj[];
  addComment: ({ body: string }) => void;
  deleteComment: (id: number) => void;
  updateComment: (id: number, body: string) => void;
  storyId: number;
  currentUser: IMemberObj;
  whispers: string[];
}

function Comments({
  comments = [],
  addComment,
  deleteComment,
  updateComment,
  storyId,
  currentUser,
  whispers,
}: CommentsProps) {
  const [body, setBody] = React.useState('');

  const throttledWhisper = React.useCallback(
    throttle(
      () => whisperStoryCommenting(storyId, currentUser.id, currentUser.name),
      1500,
      { trailing: false }
    ),
    []
  );

  const handleChange = (value: string) => {
    setBody(value);
    if (value !== '') {
      throttledWhisper();
    } else {
      whisperStoryDoneCommenting(storyId, currentUser.id);
    }
  };

  const submitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    whisperStoryDoneCommenting(storyId, currentUser.id);
    addComment({ body });
    setBody('');
  };

  return (
    <div className="card comments mb-3">
      <div className="card-body">
        <h2 className="h3">Discussion</h2>
        {comments.length === 0 && (
          <p>There is no discussion on this story yet.</p>
        )}
        <ol className="comments-list">
          {comments.map((comment) => (
            <Comment
              storyId={storyId}
              comment={comment}
              key={comment.id}
              pusherActivity={comment.pusherActivity}
              currentUserId={currentUser.id}
              updateComment={updateComment}
              deleteComment={deleteComment}
            />
          ))}
        </ol>
        {whispers.length > 0 && (
          <p className="text-muted" role="alert" aria-live="polite" aria-controls="comment-form">
            {`${whispers.join(', ')} ${whispers.length > 1 ? 'are' : 'is'} typing...`}
          </p>
        )}
        <div className={`comment-form mt-3 ${styles.commentWrapper}`}>
          <form id="comment-form" onSubmit={submitHandler}>
            <div className="form-group">
              <label className="sr-only" htmlFor="new-comment-body">Comment on this story</label>
              <CommentEditor
                id="new-comment-body"
                body={body}
                updateBody={handleChange}
                storyId={storyId}
              />
            </div>
            <input
              type="submit"
              className="btn btn-primary btn-sm"
              value="Post Comment"
              disabled={body === ''}
            />
          </form>
        </div>
      </div>
    </div>
  );
}

export default connect((state, { storyId }: { storyId: number }) => ({
  whispers: selectWhispers(state, 'storyComments', storyId),
  currentUser: getCurrentUser(state),
}))(Comments);
