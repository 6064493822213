import * as React from 'react';
import PropTypes from 'prop-types';
import trunc from 'unicode-byte-truncate';

// Internal dpendencies.
import pasteAsPlainText from '../../../utils/pasteAsPlainText';
import withInstigator from '../../../hocs/withInstigator';

// Components.
import StoryTitleWarning from '../../StoryTitleWarning';

function Title({
  updateStory, title, className, children,
}) {
  const [dirtyTitle, setDirtyTitle] = React.useState(title);
  const [hasFocus, setHasFocus] = React.useState(false);

  const handleBlur = (e) => {
    e.target.textContent = trunc(e.target.textContent, 191);
    const { target: { textContent } } = e;

    setDirtyTitle(textContent);
    updateStory({ title: textContent });
    setHasFocus(false);
  };

  const handleKeyDown = (e) => e.keyCode === 13 && e.target.blur();

  const handleKeyUp = ({ target: { textContent } }) => {
    setDirtyTitle(textContent);
  };

  return (
    <div className={`story-title-wrapper ${className}`}>
      <div
        className="issueTitle h1"
        aria-label="Story title"
        contentEditable
        suppressContentEditableWarning
        // contentEditable and role="textbox" are acceptable a11y practices
        // see: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/textbox_role
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="textbox"
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        onBlur={handleBlur}
        onPaste={pasteAsPlainText}
        onFocus={() => setHasFocus(true)}
        tabIndex={0}
      >
        {title}
      </div>

      {hasFocus ? <StoryTitleWarning length={dirtyTitle.length} /> : null}

      {children}
    </div>
  );
}

Title.defaultProps = {
  children: null,
  className: '',
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  updateStory: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default withInstigator(Title);
