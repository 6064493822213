/**
 * Update a Task.
 *
 * @param {number} taskId - Task ID.
 * @param {Object} task - Task data.
 * @param {string} [task.body] - Task body.
 * @param {boolean} [task.completed] - Task completion status.
 * @returns {Promise} Promise object representing the API request.
 */
const updateTask = (taskId, task) => axios.patch(`/api/tasks/${taskId}`, task);
export default updateTask;
