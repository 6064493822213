import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// Internal dependencies.
import * as actions from '../../actions';
import { reduceBucketsToStories, selectHydrated as selectBucketData } from '../../modules/bucket';

// Components.
import Loading from '../Loading';
import StoryFilters from '../StoryFilters';
import Bucket from '../BucketList/Bucket';
import DoneBucket from '../BucketList/DoneBucket';
import LinkMaybe from '../LinkMaybe';
import SectionReport from '../SectionReport';

function Feature() {
  const [loading, setLoading] = React.useState(true);
  const dispatch = useDispatch();
  const {
    contextType,
    id: contextIdParam,
    featureId: featureIdParam,
  } = useParams() as {
    contextType: string,
    id: string,
    featureId: string,
  };
  const contextId = +(contextIdParam || 0);
  const featureId = +(featureIdParam || 0);

  // Get buckets from state.
  const {
    title = '',
    description = '',
    kickoff = null,
    buckets = [],
  } = useSelector((state) => selectBucketData(state, `features.${featureId}.all`));

  // Build an array of all stories from buckets.
  const allStories = React.useMemo(
    () => reduceBucketsToStories(buckets || []),
    [buckets]
  );

  // Join the pusher channel for the feature's context.
  React.useEffect(() => {
    if (contextType === 'projects') {
      dispatch(actions.joinProjectChannel(contextId));
    } else {
      dispatch(actions.joinTeamChannel(contextId));
    }
  }, []);

  // Load the buckets.
  React.useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(actions.getStoryBuckets('features', featureId, 'all'));
      setLoading(false);
    })();
  }, [featureId]);

  const pageTitle = `${title || 'Feature'} Stories`;

  // Assuming we have buckets, loop over them.
  return (
    <main className="container">
      {loading ? (
        <Loading />
      ) : (
        <>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>

          <div className="d-flex justify-content-between">
            <h1>{pageTitle}</h1>
          </div>

          {description ? (
            <p className="text-muted">{description}</p>
          ) : null}

          <SectionReport type="feature" allStories={allStories} startDate={kickoff} />

          {buckets.length === 0 ? (
            <div className="alert alert-primary">There are no stories to display</div>
          ) : null}

          {buckets.length ? (
            // @ts-expect-error TS doesn't understand connect().
            <StoryFilters
              allStories={allStories}
              activeViewFilter="all"
              showPrimaryFilters={false}
              showViewFilters={false}
            />
          ) : null}

          {buckets.map((contextBucket) => (
            <div key={`${contextBucket.type}.${contextBucket.id}`} className="contexts-bucket">
              {contextBucket.title ? (
                <h2>
                  {contextBucket.type ? (
                    <span className="badge badge-secondary">
                      {contextBucket.type === 'team' ? 'Team' : 'Project'}
                    </span>
                  ) : null}
                  {' '}
                  <LinkMaybe to={contextBucket.uri}>
                    {contextBucket.title}
                  </LinkMaybe>
                </h2>
              ) : null}

              {contextBucket.title === 'Done' ? (
                <DoneBucket stories={contextBucket.buckets[0].backlog_stories} />
              ) : (
                contextBucket.buckets.map((bucket) => (
                  <Bucket key={`${bucket.type}.${bucket.id}`} bucket={bucket} />
                ))
              )}
            </div>
          ))}
        </>
      )}
    </main>
  );
}

export default Feature;
