import React from 'react';
import PropTypes from 'prop-types';
import withInstigator from '../../../hocs/withInstigator';
import pasteAsPlainText from '../../../utils/pasteAsPlainText';

function Points({
  updateStory,
  children,
  startPokerGame,
  canEstimate,
  points,
  className,
}) {
  const pointClass = (points === null) ? 'points-unpointed' : `points-${points}`;
  const intOrNull = (str) => {
    const int = parseInt(str, 10);
    return Number.isNaN(int) ? null : int;
  };
  const button = canEstimate ? (
    <button
      type="button"
      className="btn btn-primary btn-block"
      onClick={startPokerGame}
    >
      {points === null ? 'Estimate' : 'Reestimate' }
    </button>
  ) : '';

  const handleFocus = (e) => {
    if (e.target.innerText === '?') {
      e.target.innerText = '';
    }
  };

  const handleBlur = (e) => {
    const newValue = intOrNull(e.target.innerHTML);
    if (newValue !== points) {
      updateStory({ points: newValue });
    }
    if (e.target.innerText === '') {
      e.target.innerText = '?';
    }
  };

  return (
    <div className={`story-points-wrapper ${className}`}>
      <h2 className="h5">Story Points</h2>
      <p
        className={`display-4 story-points ${pointClass}`}
        contentEditable={canEstimate}
        suppressContentEditableWarning
        // contentEditable and role="textbox" are acceptable a11y practices
        // see: https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/textbox_role
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
        role="textbox"
        onKeyDown={(e) => e.keyCode === 13 && e.target.blur()}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onPaste={pasteAsPlainText}
      >
        {points === null ? '?' : points}
      </p>
      {button}
      {children}
    </div>
  );
}

Points.defaultProps = {
  points: null,
  children: null,
  className: '',
};

Points.propTypes = {
  points: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  updateStory: PropTypes.func.isRequired,
  startPokerGame: PropTypes.func.isRequired,
  canEstimate: PropTypes.bool.isRequired,
};

export default withInstigator(Points);
