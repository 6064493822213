import { receiveEntity, receiveManyEntities } from '../entity';
import {
  taskAdded,
  taskChanged,
  taskDeleted,
  tasksReordered,
} from './task';
import {
  setUserPresence,
} from './member';
import { storyAdded, storyChanged, storyDeleted } from './story';
import { commentAdded, commentChanged, commentDeleted } from './comment';
import { voteCanceled, voteReceived, votingToggled } from './poker';
import {
  setCommenting,
  setDoneCommenting,
  setEditingBody,
  setDoneEditingBody,
  setAddingTask,
  setDoneAddingTask,
} from '../../modules/whispers';
import {
  boardUpdated,
} from './board';
import { PUSHER_JOIN } from '../types';
import leaveChannel from './leaveChannel';
import { storyMoved } from './backlog';
import { attachmentAdded, attachmentDeleted } from './attachment';

export default (storyId) => (dispatch, getState) => {
  const channel = `stories.${storyId}`;
  const { pusher: { [channel]: existingConnection } } = getState();

  if (!existingConnection) {
    let commentingTimeout;
    let addingTaskTimeout;

    window.Echo.join(channel)
      .here((users) => dispatch(receiveManyEntities('members', users.map((user) => ({ ...user, online: true })))))

      // Poker games.
      .listen('VotingToggled', (data) => dispatch(votingToggled(data)))
      .listen('VoteReceived', (data) => dispatch(voteReceived(data)))
      .listen('VoteCanceled', (data) => dispatch(voteCanceled(data)))

      // Tasks.
      .listen('TaskAdded', ({ task, instigator }) => dispatch(taskAdded(task, instigator)))
      .listen('TaskChanged', ({ task, instigator }) => dispatch(taskChanged(task, instigator)))
      .listen('TaskDeleted', (data) => dispatch(taskDeleted(data.task_id, data.story_id)))
      .listen('TasksReordered', ({
        story_id: id, task_ids: taskIds, initiator, instigator,
      }) => dispatch(tasksReordered(id, taskIds, initiator, instigator)))

      // Attachments.
      .listen('AttachmentAdded', ({ attachment, instigator }) => (
        dispatch(attachmentAdded(storyId, attachment, instigator))
      ))
      .listen('AttachmentDeleted', ({ attachment_id: attachmentId, instigator }) => (
        dispatch(attachmentDeleted(storyId, attachmentId, instigator))
      ))

      // Comments.
      .listen('CommentAdded', ({ comment, subject, instigator }) => dispatch(commentAdded(comment, subject, instigator)))
      .listen('CommentChanged', ({ comment, subject, instigator }) => dispatch(commentChanged(comment, subject, instigator)))
      .listen('CommentDeleted', ({ comment_id, subject }) => dispatch(commentDeleted(comment_id, subject)))
      .listenForWhisper('Commenting', (data) => {
        clearTimeout(commentingTimeout);
        dispatch(setCommenting(storyId, data));
        commentingTimeout = setTimeout(() => dispatch(setDoneCommenting(storyId, data)), 3000);
      })
      .listenForWhisper('DoneCommenting', (data) => {
        clearTimeout(commentingTimeout);
        dispatch(setDoneCommenting(storyId, data));
      })

      .listenForWhisper('EditingBody', (data) => dispatch(setEditingBody(storyId, data)))
      .listenForWhisper('DoneEditingBody', (data) => dispatch(setDoneEditingBody(storyId, data)))
      .listenForWhisper('AddingTask', (data) => {
        clearTimeout(addingTaskTimeout);
        dispatch(setAddingTask(storyId, data));
        addingTaskTimeout = setTimeout(() => dispatch(setDoneAddingTask(storyId, data)), 3000);
      })
      .listenForWhisper('DoneAddingTask', (data) => {
        clearTimeout(addingTaskTimeout);
        dispatch(setDoneAddingTask(storyId, data));
      })

      // Story data.
      .listen('StoryAdded', ({ story, instigator }) => dispatch(storyAdded(story, instigator)))
      .listen('StoryDeleted', ({ story_id, instigator }) => dispatch(storyDeleted(story_id, instigator)))
      .listen('StoryChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StorySplit', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('ContributorsChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StoryMoved', (data) => dispatch(storyMoved(data)))
      .listen('StoryEpicChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StoryEpicRemoved', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))
      .listen('StoryChildrenChanged', ({ story, instigator }) => dispatch(storyChanged(story, instigator)))

      // When a story moves across board column.
      .listen('BoardUpdated', ({
        story_id, to, order, instigator, team_id,
      }) => dispatch(boardUpdated(team_id, story_id, to, order, instigator)))

      .joining((user) => dispatch(receiveEntity('members', { ...user, online: true })))
      .leaving((user) => dispatch(setUserPresence(user.id, false)));

    dispatch({
      type: PUSHER_JOIN,
      channel,
    });
  }

  return () => dispatch(leaveChannel(channel));
};
