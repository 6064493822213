import { denormalize } from 'normalizr';
import { receiveEntity } from '../actions/entity';
import { entitySchema } from '../types';
import createEntityReducer from '../utils/createEntityReducer';

export const STATE_KEY = 'searches';

/**
 * Search reducer.
 */
export default createEntityReducer(STATE_KEY);

export const getSearch = (fragment) => (dispatch, getState, { api }) => (
  api.search(fragment)
    .then(({ data: { data: stories } }) => {
      dispatch(receiveEntity(STATE_KEY, { id: fragment, stories }));
      return stories;
    })
    .catch(api.errorHandler)
);

/**
 * Select the hydrated search from state.
 *
 * @param {Object} state - Full redux state.
 * @param {string} id - Search fragment.
 * @returns {Object}
 */
export const selectHydrated = (state, id) => (
  denormalize(id, entitySchema[STATE_KEY], state.data.entities)?.stories || []
);
