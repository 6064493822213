import { Story } from '../types/interfaces/Story';

/**
 * Given a start date and a collection of stories, attempt to calculate when the stories will all be
 * complete.
 *
 * At present, this only performs a naive calculation, but eventually, it will return a series of
 * projections (optimistic, pessimistic, trending, etc) based on rolling velocities.
 */
export default function calculateEndDate(
  startDate: number | string,
  stories: Story[]
) {
  const { total, complete } = stories.reduce((sums, story) => ({
    total: sums.total + (story.points || 0),
    complete: sums.complete + (story.completed_at ? (story.points || 0) : 0),
  }), { total: 0, complete: 0 });

  // If no stories are complete, bail.
  if (!complete) {
    return {};
  }

  const startTime = (new Date(startDate)).getTime();
  const period = Date.now() - startTime;

  // A naively calculated end date is to determine how many points have been completed since start,
  // divide that into the total number of points, and add that to the start time.
  const naiveEnd = startTime + total / (complete / period);

  return {
    naiveEnd,
  };
}
