import * as React from 'react';

// Styles
import styles from './stats.scss';

interface BigStatProps {
  stat: string | number;
  label: string;
  children?: React.ReactNode;
}

function BigStat({
  stat,
  label,
  children = null,
}: BigStatProps) {
  return (
    <dl className={styles.stat}>
      <dt>
        {children}
        {` ${stat}`}
      </dt>
      <dd>{label}</dd>
    </dl>
  );
}

export default BigStat;
