import * as React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import PointButton from './PointButton';
import UserCard from '../../UserCard';
import usersByStatus from '../../../utils/usersByStatus';
import { PokerGame } from '../../../types/interfaces/PokerGame';
import usePendingRequest from '../../../hooks/usePendingRequest';

interface GameBoardProps {
  game: PokerGame,
  castVote: (gameId: number, vote: number | null) => Promise<void>;
  cancelVote: (gameId: number, voteId: number, userId: number) => Promise<void>;
  cancelGame: (gameId: number, storyId: number) => Promise<void>;
  endVoting: (gameId: number) => Promise<void>;
}

function GameBoard({
  game,
  castVote,
  cancelVote,
  cancelGame,
  endVoting,
}: GameBoardProps) {
  // eslint-disable-next-line no-nested-ternary
  const userVote = game.user_vote !== null
    ? (game.user_vote.points === null ? '?' : game.user_vote.points)
    : null;

  const { isPending: votePending, call } = usePendingRequest();

  const vote = async (fib: '?' | number | null) => {
    if (fib === userVote) {
      call(() => cancelVote(game.id, game.user_vote.id, game.user_vote.user_id));
    } else {
      call(() => castVote(game.id, fib === '?' ? null : fib));
    }
  };

  const makeCards = (cur, next, max, stack) => (
    cur === max ? [...stack, cur] : makeCards(next, cur + next, max, [...stack, cur])
  );
  const { online, offline } = usersByStatus(game.responses.waiting);
  const cards = makeCards(1, 2, 233, ['?', 0]);
  return (
    <>
      <h3>Vote for Story Points</h3>
      <div className="clearfix mb-2">
        {cards.map(
          (fib) => (
            <PointButton
              key={fib}
              fib={fib}
              onClick={() => vote(fib)}
              isActive={userVote === fib}
              disabled={votePending}
            />
          )
        )}
      </div>
      <h4>Voters</h4>
      <div className="clearfix mb-3">
        {game.responses.voted.map((player) => <UserCard key={player.id} user={player} status="done" />)}
        {online.map((player) => <UserCard key={player.id} user={player} status="waiting" />)}
        {offline.map((player) => <UserCard key={player.id} user={player} status="offline" />)}
      </div>
      <div>
        <button type="button" className="btn btn-primary mr-2" onClick={() => endVoting(game.id)}>
          End Voting
        </button>
        <button type="button" className="btn btn-outline-danger" onClick={() => cancelGame(game.id, game.story_id)}>
          Cancel Voting
        </button>
      </div>
    </>
  );
}

export default connect(null, {
  castVote: actions.castVote,
  cancelVote: actions.cancelVote,
  cancelGame: actions.cancelGame,
  endVoting: actions.endVoting,
})(GameBoard);
