import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import uuid from 'react-uuid';
import classNames from 'classnames';
import { push } from 'connected-react-router';

import sumStories from '../../utils/sumStories';
import { BACKLOG_SHAPE } from '../../types/props';
import * as actions from '../../actions';
import {
  makeGetBacklog,
  getBacklogTargets,
  filterCurrentBacklogFromTargets,
  getCurrentUserActiveTeamId,
} from '../../selectors';
import { errorHandler } from '../../services/api';
import * as uri from '../../utils/uri';
import { LABEL_MAP } from '../../actions/types';

// Components.
import BacklogHeader from './BacklogHeader';
import FilteredBacklog from './FilteredBacklog';
import Loading from '../Loading';
import ModifyActiveSprintDialog from '../ModifyActiveSprintDialog';
import SprintList from '../SprintList';
import StickyContainer from '../StickyContainer';
import TeamBar from '../TeamBar';
import NewSprintDialog from '../NewSprintDialog';
import Breadcrumb from '../Breadcrumbs/Breadcrumb';
import Bucket from '../BucketList/Bucket';
import StoryFilters from '../StoryFilters';
import SprintReport from './SprintReport';

function BacklogScreen({
  activeTeamId,
  addSprint,
  backlog,
  backlogTargets,
  contextType,
  createStory,
  deleteEntity,
  joinSomeChannel,
  loadStories,
  match,
  moveStories,
  reorderStories,
  navigateTo,
}) {
  const sourceType = backlog.backlogType === 'projects' ? 'projects' : 'teams';
  const sourceId = backlog.team_id || backlog.id;

  // Loading state for <Loader />.
  const [loading, setLoading] = useState(true);

  // Used when needing to confirm modifying the current sprint.
  const [
    itemsToMoveToActiveSprint,
    setItemsToMoveToActiveSprint,
  ] = useState({});

  // Used when adding a story to the active sprint.
  const [
    titleToAddToActiveSprint,
    setTitleToAddToActiveSprint,
  ] = useState();

  // Used when adding a new sprint.
  const [addingNewSprint, setAddingNewSprint] = useState(false);

  // Join to the backlog channel and implicitly unsubscribe when unmounting.
  useEffect(() => {
    const unsub = joinSomeChannel(sourceType, sourceId);
    return typeof unsub === 'function' ? unsub : () => {};
  }, [sourceType, sourceId]);

  useEffect(() => {
    loadStories(backlog)
      .then(() => setLoading(false))
      .catch((error) => {
        if (error?.response?.status === 404) {
          dispatchAlert(`${LABEL_MAP[contextType]} does not exist.`, 'danger');

          if (contextType === 'projects') {
            navigateTo(uri.projects());
          } else {
            // Navigate to the user's current team board.
            navigateTo(uri.board(activeTeamId));
          }
        } else {
          errorHandler(error);
        }
      });
  }, [backlog.backlogId, backlog.backlogType, contextType]);

  /**
   * Given a title, dispatches the create story action.
   *
   * @param {string} title - Title of story to create.
   */
  const finishAddStory = (title) => {
    // Bail early if conditions not met.
    if (title === '') {
      return;
    }

    const payload = backlog.backlogType === 'sprints'
      ? { title, sprint_id: backlog.id }
      : { title };
    const context = { type: backlog.backlogType, id: backlog.id };

    createStory(sourceType, sourceId, payload, context);
  };

  /**
   * Callback when a new story is created. Checks if the sprint is active or not.
   *
   * @param {string} title - Title of the story to create.
   */
  const addStoryHandler = (title) => {
    if (backlog.is_active) {
      setTitleToAddToActiveSprint(title);
    } else {
      finishAddStory(title);
    }
  };

  /**
   * Confirmation action to add a new story.
   */
  const confirmAddStory = () => {
    finishAddStory(titleToAddToActiveSprint);
    setTitleToAddToActiveSprint('');
  };

  // Add a sprint.
  const addSprintHandler = (data) => addSprint(activeTeamId, data)
    .then(() => setAddingNewSprint(false));

  // move a story within the backlog
  const reorderStoriesHandler = (storyId, operation, oldIndex, newIndex) => (
    reorderStories(storyId, operation, oldIndex, newIndex, backlog)
  );

  /**
   * Callback when items are dragged to a sprint target. If active, open modal.
   *
   * @todo this should be pulled out into a hook and refactored for future use.
   *
   * @param {Story[]} droppedItems - The items to move.
   * @param {BacklogTarget} target - The target backlog to receive items.
   */
  const moveStoriesHandler = (droppedItems, target) => {
    if (droppedItems.length > 0) {
      if (target.isActive) {
        setItemsToMoveToActiveSprint({ droppedItems, target });
      } else {
        // Proceed without confirmation.
        moveStories(droppedItems, target.type, target.id, backlog);
      }
    }
  };

  /**
   * Confirmation action to move a story.
   */
  const confirmMoveStories = () => {
    const {
      droppedItems,
      target: {
        id: toId,
        type: toType,
      },
    } = itemsToMoveToActiveSprint;
    if (droppedItems && toType && toId) {
      moveStories(droppedItems, toType, toId, backlog);
    }
    setItemsToMoveToActiveSprint({});
  };

  const targets = backlogTargets.map(({ title, backlogs }) => (
    <div className="target-group" key={uuid()}>
      {!!title && (
        <h3 className="h2">
          <span className="text-primary" title="Current team targets">
            <span className="sr-only">Current team targets</span>
            {/* @todo either new icon or iterate on how to make current team. */}
          </span>
          {title}
        </h3>
      )}
      <SprintList
        targets={backlogs}
        moveStoriesHandler={moveStoriesHandler}
      />
    </div>
  ));

  const pointsTotal = sumStories(backlog.stories);

  return (
    <>
      <Helmet>
        <title>
          {/* @todo move logic from backlogheader to here */}
          {backlog.title || backlog.name}
        </title>
      </Helmet>
      <div className="sidebar-bg">
        {Object.keys(itemsToMoveToActiveSprint).length > 0 && (
          <ModifyActiveSprintDialog
            closeDialog={() => setItemsToMoveToActiveSprint({})}
            confirmAction={confirmMoveStories}
            itemsToMove={itemsToMoveToActiveSprint.droppedItems}
            target={itemsToMoveToActiveSprint.target}
          />
        )}
        {titleToAddToActiveSprint && titleToAddToActiveSprint !== '' && (
          <ModifyActiveSprintDialog
            closeDialog={() => setTitleToAddToActiveSprint('')}
            confirmAction={confirmAddStory}
            target={{
              id: backlog.id,
              isActive: true,
              title: backlog.title,
              total: backlog.total_points,
              type: backlog.backlogType,
            }}
            titleOfNewStory={titleToAddToActiveSprint}
          />
        )}
        {addingNewSprint && (
          <NewSprintDialog
            closeDialog={() => setAddingNewSprint(false)}
            addSprint={addSprintHandler}
            teamId={activeTeamId}
          />
        )}
        <main className="container backlog-screen">
          {loading ? (
            <Loading />
          ) : (
            <div className={classNames('row', `context-${contextType}`)}>
              <div className="col-md-9 py-3">
                <Breadcrumb match={match} container={false} />

                <BacklogHeader backlog={backlog} deleteEntity={deleteEntity} />

                {backlog.backlogType === 'sprints' ? (
                  <SprintReport sprint={backlog} />
                ) : null}

                <h4 className="mt-3">
                  <span className="badge badge-secondary">{pointsTotal}</span>
                  {' '}
                  {backlog.backlog_title || backlog.title}
                </h4>

                {backlog.is_active && backlog.columns ? (
                  <>
                    <StoryFilters allStories={backlog.stories} />
                    {backlog.columns.map((bucket) => (
                      <Bucket key={bucket.id} bucket={bucket} />
                    ))}
                  </>
                ) : (
                  <FilteredBacklog
                    stories={backlog.stories}
                    reorderStoriesHandler={reorderStoriesHandler}
                    backlogType={backlog.backlogType}
                    backlogId={Number(backlog.backlogId)}
                    addStoryHandler={addStoryHandler}
                  />
                )}
              </div>
              <div className="team-sidebar">
                <TeamBar miniBoardButton={false} />

                <StickyContainer>
                  {targets}

                  <button type="button" className="btn btn-primary my-3 mx-auto d-flex" onClick={() => setAddingNewSprint(true)}>
                    Add Sprint
                  </button>
                </StickyContainer>
              </div>
            </div>
          )}
        </main>
      </div>
    </>
  );
}

BacklogScreen.propTypes = {
  activeTeamId: PropTypes.number.isRequired,
  addSprint: PropTypes.func.isRequired,
  backlog: PropTypes.shape(BACKLOG_SHAPE).isRequired,
  backlogTargets: PropTypes.arrayOf(PropTypes.object).isRequired,
  contextType: PropTypes.string,
  createStory: PropTypes.func.isRequired,
  deleteEntity: PropTypes.func.isRequired,
  joinSomeChannel: PropTypes.func.isRequired,
  loadStories: PropTypes.func.isRequired,
  match: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    params: PropTypes.object,
    path: PropTypes.string,
  }).isRequired,
  moveStories: PropTypes.func.isRequired,
  reorderStories: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
};

BacklogScreen.defaultProps = {
  contextType: '',
};

export default connect(
  (state, ownProps) => {
    const allBacklogTargets = getBacklogTargets(state);
    const backlogTargets = filterCurrentBacklogFromTargets(
      allBacklogTargets,
      ownProps?.match?.params
    );
    const getBacklog = makeGetBacklog(ownProps.match.params);
    return {
      backlog: getBacklog(state),
      backlogTargets,
      contextType: ownProps?.match?.params?.contextType,
      activeTeamId: getCurrentUserActiveTeamId(state),
    };
  },
  {
    addSprint: actions.addSprint,
    createStory: actions.createStory,
    deleteEntity: actions.deleteEntity,
    loadStories: actions.loadStories,
    moveStories: actions.moveStories,
    reorderStories: actions.reorderStories,
    joinSomeChannel: actions.joinSomeChannel,
    navigateTo: push,
  }
)(BacklogScreen);
