import * as React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { TEAM_SHAPE } from '../../types/props';

const props = {
  teams: PropTypes.arrayOf(PropTypes.shape(TEAM_SHAPE)),
  filter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  setFilter: PropTypes.func.isRequired,
};

function TeamFilter({
  teams,
  filter,
  setFilter,
}) {
  return (
    <div className="backlog-filters">
      <div className="dropdown mr-2">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="team-filter"
          data-toggle="dropdown"
          aria-haspopup
          aria-expanded="false"
        >
          Filter by team
        </button>
        <ul className="dropdown-menu" aria-labelledby="team-filter">
          {teams.map((team) => (
            <li key={team.id}>
              <button
                onClick={() => { setFilter(team.id); }}
                type="button"
                className={classNames('dropdown-item', { active: filter === team.id })}
              >
                {team.name}
              </button>
            </li>
          ))}
          {filter ? (
            <li>
              <button
                onClick={() => setFilter(false)}
                type="button"
                className="dropdown-item"
              >
                Clear filter
              </button>
            </li>
          ) : null}
        </ul>
      </div>
    </div>
  );
}

TeamFilter.propTypes = props;

export default TeamFilter;
