/**
 * Reorder Tasks.
 *
 * @param {number} storyId - Story ID.
 * @param {Object[]} tasks - Data to reorder tasks.
 * @param {number} tasks[].id - Task ID.
 * @param {number} tasks[].index - Updated Task order.
 * @param {number} initiator - User ID.
 * @returns {Promise} Promise object representing the API request.
 */
const reorderTasks = (storyId, tasks, initiator) => axios.put(`/api/stories/${storyId}/tasks`, { tasks, initiator });
export default reorderTasks;
