import React from 'react';
import { string } from 'prop-types';
import { formatDistance, format } from 'date-fns';

function RelativeTime({ dateTime }) {
  const dateObj = new Date(dateTime);
  return (
    <time dateTime={dateTime} title={format(dateObj, 'PPpp')}>
      {formatDistance(dateObj, new Date())}
      {' ago'}
    </time>
  );
}

RelativeTime.propTypes = {
  dateTime: string.isRequired,
};

export default RelativeTime;
