import * as React from 'react';

import { PokerGame as PokerGameShape } from '../../../types/interfaces/PokerGame';

import GameBoard from './GameBoard';
import Results from './Results';

interface PokerGameProps {
  game: PokerGameShape;
}

function PokerGame({ game }: PokerGameProps) {
  if (!game) {
    return '';
  }

  return (
    <div className="card mb-3">
      <div className="card-body poker-game">
        {game.is_open ? (
          <GameBoard game={game} />
        ) : (
          <Results game={game} />
        )}
      </div>
    </div>
  );
}

export default PokerGame;
