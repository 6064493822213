import {
  ADD_ENTITY,
  DELETE_ENTITY,
  INITIALIZE_APP_DATA,
  MERGE_ENTITY_CHANGES,
  RECEIVE_ENTITY,
  SET_ENTITY_ORDER,
  UPDATE_ENTITY,
  SET_ENTITY,
  UNSET_ENTITY,
} from '../actions/types';
import removeEntity from './removeEntity';
import reorderEntityIds from './reorderEntityIds';
import removeEntityId from './removeEntityId';
import unsetEntityId from './unsetEntityId';
import setEntityId from './setEntityId';
import appendEntityId from './appendEntityId';
import mergeManyEntityChanges from './mergeManyEntityChanges';
import mergeEntityChanges from './mergeEntityChanges';
import storeEntity from './storeEntity';

/**
 * Create an entity reducer for shared/common entity actions.
 *
 * @param {string} entityPlural - The plural form of the entities, e.g. "teams".
 * @return {function} Reducer.
 */
export default (entityPlural) => (state = {}, action = {}) => {
  switch (action.type) {
    case INITIALIZE_APP_DATA:
      return { ...action.data.entities[entityPlural] };

    case RECEIVE_ENTITY:
      if (undefined !== action.data.entities[entityPlural]) {
        return mergeManyEntityChanges(state, action.data.entities[entityPlural]);
      }
      return state;

    case SET_ENTITY_ORDER:
      if (
        action.target.backlogType === entityPlural
        || action.target.contextType === entityPlural
      ) {
        return reorderEntityIds(state, action.order, action.target.id, action.key);
      }
      return state;

    case ADD_ENTITY:
      if (action.to && entityPlural === action.to.type) {
        const key = action.to.key || action.entity.type;
        return appendEntityId(state, action.entity.data.id, action.to.id, key);
      }
      if (action.set && entityPlural === action.set.type) {
        const key = action.set.key || action.entity.type;
        return setEntityId(state, action.entity.data.id, action.set.id, key);
      }
      if (entityPlural === action.entity.type) {
        return storeEntity(state, action.entity.data);
      }
      return state;

    case UPDATE_ENTITY:
      if (entityPlural === action.entity.type) {
        return storeEntity(state, action.entity.data);
      }
      return state;

    case DELETE_ENTITY:
      if (action.from && entityPlural === action.from.type) {
        const key = action.from.key || action.entity.type;
        return removeEntityId(state, action.entity.id, action.from.id, key);
      }
      if (action.unset && entityPlural === action.unset.type) {
        const key = action.unset.key || action.entity.type;
        return unsetEntityId(state, action.entity.id, action.unset.id, key);
      }
      if (entityPlural === action.entity.type) {
        return removeEntity(state, action.entity.id);
      }
      return state;

    case MERGE_ENTITY_CHANGES:
      if (entityPlural === action.entity.type) {
        return mergeEntityChanges(state, action.entity.id, action.changes);
      }
      return state;

    case SET_ENTITY:
      if (action.set && entityPlural === action.set.type) {
        const key = action.set.key || action.entity.type;
        return setEntityId(state, action.entity.id, action.set.id, key);
      }
      return state;

    case UNSET_ENTITY:
      if (action.unset && entityPlural === action.unset.type) {
        const key = action.unset.key || action.entity.type;
        // Passing the ID in an array assumes the entity is part of an array.
        if (Array.isArray(action.entity.id)) {
          return removeEntityId(state, action.entity.id[0], action.unset.id, key);
        }
        return unsetEntityId(state, action.entity.id, action.unset.id, key);
      }
      return state;
  }
  return state;
};
